<template>
  <tr class="diagnostic-form">
    <slot name="actions" />
    <td class="color">
      <div class="d-flex justify-content-center align-items-center">
        <b-form-group
          class="color-picker-group">
          <app-color-picker
            theme="light"
            :readonly="disable"
            :color="diagnostic.color"
            :state="validateState('diagnostic.color')"
            @close="touch('diagnostic.color')"
            @changeColor="onColorInput">
            <template
              slot="color-display"
              slot-scope="props">
              <app-well :well="makeWellWithColor(props.color.hex)" />
            </template>
          </app-color-picker>
          <b-form-invalid-feedback
            v-if="invalid('diagnostic.color', 'unique')"
            class="text-center">
            {{ $t('common.validation.unique', [ $t('formDiagnosticColor') ]) }}
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
    </td>
    <td
      v-if="!isControl"
      class="validity-type">
      <div class="d-flex justify-content-center align-items-center">
        <b-form-group class="d-flex">
          <b-form-checkbox
            v-model="valid"
            name="validity-type"
            :disabled="readonly" />
        </b-form-group>
      </div>
    </td>
    <td
      v-if="isControl"
      class="control-bar-code">
      <b-form-group>
        <b-input
          :value="diagnostic.control.barCode"
          :readonly="readonly"
          :state="validateState('diagnostic.control.barCode')"
          @blur="touch('diagnostic.control.barCode')"
          @input="onBarCodeInput" />

        <b-form-invalid-feedback v-if="invalid('diagnostic.control.barCode', 'unique')">
          {{ $t('common.validation.unique', [ $t('formControlBarCode') ]) }}
        </b-form-invalid-feedback>
      </b-form-group>
    </td>
    <td
      v-if="isControl"
      class="control-sample-type">
      <b-form-group>
        <app-sample-type-dropdown
          ref="sampleTypeDropdown"
          :overriding-options="sampleTypes"
          :disabled="disable"
          :value="controlSampleType"
          :state="validateState('diagnostic.control.sampleType')"
          @close="touch('diagnostic.control.sampleType')"
          @input="onSampleTypeInput" />

        <b-form-invalid-feedback v-if="invalid('diagnostic.control.sampleType', 'required')">
          {{ $t('common.validation.required', [ $t('formControlSampleType') ]) }}
        </b-form-invalid-feedback>
        <b-form-invalid-feedback v-if="invalid('diagnostic.control.sampleType', 'unique')">
          {{ $t('common.validation.unique', [ $t('formControlSampleType') ]) }}
        </b-form-invalid-feedback>
      </b-form-group>
    </td>
    <td class="label">
      <b-form-group>
        <b-input
          :value="label"
          :readonly="disable"
          :state="validateState('label')"
          @blur="touch('label')"
          @input="onLabelInput" />

        <b-form-invalid-feedback v-if="invalid('label', 'required')">
          {{ $t('common.validation.required', [ $t('formDiagnosticLabel') ]) }}
        </b-form-invalid-feedback>
        <b-form-invalid-feedback v-if="invalid('label', 'unique')">
          {{ $t('common.validation.unique', [ $t('formDiagnosticLabel') ]) }}
        </b-form-invalid-feedback>
      </b-form-group>
    </td>
    <td class="conditions">
      <div class="diagnostic-conditions">
        <template v-for="(condition, index) in diagnostic.conditions">
          <app-diagnostic-condition-form
            :key="`condition-${index}`"
            :ref="`condition${index}`"
            :diagnostic-type="diagnosticType"
            :condition="condition"
            :readonly="disable"
            @change="onConditionChange($event, index)" />

          <div
            :key="`condition-separator-${index}`"
            class="condition-separator">
            <span
              :class="{ invisible: index === diagnostic.conditions.length-1 }"
              class="separator-line" />
            <span
              class="separator-actions"
              :style="`left: ${disable?-30:-45}px`">
              <template v-if="!disable">
                <b-button
                  :class="{ invisible: diagnostic.conditions.length===1 }"
                  variant="outline"
                  class="btn--no-outline btn-icon btn-icon-minus btn-remove"
                  @mouseenter="setRemoving(index, true)"
                  @mouseleave="setRemoving(index, false)"
                  @click="removeCondition(condition, index)">
                  <font-awesome-icon :icon="['fas', 'minus-circle']" />
                </b-button>
              </template>
              <span
                class="condition-join"
                :class="{ invisible: disable && index === diagnostic.conditions.length-1 }">
                OR
              </span>
              <template v-if="!disable">
                <b-button
                  :class="{ invisible: index !== diagnostic.conditions.length-1 }"
                  variant="outline"
                  class="btn--no-outline btn-icon btn-icon-plus btn-add"
                  @click="addCondition">
                  <font-awesome-icon :icon="['fas', 'plus-circle']" />
                </b-button>
              </template>
            </span>
          </div>
        </template>
      </div>
    </td>
  </tr>
</template>

<script>
import SecurityMixin from '@/mixins/SecurityMixin';
import ValidationMixin from '@/mixins/ValidationMixin';
import ColorPicker from '@/components/common/color-picker/ColorPicker.vue';
import SampleTypeDropdown from '@/components/common/SampleTypeDropdown.vue';
import Well from '@/components/run/editor/plate/Well.vue';
import Diagnostic from '@/models/Diagnostic';
import {default as WellModel} from '@/models/Well';
import DiagnosticConditionForm from './DiagnosticConditionForm.vue';
import {helpers, minLength, not, required, requiredIf} from '@vuelidate/validators/dist/index.cjs';
import {includedIn} from '@/validations/includedIn';

export default {
  name: 'DiagnosticForm',
  components: {
    'app-color-picker': ColorPicker,
    'app-well': Well,
    'app-sample-type-dropdown': SampleTypeDropdown,
    'app-diagnostic-condition-form': DiagnosticConditionForm
  },
  mixins: [
    SecurityMixin,
    ValidationMixin
  ],
  props: {
    diagnostic: {
      type: Object,
      default: undefined
    },
    sampleTypes: {
      type: Array,
      default: undefined
    },
    alreadyUsedLabels: {
      type: Array,
      default: undefined
    },
    alreadyUsedBarCodes: {
      type: Array,
      default: undefined
    },
    alreadyUsedSampleTypes: {
      type: Array,
      default: undefined
    },
    alreadyUsedColors: {
      type: Array,
      default: undefined
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    disable() {
      return this.readonly ? this.readonly : (this.isDoctor && !this.isAdmin) && this.isControl && !this.isCustomControl;
    },
    isDoctor() {
      return this.hasRole('WRITE_DIAGNOSTIC');
    },
    isAdmin() {
      return this.hasRole('ADMIN');
    },
    isControl() {
      return !!this.diagnostic?.control;
    },
    isCustomControl() {
      return this.isControl && this.diagnostic.control.controlType !== 'KIT';
    },
    diagnosticType() {
      return this.isControl ? 'CONTROL' : 'RESULT';
    },
    label() {
      return this.isControl ? this.diagnostic.control.label : this.diagnostic?.label;
    },
    controlSampleType() {
      return this.isControl ? this.sampleTypes.find(o => o.type === this.diagnostic.control.sampleType) : null;
    },
    valid: {
      get() {
        if (!this.diagnostic) {
          return undefined;
        }

        return this.diagnostic.validityType === 'VALID';
      },
      set(checked) {
        this._changeDiagnostic(diagnostic => {
          if (typeof checked !== 'boolean') {
            diagnostic.validityType = null;
          } else {
            diagnostic.validityType = checked ? 'VALID' : 'INVALID';
          }
        });
      }
    }
  },
  validations() {
    return {
      label: {
        required: requiredIf(() => !this.isControl || this.isCustomControl),
        unique: helpers.withMessage(this.$t(''), not(includedIn(this.alreadyUsedLabels)))
      },
      diagnostic: {
        color: {
          required,
          unique: helpers.withMessage(this.$t('common.validation.unique', [ this.$t('formDiagnosticColor') ]),
            not(value => includedIn(this.alreadyUsedColors)(value.toLowerCase()))
          )
        },
        validityType: {
          required
        },
        control: {
          sampleType: {
            requiredIf: requiredIf(() => this.isControl),
            unique: helpers.withMessage(
              this.$t('common.validation.unique', [ this.$t('formControlSampleType') ]),
              not(includedIn(this.alreadyUsedSampleTypes))
            )
          },
          barCode: {
            unique: helpers.withMessage(this.$t('common.validation.unique', [ this.$t('formControlBarCode') ]),
              not(includedIn(this.alreadyUsedBarCodes)))
          }
        },
        conditions: {
          required,
          minLength: minLength(1)
        }
      }
    };
  },
  methods: {
    onColorInput(event) {
      this._changeDiagnostic(diagnostic => {
        diagnostic.color = event.hex.toLowerCase();
      });
    },
    onBarCodeInput(event) {
      this._changeDiagnostic(diagnostic => {
        diagnostic.control.barCode = event;
      });
    },
    onSampleTypeInput(event) {
      this._changeDiagnostic(diagnostic => {
        diagnostic.control.sampleType = event?.type;
      });
    },
    onLabelInput(event) {
      this._changeDiagnostic(diagnostic => {
        if (this.isControl) {
          diagnostic.control.label = event;
        } else {
          diagnostic.label = event;
        }
      });
    },
    addCondition() {
      this._changeDiagnostic(diagnostic => {
        diagnostic.conditions.push(Diagnostic.singleCondition());
      });
    },
    onConditionChange(event, index) {
      this._changeDiagnostic(diagnostic => {
        diagnostic.conditions[index] = event;
      });
    },
    setRemoving(index, removing) {
      const cssClasses = this.$refs[`condition${index}`][0].$el.classList;
      if (removing) {
        cssClasses.add('removing');
      } else {
        cssClasses.remove('removing');
      }
    },
    removeCondition(condition, index) {
      index = index ?? this.diagnostic.conditions.find(d => d === condition);
      if (!isFinite(index)) {
        return;
      }
      this._changeDiagnostic(diagnostic => {
        diagnostic.conditions.splice(index, 1);
      });
    },
    makeWellWithColor(color) {
      return WellModel.parse({ analyseWell: true, diagnostic: { result: { color } } });
    },
    _changeDiagnostic(applyModification) {
      const diagnostic = Diagnostic.parse(this.diagnostic);
      applyModification(diagnostic);
      this.$emit('change', diagnostic);
    }
  }
};
</script>