export default {
  'en-GB': {
    app: {
      navbar: {
        title: 'GeneFoxCub-SMA',
        logoutButtonText: 'Log out'
      },
      sidebar: {
        runButtonText: 'Runs',
        aboutButtonText: 'About',
        configurationButtonText: 'Configuration',
        laboratoriesButtonText: 'Laboratories',
        laboratoryButtonText: 'Laboratory',
        reindex: 'Reindex data'
      }
    },

    common: {
      add: 'Add',
      cannot_save: 'Cannot save',
      delete: 'Delete',
      no: 'No',
      yes: 'Yes',
      reset: 'Reset',
      back: 'Back',
      loading: 'Loading...',
      ok: 'Ok',
      saveChanges: 'Save changes',
      cancel: 'Cancel',
      configure: 'Configure',
      validation: {
        required: '{0} is required',
        unique: '{0} must be unique'
      },
      exitRun: 'Exit run'
    },

    language: 'English',

    //Common run import
    titleNewRun: 'Start New Run',

    headerRun: 'Runs',
    headerConfigurations: 'Configurations',
    noDataFound: 'No data found',
    import: 'Import',
    headerSearch: 'Search',

    //Update Status Modal
    modalSubmitResultsTitle: 'Send result to the physician',
    modalSubmitResultsMessage: 'Do you wish to submit results?',
    modalApproveResultsMessage: 'Do you want to approve the results of this run?',
    modalRejectResultsMessage: 'Do you want to reject the results of this run?',
    errorUpdateStatusTitle: 'An error occurred during the status update',
    submitResults: 'submit results',
    approveResults: 'approve results',
    rejectResults: 'reject results',

    //New Run import page
    formLoadFile: 'Choose a file or drop it here',
    formLoadPlateFile: 'Select/drop a file',
    formDropFile: 'Drop file here',
    subTitleImportPage: 'Import file to start a new run',
    descriptionSubTitleImportPage: 'Select the analyzer and the run export file(s) in order to start importing run results.',
    importRunSelectFile: 'Select file to import',
    importRunSelectFiles: 'Select file(s) to import',
    importRunSelectAnalyser: 'Select an analyzer',
    importRunSelectPlatePlan: 'Select plate plan file',

    //New Run parameters page
    formAnalyser: 'Analyzer',
    formPlateId: 'Plate ID',
    formRunDate: 'Run Date',
    formKit: 'Kit',
    formKitName: 'Name in application',
    formKitStatus: 'Status',
    formReactionBuffer: 'Reaction Buffer',
    formChannel: 'Channel',
    formPhase: 'Phase',
    formLot: 'Lot',
    formLots: 'Lots',
    formLotExpirationDate: 'Expiration date',
    formRunBy: 'Run by: ',
    formSubmittedBy: 'Submitted by: ',
    formInterpretationRunBy: 'Interpreted by: ',
    formMinThreshold: 'Min threshold',
    formMinQuality: 'Min quality',
    formMinTemp: 'Min temp',
    formMaxTemp: 'Max temp',
    formLabel: 'Label',
    formData: 'Data',
    formZone: 'Zone',
    formSelectKit: 'Select a kit',
    formSelectReactionBuffer: 'Select a reaction buffer',
    formSelectLot: 'Select a lot',
    formSelectAnalyser: 'Select an analyzer',
    formSelectPlatePlan: 'Select a plate plane type',
    formSelectStatus: 'Select a status',
    formSelectChannel: 'Select a channel',
    formSelectPhase: 'Select a phase',
    formSelectZone: 'Select a zone',
    formSelectValue: 'Select a value',
    subTitlePageRun: 'Select the parameters of the new run',
    formApprovedBy: 'Approved by: ',
    formRejectedBy: 'Rejected by: ',
    successAddingLot: 'Lot has been correctly created',
    errorAddingLot: 'An error occurred while creating lot',
    errorNoLotName: 'No lot name written',
    errorNoKitSelected: 'No kit selected',
    errorTitleAddingLot: 'Can\'t add lot',
    errorTitleParametersMissing: 'Bad well configuration',
    errorMessageWellPosition: 'Well at position {0} is configured with invalid {1}',


    // Kit versions page
    tableHeaderOfficialVersion: 'Official setting version',
    tableHeaderLaboratoryVersion: 'Laboratory version',
    tableHeaderVersionAnalysers: 'Analyzers',
    tableHeaderVersionLots: 'Lots',
    tableHeaderVersionActions: 'Actions',
    modalTitleNewKit: 'New kit',
    buttonAddOfficialVersion: 'Add new version',
    modalTitleNewKitVersion: 'New version for kit {0}',
    tableEmptyRowLaboratoryVersions: 'No laboratory versions found',
    tableEmptyRowOwnLaboratoryVersions: 'No versions found for this laboratory',
    tableEmptyRowCustomizeVersionLabel: 'Add first version for this laboratory',


    // Kit configuration page
    formKitVersion: 'Version',
    formKitReference: 'Reference name in reports',
    formKitVersionLaboratory: 'Laboratory',
    formKitVersionCriteriaLot: 'Search by lot',
    formKitVersionCriteriaAnalyser: 'Search by analyzer',
    formKitVersionCriteriaVersion: 'Search by version',
    formKitVersionCriteriaLaboratoryVersion: 'Search by laboratory and/or version',
    formKitVersionSearch: 'Find current version',
    formThresholds: 'Thresholds',
    formSelectLaboratory: 'Select a laboratory',
    formSelectVersion: 'Select a version',
    formKitSampleInterpretation: 'Sample interpretation',
    formKitZentechControls: 'ZenTech controls',
    formLaboratoryControls: 'Laboratory controls',
    formDiagnosticColor: 'Color',
    formValidityType: 'Valid',
    formControlBarCode: 'Bar code',
    formControlSampleType: 'Control',
    formDiagnosticLabel: 'Label',
    formDiagnosticConditions: 'Conditions',

    // Lot configuration page
    formLotActiveLabel: 'Active',
    formLotNameLabel: 'Lot',
    formLotExpirationDateLabel: 'Expiration date',
    formLotKitVersionLabel: 'Current setting version',
    lotVersionsTimeline: 'Timeline',
    lotModification: 'Modification:',
    lotDescriptionModification: 'has been modified by {0} | have been modified by {0}',
    lotDescriptionCreation: 'Has been created by {0}',
    lotTimeLineErrorTitle: 'An error occurred during the timeline loading.',

    //Diagnostic Variable
    RFU_FAM_DIVBY_HEX: 'RFU FAM/HEX',
    CQ_FAM: 'CQ FAM',
    CQ_HEX: 'CQ HEX',

    //Diagnostic Operator
    EQUALS: '=',
    GREATER_THAN: '>',
    GREATER_THAN_OR_EQUAL: '>=',
    LOWER_THAN: '<',
    LOWER_THAN_OR_EQUAL: '<=',

    //SampleType
    dropdownMultiple: 'Multiple',
    SAMPLE: 'Sample',
    NEGATIVE_AMPLIFICATION_CONTROL: 'Negative amplification control',
    NEGATIVE_EXTRACTION_CONTROL: 'Negative extraction control',
    POSITIVE_CONTROL: 'Positive control',
    BLANK_CONTROL: 'Blank control',
    INVALID: 'Invalid',
    VALID: 'Valid',

    //List
    listRunId: 'Run Id',
    listInstrumentName: 'Instrument Name',
    listKitReactionBufferPairs: 'Kit (Reaction Buffer)',
    listTechnician: 'Operator',
    listImportDate: 'Import Date',
    listRunStatus: 'Run Status',
    listCreatedDate: 'Run Date',
    listWellName: 'Name',
    listWellPositions: 'Positions',
    listWellSampleType: 'Sample Type',
    listWellKit: 'Kit',
    listeFileName: 'File Name',

    //Status
    RUN_TO_INTERPRET: 'Run to interpret',
    WAIT_FOR_APPROVAL: 'Wait for approval',
    APPROVED: 'Approved',
    REJECTED: 'Rejected',

    //Run comment
    runCommentTitle: 'Run comment',

    //Well caption
    captionTitle: 'Caption',
    captionAnalysedWell: 'No sample type selected',
    sampleInitial: 'SMP',
    captionSampleWell: 'Sample',
    captionControlWell: 'Control',
    captionPositiveControlWell: 'Positive Control',
    captionNegativeControl1Well: 'Negative amplification control',
    captionNegativeControl2Well: 'Negative extraction control',
    captionValidPositiveTestWell: 'Valid Positive Control',
    captionBadPositiveTestWell: 'Invalid Positive Control',
    captionValidNegativeTestWell: 'Valid Negative Control',
    captionBadNegativeTestWell: 'Invalid Negative Control',
    captionNonAnalysedWell: 'Not Analyzed',
    captionNegativeWell: 'Negative',
    captionHealthyWell: 'Negative Detection',
    captionUnhealthyWell: 'Positive Detection',
    captionAbnormalWell: 'Abnormal',
    captionHomozygousWildTypeWell: 'Homozygous Wild Type',
    captionHomozygousMutatedWell: 'Homozygous Mutated',
    captionHomozygousMutated13910Well: 'Homo Mutated -13910',
    captionHomozygousMutated13915Well: 'Homo Mutated -13915',
    captionHomozygousMutated14009Well: 'Homo Mutated -14009',
    captionHomozygousMutated14010Well: 'Homo Mutated -14010',
    captionHeterozygousWell: 'Heterozygous',
    captionHeterozygous13910Well: 'Heterozygous -13910',
    captionHeterozygous13915Well: 'Heterozygous -13915',
    captionHeterozygous13910_13915Well: 'Hetero -13910 -13915',
    captionHeterozygous14009Well: 'Heterozygous -14009',
    captionHeterozygous14010Well: 'Heterozygous -14010',
    captionHeterozygous14009_14010Well: 'Hetero -14009 -14010',
    captionValid: 'Valid {0}',
    captionInvalid: 'Invalid {0}',

    templateTitle: 'Template',
    buttonSaveTemplate: 'Save as template',
    buttonLoadTemplate: 'Load template',
    wellNotCorrectlyConfiguredTitle: 'Well not correctly configured',
    wellNotCorrectlyConfiguredBody: 'The following well is not fully configured',
    plateTemplateCreatedTitle: 'Template creation success',
    plateTemplateCreatedBody: 'Template has been successfully created',
    plateTemplateCreatedErrorTitle: 'Template creation error',
    runWellMetadataDropdown: 'Select an option',
    runWellMetadataLoadPlateTemplateTitle: 'Load plate template',
    runWellMetadataConfigurePlateTitle: 'Configure the selected wells',
    runWellMetadataConfigurePlateTemplateTitle: 'Configure the wells template',

    // Suggested result
    suggestedResultHEALTHY_SAMPLE: 'Healthy',
    suggestedResultAFFECTED_SAMPLE: 'Affected',
    suggestedResultTEST_OK: 'OK',
    suggestedResultTEST_KO: 'NOT OK',

    // Diagnostics
    HEALTHY: 'Negative detection',
    SICK: 'Positive detection',
    ABNORMAL: 'Abnormal',
    NEGATIVE: 'Negative',
    HOMOZYGOUS_WILD_TYPE: 'Homozygous Wild Type',
    HOMOZYGOUS_MUTATED: 'Homozygous Mutated',
    HOMOZYGOUS_MUTATED_13910: 'Homo Mutated -13910',
    HOMOZYGOUS_MUTATED_13915: 'Homo Mutated -13915',
    HOMOZYGOUS_MUTATED_14009: 'Homo Mutated -14009',
    HOMOZYGOUS_MUTATED_14010: 'Homo Mutated -14010',
    HETEROZYGOUS: 'Heterozygous',
    HETEROZYGOUS_13910: 'Heterozygous -13910',
    HETEROZYGOUS_13915: 'Heterozygous -13915',
    HETEROZYGOUS_13910_13915: 'Heterozygous -13910 -13915',
    HETEROZYGOUS_14009: 'Heterozygous -14009',
    HETEROZYGOUS_14010: 'Heterozygous -14010',
    HETEROZYGOUS_14009_14010: 'Heterozygous -14009 -14010',
    POSITIVE_TEST_TEST_OK: 'Valid Positive Control',
    POSITIVE_TEST_TEST_KO: 'Invalid Positive Control',
    NEGATIVE_TEST_TEST_OK: 'Valid Negative Control',
    NEGATIVE_TEST_TEST_KO: 'Invalid Negative Control',


    // Run interpretation
    runInterpretationChannel: 'CHANNEL',
    runInterpretationNoInterpretationResultMessage1: 'No interpretation result can be shown for this run.',
    runInterpretationNoInterpretationResultMessage2: 'This can mean that (some of) the wells have been incorrectly configured.',
    runInterpretationFooterDeleteRunButtonText: 'Delete run',
    logarithmicVue: 'Logarithmic vue',
    runInterpretation: {
      wellInterpretation: {
        wellSelectionFilters: {
          kitDropdownPlaceholder: 'Kit',
          reactionBufferDropdownPlaceholder: 'Reaction Buffer'
        }
      },
      deletionModal: {
        title: 'Delete run',
        message: 'Do you really wish to delete this run and the associated data? This action is irreversible. (This could take a while.)',
        okButtonText: 'Yes, delete run',
        cancelButtonText: 'No, do not delete run'
      },
      deleteRun: {
        successNotification: {
          title: 'Run deleted',
          message: 'The run {0} has been deleted successfully.'
        },
        errorNotification: {
          title: 'Run not deleted'
        }
      },
      ftp: {
        runInterpretationSendFileFtp: 'Send results to FTP',
        successNotification: {
          title: 'File send to FTP',
          message: 'The file has been sent successfully to the FTP server'
        },
        errorNotification: {
          title: 'File couldn\'t be sent to FTP server',
          message: 'A problem has occurred during file transfer'
        }
      }
    },

    // Well Configure Window
    formRunConfigurationTitle: 'Plate configuration',
    formSampleType: 'Sample Type',
    formWellName: 'Well Name',
    interpretationInProgress: 'Interpretation in progress',
    wellUpdateConfirmationMessage: 'Do you want to update this value for all the selected wells?',

    formSelectWellConfigurationTemplate: 'Select a template',
    deleteTemplateButton: 'Delete template',
    formTemplateName: 'Template name',
    wellConfigurationWindow: {
      formSelectWellConfigurationTarget: 'Select a target',
      target: 'Target'
    },
    deletionTemplateModal: {
      title: 'Delete template',
      message: 'Do you really wish to delete this template?',
      okButtonText: 'Yes, delete it',
      cancelButtonText: 'No, do not delete',
      successNotification: {
        title: 'Template deleted',
        message: 'Template {0} has been deleted successfully.'
      },
      errorNotification: {
        title: 'Template not deleted'
      }
    },

    // Well Interpretation
    wellInterpretationConfigurationTitle: 'Review interpretation results',
    wellInterpretationNoPhaseData: 'No phase data for this well',
    wellInterpretationMultipleKitOrVersionSelected: 'Multiple kits or versions selected, unable to render results. Please select only one kit and one version to display interpretations.',
    wellInterpretationUpdateDiagnosticButtonText: 'Update Suggested Result',
    wellInterpretationNotificationTitle: 'Well',
    wellInterpretationCommentAdded: 'Comment successfully added',
    wellInterpretationChartTab: 'Chart',
    wellInterpretationTableTab: 'Table',
    wellInterpretationChanged: 'Suggested result changed from {0} to {1} by {2}',

    // Well Interpretation Chart
    wellInterpretationChartTitle: 'Amplification',
    wellInterpretationChartXAxis: 'Cycles',
    wellInterpretationChartYAxis: 'RFU',

    // Well Interpretation Data Table
    wellInterpretationDataTableColumnHeaderChannel1Name: 'FAM',
    wellInterpretationDataTableColumnHeaderChannel2Name: 'HEX',
    wellInterpretationDataTableColumnHeaderPosition: 'Well Pos',
    wellInterpretationDataTableColumnHeaderName: 'Sample Name',
    wellInterpretationDataTableColumnHeaderChannelCq: 'Cq',
    wellInterpretationDataTableColumnHeaderChannelEndRFU: 'End RFU',
    wellInterpretationDataTableColumnHeaderRatio: 'Ratio',
    wellInterpretationDataTableColumnHeaderSuggestedResult: 'Suggested Result',

    // Well Meta Data
    wellMetaDataWellName: 'Well Name',
    wellMetaDataLot: 'Lot',
    wellMetaDataKit: 'Kit',
    wellMetaDataSampleType: 'Sample type',

    // Diagnostic Update Form
    diagnosticUpdateFormIntroductionParagraph: 'Select the new suggested result for well {0}',
    diagnosticUpdateFormValidationButtonText: 'Update Suggested Result',
    diagnosticUpdateFormUpdateErrorNotificationTitle: 'Suggested result not updated.',
    diagnosticUpdateFormUpdateSuccessNotificationTitle: 'Suggested result successfully updated for well {0}.',
    diagnosticUpdateFormUpdateSuccessNotificationMessage: 'New suggested result: {0}',

    // Diagnostic Dropdown
    diagnosticDropdownPlaceholder: 'Select a suggested result',

    //Export Window
    showExportWindowButton: 'Export results',
    exportWindowTitle: 'Export results',
    formExportFormat: 'Export type',
    formExportFormatPlaceholder: 'Select an export type',
    formExportData: 'Export data',
    formExportDataPlaceholder: 'Select data to export',
    exportButton: 'Export',
    exportAsAPlate: 'Export as a plate',
    filters: 'Filters',
    separator: 'Separator',
    separatorPlaceholder: 'Select a separator',
    EXPORT_FORMAT_PDF: 'pdf',
    EXPORT_FORMAT_XLSX: 'xlsx',
    EXPORT_FORMAT_CSV: 'csv',
    EXPORT_FORMAT_XML: 'xml',
    EXPORT_DATA_ALL_PLATE: 'All plate',
    EXPORT_DATA_SELECTION: 'Selected wells',

    //Comment Configure Window
    addComment: 'Add Comment',

    //Cancel modal
    modalDiscardChangesTitle: 'Cancel',
    modalDiscardChangesMessage: 'Do you want to exit this run?',

    modalCreateTemplateTitle: 'Create template',
    modalCreateTemplateMessage: 'A template already exist with the name {0}. Would you like to update it ?',

    //Common
    actions: 'actions',
    cancel: 'Cancel',
    next: 'Next',
    save: 'Save',
    yes: 'Yes',
    no: 'No',
    close: 'Close',
    startInterpretation: 'Start Interpretation',

    // AnalyserDropdown
    analyserDropdownLoadingErrorTitle: 'An error occurred during the analyzers loading.',

    //PlateTypeDropdown
    plateTypeDropdownLoadingErrorTitle: 'An error occurred during the plate plan loading.',

    //KitDropdown
    kitDropdownLoadingErrorTitle: 'An error occurred during the kit options loading',

    //KitStatusDropdown
    kitStatusDropdownLoadingErrorTitle: 'An error occurred during the kit status options loading',

    //ChannelDropdown
    channelDropdownLoadingErrorTitle: 'An error occurred during the channel options loading',

    //LotDropdown
    lotDropdownLoadingErrorTitle: 'An error occurred during the lot options loading',
    lotDropdownDropdownAddLotErrorTitle: 'An error occurred while adding lot',
    lotDropdownDropdownAddLotErrorMessage: 'A lot named {0} already exists',

    //KitVersionDropdown
    KitVersionDropdownSearchingNotFoundTitle: 'No versions found',
    KitVersionDropdownSearchingNotFoundMessageForLot: 'Lot {0} is not referenced with any versions',
    KitVersionDropdownSearchingErrorTitle: 'An error occurred during the kit version options searching',
    KitVersionDropdownAddKitVersionErrorTitle: 'An error occurred while adding Kit version',
    KitVersionDropdownAddKitVersionErrorMessage: 'You cannot add a Kit version with a same name as another one',

    //SampleTypeDropdown
    sampleTypeDropdownPlaceholder: 'Select a sample type',
    sampleTypeDropdownLoadingErrorTitle: 'An error occurred during the sample type options loading',

    //DiagnosticExpressionVariableDropdown
    diagnosticExpressionVariableDropdownPlaceholder: 'Select a variable',
    diagnosticExpressionVariableDropdownLoadingErrorTitle: 'An error occurred during the diagnostic variable options loading',

    //DiagnosticExpressionOperatorDropdown
    diagnosticExpressionOperatorDropdownPlaceholder: 'Select an operator',
    diagnosticExpressionOperatorDropdownLoadingErrorTitle: 'An error occurred during the diagnostic operator options loading',

    //Errors
    error: 'error',
    runImportError: 'An unexpected error occurred during the run file import.',
    invalidFileError: 'An error occurred during the file parsing : invalid file : {0}',
    corruptedFileError: 'An error occurred during the file parsing : corrupted file or invalid format. {0}',
    errorCommentCreation: 'An error occurred during the comment creation.',
    errorDefaultDropdown: 'An unexpected error occurred during a dropdown options loading.',
    errorExportGeneration: 'An error occurred during the export file generation.',
    errorExportGenerationNoWellAvailable: 'No well to export.',
    errorExportGenerationSeparatorIncorrect: 'The separator chosen is incorrect. The separator should be only one character',
    errorTitleCannotAccessRunPage: 'Cannot access run page',
    errorTitleCannotSaveRunAndStartInterpretation: 'Cannot save run and start interpretation',
    errorParsingNotFinished: 'The run data has not yet been fully downloaded. Please wait before you proceed again with the interpretation.',
    errorSavingWellsNotFinished: 'The interpreted run data has not yet been saved in the database.  Please wait before doing action again.',
    invalidTemplateError: 'Plate plane type and plate plan file must both be filled in or both empty',

    //Graph Zone
    A: 'A',
    B: 'B',
    C: 'C',
    D: 'D',

    //Configuration
    formConfigureLot: 'Lot',
    formConfigureDiagnostic: 'Results Labelling',
    formConfigureKit: 'Kit',
    formConfigureFTP: 'FTP server connection',
    errorTitleConfigureLot: 'An error occurred while updating lots',
    successTitleConfigureLot: 'Lot(s) has/have been correctly updated',
    errorTitleCreateKit: 'An error occurred while creating the kit',
    successTitleCreateKit: 'Kit has been correctly created',
    errorTitleUpdateKit: 'An error occurred while updating the kit',
    successTitleUpdateKit: 'Kit has been correctly updated',
    addLot: 'Add lot(s)',
    deleteLot: 'Delete lot(s)',
    deleteLotModal: 'Are you sure to delete lot ?',
    updateLot: 'Update lot(s)',
    updateLotModal: 'Are you sure to update lot ?',
    errorTitleCannotUpdateAndDeleteLotAtSameTime: 'Can not update and delete',
    errorCannotUpdateAndDeleteLotAtSameTime: 'You can not update and delete lot(s) at the same time',
    infoTitleCannotSaveWithNoKitToAddOrDelete: 'You can not save',
    infoCannotSaveWithNoKitToAddOrDelete: 'You can not save if you do not have any kit to add/delete',
    formDiagnosticHeader: 'Suggested Result',
    label: 'Label',
    errorCannotSaveEmptyDiagnostic: 'You must choose at least one diagnostic, one kit and a label before saving',
    successTitleConfigureDiagnostic: 'Result(s) labelling has/have been correctly updated',
    errorConfigureDiagnostic: 'An error occurred while updating diagnostic',
    diagnosticConfigIntro: 'Configure the diagnostic label displayed in the export files',
    modalConfirmLotDeletion: {
      title: 'Update lot(s)',
      message: 'Are you sure you want to update lot(s)?',
      okButtonText: 'Yes',
      cancelButtonText: 'No'
    },
    modalConfirmExpirationDateLot: {
      title: 'Expiration date already passed',
      message: 'One or more lot(s) have an expiration date in the past. Are you sure you want to add lot(s) ?',
      okButtonText: 'Yes',
      cancelButtonText: 'No'
    },
    modalSelectedLotsExpired: {
      title: 'Expiration date already passed',
      message: 'One or more lot(s) have an expiration date in the past.',
      okButtonText: 'Ok'
    },
    plate: {
      missing: {
        kit: 'Kit is not selected',
        lot: 'Lot is not selected',
        sampleType: 'Sample type is not selected'
      }
    },

    //Laboratory
    laboratory: {
      name: 'Name',
      create: {
        title: 'Create laboratory',
        notification: {
          title: 'Create laboratories',
          success_message: 'All laboratories have been created',
          cannot_save_message: 'You cannot save if there is no laboratory or not all of them are named'
        }
      },
      update: {
        notification: {
          title: 'Update laboratory',
          success_message: 'Laboratory has been updated'
        }
      },
      delete: {
        title: 'Delete laboratory',
        message: 'Do you really wish to delete this laboratory ?',
        notification: {
          title: 'Delete laboratory',
          success_message: 'Laboratory has been deleted'
        }
      },
      cant_delete: {
        title: 'Cannot delete laboratory',
        message: 'You cannot delete this laboratory because users are still linked'
      },
      search: 'Search by name'
    },

    //User
    user: {
      enabled: 'Enabled',
      login: 'Login',
      lastname: 'Lastname',
      firstname: 'Firstname',
      email: 'E-mail',
      email_verified: 'E-mail verified',
      role: {
        admin: 'Admin',
        physician: 'Physician'
      },
      update: {
        notification: {
          title: 'Update user',
          success_message: 'User has been updated'
        }
      },
      delete: {
        title: 'Delete user',
        message: 'Do you really wish to delete this user ?',
        notification: {
          title: 'Delete user',
          success_message: 'User has been deleted'
        }
      },
      search: 'Search by login, lastname, firstname and email'
    },

    //reindex data
    modalConfirmReindexTitle: 'Reindex',
    modalConfirmReindexMessage: 'Do you want to reindex all runs?',
    reindexDataSuccessTitle: 'Reindex data success',
    reindexDataSuccessMessage: 'Reindex data has started asynchronously, please check server logs',
    reindexDataErrorTitle: 'Reindex data error',
    reindexDataErrorMessage: 'An error occured during reindex data',

    //about modal
    modalAboutTitle: 'About GeneFoxCub-SMA',

    //release note modal
    modalReleaseNoteTitle: 'Release Note',

    //lot changes modal
    modalLotChangesTitle: 'Changes in lots since last login',
    modalLotChangesCreationSectionTitle: 'New lots have been created',
    modalLotChangesCreationLine: 'Lot {0} is using version {1} of {2}',
    modalLotChangesVersionSectionTitle: 'Lots have changed current version of kit',
    modalLotChangesVersionLine: 'Lot {0} is using version {1} of {2}',
    modalLotChangesDeactivateSectionTitle: 'Lots have been disable',
    modalLotChangesDeactivateLine: 'Lot {0} is not available anymore for version {1} of {2}',
    modalLotChangesActivateSectionTitle: 'Lots have been activated',
    modalLotChangesActivateLine: 'Lot {0} is available for version {1} of {2}',

    //configuration
    configuration: {
      kit: {
        kit: 'Kit',
        analyzer: 'Analyzer',
        reaction_buffers: 'Reaction Buffers',
        phases: 'Phases',
        zones: 'Zones',
        create: {
          notification: {
            title: 'Create kit',
            success_message: 'Kit {0} has been saved'
          }
        },
        save: {
          notification: {
            title: 'Save configuration',
            success_message: 'Configuration has been saved',
            invalid_message: 'Configuration cannot be saved: {0} must be configured'
          }
        },
        'delete-version': {
          notification: {
            title: 'Delete kit version',
            success_message: 'Version {0} has been deleted'
          }
        },
        'load-versions': {
          notification: {
            title: 'Load versions'
          }
        },
        'load-analysers': {
          notification: {
            title: 'Load analyzers'
          }
        },
        'load-lots': {
          notification: {
            title: 'Load lots'
          }
        },
        modal: {
          'delete-version': {
            title: 'Delete kit version',
            message: 'Do you really wish to delete kit version {0} and all configuration data associated with ?'
          }
        },
        header: {
          version: 'Version: {0}',
          customVersion: 'Custom version: {0}',
          analyser: 'Analyzer: {0}',
          laboratory: 'Laboratory: {0}',
          kit: 'Kit: {0}'
        }
      },
      lot: {
        save: {
          notification: {
            title: 'Save lot',
            success_message: 'Lot has been saved',
            invalid_message: 'Lot cannot be saved: invalid inputs'
          }
        },
        deleting: {
          notification: {
            title: 'Delete lot',
            success_message: 'Lot has been deleted'
          }
        }
      },
      ftp: {
        not_configured: 'Connection is not configured, press + button to setup connection',
        sendFilesOnApproval: 'Send results when run is approved by the doctor',
        protocol: 'Protocol',
        host: 'Host',
        port: 'Port',
        warn: 'Warning: we recommend to use a SFTP server to ensure data is encrypted during the transfer',
        directory: 'Directory',
        authentication: 'Authentication',
        username: 'Username',
        password: 'Password',
        key_file: 'Key file',
        test_connection: 'Test connection',
        title: 'FTP',
        error: {
          get: 'Unable to find FTP configuration',
          save: 'Unable to save FTP configuration',
          delete: 'Unable to delete FTP configuration',
          test: 'Test connection failed'
        },
        success: {
          save: 'Successfully saved FTP configuration',
          delete: 'Successfully deleted FTP configuration',
          test: 'Successfully ran test connection'
        }
      }
    },
    expirationDateModal: 'Select an expiration date',
    errorTitleAddingRb: 'Can\'t add reaction buffer',
    errorNoRbName: 'No reaction buffer name written',
    modal: {
      kit_configuration: {
        cancel: {
          title: 'Cancel',
          message: 'Are you sure you want to discard all changes ?'
        }
      },
      ftp_configuration: {
        cancel: {
          title: 'Cancel',
          message: 'Are you sure you want to discard all changes ?'
        },
        delete: {
          title: 'Delete FTP Configuration',
          message: 'This is irreversible. Are you sure you want to delete the FTP configuration ?'
        }
      }
    },
    verifyControlsModalMessage: 'Are you sure that the controls are correct?',
    verifyControlsModalTitle: 'Check all controls',
    tooltip: {
      envelope: {
        ok: 'Results have been sent to the laboratory',
        ko: 'An error has occurred while sending results to the laboratory'
      }
    },
    PASSWORD: 'Password',
    SSH_KEY: 'SSH Key'
  }
};
