<template>
  <div class="diagnostic-expression-form">
    <b-form-group class="left-variable">
      <app-diagnostic-expression-variable-dropdown
        :overriding-options="expressionVariables"
        :show-labels="false"
        :disabled="readonly"
        :value="expression.leftVariable"
        :state="validateState('expression.leftVariable')"
        @close="touch('expression.leftVariable')"
        @input="onLeftVariableSelect($event)" />
    </b-form-group>

    <b-form-group class="operator">
      <app-diagnostic-expression-operator-dropdown
        :overriding-options="expressionOperators"
        :show-labels="false"
        :disabled="readonly"
        :value="expression.operator"
        :state="validateState('expression.operator')"
        @close="touch('expression.operator')"
        @input="onOperatorSelect($event)" />
    </b-form-group>

    <b-form-group class="right-variable">
      <b-form-input
        :disabled="readonly"
        :value="expression.rightVariable"
        :state="validateState('expression.rightVariable')"
        @blur="touch('expression.rightVariable')"
        @input="onRightVariableInput($event)" />
    </b-form-group>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import ValidationMixin from '@/mixins/ValidationMixin';
import DiagnosticExpressionVariableDropdown from '@/components/common/DiagnosticExpressionVariableDropdown.vue';
import DiagnosticExpressionOperatorDropdown from '@/components/common/DiagnosticExpressionOperatorDropdown.vue';
import { decimal, required } from '@vuelidate/validators/dist/index.cjs';

export default {
  name: 'DiagnosticExpressionForm',
  components: {
    'app-diagnostic-expression-variable-dropdown': DiagnosticExpressionVariableDropdown,
    'app-diagnostic-expression-operator-dropdown': DiagnosticExpressionOperatorDropdown
  },
  mixins: [
    ValidationMixin
  ],
  props: {
    diagnosticType: {
      type: String,
      default: 'RESULT'
    },
    expression: {
      type: Object,
      default: undefined
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  validations () {
    return {
      expression: {
        leftVariable: {
          required
        },
        operator: {
          required
        },
        rightVariable: {
          required,
          decimal
        }
      }
    };
  },
  computed: {
    ...mapFields('configuration', [
      'expressionVariables',
      'expressionOperators'
    ])
  },
  methods: {
    onLeftVariableSelect(event) {
      const expression = JSON.parse(JSON.stringify(this.expression));
      expression.leftVariable = event;
      this.$emit('change', expression);
    },
    onOperatorSelect(event) {
      const expression = JSON.parse(JSON.stringify(this.expression));
      expression.operator = event;
      this.$emit('change', expression);
    },
    onRightVariableInput(event) {
      const expression = JSON.parse(JSON.stringify(this.expression));
      expression.rightVariable = event;
      this.$emit('change', expression);
    }
  }
};
</script>