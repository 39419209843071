<template>
  <div
    class="color-picker d-flex justify-content-start"
    :class="{ readonly: readonly, 'is-valid': state === true, 'is-invalid': state === false }">
    <div
      ref="pickerDisplay"
      class="color-picker-display m-auto"
      @click="toggle">
      <slot
        name="color-display"
        :color="colorObject">
        <div
          :style="`
            background: ${colorObject.hex};
            box-shadow: inset 0 0 0 2px black;}
          `" />
      </slot>
    </div>
    <div
      ref="pickerContainer"
      v-click-outside="onClickOutside"
      class="color-picker-container"
      :hidden="!picking || readonly">
      <div
        class="hu-color-picker"
        :class="{ light: isLightTheme }">
        <div class="color-set">
          <Saturation
            ref="saturation"
            :color="rgbString"
            :hsv="hsv"
            :size="hueHeight"
            @selectSaturation="selectSaturation" />
          <Hue
            ref="hue"
            :hsv="hsv"
            :width="hueWidth"
            :height="hueHeight"
            @selectHue="selectHue" />
          <Alpha
            v-if="!alphaHide"
            ref="alpha"
            :color="rgbString"
            :rgba="rgba"
            :width="hueWidth"
            :height="hueHeight"
            @selectAlpha="selectAlpha" />
          <Colors
            ref="colors"
            :color="rgbaString"
            :colors-default="colorsDefault"
            :colors-history-key="colorsHistoryKey"
            @selectColor="selectColor" />
        </div>
        <!--    <div v-if="!previewHide" :style="{ height: previewHeight + 'px' }" class="color-show">
              <Preview
                v-if="!previewHide"
                :color="rgbaString"
                :width="previewWidth"
                :height="previewHeight"
              />
              <Sucker
                v-if="!suckerHide"
                :sucker-canvas="suckerCanvas"
                :sucker-area="suckerArea"
                @openSucker="openSucker"
                @selectSucker="selectSucker"
              />
            </div>-->
        <div class="mt-2">
          <Box
            class="float-left"
            name="HEX"
            :color="modelHex"
            @enter="inputHex" />
          <Box
            class="float-right"
            name="RGBA"
            :color="modelRgba"
            @enter="inputRgba" />
        </div>
        <!-- custom options -->
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import Saturation from './Saturation.vue';
import Hue from './Hue.vue';
import Alpha from './Alpha.vue';
import Preview from './Preview.vue';
import Sucker from './Sucker.vue';
import Box from './Box.vue';
import Colors from './Colors.vue';
import { rgb2hex, setColorValue } from '@/components/common/color-picker/composible.ts';

export default {
  components: {
    Saturation,
    Hue,
    Alpha,
    Preview,
    Sucker,
    Box,
    Colors
  },
  props: {
    color: {
      type: String,
      default: '#000000'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    state: {
      // Tri-state prop: true, false, null (or undefined)
      type: Boolean,
      default: null
    },
    theme: {
      type: String,
      default: 'dark'
    },
    alphaHide: {
      type: Boolean,
      default: true
    },
    previewHide: {
      type: Boolean,
      default: true
    },
    suckerHide: {
      type: Boolean,
      default: true
    },
    suckerCanvas: {
      type: null, // HTMLCanvasElement
      default: null
    },
    suckerArea: {
      type: Array,
      default: () => []
    },
    colorsDefault: {
      type: Array,
      default: () => [
        '#0000AC',
        '#F1FF2F',
        '#919191',
        '#F5a442',
        '#42F5F5',
        '#CCFFFF',
        '#00A9A9',
        '#CCE4FF',
        '#007CA9',
        '#D142F5',
        '#E4C2FF',
        '#BB72FF',
        '#7A00D4',
        '#FFC2E7',
        '#F557EB',
        '#C200AE',
        'rgba(0,0,0,0)'
      ]
    },
    colorsHistoryKey: {
      type: String,
      default: 'vue-colorpicker-history'
    }
  },
  data() {
    return {
      picking: false,
      hueWidth: 15,
      hueHeight: 92,
      previewHeight: 20,
      modelRgba: '',
      modelHex: '',
      r: 0,
      g: 0,
      b: 0,
      a: 1,
      h: 0,
      s: 0,
      v: 0
    };
  },
  computed: {
    isLightTheme() {
      return this.theme === 'light';
    },
    totalWidth() {
      return this.hueHeight + (this.hueWidth + 8) * 2;
    },
    previewWidth() {
      return this.totalWidth - (this.alphaHide ? 20 : 0) - (this.suckerHide ? 0 : this.previewHeight);
    },
    rgba() {
      return {
        r: this.r,
        g: this.g,
        b: this.b,
        a: this.a
      };
    },
    hsv() {
      return {
        h: this.h,
        s: this.s,
        v: this.v
      };
    },
    rgbString() {
      return `rgb(${this.r}, ${this.g}, ${this.b})`;
    },
    rgbaStringShort() {
      return `${this.r}, ${this.g}, ${this.b}, ${this.a}`;
    },
    rgbaString() {
      return `rgba(${this.rgbaStringShort})`;
    },
    hexString() {
      return rgb2hex(this.rgba, true);
    },
    colorObject() {
      return {
        rgba: this.rgba,
        hsv: this.hsv,
        hex: this.modelHex ?? this.hexString
      };
    }
  },
  watch: {
    color(newColor) {
      const { r, g, b, a, h, s, v } = setColorValue(newColor);
      Object.assign(this, { r, g, b, a, h, s, v });
      this.setText();
    }
  },
  mounted() {
    const { r, g, b, a, h, s, v } = setColorValue(this.color);
    Object.assign(this, { r, g, b, a, h, s, v });
    this.setText();
  },
  methods: {
    toggle() {
      if (this.readonly) {
        return;
      }
      if (this.picking) {
        this.close();
      } else {
        this.open();
      }
    },
    open() {
      this.initColorPicker();
      this.picking = true;
      this.$emit('open');
    },
    close() {
      this.picking = false;
      this.$emit('close');
    },
    onClickOutside(event) {
      if (this.$refs.pickerDisplay?.contains(event?.target)) {
        return;
      }
      if (this.picking) {
        this.close();
      }
    },
    initColorPicker() {
      if (this.readonly) {
        return;
      }
      this.$nextTick(() => {
        this.$refs.pickerContainer.style.bottom = `${-this.$refs.pickerContainer.clientHeight}px`;
        this.$refs.saturation.renderColor();
        this.$refs.saturation.renderSlide();
        this.$refs.hue.renderSlide();
      });
      this.$refs.colors.initColors();
      this.$refs.hue.initHue();
      this.$refs.saturation.initSaturation();
      if (!this.alphaHide) {
        this.$refs.alpha.initAlpha();
      }
      this.$watch('rgba', () => {
        if (this.readonly) {
          return;
        }
        this.$emit('changeColor', this.colorObject);
      });
    },
    selectSaturation(color, history) {
      const { r, g, b, h, s, v } = setColorValue(color);
      this.assignColor(color, r, g, b, 1, h, s, v, history);
    },
    selectHue(color, history) {
      const { r, g, b, h, s, v } = setColorValue(color);
      this.assignColor(color, r, g, b, 1, h, s, v, history);
      this.$nextTick(() => {
        this.$refs.saturation.renderColor();
        this.$refs.saturation.renderSlide();
      });
    },
    selectAlpha(a) {
      this.a = a;
      this.setText();
    },
    inputHex(color) {
      const { r, g, b, a, h, s, v } = setColorValue(color);
      this.assignColor(color, r, g, b, a, h, s, v, true);
      this.modelHex = color;
      this.modelRgba = this.rgbaStringShort;
      this.$nextTick(() => {
        this.$refs.saturation.renderColor();
        this.$refs.saturation.renderSlide();
        this.$refs.hue.renderSlide();
      });
      this.close();
    },
    inputRgba(color) {
      const { r, g, b, a, h, s, v } = setColorValue(color);
      this.assignColor(color, r, g, b, a, h, s, v, true);
      this.modelHex = this.hexString;
      this.modelRgba = color;
      this.$nextTick(() => {
        this.$refs.saturation.renderColor();
        this.$refs.saturation.renderSlide();
        this.$refs.hue.renderSlide();
      });
      this.close();
    },
    setText() {
      this.modelHex = this.hexString;
      this.modelRgba = this.rgbaStringShort;
    },
    openSucker(isOpen) {
      this.$emit('openSucker', isOpen);
    },
    selectSucker(color) {
      const { r, g, b, a, h, s, v } = setColorValue(color);
      this.assignColor(color, r, g, b, a, h, s, v, true);
      this.$nextTick(() => {
        this.$refs.saturation.renderColor();
        this.$refs.saturation.renderSlide();
        this.$refs.hue.renderSlide();
      });
    },
    selectColor(color) {
      const { r, g, b, a, h, s, v } = setColorValue(color);
      this.assignColor(color, r, g, b, a, h, s, v, true);
      this.$nextTick(() => {
        this.$refs.saturation.renderColor();
        this.$refs.saturation.renderSlide();
        this.$refs.hue.renderSlide();
      });
      this.close();
    },
    assignColor(color, r, g, b, a, h, s, v, history) {
      Object.assign(this, { r, g, b, a, h, s, v });
      this.setText();
      if (history) {
        this.$refs.colors.setColorsHistory(color);
      }
    }
  }
};
</script>

<style lang="scss">
.color-picker {
  display: flex;
  flex: 1 1 auto;

  position: relative;

  &:not(.readonly) {
    .color-picker-display {
      cursor: pointer;
    }
  }

  &.is-valid {
    .color-picker-display {
      border: 1px solid var(--green);
      box-shadow: 0 0 2px 0.5px var(--green);
    }
  }

  &.is-invalid {
    .color-picker-display {
      border: 1px solid var(--red);
      box-shadow: 0 0 2px 0.5px var(--red);
    }
  }

  .color-picker-display {
    display: flex;
    flex: 1 1 auto;
  }

  .color-picker-container {
    position: absolute;
    z-index: 1018;

    ~ div {
      z-index: 1019;
    }
  }

  .hu-color-picker {
    width: 359px;
    padding: 10px;
    background: #1d2024;
    border-radius: 4px;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);
    z-index: 1;
    overflow: hidden;

    &.light {
      background: #f7f8f9;
      box-shadow: 0 0 15px 0 #919191;

      .color-show {
        .sucker {
          background: #eceef0;
        }
      }

      .color-type {
        .name {
          background: #e7e8e9;
        }

        .value {
          color: #666;
          background: #eceef0;
        }
      }

      .colors.history {
        border-top: 1px solid #dddddd;
      }
    }

    canvas {
      vertical-align: top;
    }

    .color-set {
      display: flex;
    }

    .color-show {
      margin-top: 8px;
      display: flex;
    }
  }

}
</style>
