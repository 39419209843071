<template>
  <b-form-group
    :label="channel.channel"
    label-cols="auto">
    <b-form-input
      id="input-horizontal"
      :value="channel.threshold"
      :readonly="readonly"
      :state="validateState(`channel.threshold`)"
      @blur="touch(`channel.threshold`)"
      @input="onInputThreshold($event)" />
  </b-form-group>
</template>

<script>
import SecurityMixin from '@/mixins/SecurityMixin';
import ValidationMixin from '@/mixins/ValidationMixin';
import { decimal, required } from '@vuelidate/validators/dist/index.cjs';

export default {
  name: 'KitAnalyserChannelForm',
  mixins: [
    SecurityMixin,
    ValidationMixin
  ],
  props: {
    channel: {
      type: Object,
      default: undefined
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  validations() {
    return {
      channel: {
        threshold: {
          required,
          decimal
        }
      }
    };
  },
  methods: {
    onInputThreshold(event) {
      this.$emit('change', { ...this.channel, threshold: event });
    }
  }
};
</script>