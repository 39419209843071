<template>
  <b-modal
    :id="id"
    :header-text-variant="headerTextVariant"
    :ok-only="true"
    :title="$t('modalLotChangesTitle')"
    size="lg"
    @hide="onHide">
    <template v-if="creationChanges && creationChanges.length">
      <app-lot-changes-section
        :changes="creationChanges"
        title-translation="modalLotChangesCreationSectionTitle"
        message-translation="modalLotChangesCreationLine" />
    </template>

    <template v-if="versionChanges && versionChanges.length">
      <app-lot-changes-section
        :changes="versionChanges"
        title-translation="modalLotChangesVersionSectionTitle"
        message-translation="modalLotChangesVersionLine" />
    </template>

    <template v-if="activateChanges && activateChanges.length">
      <app-lot-changes-section
        :changes="activateChanges"
        title-translation="modalLotChangesActivateSectionTitle"
        message-translation="modalLotChangesActivateLine" />
    </template>

    <template v-if="deactivateChanges && deactivateChanges.length">
      <app-lot-changes-section
        :changes="deactivateChanges"
        title-translation="modalLotChangesDeactivateSectionTitle"
        message-translation="modalLotChangesDeactivateLine" />
    </template>
  </b-modal>
</template>

<script>
import ModalMixin from '@/mixins/ModalMixin';
import { updateLotChangesAsSeen } from '@/service/SmazentechService';
import LotChangesSection from './LotChangesSection.vue';

export default {
  name: 'LotChangesModal',
  components: {
    'app-lot-changes-section': LotChangesSection
  },
  mixins: [ ModalMixin ],
  props: {
    changes: {
      type: Array,
      default: null
    },
    seen: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({

  }),
  computed: {
    creationChanges() {
      return this.changes?.filter(ch => ch.changeType === 'LOT_CREATED');
    },
    versionChanges() {
      return this.changes?.filter(ch => ch.changeType === 'LOT_VERSION_CHANGED' && this.isVersionChanged(ch.modifications));
    },
    activateChanges() {
      return this.changes?.filter(ch => ch.changeType === 'LOT_VERSION_CHANGED' && this.isActiveChanged(ch.modifications) && ch.lot.active);
    },
    deactivateChanges() {
      return this.changes?.filter(ch => ch.changeType === 'LOT_VERSION_CHANGED' && this.isActiveChanged(ch.modifications) && !ch.lot.active);
    }
  },
  methods: {
    onHide() {
      if (!this.seen) {
        updateLotChangesAsSeen(this.release);
      }
      this.hideModal();
    },
    isVersionChanged(modifications) {
      return modifications.some(modification => modification === 'kitVersion') && modifications.every(modification => modification !== 'active');
    },
    isActiveChanged(modifications) {
      return modifications.some(modification => modification === 'active');
    }
  }
};
</script>