<template>
  <div>
    <transition
      appear
      name="fade"
      mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import {store} from '@/store/store';

export default {
  beforeRouteEnter(to, from, next) {
    const id = to.params.id;
    const fetching = to.name === 'run-interpretation' ?
      store.dispatch('runImport/findInterpretedRun', id) :
      store.dispatch('runImport/findRun', id);

    fetching.then(() => {
      next();
    })
      .catch(error => {
        console.error(error);
        next({ name: 'run-list' });
      });
  }
};
</script>
