<template>
  <multiselect
    v-model="model"
    :disabled="disabled"
    :options="options"
    :placeholder="$t('sampleTypeDropdownPlaceholder')"
    :custom-label="customLabel"
    :show-labels="showLabels"
    :class="cssClasses"
    @open="$emit('open', $event)"
    @close="$emit('close', $event)" />
</template>

<script>
import DropdownMixin from '../../mixins/DropdownMixin';
import {findAllWellSampleTypes} from '../../service/SmazentechService';
import SampleType from '../../models/SampleType';

export default {
  mixins: [
    DropdownMixin
  ],
  props: {
    value: {
      type: Object,
      default: undefined
    }
  },
  computed: {
    errorNotificationTitle() {
      return this.$t('sampleTypeDropdownLoadingErrorTitle');
    }
  },
  methods: {
    findOptions() {
      return findAllWellSampleTypes();
    },
    customLabel (sampleType) {
      return SampleType.labelize(sampleType);
    }
  }
};
</script>
