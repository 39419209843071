export default {
  methods: {
    showErrorNotification(title, content, options = {}) {
      const delay = options.delay || 5000;
      const root = options.root || false;

      let message = 'No detail available.';

      if (content?.request?.responseType === 'arraybuffer' && content?.response?.data) {
        content.response.data = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(content.response.data)));
      }

      if (content?.response?.data?.message) {
        message = content.response.data.message !== 'No message available' ? this.$t(content.response.data.message) : content.response.data.message;
      } else if (content?.message) {
        message = content.message;
      } else if (content) {
        message = content;
      }

      const vueInstance = root ? this.$root : this;

      vueInstance.$bvToast.toast(message, {
        title: this.$options.filters.capitalize(title),
        variant: 'danger',
        autoHideDelay: delay,
        toaster: 'b-toaster-bottom-left'
      });
    },
    showSuccessNotification(title, content, options = {}) {
      const delay = options.delay || 3000;
      const root = options.root || false;

      const vueInstance = root ? this.$root : this;

      vueInstance.$bvToast.toast(content, {
        title: this.$options.filters.capitalize(title),
        variant: 'success',
        autoHideDelay: delay,
        toaster: 'b-toaster-bottom-left'
      });
    },
    showInfoNotification(title, content, options = { delay: 3000, root: false }) {
      const delay = options.delay || 3000;
      const root = options.root || false;

      const vueInstance = root ? this.$root : this;

      vueInstance.$bvToast.toast(content, {
        title: this.$options.filters.capitalize(title),
        variant: 'info',
        autoHideDelay: delay,
        toaster: 'b-toaster-bottom-left'
      });
    }
  }
};
