import Vue from 'vue';
import Vuex from 'vuex';
import Security from '@/store/modules/Security';
import RunImport from '@/store/modules/RunImport';
import PlateTemplate from '@/store/modules/PlateTemplate';
import Laboratory from '@/store/modules/Laboratory';
import RunList from '@/store/modules/RunList';
import Configuration from '@/store/modules/Configuration';
import ApplicationProperties from '@/store/modules/ApplicationProperties';

Vue.use(Vuex);

export const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',
  modules: {
    'security': Security,
    'applicationProperties': ApplicationProperties,
    'runImport': RunImport,
    'plateTemplate': PlateTemplate,
    'laboratory': Laboratory,
    'runList': RunList,
    'configuration': Configuration
  }
});
