<template>
  <div>
    <div class="h-100">
      <b-navbar
        class="border-bottom shadow-sm"
        toggleable="sm"
        type="light"
        variant="light"
        :sticky="true">
        <b-navbar-toggle target="nav-text-collapse" />

        <b-collapse
          id="nav-text-collapse"
          is-nav>
          <b-navbar-nav class="ml-auto">
            <app-language-dropdown />

            <b-nav-item-dropdown
              id="user"
              right>
              <template slot="button-content">
                <span class="user-icon">
                  <v-icon
                    v-if="hasRole('ADMIN')"
                    name="user-cog" />
                  <v-icon
                    v-else-if="hasRole('WRITE_DIAGNOSTIC')"
                    name="user-md" />
                  <v-icon
                    v-else
                    name="user-circle" />
                </span>
                <em>{{ getUserName }}</em>
              </template>
              <b-dropdown-item
                href="#"
                @click="logout()">
                {{ $t('app.navbar.logoutButtonText') }}
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
      <div class="genefox-cub-logo-container">
        <img
          class="d-inline-block align-top is-hover genefox-cub-logo"
          src="@/assets/images/genefox-cub-logo.png"
          :alt="$t('app.navbar.title')"
          @click="$router.push({ name: 'run-list' })">
      </div>
      <div class="genefox-cub-logo-container">
        <p class="version">
          {{ getVersionInfo() }}
        </p>
      </div>
      <div class="content">
        <router-view />
      </div>
    </div>

    <app-left-sidebar />

    <app-release-note-modal
      ref="releaseNoteModal"
      :release="release"
      :not-seen="true"
      :ok-button-name="$t('common.ok')" />

    <app-lot-changes-modal
      ref="lotChangesModal"
      :changes="lotChanges"
      :ok-button-name="$t('common.ok')" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import NotificationMixin from './mixins/NotificationMixin';
import SecurityMixin from './mixins/SecurityMixin';
import LeftSidebar from '@/components/menu/LeftSidebar.vue';
import LanguageDropdown from '@/components/common/LanguageDropdown.vue';
import LotChangesModal from '@/components/common/LotChangesModal.vue';
import { getAllLotChangesSinceLastSeen } from '@/service/SmazentechService';
import { getLastRelease } from './service/SmazentechService';
import ReleaseNoteModal from './components/common/ReleaseNoteModal.vue';

export default {
  components: {
    'app-left-sidebar': LeftSidebar,
    'app-language-dropdown': LanguageDropdown,
    'app-lot-changes-modal': LotChangesModal,
    'app-release-note-modal': ReleaseNoteModal
  },
  mixins: [
    NotificationMixin,
    SecurityMixin
  ],
  data: () => ({
    release: undefined,
    lotChanges: undefined
  }),
  computed: {
    getUserName() {
      return this.userName();
    },
    getCommitHash() {
      if (process.env.VUE_APP_COMMIT_HASH) {
        // local env
        return process.env.VUE_APP_COMMIT_HASH;
      }

      // test and prod
      return process.env.VUE_APP_GIT_COMMIT;
    },
    isAdmin() {
      return this.hasRole('ADMIN');
    }
  },
  created () {
    document.title = this.$t('app.navbar.title').toString();
  },
  mounted() {
    this.handleLotChangesSinceLastSeen();
    this.getLastReleaseIfNotSeen();
  },
  methods: {
    ...mapActions('security', [
      'logout'
    ]),
    getVersionInfo() {
      let info = `v ${this.release?.version}`;
      if (info.includes('latest') || info.includes('SNAPSHOT')) {
        info += ` commit: ${this.getCommitHash}`;
      }

      return info;
    },
    handleLotChangesSinceLastSeen() {
      if (!this.isAdmin) {
        getAllLotChangesSinceLastSeen()
          .then(changes => {
            if (changes?.length > 0) {
              this.lotChanges = changes;
              this.$refs.lotChangesModal.showModal();
            }
          })
          .catch(error => this.showErrorNotification(this.$t('error'), error));
      }
    },
    getLastReleaseIfNotSeen() {
      getLastRelease()
        .then(release => {
          if (release) {
            this.release = release;
            if (release.note && !release.alreadySeen) {
              this.$refs.releaseNoteModal.showModal();
            }
          }
        });
    }
  }
};
</script>