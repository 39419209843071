<template>
  <div class="my-2">
    <b-row>
      <b-col class="cols-6">
        <h6> {{ versionHeader }}</h6>
        <h6 v-if="kitAnalyser && kitAnalyser.name">
          {{ $t('configuration.kit.header.analyser', [kitAnalyser.name]) }}
        </h6>
      </b-col>
      <b-col class="cols-6">
        <h6 v-if="isAdmin && customKitVersion != null">
          {{ $t('configuration.kit.header.laboratory', [customKitVersion.laboratoryName]) }}
        </h6>
        <h6> {{ $t('configuration.kit.header.kit', [kitName]) }} </h6>
      </b-col>
    </b-row>
    <hr>
  </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields';
import SecurityMixin from '@/mixins/SecurityMixin';

export default {
  mixins: [
    SecurityMixin
  ],
  computed: {
    ...mapFields('configuration', [
      'kitVersion',
      'customKitVersion',
      'kitAnalyser'
    ]),
    isAdmin() {
      return this.hasRole('ADMIN');
    },
    version() {
      return this.customKitVersion ? this.customKitVersion : this.kitVersion;
    },
    versionHeader() {
      return this.customKitVersion ?
        this.$t('configuration.kit.header.customVersion', [ this.version?.version ]) :
        this.$t('configuration.kit.header.version', [ this.version?.version ]);
    },
    kitName() {
      return this.version?.kit?.name;
    }
  }
};
</script>