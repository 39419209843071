<template>
  <multiselect
    v-model="model"
    :options="options"
    :placeholder="$t('formSelectPlatePlan')"
    :custom-label="customLabel"
    :loading="isLoading"
    :allow-empty="allowEmpty"
    :deselect-label="!allowEmpty ? '' : undefined"
    :class="stateClass"
    :disabled="disabled"
    :show-labels="showLabels"
    @select="$emit('select', $event)"
    @remove="$emit('remove', $event)" />
</template>

<script>
import DropdownMixin from '../../mixins/DropdownMixin';
import { findAllPlatePlans } from '@/service/SmazentechService';

export default {
  mixins: [ DropdownMixin ],
  props: {
    allowEmpty: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value : {
      type: Object,
      default : undefined
    }
  },
  computed: {
    errorNotificationTitle() {
      return this.$t('plateTypeDropdownLoadingErrorTitle');
    }
  },
  methods: {
    findOptions() {
      return findAllPlatePlans();
    },
    customLabel (value) {
      return this.$options.filters.capitalize(value.plateName);
    }
  }
};
</script>
