import { SmazentechApi, SmazentechDownloadFile, SmazentechMultipartApi } from './http-common';
import Analyser from '@/models/Analyser';
import Kit from '@/models/Kit';
import Lot from '@/models/Lot';
import Run from '@/models/Run';
import PlateTemplate from '@/models/PlateTemplate';
import Laboratory from '@/models/Laboratory';
import User from '@/models/User';
import WellInterpretation from '@/models/WellInterpretation';
import WellChart from '@/models/WellChart';
import PublicProperties from '@/models/PublicProperties';
import Diagnostic from '@/models/Diagnostic';
import WellDiagnostic from '@/models/WellDiagnostic';
import Comment from '@/models/Comment';
import SampleType from '../models/SampleType';
import LotChangesData from '../models/LotChangesData';
import FTPConfiguration from '../models/FTPConfiguration';
import Release from '../models/Release';

export function promiseSequence(...promises) {
  return promises.map(p => (p !== undefined && p !== null && p instanceof Promise) ? p : Promise.resolve())
    .reduce((prev, curr) => prev.then(() => curr), Promise.resolve());
}

export const findRun = id => new Promise((resolve, reject) => {
  SmazentechApi.get(`/run/${id}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(Run.parse(response.data)))
    .catch(error => reject(error));
});

export const createRun = run => new Promise((resolve, reject) => {
  SmazentechApi.post('/run', run, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(Run.parse(response.data)))
    .catch(error => reject(error));
});

export const saveRunFiles = (id, files) => new Promise((resolve, reject) => {
  const formData = new FormData();
  files.forEach(file => formData.append('analyserFiles', file));

  SmazentechMultipartApi.post(`/run/${id}/files`, formData, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(Run.parse(response.data)))
    .catch(error => reject(error));
});

export const getSampleTypesForRunInterpretation = runId => new Promise((resolve, reject) => {
  SmazentechApi.get(`/sample-type/run/interpretation/${runId}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data.map(s => SampleType.parse(s))))
    .catch(error => reject(error));
});

export const getSampleTypesForTemplating = (kitVersionIds, analyserId) => new Promise((resolve, reject) => {
  SmazentechApi.get('/sample-type/template', { params: { kitVersionIds, analyserId },
    headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data.map(s => SampleType.parse(s))))
    .catch(error => reject(error));
});

export const getSampleTypesForRunConfiguration = (lotIds, analyserId) => new Promise((resolve, reject) => {
  const params = [];
  if (lotIds) {
    params.push(`lotIds=${lotIds.join(',')}`);
  }
  if (Number.isFinite(analyserId)) {
    params.push(`analyserId=${analyserId}`);
  }
  SmazentechApi.get(`/sample-type/run/configuration${params ? '?' : ''}${params.join('&')}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data.map(s => SampleType.parse(s))))
    .catch(error => reject(error));
});

export const findAllSampleTypesForControl = () => new Promise((resolve, reject) => {
  SmazentechApi.get('/sample-type/control', { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data.map(sampleType => SampleType.parse(sampleType))))
    .catch(error => reject(error));
});

export const findAllWellSampleTypes = () => new Promise((resolve, reject) => {
  SmazentechApi.get('/sample-type', { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data.map(sampleType => SampleType.parse(sampleType))))
    .catch(error => reject(error));
});


export const getRunDiagnosticsByKitVersionId = (runId, kitVersionId) => new Promise((resolve, reject) => {
  SmazentechApi.get(`/diagnostic/run/${runId}/kit-version/${kitVersionId}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data.map(diagnostic => Diagnostic.parse(diagnostic))))
    .catch(error => reject(error));
});

export const getRunInterpretation = runId => new Promise((resolve, reject) => {
  SmazentechApi.get(`/run/${runId}/interpretation`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data.map(wellInterpretation => WellInterpretation.parse(wellInterpretation))))
    .catch(error => reject(error));
});

export const getRunDiagnostic = runId => new Promise((resolve, reject) => {
  SmazentechApi.get(`/run/${runId}/diagnostic`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data.map(wellDiagnostic => WellDiagnostic.parse(wellDiagnostic))))
    .catch(error => reject(error));
});

export const updateRunDiagnostic = (runId, wellDiagnostic) => new Promise((resolve, reject) => {
  SmazentechApi.put(`/run/${runId}/diagnostic`, wellDiagnostic, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(WellDiagnostic.parse(response.data)))
    .catch(error => reject(error));
});

export const getRunChart = runId => new Promise((resolve, reject) => {
  SmazentechApi.get(`/run/${runId}/chart`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data.map(wellChart => WellChart.parse(wellChart))))
    .catch(error => reject(error));
});

export const createRunComment = (runId, commentValue) => new Promise((resolve, reject) => {
  const runComment = { value: commentValue };
  SmazentechApi.post(`/run/${runId}/comment`, runComment, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data.map(comment => Comment.parse(comment))))
    .catch(error => reject(error));
});

export const createWellsComment = (runId, wellIds, commentValue) => new Promise((resolve, reject) => {
  const wellsComment = { wellIds, comment: commentValue };
  SmazentechApi.post(`/run/${runId}/wells-comment`, wellsComment, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data.map(wellComment => ({ ...wellComment, comments: wellComment.comments.map(c => Comment.parse(c)) }))))
    .catch(error => reject(error));
});

export const updateComment = (runId, commentId, commentValue) => new Promise((resolve, reject) => {
  const comment = { id: commentId, value: commentValue };
  SmazentechApi.put(`/run/${runId}/comment`, comment, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(Comment.parse(response.data)))
    .catch(error => reject(error));
});

export const findAllAnalysers = () => new Promise((resolve, reject) => {
  SmazentechApi.get('/analysers', { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data.map(analyser => Analyser.parse(analyser))))
    .catch(error => reject(error));
});

export const parseRun = (analyserFiles, analyserId) => new Promise((resolve, reject) => {
  const formData = new FormData();
  analyserFiles.forEach(file => formData.append('analyserFiles', file));
  formData.append('analyserId', JSON.stringify(analyserId));

  SmazentechApi.post('/run/parse', formData, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(Run.parse(response.data)))
    .catch(error => reject(error));
});

export const parsePlatePlan = (run, platePlanFile, platePlanType, lotIds) => new Promise((resolve, reject) => {
  const formData = new FormData();
  formData.append('run', JSON.stringify(run));
  formData.append('lotIds', lotIds);
  formData.append('platePlanFile', platePlanFile);
  if (platePlanType) {
    formData.append('platePlanType', platePlanType);
  }

  SmazentechApi.post('/run/parse/plate-plan', formData, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(Run.parse(response.data)))
    .catch(error => reject(error));
});

export const findAllKits = () => new Promise((resolve, reject) => {
  SmazentechApi.get('/kit', { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data.map(kit => Kit.parse(kit))))
    .catch(error => reject(error));
});

export const findAllKitsLinkedToLots = () => new Promise((resolve, reject) => {
  SmazentechApi.get('/kit/link/lots', { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data.map(kit => Kit.parse(kit))))
    .catch(error => reject(error));
});

export const createKit = kit => new Promise((resolve, reject) => {
  SmazentechApi.post('/kit', kit, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(Kit.parse(response.data)))
    .catch(error => reject(error));
});

export const findAllKitsByAnalyserId = id => new Promise((resolve, reject) => {
  SmazentechApi.get(`/kit/analyser/${id}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data.map(kit => Kit.parse(kit))))
    .catch(error => reject(error));
});

export const findAllKitsByAnalyserIdLinkedToLots = id => new Promise((resolve, reject) => {
  SmazentechApi.get(`/kit/analyser/${id}/link/lots`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data.map(kit => Kit.parse(kit))))
    .catch(error => reject(error));
});

export const findAllLotByAnalyserIdAndKitId = (analyserId, kitId) => new Promise((resolve, reject) => {
  SmazentechApi.get(`/lots/analyser/${analyserId}/kit/${kitId}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data.map(lot => Lot.parse(lot))))
    .catch(error => reject(error));
});

export const findAllKitStatuses = () => new Promise((resolve, reject) => {
  SmazentechApi.get('/kit/statuses', { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
});

export const getKitConfiguration = kitAnalyserId => new Promise((resolve, reject) => {
  SmazentechApi.get(`/kit/configuration/${kitAnalyserId}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
});

export const getKitConfigurationNextVersion = options => new Promise((resolve, reject) => {
  const params = [];
  if (options.kitVersionId) {
    params.push(`kitVersion=${options.kitVersionId}`);
  }
  if (options.analyserId) {
    params.push(`analyser=${options.analyserId}`);
  }
  if (options.laboratoryId) {
    params.push(`laboratory=${options.laboratoryId}`);
  }
  SmazentechApi.get(`/kit/configuration/next${params ? '?' : ''}${params.join('&')}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
});

export const createKitConfiguration = configuration => new Promise((resolve, reject) => {
  SmazentechApi.post('/kit/configuration', configuration, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
});

export const updateKitConfiguration = configuration => new Promise((resolve, reject) => {
  SmazentechApi.put('/kit/admin/configuration', configuration, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
});

export const updateKitConfigurationForDoctor = configuration => new Promise((resolve, reject) => {
  SmazentechApi.put('/kit/doctor/configuration', configuration, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
});

export const findAllLotsByKitId = kitId => new Promise((resolve, reject) => {
  SmazentechApi.get(`/lots/admin/kit/${kitId}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data.map(lot => Lot.parse(lot))))
    .catch(error => reject(error));
});
export const findAllActiveLotsByKitId = kitId => new Promise((resolve, reject) => {
  SmazentechApi.get(`/lots/kit/${kitId}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data.map(lot => Lot.parse(lot))))
    .catch(error => reject(error));
});
export const getAllLotChangesSinceLastSeen = () => new Promise((resolve, reject) => {
  SmazentechApi.get('/users/me/last-changes/lot/since-seen', { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
});
export const updateLotChangesAsSeen = () => new Promise((resolve, reject) => {
  SmazentechApi.put('/users/me/last-changes/lot/seen', null, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
});

export const createLot = lot => new Promise((resolve, reject) => {
  SmazentechApi.post('/lots', Lot.convertToIsoDate(lot), { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(Lot.parse(response.data)))
    .catch(error => reject(error));
});

export const updateLot = lot => new Promise((resolve, reject) => {
  SmazentechApi.put(`/lots/${lot.id}`, Lot.convertToIsoDate(lot), { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(Lot.parse(response.data)))
    .catch(error => reject(error));
});

export const deleteLot = id => new Promise((resolve, reject) => {
  SmazentechApi.delete(`/lots/${id}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response))
    .catch(error => reject(error));
});


export const findAllLots = () => new Promise((resolve, reject) => {
  SmazentechApi.get('/lots', { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data.map(lot => Lot.parse(lot))))
    .catch(error => reject(error));
});

export const findLotChanges = id => new Promise((resolve, reject) => {
  SmazentechApi.get(`/lots/${id}/changes`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data.map(auditData => LotChangesData.parse(auditData))))
    .catch(error => reject(error));
});

export const findAllDiagnosticExpressionVariables = () => new Promise((resolve, reject) => {
  SmazentechApi.get('/kit/diagnostic/expression-variables', { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
});

export const findAllDiagnosticExpressionOperators = () => new Promise((resolve, reject) => {
  SmazentechApi.get('/kit/diagnostic/expression-operators', { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
});

export const findAllPlatePlans = () => new Promise((resolve, reject) => {
  SmazentechApi.get('/plate-template/plateplans', { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
});

export const createPlateTemplate = plateTemplate => new Promise((resolve, reject) => {
  SmazentechApi.post('/plate-template', plateTemplate, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(PlateTemplate.parse(response.data)))
    .catch(error => reject(error));
});

export const updatePlateTemplate = plateTemplate => new Promise((resolve, reject) => {
  SmazentechApi.put(`/plate-template/${plateTemplate.id}`, plateTemplate, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(PlateTemplate.parse(response.data)))
    .catch(error => reject(error));
});

export const deletePlateTemplate = plateTemplate => new Promise((resolve, reject) => {
  SmazentechApi.delete(`/plate-template/${plateTemplate.id}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(PlateTemplate.parse(response.data)))
    .catch(error => reject(error));
});

export const findAllPlateTemplatesByVersions = versionIds => new Promise((resolve, reject) => {
  SmazentechApi.get('/plate-template', {
    params: { versionIds },
    headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` }
  })
    .then(response => resolve(response.data.map(template => PlateTemplate.parse(template))))
    .catch(error => reject(error));
});

export const findAllLaboratories = options => new Promise((resolve, reject) => {
  const params = [];
  if (options.isKitManufacturer != undefined) {
    params.push(`isKitManufacturer=${options.isKitManufacturer}`);
  }
  SmazentechApi.get(`/laboratory${params ? '?' : ''}${params.join('&')}`, {
    headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` }
  })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
});

export const searchLaboratories = (search, page) => new Promise((resolve, reject) => {
  SmazentechApi.get(`/laboratory${search && search !== '' ? '/search' : ''}`, {
    params: page?.index >= 0 && page?.size > 0 ? { page: page.index - 1, size: page.size, sort: page.sort, search } :  { search },
    headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` }
  })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
});

export const createLaboratoryUser = (keycloakGroupId, user) => new Promise((resolve, reject) => {
  SmazentechApi.post(`/laboratory/${keycloakGroupId}/users`, user, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(User.parse(response.data)))
    .catch(error => reject(error));
});

export const updateLaboratoryUser = (keycloakGroupId, id, user) => new Promise((resolve, reject) => {
  SmazentechApi.put(`/laboratory/${keycloakGroupId}/users/${id}`, user, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(User.parse(response.data)))
    .catch(error => reject(error));
});

export const deleteLaboratoryUser = (keycloakGroupId, id) => new Promise((resolve, reject) => {
  SmazentechApi.delete(`/laboratory/${keycloakGroupId}/users/${id}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(() => resolve())
    .catch(error => reject(error));
});

export const deleteLaboratory = id => new Promise((resolve, reject) => {
  SmazentechApi.delete(`/laboratory/${id}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(() => resolve())
    .catch(error => reject(error));
});

export const updateLaboratory = (id, laboratory) => new Promise((resolve, reject) => {
  SmazentechApi.put(`/laboratory/${id}`, laboratory, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(Laboratory.parse(response.data)))
    .catch(error => reject(error));
});

export const getLaboratory = keycloakGroupId => new Promise((resolve, reject) => {
  SmazentechApi.get(`/laboratory/${keycloakGroupId}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(Laboratory.parse(response.data)))
    .catch(error => reject(error));
});

export const getLaboratoryUser = (keycloakGroupId, page, search) => new Promise((resolve, reject) => {
  SmazentechApi.get(`/laboratory/${keycloakGroupId}/users`, {
    params: page?.index >= 0 && page?.size > 0 ? { page: page.index - 1, size: page.size, sort: page.sort, search } :  { search },
    headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` }
  })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
});

export const createLaboratories = laboratories => new Promise((resolve, reject) => {
  SmazentechApi.post('/laboratory', laboratories, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data.map(laboratory => Laboratory.parse(laboratory))))
    .catch(error => reject(error));
});

export const getPublicProperties = () => new Promise((resolve, reject) => {
  SmazentechApi.get('/public-properties', { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(PublicProperties.parse(response.data)))
    .catch(error => reject(error));
});

export const getRunsPerPage = (page, perPage, search, sort, sortDirection) => new Promise((resolve, reject) => {
  SmazentechApi.get('/run', {
    params: { page: page - 1, size: perPage, search, sort, sortDirection },
    headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` }
  })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
});

export const downloadFileRuns = id => new Promise((resolve, reject) => {
  SmazentechDownloadFile.get(`/run/${id}/fileruns`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response))
    .catch(error => reject(error));
});

export const reindexData = () => new Promise((resolve, reject) => {
  SmazentechApi.post('/run/reindex-elasticsearch', null, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(_ => resolve())
    .catch(error => reject(error));
});

export const downloadReport = (fileName, runId, exportModel) => new Promise((resolve, reject) => {
  SmazentechApi.post(`/run/export/${runId}`, exportModel, {
    responseType: 'arraybuffer',
    headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` }
  })
    .then(response => {
      const blob = new Blob([ response.data ], { type: response.headers['content-type'] });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${fileName}.${exportModel.format.toLowerCase()}`;
      link.click();
      resolve();
    })
    .catch(error => reject(error));
});

export const deleteRun = runId => new Promise((resolve, reject) => {
  SmazentechApi.delete(`/run/${runId}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(Run.parse(response.data)))
    .catch(error => reject(error));
});

export const createFTP = ftpConfiguration => new Promise((resolve, reject) => {
  SmazentechApi.post('/ftp-connection', ftpConfiguration, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(FTPConfiguration.parse(response.data)))
    .catch(error => reject(error));
});

export const updateFTP = ftpConfiguration => new Promise((resolve, reject) => {
  SmazentechApi.put('/ftp-connection', ftpConfiguration, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(FTPConfiguration.parse(response.data)))
    .catch(error => reject(error));
});

export const deleteFTP = id => new Promise((resolve, reject) => {
  SmazentechApi.delete(`/ftp-connection/${id}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(() => resolve())
    .catch(error => reject(error));
});

export const getFTP = () => new Promise((resolve, reject) => {
  SmazentechApi.get('/ftp-connection', { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(FTPConfiguration.parse(response.data)))
    .catch(error => reject(error));
});

export const testFTP = ftpConfiguration => new Promise((resolve, reject) => {
  SmazentechApi.post('/ftp/test', ftpConfiguration, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(FTPConfiguration.parse(response.data)))
    .catch(error => reject(error));
});

export const sendFileFtp = runId => new Promise((resolve, reject) => {
  SmazentechApi.post(`/ftp/send/run/${runId}`, null , { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(() => resolve())
    .catch(error => reject(error));
});

export const isConfigurationValidAndSendEnable = () => new Promise((resolve, reject) => {
  SmazentechApi.get('/ftp-connection/configuration/valid', { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
});

export const getLastRelease = () => new Promise((resolve, reject) => {
  SmazentechApi.get('/release/last', { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response =>  resolve(Release.parse(response.data)))
    .catch(error => reject(error));
});

export const updateSeenRelease = release => new Promise((resolve, reject) => {
  SmazentechApi.put(`/release/${release.version}/update-seen`, release, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(Release.parse(response.data)))
    .catch(error => reject(error));
});
