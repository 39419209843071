import { useVuelidate } from '@vuelidate/core/dist/index.cjs';
import get from 'lodash/get';

export default {
  setup () {
    return {
      v$: useVuelidate()
    };
  },
  methods: {
    touch(path) {
      const validation = path ? get(this.v$, path) : this.v$;
      validation?.$touch();
    },
    validateState(path) {
      const validation = path ? get(this.v$, path) : this.v$;

      return validation?.$dirty ? !validation.$error : null;
    },
    invalid(path, validatorName) {
      const validation = path ? get(this.v$, path) : this.v$;
      if (!validatorName) {
        return validation.$invalid;
      }
      const validator = get(validation, validatorName);

      return validator?.$invalid;
    },
    isValid() {
      this.v$.$touch();

      return !this.v$.$invalid;
    },
    getError() {
      this.v$.$touch();

      return this.v$.$errors;
    },
    resetValidation() {
      this.v$.$reset();
    }
  }
};
