export default class PublicProperties {
    constructor(neoware) {
        this.neoware = neoware;
    }

    static build({neoware}) {
        return new PublicProperties(neoware);
    }

    static parse(value) {
        return value ? PublicProperties.build({
            neoware: value.neoware,
        }) : null;
    }
}