<template>
  <editable-multiselect
    v-model="model"
    :options="options"
    :custom-label="customLabel"
    :placeholder="multiselectPlaceholder"
    :loading="isLoading"
    :allow-empty="allowEmpty"
    :deselect-label="!allowEmpty ? '' : undefined"
    :show-labels="showLabels"
    :class="cssClasses"
    :disabled="disabled"
    @select="$emit('select', $event)"
    @remove="$emit('remove', $event)" />
</template>

<script>
import EditableMultiselect from '@/components/common/EditableMultiselect.vue';
import DropdownMixin from '@/mixins/DropdownMixin';
import SecurityMixin from '@/mixins/SecurityMixin';
import {findAllKitStatuses} from '@/service/SmazentechService';

export default {
  components: {
    'editable-multiselect': EditableMultiselect
  },
  mixins: [
    DropdownMixin,
    SecurityMixin
  ],
  props: {
    value: {
      type: String,
      default: undefined
    },
    kitId: {
      type: Number,
      default: undefined
    },
    placeholder: {
      type: String,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    multiselectPlaceholder() {
      return this.placeholder !== undefined ? this.placeholder : this.$t('formSelectStatus');
    }
  },
  methods: {
    findOptions() {
      return findAllKitStatuses();
    },
    customLabel(value) {
      return value;
    }
  }
};
</script>