import {getField, updateField} from 'vuex-map-fields';
import {findRun, getRunsPerPage} from '@/service/SmazentechService';
import Run from '../../models/Run';

const state = {
    runs: [],
    runCount: 0,
    runPerPage: 13,
    run: new Run()
};

const getters = {
    getField
};

export const mutations = {
    updateField
};

export const actions = {
    findRuns({commit}, {page, search, sort, sortDirection}) {
        return new Promise((resolve, reject) => {
            getRunsPerPage(page, state.runPerPage, search, sort, sortDirection)
                .then(data => {
                    const runs = data.listObjects.map(run => Run.parse(run));
                    commit('updateField', {path: 'runCount', value: data.numberOfObjects});
                    commit('updateField', {path: 'runs', value: runs});
                    resolve(runs);
                })
                .catch(error => reject(error));
        });
    },
    findRun({commit}, id) {
        return new Promise((resolve, reject) => {
            findRun(id)
                .then(run => {
                    commit('updateField', {path: 'run', value: run});
                    resolve(run);
                })
                .catch(error => reject(error));
        });
    },
    setRunPerPage({commit}, runPerPage) {
        commit('updateField', {path: 'runPerPage', value: runPerPage});
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
