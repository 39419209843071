<template>
  <div
    v-if="shouldDisplay"
    class="well"
    :class="wellSampleClasses"
    :style="wellStyle"
    @click="onClick($event)">
    <div
      v-if="!valid"
      class="well-cross" />
    <div
      v-if="badged"
      class="well-badge" />
    <div
      v-if="isIncomplete"
      :class="`incomplete-well ${plateType === PlateType.RECTANGULAR_96 ? 'plate-96' : 'plate-384'}`"
      @mouseover="mouseOverMissingParameter"
      @mouseleave="mouseLeaveMissingParameter">
      <span
        :ref="`missingParameterSpan`"
        :class="`missing-parameter ${positionCssClass}`"
        :hidden="true"
        v-html="missingParameter" />
    </div>
  </div>
  <div
    v-else
    class="well not-analyzed" />
</template>

<script>
import { mapGetters } from 'vuex';
import PlateSelectableCellMixin from './PlateSelectableCellMixin';
import { getColor, getDiagnostic, isSampleTypeOfControl } from '@/models/Well';
import PlateType from '../../../../models/PlateType';
import { mapFields } from 'vuex-map-fields';

export default {
  mixins: [ PlateSelectableCellMixin ],
  props: {
    well: {
      type: Object,
      default: undefined
    },
    selected: {
      type: Boolean,
      default: false
    },
    badged: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    PlateType() {
      return PlateType;
    },
    ...mapGetters('runImport', [
      'isRunInterpreted'
    ]),
    ...mapFields('runImport', [
      'run.plateType'
    ]),
    sampleType() {
      return this.well ? this.well.sampleType?.type : null;
    },
    diagnostic() {
      const diagnostic =  this.well ? getDiagnostic(this.well) : null;

      return diagnostic ?? null;
    },
    color() {
      return getColor(this.well);
    },
    validityType() {
      return this.diagnostic?.validityType;
    },
    valid() {
      return this.validityType === 'VALID';
    },
    shouldDisplay() {
      if (!this.well) {
        return false;
      }

      return this.well.analyseWell;
    },
    wellSampleClasses() {
      const sampleTypeClass = `${this.sampleType?.toLowerCase().replaceAll('_','-')}-type`;
      const validityTypeClass = `${this.validityType?.toLowerCase().replaceAll('_','-')}-type`;

      return {
        'focused': this.selected,
        'control': isSampleTypeOfControl(this.well.sampleType),
        [sampleTypeClass]: true,
        [validityTypeClass]: true,
        'abnormal': this.well?.interpretation && !this.diagnostic
      };
    },
    wellStyle() {
      return this.color ? `background: ${this.color}` : '';
    },
    isIncomplete() {
      if (!this.well.position) {
        return false;
      }
      if (this.selected) {
        return false;
      }

      return this.well
          && (!!this.well.kit || !!this.well.lot || !!this.well.sampleType)
          && !(this.well.kit && this.well.lot && this.well.sampleType);
    },
    missingParameter() {
      if (!this.well) {
        return '';
      }

      const missingParameter = [];
      if (!this.well.kit) {
        missingParameter.push(this.$t('plate.missing.kit'));
      }
      if (!this.well.lot) {
        missingParameter.push(this.$t('plate.missing.lot'));
      }
      if (!this.well.sampleType) {
        missingParameter.push(this.$t('plate.missing.sampleType'));
      }

      return missingParameter.length > 0 ? missingParameter.join('<br/>') : '';
    },
    positionCssClass() {
      if (!this.well.position) {
        return ;
      }
      let cssClass = parseInt(this.well.position.slice(1, this.well.position.length)) > this.plateType.columnCount / 2 ? 'left' : 'right';
      if (this.well.position.charCodeAt(0) - 64 > this.plateType.rowCount / 2) { // 65 == A, we want that A == 1. For A, charCodeAt returns 65 - 64 = 1
        cssClass += ' top';
      }

      return cssClass;
    }
  },
  methods: {
    mouseOverMissingParameter() {
      this.$refs.missingParameterSpan.hidden = false;
    },
    mouseLeaveMissingParameter() {
      this.$refs.missingParameterSpan.hidden = true;
    }
  }
};
</script>
