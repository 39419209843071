import Vue from 'vue';
import App from './App.vue';

import Vuex from 'vuex';
import Multiselect from 'vue-multiselect';
import Icon from 'vue-awesome/components/Icon';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';

import {BootstrapVue, IconsPlugin} from 'bootstrap-vue';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import {far} from '@fortawesome/free-regular-svg-icons';
import {fas} from '@fortawesome/free-solid-svg-icons';

// Application specific
import {i18n} from './translation/i18n.js';
import {store} from './store/store';
import router from './router';

// CSS
import 'vue-multiselect/dist/vue-multiselect.min.css';
import './assets/css/custom.scss';
import 'vue-awesome/icons';
import ECharts from 'vue-echarts';

library.add(far);
library.add(fas);

Vue.use(Vuex);

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

// register globally
Vue.component('Multiselect', Multiselect);
Vue.component('FontAwesomeIcon', FontAwesomeIcon);
Vue.component('VChart', ECharts);
Vue.component('VIcon', Icon);
Vue.component('DatePicker', DatePicker);

// Plugins configuration
Vue.config.productionTip = false;

Vue.filter('capitalize', function (value) {
  return value ? String(value).charAt(0).toUpperCase() + String(value).slice(1) : value;
});

Vue.filter('capitalizeAll', function (value) {
  return value.replace(/\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
});

Vue.filter('uppercase', function (value) {
  return value ? String(value).toUpperCase() : value;
});
export default new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
});
