<template>
  <app-comments-form
    ref="form"
    :comments="comments"
    @save="save" />
</template>

<script>
import {createWellsComment, updateComment} from '@/service/SmazentechService';
import CommentsForm from './CommentsForm.vue';

export default {
  components: {
    'app-comments-form': CommentsForm
  },
  props: {
    runId: {
      type: Number,
      default: () =>  undefined
    },
    wells: {
      type: Array,
      default: () => []
    },
    comments: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    well() {
      return this.wells[0];
    }
  },
  methods: {
    save(comment) {
      let save;
      if (comment.id) {
        const position = this.well.position;
        const comments = this.well.comments;
        const commentId = comment.id;
        save = updateComment(this.runId, commentId, comment.value)
          .then(updatedComment => {
            const updates = comments.slice();
            updates.splice(comments.findIndex(c => c.id === commentId), 1, updatedComment);

            return [ { position, comments: updates  } ];
          });
      } else {
        save = createWellsComment(this.runId, this.wells.map(w => w.id), comment.value);
      }
      save.then(wellComments => {
        this.$refs.form.reset();
        this.$emit('updated', wellComments);
      })
        .catch(error => this.showErrorNotification(this.$t('errorCommentCreation'), error));
    }
  }
};
</script>