<template>
  <div class="h-100 d-flex">
    <div class="col-3 p-0 border-right h-100">
      <b-form-input
        :placeholder="$t('laboratory.search') | capitalize"
        class="form-control laboratory-search"
        type="text"
        :value="search"
        @input="updateSearch" />
      <b-table
        responsive
        striped
        hover
        show-empty
        selectable
        :select-mode="'single'"
        :fields="fields"
        :items="laboratories"
        :empty-filtered-text="$t('noDataFound')"
        :empty-text="$t('noDataFound')"
        :no-local-sorting="true"
        sort-by="name"
        @row-selected="selectLaboratory"
        @sort-changed="reloadSort" />
      <b-button
        id="addLaboratory"
        class="btn-sm list-add-button"
        variant="success"
        @click="addLaboratory">
        <font-awesome-icon :icon="['fas', 'plus']" />
        {{ $t('common.add') }}
      </b-button>
      <b-pagination
        v-if="laboratories.length"
        :value="page.index"
        align="center"
        :total-rows="laboratoryCount"
        :per-page="page.size"
        @change="pageChange" />
    </div>
    <div class="col-9 p-0 h-100">
      <app-laboratory
        ref="appLaboratory"
        @updated="pageChange(page.index)"
        @deleted="pageChange(page.index)" />
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import Laboratory from '@/components/admin/laboratory/Laboratory';

export default {
  name: 'Laboratories',
  components: {
    'app-laboratory': Laboratory
  },
  data() {
    return {
      search: undefined,
      timer: undefined,
      page: {
        index : 1,
        size: 9,
        sort: undefined
      }
    };
  },
  computed: {
    ...mapFields('laboratory', [
      'laboratories',
      'laboratoryCount',
      'laboratoryPerPage',
      'laboratory'
    ]),
    fields() {
      return [
        {
          key: 'name',
          label: this.$options.filters.capitalize(this.$t('laboratory.name')),
          sortable: true
        }
      ];
    }
  },
  beforeMount() {
    this.pageChange(1);
    // clean the selection before displaying the screen
    this.$store.dispatch('laboratory/selectLaboratory', null)
      .catch(error => {
        this.$bvToast.toast(error.message, {
          title: this.$options.filters.capitalize(this.$t('error')),
          variant: 'danger',
          autoHideDelay: '3000',
          toaster: 'b-toaster-bottom-left'
        });
      });
  },
  methods: {
    reloadSort(ctx) {
      this.page.sort = `${ctx.sortBy},${ctx.sortDesc ? 'desc' : 'asc'}`;
      this.pageChange(1);
    },
    updateSearch(search) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      if (search.length >= 1) {
        this.search = search;
      } else {
        this.search = undefined;
      }
      this.timer = setTimeout(() => this.pageChange(1), 1000);
    },
    pageChange(index) {
      this.page.index = index;
      this.$store.dispatch('laboratory/findLaboratories', { page: this.page, search: this.search })
        .catch(error => {
          this.$bvToast.toast(error.message, {
            title: this.$options.filters.capitalize(this.$t('error')),
            variant: 'danger',
            autoHideDelay: '3000',
            toaster: 'b-toaster-bottom-left'
          });
        });
    },
    selectLaboratory(laboratories) {
      this.$store.dispatch('laboratory/selectLaboratory', laboratories[0])
        .then(() => {
          this.$refs?.appLaboratory?.$refs?.appLaboratoryUsers?.pageChange(1);
        })
        .catch(error => {
          this.$bvToast.toast(error.message, {
            title: this.$options.filters.capitalize(this.$t('error')),
            variant: 'danger',
            autoHideDelay: '3000',
            toaster: 'b-toaster-bottom-left'
          });
        });
    },
    addLaboratory() {
      this.$router.push({ name: 'laboratory-add' });
    }
  }
};
</script>