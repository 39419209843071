export default class LotChangesData {
  constructor(userName, modifications, modificationDate, type, kitVersion, lot) {
    this.userName = userName ? userName : null;
    this.modifications = modifications ? modifications : null;
    this.modificationDate = modificationDate ? modificationDate : null;
    this.type = type ? type : null;
    this.kitVersion = kitVersion ? kitVersion : null;
    this.lot = lot ? lot : null;
  }

  static build({ userName, modifications, modificationDate, type, kitVersion, lot }) {
    return new LotChangesData(userName, modifications, modificationDate, type, kitVersion, lot);
  }

  static parse(value) {
    return value ? new LotChangesData(
      value.userName,
      value.modifications,
      value.modificationDate ? new Date(value.modificationDate) : null,
      value.type,
      value.kitVersion,
      value.lot
    ) : null;
  }

  toJSON() {
    return {
      userName: this.userName,
      modifications: this.modifications,
      modificationDate: this.modificationDate,
      type: this.type,
      kitVersion: this.kitVersion,
      lot: this.lot
    };
  }
}