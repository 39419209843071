import axios from 'axios';
import * as Qs from 'qs';

require('dotenv/config');

export const SmazentechApi = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, X-Custom-Information'
  },
  paramsSerializer: params => Qs.stringify(params, { arrayFormat: 'repeat' })
});

export const SmazentechMultipartApi = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'multipart/form-data',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, X-Custom-Information'
  }
});

export const SmazentechDownloadFile = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/zip',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, X-Custom-Information'
  },
  responseType: 'blob'
});

export const authorize = () => ({ 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` });
