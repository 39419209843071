<template>
  <div>
    <app-information-header
      :business-id="businessId"
      :file-name="fileName"
      :created-by-name="createdByName" />
    <b-container>
      <b-form-group>
        <h3>{{ $t('subTitlePageRun') }}</h3>
      </b-form-group>
      <b-form-row>
        <b-col sm="4">
          <b-form-group
            :label="$t('formAnalyser')">
            <b-input-group>
              <b-form-input
                v-model="instrumentName"
                :state="validateState('instrumentName')"
                type="text" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group
            :label="$t('formPlateId')">
            <b-input-group>
              <b-form-input
                v-model="plateId"
                type="text" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group
            :label="$t('formRunDate')">
            <b-input-group>
              <b-form-input
                v-model="created"
                disabled
                type="date" />
              <h3 class="ml-2">
                <font-awesome-icon :icon="['far', 'calendar-alt']" />
              </h3>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-form-row>
      <app-kit-and-lot-input-rows
        :state="validateState('availableLots')"
        @input="updateLotAndKit" />
      <hr
        class="my-3"
        color="lightgrey">
      <h4>{{ $t('importRunSelectPlatePlan') }}</h4>
      <b-form-row>
        <b-col lg="5">
          <app-plate-type-dropdown
            v-model="plateType"
            :allow-empty="true" />
        </b-col>
        <b-col lg="7">
          <app-file-inputs
            ref="platePlan"
            v-model="plateTemplateFile"
            :placeholder="$t('formLoadPlateFile')"
            :accepts="plateType ? plateType.extension : null"
            @input="setPlateTemplateFile" />
        </b-col>
      </b-form-row>
      <br><br><br>
    </b-container>
    <b-form-row>
      <b-container class="d-flex flex-row align-items-center justify-content-between p-4">
        <app-cancel-run />
        <b-button
          ref="nextButton"
          @click="validateRun">
          {{ $t('next') }}
        </b-button>
      </b-container>
    </b-form-row>
    <app-confirmation-modal
      ref="selectedLotsIsExpiredModal"
      :message="$t('modalSelectedLotsExpired.message')"
      :ok-button-name="$t('modalSelectedLotsExpired.okButtonText')"
      :ok-button-classes="['run-deletion-modal-ok-button']"
      :cancel-button-enabled="false"
      ok-button-variant="outline-danger"
      header-text-variant="danger"
      @ok="parseFile()" />
  </div>
</template>

<script>

import {mapFields} from 'vuex-map-fields';
import {required} from '@vuelidate/validators/dist/index.cjs';
import RunInformationHeader from '../../../RunInformationHeader';
import KitAndLotInputRows from './KitAndLotInputRows';
import ValidationMixin from '@/mixins/ValidationMixin';
import NotificationMixin from '@/mixins/NotificationMixin';
import CancelRun from '../../../CancelRun';
import ConfirmationModal from '../../../../../common/ConfirmationModal';
import {
  getSampleTypesForRunConfiguration,
  parsePlatePlan,
  promiseSequence
} from '../../../../../../service/SmazentechService';
import PlateTypeDropdown from '@/components/common/PlateTypeDropdown.vue';
import FileInputs from '@/components/run/import/FileInputs.vue';

export default {
  components: {
    'app-information-header': RunInformationHeader,
    'app-kit-and-lot-input-rows': KitAndLotInputRows,
    'app-cancel-run': CancelRun,
    'app-confirmation-modal': ConfirmationModal,
    'app-plate-type-dropdown': PlateTypeDropdown,
    'app-file-inputs': FileInputs
  },
  mixins: [
    NotificationMixin,
    ValidationMixin
  ],
  data() {
    return {
      plateTemplateFile: undefined,
      plateType: undefined,
      fetching: undefined
    };
  },
  computed: {
    ...mapFields('runImport', [
      'run',
      'run.fileName',
      'run.instrumentName',
      'run.plateId',
      'run.created',
      'run.createdByName',
      'run.lotName',
      'runFormStep',
      'run.businessId',
      'run.analyser',
      'availableLots',
      'availableKits',
      'availableSampleTypes'
    ])
  },
  validations: {
    instrumentName: {
      required
    },
    availableLots: {
      atLeastOneLot: value => value.length > 0
    }
  },
  methods: {
    goToRunWellsMetadata() {
      this.runFormStep = 2;
    },
    validateRun() {
      if (this.isValid()) {
        const isExpired = this.isLotDateValid();
        if ((!this.plateTemplateFile && this.plateType) || (this.plateTemplateFile && !this.plateType)) {
          this.showErrorNotification(this.$t('error'), this.$t('invalidTemplateError'));

          return;
        }

        if (isExpired) {
          this.$refs.selectedLotsIsExpiredModal.showModal();
        } else {
          //call backend parse plate plan file
          this.parseFile();
        }
      }
    },
    isLotDateValid() {
      let isExpired = false;
      const now = new Date();
      now.setHours(0, 0, 0, 0);
      this.availableLots.forEach(lot => {
        if (lot.expirationDate && now > lot.expirationDate) {
          isExpired = true;
        }
      });

      return isExpired;
    },
    updateLotAndKit(lots) {
      this.availableLots = lots;
      this.availableKits = lots
        .map(availableLot => availableLot.kitVersion.kit)
        .filter((value, index, array) => array.map(availableKit => availableKit.id).indexOf(value.id) === index);
      this.fetching = promiseSequence(this.fetching, getSampleTypesForRunConfiguration(this.availableLots.map(l => l.id), this.analyser.id))
        .then(sampleTypes => this.availableSampleTypes = sampleTypes)
        .then(this.fetching = undefined);
    },
    setPlateTemplateFile() {
      if (this.plateTemplateFile.length) {
        this.plateTemplateFile = this.plateTemplateFile[0];
      } else {
        this.plateTemplateFile = undefined;
      }
    },
    parseFile() {
      parsePlatePlan(this.run, this.plateTemplateFile, this.plateType?.plateName, this.availableLots.map(lot => lot.id))
        .then(run => {
          if (this.plateTemplateFile) {
            const updates = new Map();
            for (const well of run.wells.values()) {
              if (well.sampleType) {
                if (this.availableLots.length === 1) {
                  const lot = this.availableLots[0];
                  well.kit = lot.kitVersion.kit;
                  well.lot = lot;
                } else if (this.availableKits.length === 1) {
                  well.kit = this.availableKits[0];
                  well.lot = null;
                } else {
                  well.kit = null;
                  well.lot = null;
                }
              }
              updates.set(well.position, well);
            }
            run.wells = updates;
          }
          this.$store.dispatch('runImport/setCurrentRun', run);
          this.goToRunWellsMetadata();
        })
        .catch(error => this.showErrorNotification(this.$t('error'), error));
    }
  }
};
</script>
