<template>
  <b-container>
    <b-row><b class="mr-1">{{ $t('wellMetaDataWellName') }}:</b>{{ wellName }}</b-row>
    <b-row><b class="mr-1">{{ $t('wellMetaDataKit') }}:</b>{{ kitName }}{{ isAdmin || isDoctor ? ` (${kitVersionName})` : '' }}</b-row>
    <b-row><b class="mr-1">{{ $t('wellMetaDataLot') }}:</b>{{ lotName }}</b-row>
    <b-row><b class="mr-1">{{ $t('wellMetaDataSampleType') }}:</b>{{ sampleTypeName }}</b-row>
  </b-container>
</template>

<script>
import SecurityMixin from '@/mixins/SecurityMixin';
import SampleType from '../../../../../models/SampleType';

export default {
  mixins: [ SecurityMixin ],
  props: {
    well: {
      type: Object,
      required: true
    }
  },
  computed: {
    isAdmin() {
      return this.hasRole('ADMIN');
    },
    isDoctor() {
      return this.hasRole('WRITE_DIAGNOSTIC');
    },
    wellName() {
      return this.well?.name;
    },
    kitName() {
      return this.well?.kit?.name ? this.well.kit.name : '';
    },
    kitVersion() {
      return this.well?.kitVersion;
    },
    kitVersionName() {
      if (this.kitVersion?.source) {
        return `${this.kitVersion?.source?.version}-${this.kitVersion?.laboratoryName?.toLowerCase()}-${this.kitVersion?.version}`;
      }

      return this.kitVersion?.version;
    },
    lotName() {
      return this.well?.lot?.name;
    },
    sampleTypeName() {
      return this.well?.sampleType ? this.$options.filters.capitalize(SampleType.labelize(this.well?.sampleType)) : '';
    }
  }
};
</script>
