import {i18n} from '@/translation/i18n';

export default class Diagnostic {
  constructor(id, diagnosticType, label, color, validityType, control, conditions, index) {
    this.id = id;
    this.index = index;
    this.diagnosticType = diagnosticType;
    this.label = label;
    this.color = color;
    this.validityType = validityType;
    this.control = control;
    this.conditions = conditions;
  }

  static build({ id, diagnosticType, label, color, validityType, control, conditions, index }) {
    return new Diagnostic(id, diagnosticType, label, color, validityType, control, conditions, index);
  }

  static parse(value) {
    return value ? Diagnostic.build({
      ...value,
      control: value.control ? { ...value.control } : null,
      conditions: value.conditions?.map(c => ({ ...c, expressions: c.expressions?.map(e => ({ ...e })) }))
    }) : null;
  }

  static singleCondition() {
    return { expressions: [ {} ] };
  }

  static labelize(label, hasControl, controlLabel, sampleType, valid) {
    if (hasControl) {
      const controlName = controlLabel ? controlLabel : i18n.t(sampleType);
      label = i18n.t(valid ? 'captionValid' : 'captionInvalid', [ controlName ]);
    }

    return label;
  }

  getLabel() {
    return Diagnostic.labelize(this.label, this.control, this.control?.label, this.control?.sampleType, this.isValid());
  }

  isValid() {
    return this.validityType === 'VALID';
  }

  isInvalid() {
    return this.validityType === 'INVALID';
  }

  toJSON() {
    return {
      id: this.id !== undefined && this.id !== '' ? this.id : null,
      index: this.index !== undefined && this.index !== '' ? this.index : null,
      diagnosticType: this.diagnosticType !== undefined && this.diagnosticType !== '' ? this.diagnosticType : null,
      label: this.label !== undefined && this.label !== '' ? this.label : null,
      color: this.color !== undefined && this.color !== '' ? this.color : null,
      validityType: this.validityType !== undefined && this.validityType !== '' ? this.validityType : null,
      control: this.control !== undefined && this.control !== '' ? this.control : null,
      conditions: this.conditions !== undefined ? this.conditions : null
    };
  }
}
