<template>
  <div class="plate-captions">
    <h3> {{ $t('captionTitle') }}</h3>
    <b-jumbotron
      bg-variant="transparent"
      border-variant="dark"
      class="captions-groups clearfix init-jumbotron p-2">
      <div
        v-for="group in groups"
        :key="group.key"
        class="captions-group"
        :class="`${group.key}-group`">
        <h5
          v-if="mode === 'interpretation'"
          class="dotted-text">
          {{ group.title }}
        </h5>

        <template
          v-for="caption in group.captions">
          <slot
            name="caption"
            :caption="caption">
            <app-plate-caption :caption="caption" />
          </slot>
        </template>
      </div>

      <div class="clearfix" />
    </b-jumbotron>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import PlateCaption from './PlateCaption.vue';
import Well, {getColor, getDiagnostic} from '@/models/Well';
import Diagnostic from '@/models/Diagnostic';
import SampleType from '@/models/SampleType';

const GROUP_KEY_SAMPLE = 'sample';
const GROUP_KEY_CONTROL = 'control';

export default {
  name: 'PlateCaptions',
  components: {
    'app-plate-caption': PlateCaption
  },
  props: {
    mode: {
      type: String, // configuration or interpretation
      default: 'configuration'
    },
    sampleTypes: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters('runImport', [
      'wells'
    ]),
    groups() {
      if (!this.sampleTypes?.length) {
        return [];
      }

      const groups = new Map();
      for (const sampleType of this.sampleTypes) {
        const key = this.groupKey(sampleType);
        if (!groups.has(key)) {
          groups.set(key, this.createGroup(key, sampleType));
        }
        if (key === GROUP_KEY_SAMPLE && !this.hasWellAbnormal && sampleType.diagnosticType === 'ABNORMAL') {
          continue;
        }
        groups.get(key).legends.push(sampleType);
      }

      const sample = groups.get(GROUP_KEY_SAMPLE);
      groups.delete(GROUP_KEY_SAMPLE);
      const sorted = (sample ? [sample] : []).concat(Array.from(groups.values()));

      return sorted.map(group => {
        const captions = group.legends
          .filter(sampleType => !!sampleType)
          .reduce(
            (map, sampleType) => {
              const caption = this.createCaption(sampleType);
              map.set(`${getColor(caption.well)?.toLowerCase()}-${caption.label}`, caption);

              return map;
            }, new Map()
          );
        if (group.key === GROUP_KEY_SAMPLE && this.hasWellNotConfigured) {
          captions.set('not-configured', { well: { analyseWell: true }, label: this.$t('captionAnalysedWell') });
        }
        if (group.key === GROUP_KEY_SAMPLE && this.hasWellNotAnalysed) {
          captions.set('not-analysed', { well: null, label: this.$t('captionNonAnalysedWell') });
        }

        return {
          key: group.key,
          title: group.title,
          captions: Array.from(captions.values())
        };
      });
    },
    wellArray() {
      return Array.from(this.wells?.values());
    },
    hasWellAbnormal() {
      return this.wellArray.some(well => getDiagnostic(well)?.diagnosticType === 'ABNORMAL');
    },
    hasWellNotConfigured() {
      return this.wellArray.some(well => well.analyseWell && !well.sampleType);
    },
    hasWellNotAnalysed() {
      return this.wellArray.some(well => !well.analyseWell || well.interpretation == null);
    }
  },
  methods: {
    groupKey(sampleType) {
      switch (this.mode) {
        case 'configuration':
          return sampleType?.controlId ? GROUP_KEY_CONTROL : GROUP_KEY_SAMPLE;
        case 'interpretation':
          return sampleType?.controlId ? this.controlGroupTitle(sampleType) : GROUP_KEY_SAMPLE;
      }
    },
    groupTitle(sampleType) {
      switch (this.mode) {
        case 'configuration':
          return sampleType.controlId ? this.$t('captionControlWell') : this.sampleGroupTitle();
        case 'interpretation':
          return sampleType.controlId ? this.controlGroupTitle(sampleType) : this.sampleGroupTitle(sampleType);
      }
    },
    sampleGroupTitle() {
      return `${this.$t('captionSampleWell')} (${this.$t('sampleInitial')})`;
    },
    controlGroupTitle(sampleType) {
      return SampleType.labelize({...sampleType});
    },
    createGroup(key, sampleType) {
      return {
        key,
        title: this.groupTitle(sampleType),
        controlId: sampleType.controlId,
        legends: []
      };
    },
    createCaption(sampleType) {
      if (!sampleType) {
        return null;
      }
      const label = this.captionLabel(sampleType);
      const diagnosticId = sampleType.diagnosticId;
      const well = Well.parse({
        sampleType,
        analyseWell: true,
        diagnostic: { result: {
          ...sampleType,
          id: diagnosticId,
          validityType: sampleType.validityType,
          control: sampleType.controlId ? {
            id: sampleType.controlId,
            controlType: sampleType.controlType,
            sampleType: sampleType.type,
            initial: sampleType.initial
          } : null
        } }
      });

      return { well, label, diagnosticId };
    },
    captionLabel(sampleType) {
      switch (this.mode) {
        case 'configuration':
          return sampleType.controlId ? this.controlGroupTitle(sampleType) : SampleType.labelize({...sampleType});
        case 'interpretation':
          return this.interpretationLabel(sampleType);
      }
    },
    interpretationLabel(sampleType) {
      return Diagnostic.labelize(sampleType.label, Number.isFinite(sampleType.controlId), sampleType.label, sampleType.type, sampleType.validityType === 'VALID');
    }
  }
};
</script>