<template>
  <div>
    <v-chart
      :option="option"
      :update-options="updateOptions"
      class="chart-container"
      autoresize/>
    <b-form-group>
      <b-form-checkbox
        v-model="isLogarithmic">
        {{ $t('logarithmicVue') }}
      </b-form-checkbox>
      <div class="d-flex justify-content-center">
        <b-button-group>
          <template v-for="channel in channels">
            <b-button
              :key="channel.name"
              :variant="getVariant(channel)"
              @click="setSelectedChannels(channel)">
              {{ channel.name }}
            </b-button>
          </template>
        </b-button-group>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import VChart from 'vue-echarts';

import { CanvasRenderer } from 'echarts/renderers';
import { LineChart } from 'echarts/charts';
import {
  DataZoomComponent,
  GridComponent,
  LegendComponent,
  MarkLineComponent,
  MarkPointComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { use } from 'echarts/core';

use([
  CanvasRenderer,
  LineChart,
  TitleComponent,
  LegendComponent,
  GridComponent,
  DataZoomComponent,
  MarkPointComponent,
  MarkLineComponent,
  TooltipComponent
]);

export default {
  components: {
    'v-chart': VChart
  },
  props: {
    wellChartDiagnostics: {
      type: Array,
      default: () => []
    },
    channels: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      updateOptions: {
        replaceMerge: [ 'series' ]
      },
      selectedChannels: [],
      isLogarithmic: false
    };
  },
  computed: {
    option() {
      const series = [ ...this.wellLines ];
      if (this.thresholdLine) {
        series.unshift(...this.thresholdLine);
      }

      return {
        title: {
          text: this.$t('wellInterpretationChartTitle'),
          left: 'center'
        },
        legend: {
          type: 'scroll',
          orient: 'horizontal',
          right: 10,
          top: 20,
          bottom: 20
        },
        grid: {
          left: '14%',
          top: '25%'
        },
        xAxis: {
          name: this.$t('wellInterpretationChartXAxis'),
          nameLocation: 'middle',
          nameGap: 20,
          type: 'value',
          max: this.wellCharts.length > 0 ? this.wellCharts[0].channels[0].line.length : undefined
        },
        yAxis: {
          name: this.$t('wellInterpretationChartYAxis'),
          nameLocation: 'middle',
          nameGap: 60,
          type: this.isLogarithmic ? 'log' : 'value',
          min: this.isLogarithmic ? 1 : 0,
          max: this.isChannelCqPresent ? null : this.chartMax + 50
        },
        dataZoom: [
          {
            type: 'slider',
            xAxisIndex: [ 0 ],
            filterMode: 'none',
            bottom: 15,
            height: 20
          },
          {
            type: 'inside',
            xAxisIndex: [ 0 ],
            filterMode: 'none'
          },
          {
            type: 'slider',
            yAxisIndex: [ 0 ],
            filterMode: 'none',
            right: 15,
            width: 20
          },
          {
            type: 'inside',
            yAxisIndex: [ 0 ],
            filterMode: 'none'
          }
        ],
        series
      };
    },
    wellCharts() {
      return this.wellChartDiagnostics.map(wellChartDiagnostic => wellChartDiagnostic.chart);
    },
    isChannelCqPresent() {
      return this.wellCharts.flatMap(wc => wc.channels)
        .filter(ch => this.selectedChannels.includes(ch.name))
        .map(ch => ch.cq)
        .some(cq => cq != null);
    },
    chartMax() {
      return Math.max(...this.wellCharts[0].channels.filter(ch => this.selectedChannels.includes(ch.name)).map(ch => ch.threshold));
    },
    thresholdLine() {
      const channels = this.channels.filter(c => this.selectedChannels.includes(c.name));
      if (!channels) {
        return null;
      }

      return channels.map(channel => ({
        type: 'line',
        markLine: {
          symbol: [ 'none', 'none' ],
          emphasis: {
            label: { show: true }
          },
          label: { show: false },
          lineStyle: {
            color: '#ff0000',
            type: 'dashed'
          },
          data: [ { yAxis: channel.threshold } ]
        }
      }));

    },
    wellLines() {
      if (!this.selectedChannels) {
        return [];
      }

      return this.wellChartDiagnostics.flatMap(wellChartDiagnostic => {
        const wellChart = wellChartDiagnostic.chart;
        const wellDiagnostic = wellChartDiagnostic.diagnostic;
        const channels = wellChart.channels.filter(c => this.selectedChannels.includes(c.name));
        if (!channels) {
          return { name: wellChart.position };
        }

        return channels.map(channel => {
          const cq = channel.cq && channel.threshold ? {
            xAxis: channel.cq,
            yAxis: channel.threshold,
            value: `${channel.cq.toFixed(2)}`
          } : null;
          const colorLine = wellDiagnostic?.control ? wellDiagnostic.color : channel.name === 'FAM' ? '#00aa00' : '#0000aa';
          const colorFocusedLine = channel.name === 'FAM' ? '#00ff00' : '#55bbff';

          return {
            name: channels.length === 1 ? wellChart.position : `${wellChart.position}_${channel.name}`,
            type: 'line',
            smooth: 0.6,
            symbol: 'none',
            lineStyle: {
              color: colorLine
            },
            itemStyle: {
              color: colorLine
            },
            markPoint: this.wellCharts.length === 1 && cq ? { data: [ cq ] } : null,
            data: channel.line.map(point => [ point.x, point.y ]),
            emphasis: {
              focus: 'self',
              blurScope: 'series',
              lineStyle: {
                color: colorFocusedLine
              },
              itemStyle: {
                color: colorFocusedLine
              },
              endLabel: {
                show: true,
                fontWeight: 'bold',
                fontSize: 18,
                formatter: params => params.componentType === 'series' ? params.seriesName.split('_')[0] : undefined
              }
            }
          };
        });
      });
    }
  },
  watch: {
    series(series) {
      this.option.series = series;
    },
    wellChartDiagnostics() {
      this.selectedChannels = this.channels.map(channel => channel.name);
    }
  },
  beforeMount() {
    this.selectedChannels = this.channels.map(channel => channel.name);
  },
  methods: {
    getVariant(channel) {
      return this.selectedChannels.includes(channel.name) ? 'secondary' : 'outline-secondary';
    },
    setSelectedChannels(channel) {
      if (this.selectedChannels.includes(channel.name)) {
        this.selectedChannels.splice(this.selectedChannels.indexOf(channel.name), 1);
      } else {
        this.selectedChannels.push(channel.name);
      }
    }
  }
};
</script>