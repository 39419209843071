import Kit from '@/models/Kit';
import Lot from '@/models/Lot';

export default class KitVersion {
  constructor(id, kit, version, locked, laboratoryId, laboratoryName, laboratoryVersionsConfiguredSize, source, lots, analysers, officialVersionId, deleted) {
    this.id = id;
    this.kit = kit;
    this.version = version;
    this.locked = locked;
    this.laboratoryId = laboratoryId;
    this.laboratoryName = laboratoryName;
    this.laboratoryVersionsConfiguredSize = laboratoryVersionsConfiguredSize;
    this.source = source;
    this.lots = lots;
    this.analysers = analysers;
    this.officialVersionId = officialVersionId;
    this.deleted = deleted;

  }

  static build({ id, kit, version, locked, laboratoryId, laboratoryName, laboratoryVersionsConfiguredSize, source, lots, analysers, officialVersionId, deleted }) {
    return new KitVersion(id, kit, version, locked, laboratoryId, laboratoryName, laboratoryVersionsConfiguredSize, source, lots, analysers, officialVersionId, deleted);
  }

  static parse(value) {
    return value ? KitVersion.build({
      id: value.id,
      kit: Kit.parse(value.kit),
      version: value.version,
      locked: value.locked,
      laboratoryId: value.laboratoryId,
      laboratoryName: value.laboratoryName,
      laboratoryVersionsConfiguredSize: value.laboratoryVersionsConfiguredSize,
      source: value.source ? KitVersion.parse(value.source) : null,
      lots: value.lots ? value.lots.map(l => Lot.parse(l)) : value.lots,
      analysers: value.analysers,
      officialVersionId: value.officialVersionId,
      deleted: value.deleted
    }) : null;
  }

  toJSON() {
    return {
      id: this.id !== undefined || this.id !== '' ? this.id : null,
      kit: this.kit !== undefined ? this.kit : null,
      version: this.version !== undefined || this.version !== '' ? this.version : null,
      locked: this.locked !== undefined ? this.locked : null,
      laboratoryId: this.laboratoryId !== undefined || this.laboratoryId !== '' ? this.laboratoryId : null,
      laboratoryName: this.laboratoryName !== undefined || this.laboratoryName !== '' ? this.laboratoryName : null,
      source: this.source !== undefined ? this.source : null,
      officialVersionId: this.officialVersionId !== undefined ? this.officialVersionId : null,
      deleted: this.deleted !== undefined ? this.deleted : null
    };
  }
}