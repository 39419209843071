<template>
  <header
    class="header"
    role="banner">
    <div class="nav-wrap">
      <nav
        class="main-nav"
        role="navigation">
        <ul class="unstyled list-hover-slide">
          <li>
            <a
              href="#"
              @click="goToRunPage">
              <i class="fa fa-braille fa-2x" />
              <span class="nav-text">
                {{ $t('app.sidebar.runButtonText') }}
              </span>
            </a>
          </li>
          <li v-if="canConfigure">
            <a href="#"
               @click="goToConfigurationPage">
              <i class="fa fa-wrench fa-2x"></i>
              <span class="nav-text">
                {{ $t('app.sidebar.configurationButtonText') }}
              </span>
            </a>
          </li>
          <li v-if="canManageAllLaboratories">
            <a href="#"
               @click="goToLaboratoriesPage">
              <i class="fa fa-flask fa-2x"></i>
              <span class="nav-text">
                {{ $t('app.sidebar.laboratoriesButtonText') }}
              </span>
            </a>
          </li>
          <li v-if="canManageOwnLaboratory">
            <a href="#"
               @click="goToLaboratoryPage">
              <i class="fa fa-flask fa-2x"></i>
              <span class="nav-text">
                {{ $t('app.sidebar.laboratoryButtonText') }}
              </span>
            </a>
          </li>
          <li v-if="canReindexSearchData">
            <a href="#"
               @click="showConfirmationModal()">
              <i class="fa fa-refresh fa-2x"></i>
              <span class="nav-text">
                {{ $t('app.sidebar.reindex') }}
              </span>
            </a>
          </li>
          <li>
            <a
              href="#"
              @click="displayLabel()">
              <i class="fa fa-question-circle fa-2x" />
              <span class="nav-text">
                {{ $t('app.sidebar.aboutButtonText') }}
              </span>
            </a>
          </li>
        </ul>
      </nav>
    </div>

    <app-confirmation-modal
      ref="confirmModal"
      :cancel-button-name="$t('no')"
      :message="$t('modalConfirmReindexMessage')"
      :ok-button-name="$t('yes')"
      :title="$t('modalConfirmReindexTitle')"
      @ok="reindex()" />
    <app-about-modal
      ref="aboutModal"
      :title="$t('modalAboutTitle')"
      :ok-button-name="$t('common.ok')" />
  </header>
</template>

<script>
import SecurityMixin from '@/mixins/SecurityMixin';
import ConfirmationModal from '@/components/common/ConfirmationModal.vue';
import { mapActions } from 'vuex';
import NotificationMixin from '@/mixins/NotificationMixin';
import AboutModal from '../common/AboutModal.vue';

export default {
  components: {
    'app-confirmation-modal': ConfirmationModal,
    'app-about-modal': AboutModal
  },
  mixins: [
    SecurityMixin,
    NotificationMixin
  ],
  computed: {
    isDoctor() {
      return this.hasRole('WRITE_DIAGNOSTIC');
    },
    isAdmin() {
      return this.hasRole('ADMIN');
    },
    laboratory() {
      return this.userLaboratory();
    },
    isManufacturerAdmin() {
      return this.isAdmin && (this.laboratory?.kitManufacturer ?? false);
    },
    isSuperAdmin() {
      return this.hasRole('SUPER_ADMIN');
    },
    canConfigure() {
      return this.isAdmin || this.isDoctor;
    },
    canManageAllLaboratories() {
      return this.isSuperAdmin || this.isManufacturerAdmin;
    },
    canManageOwnLaboratory() {
      return this.isDoctor && !this.canManageAllLaboratories;
    },
    canReindexSearchData() {
      return this.isSuperAdmin;
    }
  },
  methods: {
    ...mapActions('configuration', [
      'reindexData'
    ]),
    goToRunPage() {
      this.$router.push({
        name: 'run-list'
      });
    },
    goToConfigurationPage() {
      this.$router.push({
        name: 'configure'
      });
    },
    goToLaboratoriesPage() {
      this.$router.push({
        name: 'laboratories'
      });
    },
    goToLaboratoryPage() {
      this.$router.push({
        name: 'laboratory'
      });
    },
    showConfirmationModal() {
      this.$refs.confirmModal.showModal();
    },
    displayLabel() {
      this.$refs.aboutModal.showModal();
    },
    reindex() {
      this.reindexData()
        .then(() => this.showSuccessNotification(this.$t('reindexDataSuccessTitle'), this.$t('reindexDataSuccessMessage')))
        .catch(() => this.showSuccessNotification(this.$t('reindexDataErrorTitle'), this.$t('reindexDataErrorMessage')));
    }
  }
};
</script>