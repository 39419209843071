<template>
  <multiselect
    v-model="model"
    track-by="id"
    :options="options"
    :custom-label="extractNameAndVersion"
    :placeholder="$t('formSelectAnalyser')"
    :loading="isLoading"
    :multiple="multiple"
    :allow-empty="allowEmpty"
    :deselect-label="!allowEmpty ? '' : undefined"
    :class="stateClass"
    :disabled="disabled"
    @select="$emit('select', $event)"
    @remove="$emit('remove', $event)" />
</template>

<script>
import DropdownMixin from '../../mixins/DropdownMixin';
import { findAllAnalysers } from '@/service/SmazentechService';

export default {
  mixins: [ DropdownMixin ],
  props: {
    value: {
      type: Array | Object,
      default: undefined
    },
    multiple: {
      type: Boolean,
      default: false
    },
    allowEmpty: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    errorNotificationTitle() {
      return this.$t('analyserDropdownLoadingErrorTitle');
    }
  },
  methods: {
    findOptions() {
      return findAllAnalysers();
    },
    extractNameAndVersion(option) {
      return option.version ? option.manufacturer ? `${option.name} ${option.version} (${option.manufacturer})` : `${option.name} ${option.version}` : option.name;
    }
  }
};
</script>
