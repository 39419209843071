class PlateType {
    static RECTANGULAR_96 = new PlateType('RECTANGULAR_96', 8, 12, 0);
    static RECTANGULAR_384 = new PlateType('RECTANGULAR_384', 16, 24, 1);

    static get values() {
      return [ this.RECTANGULAR_96, this.RECTANGULAR_384 ];
    }

    static fromString(name) {
      const value = this[name];
      if (value) return value;

      throw new RangeError(`No instance of PlateType exists with the name ${name}.`);
    }

    constructor(name, rowCount, columnCount, index) {
      this.name = name;
      this.rowCount = rowCount;
      this.columnCount = columnCount;
      this.index = index;
      Object.freeze(this);
    }

    toJSON() {
      return this.name;
    }
}

export default new Proxy(PlateType, {
  construct() {
    throw new TypeError(`${PlateType.name} is an PlateType; no instances of it can be constructed.`);
  },
  defineProperty() {
    throw new TypeError(`${PlateType.name} is an PlateType; no new properties can be appended to it.`);
  },
  deleteProperty() {
    throw new TypeError(`${PlateType.name} is an PlateType; no new properties can be appended to it.`);
  },
  set() {
    throw new TypeError(`${PlateType.name} is an PlateType; its instances cannot be modified.`);
  },
  setPrototypeOf() {
    throw new TypeError(`${PlateType.name} is an PlateType; its prototype cannot be changed.`);
  }
});
