<template>
  <b-col>
    <b-jumbotron
      class="pb-4 well-configuration-jumbotron"
      border-variant="dark"
      bg-variant="transparent">
      <transition
        name="slide-fade"
        mode="out-in">
        <b-container>
          <app-wells-template-dropdown
            ref="plateTemplateDropdown"
            v-model="selectedPlateTemplate"
            @select="applyPlateTemplate"
            @remove="resetPlateTemplate" />
          <br><br>
          <b-row>
            <b-col
              class="px-1"
              sm="6">
              <b-button @click="$emit('close')">
                {{ $t('close') }}
              </b-button>
            </b-col>
            <b-col
              class="px-1"
              sm="6">
              <b-button
                ref="deleteTemplateButton"
                variant="outline-danger"
                :disabled="!selectedPlateTemplate"
                @click="showTemplateDeletionModal">
                {{ $t('deleteTemplateButton') | capitalizeAll }}
              </b-button>
            </b-col>
            <app-confirmation-modal
              ref="showTemplateDeletionModal"
              :title="$t('deletionTemplateModal.title')"
              :message="$t('deletionTemplateModal.message')"
              :ok-button-name="$t('deletionTemplateModal.okButtonText')"
              :ok-button-classes="['run-deletion-modal-ok-button']"
              ok-button-prevent-hiding
              ok-button-spinners-enabled
              ok-button-variant="outline-danger"
              :cancel-button-name="$t('deletionTemplateModal.cancelButtonText')"
              header-text-variant="danger"
              @ok="deleteTemplate" />
          </b-row>
        </b-container>
      </transition>
    </b-jumbotron>
  </b-col>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import WellsTemplateDropdown from '../../../../../../common/WellsTemplateDropdown';
import ConfirmationModal from '../../../../../../common/ConfirmationModal';
import NotificationMixin from '../../../../../../../mixins/NotificationMixin';
import { getSampleTypesForTemplating } from '@/service/SmazentechService';

export default {
  components: {
    'app-wells-template-dropdown': WellsTemplateDropdown,
    'app-confirmation-modal': ConfirmationModal
  },
  mixins: [
    NotificationMixin
  ],
  data() {
    return {
      selectedPlateTemplate: undefined
    };
  },
  computed: {
    ...mapFields('runImport', [
      'run.analyser'
    ])
  },
  methods: {
    ...mapActions('runImport', [
      'setPlateTemplate',
      'resetPlateTemplate',
      'setAvailableSampleTypeTemplate'
    ]),
    applyPlateTemplate(plateTemplate) {
      this.resetPlateTemplate();
      const kitVersionIds = plateTemplate?.wells?.map(well => well?.kitVersion?.id)
        .filter(id => id)
        .filter((value, index, self) => self.indexOf(value) === index);

      if (kitVersionIds && this.analyser?.id) {
        getSampleTypesForTemplating(kitVersionIds, this.analyser?.id)
          .then(sampleTypes => {
            this.setPlateTemplate({ template: plateTemplate, sampleTypesTemplate : sampleTypes });
          })
          .catch(error => {
            console.error(error);
            this.setPlateTemplate({ template: plateTemplate });
          });

      } else {
        this.setPlateTemplate({ template: plateTemplate });
      }

    },
    showTemplateDeletionModal() {
      this.$refs.showTemplateDeletionModal.showModal();
    },
    deleteTemplate() {
      this.$store.dispatch('plateTemplate/deletePlateTemplate', this.selectedPlateTemplate)
        .then(template => {
          this.showSuccessNotification(
            this.$t('deletionTemplateModal.successNotification.title'),
            this.$t('deletionTemplateModal.successNotification.message', [ template.name ]),
            { root: true });
          this.selectedPlateTemplate = null;
        })
        .catch(error => {
          this.showErrorNotification(this.$t('deletionTemplateModal.errorNotification.title'), error);
        });
      this.$refs.showTemplateDeletionModal.hideModal();
    }
  }
};
</script>
