import AppSmazentech from './index';
import { SmazentechApi, SmazentechMultipartApi, SmazentechDownloadFile } from '@/service/http-common';
import Keycloak from 'keycloak-js';

initAppWithKeycloak();

async function initAppWithKeycloak() {
  let configPath = '/config.json';
  if (process.env.VUE_APP_ENV === 'development') {
    configPath = '/config-dev.json'
  }
  const response = await fetch(configPath);
  const config = await response.json();
  window.top.applicationConfig = config.application;
  SmazentechApi.defaults.baseURL = window.top.applicationConfig.backendUrl;
  SmazentechMultipartApi.defaults.baseURL = window.top.applicationConfig.backendUrl;
  SmazentechDownloadFile.defaults.baseURL = window.top.applicationConfig.backendUrl;
  const keycloakConfig =  config.keycloak;

  const initOptions = {
    url: keycloakConfig.url,
    realm: keycloakConfig.realm,
    clientId: keycloakConfig.clientId,
    onLoad: 'login-required'
  };

  const keycloak = new Keycloak(initOptions);

  //disable iframe and active pkceMethod. Iframe will stop working in future browser update. It's more recommended to use pkce
  keycloak.init({ onLoad: initOptions.onLoad, checkLoginIframe: false, pkceMethod: 'S256' }).then(auth => {
    if (!auth) {
      window.location.reload();
    } else {
      // NOTE: this cannot be explained, but without it, it does not work.
      // Previously was window.location = window.location; but this looks better (and does not generate eslint "no-self-assign" error).
      window.location.assign(window.location.href);
    }

    localStorage.setItem('vue-token', keycloak.token);

    initAppAndModulesSecurityStore(keycloak);
    mountAppAndModules();
    initKeycloakTokenAutoRefresh(keycloak);

    if (!AppSmazentech.$router.currentRoute?.name) { // Avoid NavigationDuplicated error
      // NOTE: Required for Firefox usage. Trigger that initial Vue-router init when being redirected from Keycloak
      AppSmazentech.$router.push(window.location.hash.substring(1));
    }
  }).catch(error => {
    console.log(`Authentication Failed ${error}`);
  });
}

function mountAppAndModules() {
  AppSmazentech.$mount('#app');
}

function initAppAndModulesSecurityStore(keycloakInstance) {
  AppSmazentech.$store.dispatch('security/setKeycloakInstance', keycloakInstance);
}

function initKeycloakTokenAutoRefresh(keycloakInstance) {
  setInterval(() => {
    keycloakInstance.updateToken(70).then(refreshed => {
      if (refreshed) {
        localStorage.setItem('vue-token', keycloakInstance.token);
        initAppAndModulesSecurityStore(keycloakInstance);
      }
    }).catch(() => {
      console.log('Failed to refresh token');
    });
  }, 5000);
}
