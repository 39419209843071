import {getField, updateField} from 'vuex-map-fields';
import {getPublicProperties} from '@/service/SmazentechService';

const state = {
  neoware: {
    run: {
      allowFileDownload: undefined
    }
  }
};

const getters = {
  getField
};

export const mutations = {
  setApplicationProperties(state, applicationProperties) {
    const keys = Object.keys(applicationProperties);
    for (const key of keys) {
      updateField(state, { path: key, value: applicationProperties[key] });
    }
  }
};

export const actions = {
  load({ commit }) {
    return getPublicProperties().then(properties => commit('setApplicationProperties', properties));
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};