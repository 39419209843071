<template>
  <div>
    <app-information-header
      :business-id="businessId"
      :instrument-name="instrumentName"
      :plate-id="plateId"
      :created-by-name="createdByName"
      :file-name="fileName" />
    <b-container>
      <b-form-row>
        <b-col sm="7">
          <b-container class="px-0">
            <b-form-group>
              <h3>{{ $t('formRunConfigurationTitle') }}</h3>
            </b-form-group>
            <div class="overflow-auto">
              <app-plate
                :wells="wells"
                :plate-type="plateType"
                :get-first-well="true"
                @select="handleWellSelection" />
            </div>
            <app-plate-captions
              mode="configuration"
              :sample-types="availableSampleTypes" />
            <h6> {{ $t('templateTitle') }}</h6>
            <b-form-row>
              <b-dropdown
                no-flip
                :text="$t('runWellMetadataDropdown')">
                <b-dropdown-item
                  ref="loadPlateTemplateDropdownItem"
                  @click="openLoadTemplate">
                  {{ $t('buttonLoadTemplate') }}
                </b-dropdown-item>
                <b-dropdown-item
                  ref="savePlateTemplateDropdownItem"
                  :disabled="!isOneParamSampleConfigured"
                  @click="openSaveTemplate">
                  {{ $t('buttonSaveTemplate') }}
                </b-dropdown-item>
              </b-dropdown>
            </b-form-row>
          </b-container>
        </b-col>
        <b-col sm="5">
          <b-container
            v-if="isTemplateWindowOpen"
            class="pr-0">
            <b-form-group>
              <h3>{{ $t('runWellMetadataConfigurePlateTemplateTitle') }}</h3>
            </b-form-group>
            <transition
              appear
              name="fade"
              mode="out-in">
              <app-plate-template-creation-window
                :wells="anonymizedWells"
                :available-templates="availableTemplates"
                :analyser-id="analyser.id"
                @templateSaved="closeTemplateWindowAndReloadTemplates"
                @close="closeTemplateWindowAndReloadTemplates" />
            </transition>
          </b-container>
          <b-container
            v-if="isTemplateDropdownOpen"
            class="pr-0">
            <b-form-group>
              <h3>{{ $t('runWellMetadataLoadPlateTemplateTitle') }}</h3>
            </b-form-group>
            <transition
              appear
              name="fade"
              mode="out-in">
              <app-plate-template-dropdown-window
                ref="plateTemplateDropdownWindow"
                @close="closeTemplateWindowAndReloadTemplates" />
            </transition>
          </b-container>
          <b-container
            v-if="!isTemplateDropdownOpen && !isTemplateWindowOpen && !isSelectedWellsEmpty"
            class="pr-0">
            <b-form-group>
              <h3>{{ $t('runWellMetadataConfigurePlateTitle') }}</h3>
            </b-form-group>
            <transition
              appear
              name="fade"
              mode="out-in">
              <app-well-configuration-window
                ref="plateTemplateConfigurationWindow"
                :vuex-model="configurableWells"
                @close="selectedWells = []" />
            </transition>
          </b-container>
        </b-col>
      </b-form-row>
    </b-container>
    <app-confirmation-modal
      ref="checkControl"
      :title="$t('verifyControlsModalTitle')"
      :message="$t('verifyControlsModalMessage')"
      :ok-button-name="$t('yes')"
      :cancel-button-name="$t('no')"
      @ok="startInterpretation" />
    <div>
      <b-container class="d-flex flex-row align-items-center justify-content-between p-4">
        <app-cancel-run />
        <b-button
          v-if="!isAlgorithmRunning"
          ref="saveWellConfig"
          :disabled="!isOneParamSampleConfigured"
          @click="verifyConfigurationWell()">
          {{ $t('startInterpretation') }}
        </b-button>
        <b-button
          v-if="isAlgorithmRunning"
          disabled>
          <b-spinner
            small
            type="grow" />
          Loading...
        </b-button>
      </b-container>
    </div>
  </div>
</template>

<script>
import RunInformationHeader from '../../../RunInformationHeader';
import { mapFields } from 'vuex-map-fields';
import Plate from '../../../plate/Plate';
import WellConfigurationWindow from './well-configuration/WellConfigurationWindow';
import NotificationMixin from '@/mixins/NotificationMixin';
import { mapGetters, mapState } from 'vuex';
import CancelRun from '../../../CancelRun';
import PlateTemplateCreationWindow from './template-creation/PlateTemplateCreationWindow';
import PlateTemplateDropdownWindow from './template-load/PlateTemplateDropdownWindow';
import { createRun, saveRunFiles } from '@/service/SmazentechService';
import ConfirmationModal from '@/components/common/ConfirmationModal.vue';
import PlateCaptions from '@/components/run/editor/plate/PlateCaptions.vue';

export default {
  components: {
    'app-information-header': RunInformationHeader,
    'app-plate': Plate,
    'app-well-configuration-window': WellConfigurationWindow,
    'app-plate-captions': PlateCaptions,
    'app-cancel-run': CancelRun,
    'app-plate-template-creation-window': PlateTemplateCreationWindow,
    'app-plate-template-dropdown-window': PlateTemplateDropdownWindow,
    'app-confirmation-modal': ConfirmationModal
  },
  mixins: [ NotificationMixin ],
  data() {
    return {
      selectedWells: [],
      isAlgorithmRunning: false,
      isTemplateWindowOpen: false,
      isTemplateDropdownOpen: false
    };
  },
  computed: {
    ...mapState('runImport', {
      plateType: state => state.run.plateType,
      runFiles: state => state.files,
      runToCreate: state => state.run
    }),
    ...mapState('plateTemplate', [
      'availableTemplates'
    ]),
    ...mapGetters('runImport', [
      'anonymizedWells'
    ]),
    ...mapFields('runImport', [
      'run.id',
      'run.instrumentName',
      'run.plateId',
      'run.createdByName',
      'run.fileName',
      'run.businessId',
      'availableLots',
      'availableKits',
      'availableSampleTypes',
      'run.wells',
      'run.analyser'
    ]),
    ...mapFields('applicationProperties', [
      'neoware.run.allowFileDownload'
    ]),
    configurableWells() {
      if (!this.selectedWells) {
        return [];
      }

      return this.selectedWells.filter(w => w.analyseWell).map(w => this.wells.get(w.position));
    },
    isOneParamSampleConfigured() {
      if (!this.wells) {
        return false;
      }
      for (const well of this.wells.values()) {
        if (well.sampleType) {
          return true;
        }
      }

      return false;
    },
    isSelectedWellsEmpty() {
      return Array.isArray(this.selectedWells) && this.selectedWells.length === 0;
    }
  },
  beforeMount() {
    this.selectedWells = [ this.wells.get('A1') ];
    this.$store.dispatch('plateTemplate/findAllPlateTemplates', { versionIds: this.availableLots.map(lot => lot?.kitVersion?.id) });
    if (this.allowFileDownload == undefined) {
      this.$store.dispatch('applicationProperties/load');
    }
  },
  methods: {
    startInterpretation() {
      this.isAlgorithmRunning = true;
      this.$store.dispatch('runImport/putDirtyToFalse')
        .then(() => this.createRun())
        .then(run => this.$router.push({ name: 'run-interpretation', params: { id: run.id } }))
        .catch(error => {
          this.showErrorNotification(this.$t('errorTitleCannotSaveRunAndStartInterpretation'), error);
          this.isAlgorithmRunning = false;
        });
    },
    createRun() {
      return createRun(this.runToCreate).then(run => {
        if (this.allowFileDownload) {
          saveRunFiles(run.id, this.runFiles);
        }

        return run;
      });
    },
    displayModal() {
      this.$refs.checkControl.showModal();
    },
    verifyConfigurationWell() {
      let wellConfigurationOk = true;
      for (const well of this.wells.values()) {
        const missingParameter = [];
        if (!well.kit && !well.lot && !well.sampleType) {
          continue;
        }
        if (!well.kit) {
          wellConfigurationOk = false;
          missingParameter.push(this.$t('wellMetaDataKit'));
        }
        if (!well.lot) {
          wellConfigurationOk = false;
          missingParameter.push(this.$t('wellMetaDataLot'));
        }
        if (!well.sampleType) {
          wellConfigurationOk = false;
          missingParameter.push(this.$t('wellMetaDataSampleType'));
        }
        if (!wellConfigurationOk) {
          this.showErrorNotification(this.$t('errorTitleParametersMissing'), this.$t('errorMessageWellPosition', [ well.position, missingParameter.join(' and ') ]));
          break;
        }
      }
      if (wellConfigurationOk) {
        this.displayModal();
      }
    },
    handleWellSelection(wells) {
      this.isTemplateWindowOpen = false;
      this.isTemplateDropdownOpen = false;
      this.selectedWells = wells;
    },
    openSaveTemplate() {
      this.isTemplateWindowOpen = true;
      this.isTemplateDropdownOpen = false;
    },
    openLoadTemplate() {
      this.isTemplateWindowOpen = false;
      this.isTemplateDropdownOpen = true;
    },
    closeTemplateWindowAndReloadTemplates() {
      this.isTemplateWindowOpen = false;
      this.isTemplateDropdownOpen = false;
    }
  }
};
</script>
