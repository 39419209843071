import WellTemplate from '@/models/WellTemplate';

export default class PlateTemplate {
  constructor(id, name, laboratoryId, wellTemplates, analyserId) {
    this.id = id;
    this.name = name;
    this.laboratoryId = laboratoryId;
    this.wellTemplates = wellTemplates;
    this.analyserId = analyserId;
  }

  static build({ id, name, laboratoryId, wellTemplates, analyserId }) {
    return new PlateTemplate(id, name, laboratoryId, wellTemplates, analyserId);
  }

  static parse(value) {
    return value ? PlateTemplate.build({
      id: value.id,
      name: value.name,
      laboratoryId: value.laboratoryId,
      wellTemplates : value.wellTemplates ? value.wellTemplates.map(well => WellTemplate.parse(well)) : [],
      analyserId : value.analyserId
    }) : null;
  }

  toJSON() {
    return {
      id: this.id !== undefined || this.id !== '' ? this.id : null,
      name: this.name !== undefined || this.name !== '' ? this.name : null,
      laboratoryId: this.laboratoryId !== undefined || this.laboratoryId !== '' ? this.laboratoryId : null,
      wellTemplates: this.wellTemplates !== undefined ? this.wellTemplates : null,
      analyserId: this.analyserId !== undefined ? this.analyserId : null
    };
  }
}
