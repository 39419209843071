<template>
  <b-container fluid>
    <br>
    <b-card
      bg-variant="light"
      no-body>
      <b-card-header header-tag="nav">
        <b-nav
          card-header
          justified
          lazy
          pills>
          <hr>
          <b-nav-item
            v-if="isAdmin"
            :to="{ name: 'lot-configuration' }"
            active-class="active bg-secondary"
            exact>
            {{ $t('formConfigureLot') }}
          </b-nav-item>
          <b-nav-item
            v-if="isAdmin || isDoctor"
            :to="{ name: 'kit-configuration' }"
            active-class="active bg-secondary"
            exact>
            {{ $t('formConfigureKit') }}
          </b-nav-item>
          <b-nav-item
            v-if="isAdmin || isDoctor"
            :to="{ name: 'configuration-ftp' }"
            active-class="active bg-secondary"
            exact>
            {{ $t('formConfigureFTP') }}
          </b-nav-item>
        </b-nav>
      </b-card-header>

      <b-card-body id="ConfigurationAdmin">
        <b-row>
          <b-col>
            <router-view />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </b-container>
</template>

<script>
import SecurityMixin from '@/mixins/SecurityMixin';

export default {
  name: 'Configuration',
  mixins: [
    SecurityMixin
  ],
  data() {
    return {
      tabs: [
        'lot',
        'kit'
      ],
      tabIndex: 0
    };
  },
  computed: {
    isAdmin() {
      return this.hasRole('ADMIN');
    },
    isDoctor() {
      return this.hasRole('WRITE_DIAGNOSTIC');
    }
  },
  beforeMount() {
    if (this.$route.params.tab) {
      const index = this.tabs.findIndex(tab => tab === this.$route.params.tab);
      if (index >= 0) {
        this.tabIndex = index;
      }
    }
    if (this.isDoctor && !this.isAdmin) {
      this.$router.push({ name: 'kit-configuration' });
    }
  }
};
</script>