<template>
  <div class="comment-box-height d-flex flex-column">
    <h6>
      <b>{{ commentTitle }}</b>
      <slot
        name="action"
        :comment="comment" />
    </h6>
    <slot
      name="textarea"
      :comment="comment">
      <div class="multiline flex-grow-1 overflow-auto">
        {{ commentValue }}
      </div>
    </slot>
  </div>
</template>

<script>
import Comment from '@/models/Comment';

export default {
  props: {
    comment: {
      type: Comment,
      default: () => undefined
    }
  },
  computed: {
    commentTitle() {
      return this.comment ? `${this.comment.technicianName} - ${this.$d(this.comment.creationDateTime, 'dateTime')}` : '';
    },
    commentValue() {
      return this.comment?.value;
    }
  }
};
</script>