export default {
  methods: {
    onClick(event) {
      this.emitSelect(event, {
        ctrl: event.ctrlKey || event.metaKey,
        shift: event.shiftKey
      });
    },
    emitSelect(event, options) {
      this.$emit('select', { event, options });
    }
  }
};