import {i18n} from '@/translation/i18n';

export default class SampleType {
  constructor(type, label, initial, color, diagnosticId, diagnosticType, validityType, controlId, controlType, kitId, kitVersionId, analyserId, laboratoryId) {
    this.type = type;
    this.label = label;
    this.initial = initial;
    this.color = color;
    this.diagnosticId = diagnosticId;
    this.diagnosticType = diagnosticType;
    this.validityType = validityType;
    this.controlId = controlId;
    this.controlType = controlType;
    this.kitId = kitId;
    this.kitVersionId = kitVersionId;
    this.analyserId = analyserId;
    this.laboratoryId = laboratoryId;
  }
  static build({ type, label, initial, color, diagnosticId, diagnosticType, validityType, controlId, controlType, kitId, kitVersionId, analyserId, laboratoryId }) {
    return new SampleType(type, label, initial, color, diagnosticId, diagnosticType, validityType, controlId, controlType, kitId, kitVersionId, analyserId, laboratoryId);
  }

  static parse(value) {
    return value ? SampleType.build({
      ...value
    }) : null;
  }

  static labelize(sampleType) {
    const label = `${sampleType.label ? sampleType.label : i18n.t(sampleType.type)}`;
    const initial = sampleType.initial ? `(${sampleType.initial})` : '';
    // sample type is not a control
    if (!sampleType.controlType) {
      return `${label}${initial ? ` ${initial}` : ''}`;
    }
    // sample type is a control
    // should check sampleType.controlType when label needs to be different for Zentech or laboratory

    if (sampleType.controlType === 'INTERNAL' && sampleType.type !== 'NEGATIVE_AMPLIFICATION_CONTROL') {
      // is control from laboratory and is not negative amplification (H20)
      return label;
    }

    return `${label}${initial ? ` ${initial}` : ''}`;
  }

  equals(sampleType) {
    if (Number.isFinite(this.controlId)) {
      return this.controlId === sampleType?.controlId;
    }

    return this.type = sampleType?.type;
  }

  toJSON() {
    return {
      type: this.type !== undefined && this.type !== '' ? this.type : null,
      label: this.label !== undefined && this.label !== '' ? this.label : null,
      initial: this.initial !== undefined && this.initial !== '' ? this.initial : null,
      color: this.color !== undefined && this.color !== '' ? this.color : null,
      diagnosticId: this.diagnosticId !== undefined && this.diagnosticId !== '' ? this.diagnosticId : null,
      diagnosticType: this.diagnosticType !== undefined && this.diagnosticType !== '' ? this.diagnosticType : null,
      validityType: this.validityType !== undefined && this.validityType !== '' ? this.validityType : null,
      controlId: this.controlId !== undefined && this.controlId !== '' ? this.controlId : null,
      controlType: this.controlType !== undefined && this.controlType !== '' ? this.controlType : null,
      kitId: this.kitId !== undefined && this.kitId !== '' ? this.kitId : null,
      kitVersionId: this.kitVersionId !== undefined && this.kitVersionId !== '' ? this.kitVersionId : null,
      analyserId: this.analyserId !== undefined && this.analyserId !== '' ? this.analyserId : null,
      laboratoryId: this.laboratoryId !== undefined && this.laboratoryId !== '' ? this.laboratoryId : null
    };
  }
}