<template>
  <b-navbar
    type="light">
    <b-navbar-brand>
      {{ $t('headerRun') | capitalize }}
    </b-navbar-brand>
    <b-navbar-nav>
      <b-nav-form>
        <b-form-input
          :placeholder="$t('headerSearch') | capitalize"
          class="form-control navbar-search"
          type="text"
          @input="updateSearch" />
      </b-nav-form>
    </b-navbar-nav>
    <b-navbar-nav class="ml-auto">
      <b-nav-form>
        <b-button
          id="newRun"
          class="btn-sm"
          variant="success"
          @click="newRun">
          <font-awesome-icon :icon="['fas', 'plus']" />
          {{ $t('import') }}
        </b-button>
      </b-nav-form>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
export default {
  data() {
    return {
      show: false
    };
  },
  methods: {
    updateSearch(search) {
      this.$emit('select', search);
    },
    newRun() {
      this.$router.push({
        name: 'run-import'
      });
    }
  }
};
</script>
