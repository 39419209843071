import {authorize, SmazentechApi} from '@/service/http-common';
import KitVersion from '@/models/KitVersion';

export const getAllKitVersionsByAnalyserId = analyserId =>
  SmazentechApi.get(`/kit-version/analyser/${analyserId}`, { headers: authorize() })
    .then(response => response.data.map(kitVersion => KitVersion.parse(kitVersion)));


export const createOfficialVersion = kitVersion =>
  SmazentechApi.post('/kit-version/official', kitVersion, { headers: authorize() })
    .then(response => KitVersion.parse(response.data));

export const searchOfficialVersions = (kitId, page, filters) => {
  const params = {};
  if (page?.index >= 0 && page?.size > 0) {
    params.page = page.index;
    params.size = page.size;
    params.sort = page.sort;
  }
  if (isFinite(kitId)) {
    params.kitId = kitId;
  }
  if (filters?.version) {
    params.version = filters.version;
  }
  if (filters?.lotIds?.length) {
    params.lotIds = filters.lotIds.join(',');
  }
  if (filters?.analyserIds?.length) {
    params.analyserIds = filters.analyserIds.join(',');
  }
  if (typeof filters?.analyserConfigured === 'boolean') {
    params.analyserConfigured = filters.analyserConfigured;
  }

  return SmazentechApi.get('/kit-version/official', {
    params,
    headers: authorize() })
    .then(response => {
      const page = response.data;
      page.content = page.content.map(kitVersion => KitVersion.parse(kitVersion));

      return page;
    });
};

export const getLastKitVersions = kitId =>
  SmazentechApi.get(`/kit-version/official/recent/kit/${kitId}`, { headers: authorize() })
    .then(response => KitVersion.parse(response.data));

export const deleteOfficialVersion = versionId =>
  SmazentechApi.delete(`/kit-version/official/${versionId}`, { headers: authorize() });

export const deleteLaboratoryVersion = versionId =>
  SmazentechApi.delete(`/kit-version/laboratory/${versionId}`, { headers: authorize() });


export const createLaboratoryVersion = kitVersion =>
  SmazentechApi.post('/kit-version/laboratory', kitVersion, { headers: authorize() })
    .then(response => KitVersion.parse(response.data));

export const searchLaboratoryVersions = (officialVersionId, page, filters) => {
  const params = [];
  if (isFinite(officialVersionId)) {
    params.push(`officialVersionId=${officialVersionId}`);
  }
  if (filters?.version) {
    params.push(`version=${filters.version}`);
  }
  if (filters?.laboratoryName) {
    params.push(`laboratoryName=${filters.laboratoryName}`);
  }
  if (filters?.analyserIds?.length) {
    params.push(`analyserIds=${filters.analyserIds.join(',')}`);
  }

  return SmazentechApi.get(`/kit-version/laboratory${params ? '?' : ''}${params.join('&')}`, {
    params: page?.index >= 0 && page?.size > 0 ? { page: page.index, size: page.size, sort: page.sort } :  { },
    headers: authorize() })
    .then(response => {
      const page = response.data;
      page.content = page.content.map(kitVersion => KitVersion.parse(kitVersion));

      return page;
    });
};

export const getLastLaboratoryVersionConfigured = versionId =>
  SmazentechApi.get(`/kit-version/laboratory/configured/most-recent?versionId=${versionId}`, { headers: authorize() })
    .then(response => KitVersion.parse(response.data));
