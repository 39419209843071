export default class Comment {
  constructor(id, technicianName, creationDateTime, value) {
    this.id = id ? id : null;
    this.technicianName = technicianName ? technicianName : null;
    this.creationDateTime = creationDateTime ? creationDateTime : null;
    this.value = value ? value : null;
  }

  static build({ id, technicianName, creationDateTime, value }) {
    return new Comment(id, technicianName, creationDateTime, value);
  }

  static parse(value) {
    return value ? new Comment(
      value.id,
      value.technicianName,
      value.creationDateTime ? new Date(value.creationDateTime) : null,
      value.value
    ) : null;
  }

  toJSON() {
    return {
      id: this.id,
      technicianName: this.technicianName,
      creationDateTime: this.creationDateTime,
      value: this.value
    };
  }
}
