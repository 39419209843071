<template>
  <div class="badges-overflow">
    <template v-for="badge in limitedBadges">
      <b-badge
          :key="badge[badgeKey]"
          ref="badges"
          :variant="variant(badge)">
        {{ badge[badgeLabel] }}
      </b-badge>
    </template>
    <b-dropdown
      v-show="overflow"
      class="badges-opener"
      size="sm"
      boundary="viewport"
      right
      no-flip
      no-caret
      menu-class="overflowed-badges">
      <template #button-content>
        <b-icon
          icon="three-dots"
          scale="1.5" />
      </template>
    </b-dropdown>
  </div>
</template>

<script>

export default {
  name: 'BadgesOverflow',
  props: {
    badges: {
      type: Array,
      default: undefined
    },
    badgeKey: {
      type: String,
      default: undefined
    },
    badgeLabel: {
      type: String,
      default: undefined
    },
    badgeVariant: {
      type: String | Function,
      default: 'secondary'
    },
    nowrap: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: undefined
    },
    resizedBy: {
      type: [ String, Object ],
      default: 'parentElement'
    },
    boundary: {
      type: [ String, Object ],
      default:  'element'
    }
  },
  data() {
    return {
      boundaryWidth: undefined,
      nowrapOverflowed: undefined,
      nowrapLimit: undefined
    };
  },
  computed: {
    limitedBadges() {
      let limit = undefined;
      if (this.limitOverflowed) {
        limit = this.limit;
      }

      return this.badges?.slice(0, limit);
    },
    limitOverflowed() {
      if (!this.badges || !Number.isFinite(this.limit)) {
        return false;
      }

      return this.badges.length > this.limit;
    },
    overflow() {
      return this.limitOverflowed || this.nowrapOverflowed;
    }
  },
  watch: {
    nowrap() {
      this._observeResizing();
    },
    boundary() {
      this._observeResizing();
    },
    boundaryWidth(oldWidth, newWidth) {
      if (oldWidth !== newWidth) {
        this._computeNowrapOverflowed();
      }
    },
    badges() {
      this._computeNowrapOverflowed();
    }
  },
  mounted() {
    if (this.nowrap) {
      this.boundaryWidth =  this._getElement(this.boundary)?.clientWidth;
      this._observeResizing();
    }
  },
  beforeDestroy() {
    this._stopObserver();
  },
  methods: {
    variant(badge) {
      if (typeof this.badgeVariant === 'function') {
        return this.badgeVariant(badge);
      }

      return this.badgeVariant;
    },
    _observeResizing() {
      this._stopObserver();

      if (!this.nowrap || !this.boundary || !this.resizedBy) {
        return;
      }

      const _self = this;
      const resizableElement = this._getElement(this.resizedBy);
      const boundaryElement = this._getElement(this.boundary);
      this.resizeObserver = new ResizeObserver(() => {
        if (_self.boundaryWidth === boundaryElement?.clientWidth) {
          return;
        }
        _self.boundaryWidth = boundaryElement?.clientWidth;
      });

      this.resizeObserver.observe(resizableElement);
    },
    _stopObserver() {
      this.resizeObserver?.disconnect();
      this.resizeObserver = undefined;
    },
    _getElement(elementSelector) {
      if (elementSelector?.$el instanceof Element) {
        return elementSelector?.$el;
      }
      if (elementSelector instanceof Element) {
        return elementSelector;
      }
      switch (elementSelector) {
        case 'element':
          return this.$el;
        case 'parentElement':
          return this.$el.parentElement;
        default:
          return this.$root.$el.querySelector(elementSelector);
      }
    },
    _computeNowrapOverflowed() {
      if (!this.nowrap) {
        this.nowrapOverflowed = false;
        this.nowrapLimit = undefined;

        return;
      }
      const badges = this.$el.querySelectorAll('.badge');
      const opener = this.$el.querySelector('.badges-opener');
      const overflowedBadges = this.$el.querySelector('.overflowed-badges');
      if (!badges || badges.length <= 0 || !overflowedBadges) {
        this.nowrapOverflowed = false;
        this.nowrapLimit = undefined;

        return;
      }

      const boundaryWidth = this.boundaryWidth;
      let overflowed = false;
      let limit = 0;
      let badgesWidth = opener ? opener.clientWidth : 0;
      let previous = undefined;

      this.$el.append(...badges, opener);
      for (const node of badges) {
        const rectangle = node.getBoundingClientRect();
        if (previous && previous.y !== rectangle.y) {
          overflowed = true;
          overflowedBadges.appendChild(node);
          continue;
        }
        badgesWidth += previous ? (rectangle.x - previous.x) : rectangle.width;
        previous = rectangle;
        if (badgesWidth > boundaryWidth) {
          overflowed = true;
          overflowedBadges.appendChild(node);
          continue;
        }
        limit++;
      }
      this.nowrapOverflowed = overflowed;
      this.nowrapLimit = limit;
    }
  }
};
</script>