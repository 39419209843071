import Comment from './Comment';
import Analyser from './Analyser';
import Laboratory from './Laboratory';
import Kit from './Kit';
import PlateType from './PlateType';
import Well from './Well';

export default class Run {
  constructor(id, businessId, plateId, plateType, name, created, createdByName, instrumentName, analyser, kits, lotName, wells, runImportDate, dirty, status, submittedByName, interpretationRunByName, comments, fileName, rejectedBy, approvedBy, laboratory, ftpSent) {
    this.id = id;
    this.businessId = businessId;
    this.plateId = plateId;
    this.plateType = plateType;
    this.name = name;
    this.created = created;
    this.createdByName = createdByName;
    this.instrumentName = instrumentName;
    this.analyser = analyser;
    this.kits = kits;
    this.lotName = lotName;
    this.wells = wells;
    this.runImportDate = runImportDate;
    this.dirty = dirty;
    this.status = status;
    this.submittedByName = submittedByName;
    this.interpretationRunByName = interpretationRunByName;
    this.comments = comments;
    this.fileName = fileName;
    this.rejectedBy = rejectedBy;
    this.approvedBy = approvedBy;
    this.laboratory = laboratory;
    this.ftpSent = ftpSent;
  }

  static build({
    id,
    businessId,
    plateId,
    plateType,
    name,
    created,
    createdByName,
    instrumentName,
    analyser,
    kits,
    lotName,
    wells,
    runImportDate,
    dirty,
    status,
    submittedByName,
    interpretationRunByName,
    comments,
    fileName,
    rejectedBy,
    approvedBy,
    laboratory,
    ftpSent
  }) {
    return new Run(id, businessId, plateId, plateType, name, created, createdByName, instrumentName, analyser, kits, lotName, wells, runImportDate, dirty, status, submittedByName, interpretationRunByName, comments, fileName, rejectedBy, approvedBy, laboratory, ftpSent);
  }

  static parse(value) {
    return value ? Run.build({
      id: value.id,
      businessId: value.businessId,
      plateId: value.plateId,
      plateType: value.plateType ? PlateType.fromString(value.plateType) : undefined,
      name: value.name,
      created: value.created,
      createdByName: value.createdByName,
      instrumentName: value.instrumentName,
      analyser: Analyser.parse(value.analyser),
      kits: value.kits ? value.kits.map(kit => Kit.parse(kit)) : [],
      lotName: value.lotName,
      wells: value.wells ? value.wells.reduce((map, well) => {
        map.set(well.position, Well.parse(well));

        return map;
      }, new Map()) : new Map(),
      runImportDate: value.runImportDate,
      dirty: value.dirty,
      status: value.status,
      submittedByName: value.submittedByName,
      interpretationRunByName: value.interpretationRunByName,
      comments: value.comments ? value.comments.map(comment => Comment.parse(comment)) : [],
      fileName: value.fileName,
      rejectedBy: value.rejectedBy,
      approvedBy: value.approvedBy,
      laboratory: Laboratory.parse(value.laboratory),
      ftpSent: value.ftpSent
    }) : null;
  }

  toJSON() {
    return {
      id: this.id !== undefined || this.id !== '' ? this.id : null,
      businessId: this.businessId !== undefined || this.businessId !== '' ? this.businessId : null,
      plateId: this.plateId !== undefined || this.plateId !== '' ? this.plateId : null,
      plateType: this.plateType !== undefined || this.plateType !== '' ? this.plateType : null,
      name: this.name !== undefined || this.name !== '' ? this.name : null,
      created: this.created !== undefined || this.created !== '' ? this.created : null,
      createdByName: this.createdByName !== undefined || this.createdByName !== '' ? this.createdByName : null,
      instrumentName: this.instrumentName !== undefined || this.instrumentName !== '' ? this.instrumentName : null,
      analyser: this.analyser !== undefined || this.analyser !== '' ? this.analyser : null,
      kits: this.kits !== undefined || this.kits !== '' ? this.kits : null,
      lotName: this.lotName !== undefined || this.lotName !== '' ? this.lotName : null,
      wells: this.wells !== undefined || this.wells !== '' ? Array.from(this.wells.values()) : null,
      runImportDate: this.runImportDate !== undefined || this.runImportDate !== '' ? this.runImportDate : null,
      dirty: this.dirty !== undefined || this.dirty !== '' ? this.dirty : null,
      status: this.status !== undefined || this.status !== '' ? this.status : null,
      submittedByName: this.submittedByName !== undefined || this.submittedByName !== '' ? this.submittedByName : null,
      interpretationRunByName: this.interpretationRunByName !== undefined || this.interpretationRunByName !== '' ? this.interpretationRunByName : null,
      comments: this.comments !== undefined || this.comments !== '' ? this.comments : null,
      fileName: this.fileName !== undefined || this.fileName !== '' ? this.fileName : null,
      rejectedBy: this.rejectedBy !== undefined || this.rejectedBy !== '' ? this.rejectedBy : null,
      approvedBy: this.approvedBy !== undefined || this.approvedBy !== '' ? this.approvedBy : null,
      laboratory: this.laboratory !== undefined || this.laboratory !== '' ? this.laboratory : null,
      ftpSent: this.ftpSent !== undefined || this.ftpSent !== '' ? this.ftpSent : null
    };
  }
}
