<template>
  <b-modal
    :id="id"
    :title="title"
    @hide="handleModalHideEvent"
    @ok="ok()">
    <div
      class="text-center allowLineBreak">
      <b-datepicker
        id="datepicker"
        v-model="dateSelected"
        :min="new Date()"
        :locale="$i18n.locale"
        class="mb-2" />
    </div>
  </b-modal>
</template>

<script>
import ModalMixin from '../../mixins/ModalMixin';

export default {
  mixins: [ ModalMixin ],
  data() {
    return {
      dateSelected: undefined
    };
  },
  methods: {
    ok() {
      this.$emit('ok', this.dateSelected);
    }
  }

};
</script>
