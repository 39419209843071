export default {
  'fr-FR': {
    app: {
      navbar: {
        title: 'FR_GeneFoxCub-SMA',
        logoutButtonText: 'FR_Log out'
      },
      sidebar: {
        runButtonText: 'FR_Runs',
        aboutButtonText: 'FR_About',
        configurationButtonText: 'FR_Configuration',
        laboratoriesButtonText: 'FR_Laboratories',
        laboratoryButtonText: 'FR_Laboratory',
        reindex: 'FR_Reindex data'
      }
    },

    common: {
      add: 'FR_Add',
      cannot_save: 'FR_Cannot save',
      delete: 'FR_Delete',
      no: 'FR_No',
      yes: 'FR_Yes',
      reset: 'FR_Reset',
      back: 'FR_Back',
      loading: 'FR_Loading...',
      ok: 'FR_Ok',
      saveChanges: 'FR_Save changes',
      cancel: 'FR_Cancel',
      configure: 'FR_Configure',
      validation: {
        required: 'FR_{0} is required',
        unique: 'FR_{0} must be unique'
      }
    },

    language: 'Français',

    //Common run import
    titleNewRun: 'FR_Start New Run',

    headerRun: 'FR_Runs',
    headerConfigurations: 'FR_Configurations',
    noDataFound: 'FR_No data found',
    import: 'FR_Import',
    headerSearch: 'FR_Search',

    //Update Status Modal
    modalSubmitResultsTitle: 'FR_Send result to the physician',
    modalSubmitResultsMessage: 'FR_Do you wish to submit results?',
    modalApproveResultsMessage: 'FR_Do you want to approve the results of this run?',
    modalRejectResultsMessage: 'FR_Do you want to reject the results of this run?',
    errorUpdateStatusTitle: 'FR_An error occurred during the status update',
    submitResults: 'FR_submit results',
    approveResults: 'FR_approve results',
    rejectResults: 'FR_reject results',

    //New Run import page
    formLoadFile: 'FR_Choose a file or drop it here',
    formLoadPlateFile: 'FR_Select/drop a file',
    formDropFile: 'FR_Drop file here',
    subTitleImportPage: 'FR_Import file to start a new run',
    descriptionSubTitleImportPage: 'FR_Select the analyzer and the run export file(s) in order to start importing run results.',
    importRunSelectFile: 'FR_Select file to import',
    importRunSelectFiles: 'FR_Select file(s) to import',
    importRunSelectAnalyser: 'FR_Select an analyzer',
    importRunSelectPlatePlan: 'FR_Select plate plan file',

    //New Run parameters page
    formAnalyser: 'FR_Analyzer',
    formPlateId: 'FR_Plate ID',
    formRunDate: 'FR_Run Date',
    formKit: 'FR_Kit',
    formKitName: 'FR_Name in application',
    formKitStatus: 'FR_Status',
    formReactionBuffer: 'FR_Reaction Buffer',
    formChannel: 'FR_Channel',
    formPhase: 'FR_Phase',
    formLot: 'FR_Lot',
    formLots: 'FR_Lots',
    formLotExpirationDate: 'FR_Expiration date',
    formRunBy: 'FR_Run by: ',
    formSubmittedBy: 'FR_Submitted by: ',
    formInterpretationRunBy: 'FR_Interpreted by: ',
    formMinThreshold: 'FR_Min threshold',
    formMinQuality: 'FR_Min quality',
    formMinTemp: 'FR_Min temp',
    formMaxTemp: 'FR_Max temp',
    formLabel: 'FR_Label',
    formData: 'FR_Data',
    formZone: 'FR_Zone',
    formSelectKit: 'FR_Select a kit',
    formSelectReactionBuffer: 'FR_Select a reaction buffer',
    formSelectLot: 'FR_Select a lot',
    formSelectAnalyser: 'FR_Select an analyzer',
    formSelectPlatePlan: 'FR_Select a plate plane type',
    formSelectStatus: 'FR_Select a status',
    formSelectChannel: 'FR_Select a channel',
    formSelectPhase: 'FR_Select a phase',
    formSelectZone: 'FR_Select a zone',
    formSelectValue: 'FR_Select a value',
    subTitlePageRun: 'FR_Select the parameters of the new run',
    formApprovedBy: 'FR_Approved by: ',
    formRejectedBy: 'FR_Rejected by: ',
    successAddingLot: 'FR_Lot has been correctly created',
    errorAddingLot: 'FR_An error occurred while creating lot',
    errorNoLotName: 'FR_No lot name written',
    errorNoKitSelected: 'FR_No kit selected',
    errorTitleAddingLot: 'FR_Can\'t add lot',
    errorTitleParametersMissing: 'FR_Bad well configuration',
    errorMessageWellPosition: 'FR_Well at position {0} is configured with invalid {1}',

    // Kit versions page
    tableHeaderOfficialVersion: 'FR_Official setting version',
    tableHeaderLaboratoryVersion: 'FR_Laboratory version',
    tableHeaderVersionAnalysers: 'FR_Analyzers',
    tableHeaderVersionLots: 'FR_Lots',
    tableHeaderVersionActions: 'FR_Actions',
    modalTitleNewKit: 'FR_New kit',
    buttonAddOfficialVersion: 'FR_Add new version',
    modalTitleNewKitVersion: 'FR_New version for kit {0}',
    tableEmptyRowLaboratoryVersions: 'FR_No laboratory versions found',
    tableEmptyRowOwnLaboratoryVersions: 'FR_No versions found for this laboratory',
    tableEmptyRowCustomizeVersionLabel: 'FR_Add first version for this laboratory',

    // Kit configuration page
    formKitVersion: 'FR_Version',
    formKitReference: 'FR_Reference name in reports',
    formKitVersionLaboratory: 'FR_Laboratory',
    formKitVersionCriteriaLot: 'FR_Search by lot',
    formKitVersionCriteriaVersion: 'FR_Search by version',
    formKitVersionCriteriaLaboratoryVersion: 'FR_Search by laboratory and/or version',
    formKitVersionSearch: 'FR_Find current version',
    formThresholds: 'FR_Thresholds',
    formSelectLaboratory: 'FR_Select a laboratory',
    formSelectVersion: 'FR_Select a version',
    formKitResults: 'FR_Suggested Results',
    formKitControls: 'FR_Kit Controls',
    formInternalControls: 'FR_Kit Internal Controls',
    formExternalControls: 'FR_Kit External Controls',
    formDiagnosticColor: 'FR_Color',
    formValidityType: 'FR_Valid',
    formControlBarCode: 'FR_Bar code',
    formControlSampleType: 'FR_Control',
    formDiagnosticLabel: 'FR_Label',
    formDiagnosticConditions: 'FR_Conditions',

    // Lot configuration page
    formLotActiveLabel: 'FR_Active',
    formLotNameLabel: 'FR_Lot',
    formLotExpirationDateLabel: 'FR_Expiration date',
    formLotKitVersionLabel: 'FR_Current setting version',
    lotVersionsTimeline: 'FR_Timeline',
    lotModification: 'FR_Modification:',
    lotDescriptionModification: 'FR_has been modified by {0} | FR_have been modified by {0}',
    lotDescriptionCreation: 'FR_has been created by {0}',
    lotTimeLineErrorTitle: 'FR_An error occurred during the timeline loading.',

    //Diagnostic Variable
    RFU_FAM_DIVBY_HEX: 'RFU FAM/HEX',
    CQ_FAM: 'CQ FAM',
    CQ_HEX: 'CQ HEX',

    //Diagnostic Operator
    EQUALS: '=',
    GREATER_THAN: '>',
    GREATER_THAN_OR_EQUAL: '>=',
    LOWER_THAN: '<',
    LOWER_THAN_OR_EQUAL: '<=',

    //SampleType
    dropdownMultiple: 'FR_Multiple',
    SAMPLE: 'FR_Sample',
    NEGATIVE_AMPLIFICATION_CONTROL: 'FR_Negative amplification control',
    NEGATIVE_EXTRACTION_CONTROL: 'FR_Negative extraction control',
    POSITIVE_CONTROL: 'FR_Positive control',
    BLANK_CONTROL: 'FR_Blank control',
    INVALID: 'FR_Invalid',
    VALID: 'FR_Valid',

    //List
    listRunId: 'FR_Run Id',
    listInstrumentName: 'FR_Instrument Name',
    listKitReactionBufferPairs: 'FR_Kit (Reaction Buffer)',
    listTechnician: 'FR_Operator',
    listImportDate: 'FR_Import Date',
    listRunStatus: 'FR_Run Status',
    listCreatedDate: 'FR_Run Date',
    listWellName: 'FR_Name',
    listWellPositions: 'FR_Positions',
    listWellSampleType: 'FR_Sample Type',
    listWellKit: 'FR_Kit',
    listeFileName: 'FR_File Name',

    //Status
    RUN_TO_INTERPRET: 'FR_Run to interpret',
    WAIT_FOR_APPROVAL: 'FR_Wait for approval',
    APPROVED: 'FR_Approved',
    REJECTED: 'FR_Rejected',

    //Run comment
    runCommentTitle: 'FR_Run comment',

    //Well caption
    captionTitle: 'FR_Caption',
    captionAnalysedWell: 'FR_No sample type selected',
    sampleInitial: 'SMP',
    captionSampleWell: 'FR_Sample',
    captionPositiveControlWell: 'FR_Positive Control',
    captionNegativeControl1Well: 'FR_Negative amplification control',
    captionNegativeControl2Well: 'FR_Negative extraction control',
    captionValidPositiveTestWell: 'FR_Valid Positive Control',
    captionBadPositiveTestWell: 'FR_Invalid Positive Control',
    captionValidNegativeTestWell: 'FR_Valid Negative Control',
    captionBadNegativeTestWell: 'FR_Invalid Negative Control',
    captionNonAnalysedWell: 'FR_Not Analyzed',
    captionNegativeWell: 'FR_Negative',
    captionHealthyWell: 'FR_Negative Detection',
    captionUnhealthyWell: 'FR_Positive Detection',
    captionAbnormalWell: 'FR_Abnormal',
    captionHomozygousWildTypeWell: 'FR_Homozygous Wild Type',
    captionHomozygousMutatedWell: 'FR_Homozygous Mutated',
    captionHomozygousMutated13910Well: 'FR_Homo Mutated -13910',
    captionHomozygousMutated13915Well: 'FR_Homo Mutated -13915',
    captionHomozygousMutated14009Well: 'FR_Homo Mutated -14009',
    captionHomozygousMutated14010Well: 'FR_Homo Mutated -14010',
    captionHeterozygousWell: 'FR_Heterozygous',
    captionHeterozygous13910Well: 'FR_Heterozygous -13910',
    captionHeterozygous13915Well: 'FR_Heterozygous -13915',
    captionHeterozygous13910_13915Well: 'FR_Hetero -13910 -13915',
    captionHeterozygous14009Well: 'FR_Heterozygous -14009',
    captionHeterozygous14010Well: 'FR_Heterozygous -14010',
    captionHeterozygous14009_14010Well: 'FR_Hetero -14009 -14010',

    templateTitle: 'FR_Template',
    buttonSaveTemplate: 'FR_Save as template',
    buttonLoadTemplate: 'FR_Load template',
    wellNotCorrectlyConfiguredTitle: 'FR_Well not correctly configured',
    wellNotCorrectlyConfiguredBody: 'FR_The following well is not fully configured',
    plateTemplateCreatedTitle: 'FR_Template creation success',
    plateTemplateCreatedBody: 'FR_Template has been successfully created',
    plateTemplateCreatedErrorTitle: 'FR_Template creation error',
    runWellMetadataDropdown: 'FR_Select an option',
    runWellMetadataLoadPlateTemplateTitle: 'FR_Load plate template',
    runWellMetadataConfigurePlateTitle: 'FR_Configure the selected wells',
    runWellMetadataConfigurePlateTemplateTitle: 'FR_Configure the wells template',

    // Suggested result
    suggestedResultHEALTHY_SAMPLE: 'FR_Healthy',
    suggestedResultAFFECTED_SAMPLE: 'FR_Affected',
    suggestedResultTEST_OK: 'FR_OK',
    suggestedResultTEST_KO: 'FR_NOT OK',

    // Diagnostics
    HEALTHY: 'FR_Negative detection',
    SICK: 'FR_Positive detection',
    ABNORMAL: 'FR_Abnormal',
    NEGATIVE: 'FR_Negative',
    HOMOZYGOUS_WILD_TYPE: 'FR_Homozygous Wild Type',
    HOMOZYGOUS_MUTATED: 'FR_Homozygous Mutated',
    HOMOZYGOUS_MUTATED_13910: 'FR_Homo Mutated -13910',
    HOMOZYGOUS_MUTATED_13915: 'FR_Homo Mutated -13915',
    HOMOZYGOUS_MUTATED_14009: 'FR_Homo Mutated -14009',
    HOMOZYGOUS_MUTATED_14010: 'FR_Homo Mutated -14010',
    HETEROZYGOUS: 'FR_Heterozygous',
    HETEROZYGOUS_13910: 'FR_Heterozygous -13910',
    HETEROZYGOUS_13915: 'FR_Heterozygous -13915',
    HETEROZYGOUS_13910_13915: 'FR_Heterozygous -13910 -13915',
    HETEROZYGOUS_14009: 'FR_Heterozygous -14009',
    HETEROZYGOUS_14010: 'FR_Heterozygous -14010',
    HETEROZYGOUS_14009_14010: 'FR_Heterozygous -14009 -14010',
    POSITIVE_TEST_TEST_OK: 'FR_Valid Positive Control',
    POSITIVE_TEST_TEST_KO: 'FR_Invalid Positive Control',
    NEGATIVE_TEST_TEST_OK: 'FR_Valid Negative Control',
    NEGATIVE_TEST_TEST_KO: 'FR_Invalid Negative Control',


    // Run interpretation
    runInterpretationChannel: 'FR_CHANNEL',
    runInterpretationNoInterpretationResultMessage1: 'FR_No interpretation result can be shown for this run.',
    runInterpretationNoInterpretationResultMessage2: 'FR_This can mean that (some of) the wells have been incorrectly configured.',
    runInterpretationFooterDeleteRunButtonText: 'FR_Delete run',
    logarithmicVue: 'Logarithmic vue',
    runInterpretation: {
      wellInterpretation: {
        wellSelectionFilters: {
          kitDropdownPlaceholder: 'FR_Kit',
          reactionBufferDropdownPlaceholder: 'FR_Reaction Buffer'
        }
      },
      deletionModal: {
        title: 'FR_Delete run',
        message: 'FR_Do you really wish to delete this run and the associated data? This action is irreversible. (This could take a while.)',
        okButtonText: 'FR_Yes, delete run',
        cancelButtonText: 'FR_No, do not delete run'
      },
      deleteRun: {
        successNotification: {
          title: 'FR_Run deleted',
          message: 'FR_The run {0} has been deleted successfully.'
        },
        errorNotification: {
          title: 'FR_Run not deleted'
        }
      },
      ftp: {
        runInterpretationSendFileFtp: 'FR_Send results to FTP',
        successNotification: {
          title: 'FR_File send to FTP',
          message: 'FR_The file has been sent successfully to the FTP server'
        },
        errorNotification: {
          title: 'FR_File couldn\'t be sent to FTP server',
          message: 'FR_A problem has occurred during file transfer'
        }
      }
    },

    // Well Configure Window
    formRunConfigurationTitle: 'FR_Plate configuration',
    formSampleType: 'FR_Sample Type',
    formWellName: 'FR_Well Name',
    interpretationInProgress: 'FR_Interpretation in progress',
    wellUpdateConfirmationMessage: 'FR_Do you want to update this value for all the selected wells?',

    formSelectWellConfigurationTemplate: 'FR_Select a template',
    deleteTemplateButton: 'FR_Delete template',
    formTemplateName: 'FR_Template name',
    wellConfigurationWindow: {
      formSelectWellConfigurationTarget: 'FR_Select a target',
      target: 'FR_Target'
    },
    deletionTemplateModal: {
      title: 'FR_Delete template',
      message: 'FR_Do you really wish to delete this template?',
      okButtonText: 'FR_Yes, delete it',
      cancelButtonText: 'FR_No, do not delete',
      successNotification: {
        title: 'FR_Template deleted',
        message: 'FR_Template {0} has been deleted successfully.'
      },
      errorNotification: {
        title: 'FR_Template not deleted'
      }
    },

    // Well Interpretation
    wellInterpretationConfigurationTitle: 'FR_Review interpretation results',
    wellInterpretationNoPhaseData: 'FR_No phase data for this well',
    wellInterpretationMultipleKitOrVersionSelected: 'FR_Multiple kits or versions selected, unable to render results. Please select only one kit and one version to display interpretations.',
    wellInterpretationUpdateDiagnosticButtonText: 'FR_Update Suggested Result',
    wellInterpretationNotificationTitle: 'FR_Well',
    wellInterpretationCommentAdded: 'FR_Comment successfully added',
    wellInterpretationChartTab: 'FR_Chart',
    wellInterpretationTableTab: 'FR_Table',
    wellInterpretationChanged: 'FR_Suggested result changed from {0} to {1} by {2}',

    // Well Interpretation Chart
    wellInterpretationChartTitle: 'FR_Amplification',
    wellInterpretationChartXAxis: 'FR_Cycles',
    wellInterpretationChartYAxis: 'FR_RFU',

    // Well Interpretation Data Table
    wellInterpretationDataTableColumnHeaderChannel1Name: 'FR_FAM',
    wellInterpretationDataTableColumnHeaderChannel2Name: 'FR_HEX',
    wellInterpretationDataTableColumnHeaderPosition: 'FR_Well Pos',
    wellInterpretationDataTableColumnHeaderName: 'FR_Sample Name',
    wellInterpretationDataTableColumnHeaderChannelCq: 'FR_Cq',
    wellInterpretationDataTableColumnHeaderChannelEndRFU: 'FR_End RFU',
    wellInterpretationDataTableColumnHeaderRatio: 'FR_Ratio',
    wellInterpretationDataTableColumnHeaderSuggestedResult: 'FR_Suggested Result',

    // Well Meta Data
    wellMetaDataWellName: 'FR_Well Name',
    wellMetaDataLot: 'FR_Lot',
    wellMetaDataKit: 'FR_Kit',
    wellMetaDataSampleType: 'FR_Sample type',

    // Diagnostic Update Form
    diagnosticUpdateFormIntroductionParagraph: 'FR_Select the new suggested result for well {0}',
    diagnosticUpdateFormValidationButtonText: 'FR_Update Suggested Result',
    diagnosticUpdateFormUpdateErrorNotificationTitle: 'FR_Suggested result not updated.',
    diagnosticUpdateFormUpdateSuccessNotificationTitle: 'FR_Suggested result successfully updated for well {0}.',
    diagnosticUpdateFormUpdateSuccessNotificationMessage: 'FR_New suggested result: {0}',

    // Diagnostic Dropdown
    diagnosticDropdownPlaceholder: 'FR_Select a suggested result',

    //Export Window
    showExportWindowButton: 'FR_Export results',
    exportWindowTitle: 'FR_Export results',
    formExportFormat: 'FR_Export type',
    formExportFormatPlaceholder: 'FR_Select an export type',
    formExportData: 'FR_Export data',
    formExportDataPlaceholder: 'FR_Select data to export',
    exportButton: 'FR_Export',
    exportAsAPlate: 'FR_Export as a plate',
    filters: 'FR_Filters',
    separator: 'FR_Separator',
    separatorPlaceholder: 'FR_Select a separator',
    EXPORT_FORMAT_PDF: 'FR_pdf',
    EXPORT_FORMAT_XLSX: 'FR_xlsx',
    EXPORT_FORMAT_CSV: 'FR_csv',
    EXPORT_FORMAT_XML: 'FR_xml',
    EXPORT_DATA_ALL_PLATE: 'FR_All plate',
    EXPORT_DATA_SELECTION: 'FR_Selected wells',

    //Comment Configure Window
    addComment: 'FR_Add Comment',

    //Cancel modal
    modalDiscardChangesTitle: 'FR_Cancel',
    modalDiscardChangesMessage: 'FR_Do you want to exit this run?',

    modalCreateTemplateTitle: 'FR_Create template',
    modalCreateTemplateMessage: 'FR_A template already exist with the name {0}. Would you like to update it ?',

    //Common
    actions: 'FR_actions',
    cancel: 'FR_Cancel',
    next: 'FR_Next',
    save: 'FR_Save',
    yes: 'FR_Yes',
    no: 'FR_No',
    close: 'FR_Close',
    startInterpretation: 'FR_Start Interpretation',

    // AnalyserDropdown
    analyserDropdownLoadingErrorTitle: 'FR_An error occurred during the analyzers loading.',

    //PlateTypeDropdown
    plateTypeDropdownLoadingErrorTitle: 'FR_An error occurred during the plate plan loading.',

    //KitDropdown
    kitDropdownLoadingErrorTitle: 'FR_An error occurred during the kit options loading',

    //KitStatusDropdown
    kitStatusDropdownLoadingErrorTitle: 'FR_An error occurred during the kit status options loading',

    //ChannelDropdown
    channelDropdownLoadingErrorTitle: 'FR_An error occurred during the channel options loading',

    //LotDropdown
    lotDropdownLoadingErrorTitle: 'FR_An error occurred during the lot options loading',
    lotDropdownDropdownAddLotErrorTitle: 'An error occurred while adding lot',
    lotDropdownDropdownAddLotErrorMessage: 'A lot named {0} already exists',

    //KitVersionDropdown
    KitVersionDropdownSearchingNotFoundTitle: 'No versions found',
    KitVersionDropdownSearchingNotFoundMessageForLot: 'Lot {0} is not referenced with any versions',
    KitVersionDropdownSearchingErrorTitle: 'An error occurred during the kit version options searching',
    KitVersionDropdownAddKitVersionErrorTitle: 'FR_An error occurred while adding Kit version',
    KitVersionDropdownAddKitVersionErrorMessage: 'FR_You cannot add a Kit version with a same name as another one',

    //SampleTypeDropdown
    sampleTypeDropdownPlaceholder: 'FR_Select a sample type',
    sampleTypeDropdownLoadingErrorTitle: 'FR_An error occurred during the sample type options loading',

    //DiagnosticExpressionVariableDropdown
    diagnosticExpressionVariableDropdownPlaceholder: 'FR_Select a variable',
    diagnosticExpressionVariableDropdownLoadingErrorTitle: 'FR_An error occurred during the diagnostic variable options loading',

    //DiagnosticExpressionOperatorDropdown
    diagnosticExpressionOperatorDropdownPlaceholder: 'FR_Select an operator',
    diagnosticExpressionOperatorDropdownLoadingErrorTitle: 'FR_An error occurred during the diagnostic operator options loading',

    //Errors
    error: 'FR_error',
    runImportError: 'FR_An unexpected error occurred during the run file import.',
    invalidFileError: 'FR_An error occurred during the file parsing : invalid file : {0}',
    corruptedFileError: 'FR_An error occurred during the file parsing : corrupted file or invalid format. {0}',
    errorCommentCreation: 'FR_An error occurred during the comment creation.',
    errorDefaultDropdown: 'FR_An unexpected error occurred during a dropdown options loading.',
    errorExportGeneration: 'FR_An error occurred during the export file generation.',
    errorExportGenerationNoWellAvailable: 'FR_No well to export.',
    errorExportGenerationSeparatorIncorrect: 'FR_The separator choosen is incorrect. The separator should be only one character',
    errorTitleCannotAccessRunPage: 'FR_Cannot access run page',
    errorTitleCannotSaveRunAndStartInterpretation: 'FR_Cannot save run and start interpretation',
    errorParsingNotFinished: 'FR_The run data has not yet been fully downloaded. Please wait before you proceed again with the interpretation.',
    errorSavingWellsNotFinished: 'FR_The interpreted run data has not yet been saved in the database.  Please wait before doing action again.',
    invalidTemplateError: 'FR_Plate plane type and plate plan file must both be filled in or both empty',

    //Graph Zone
    A: 'FR_A',
    B: 'FR_B',
    C: 'FR_C',
    D: 'FR_D',

    //Configuration
    formConfigureLot: 'FR_Lot',
    formConfigureDiagnostic: 'FR_Results Labelling',
    formConfigureKit: 'FR_Kit',
    formConfigureFTP: 'FR_FTP server connection',
    errorTitleConfigureLot: 'FR_An error occurred while updating lots',
    successTitleConfigureLot: 'FR_Lot(s) has/have been correctly updated',
    errorTitleCreateKit: 'FR_An error occurred while creating the kit',
    successTitleCreateKit: 'FR_Kit has been correctly created',
    errorTitleUpdateKit: 'FR_An error occurred while updating the kit',
    successTitleUpdateKit: 'FR_Kit has been correctly updated',
    addLot: 'FR_Add lot(s)',
    deleteLot: 'FR_Delete lot(s)',
    deleteLotModal: 'Are you sure to delete lot ?',
    updateLot: 'FR_Update lot(s)',
    updateLotModal: 'FR_Are you sure to update lot ?',
    errorTitleCannotUpdateAndDeleteLotAtSameTime: 'FR_Can not update and delete',
    errorCannotUpdateAndDeleteLotAtSameTime: 'FR_You can not update and delete lot(s) at the same time',
    infoTitleCannotSaveWithNoKitToAddOrDelete: 'FR_You can not save',
    infoCannotSaveWithNoKitToAddOrDelete: 'FR_You can not save if you do not have any kit to add/delete',
    formDiagnosticHeader: 'FR_Suggested Result',
    label: 'FR_Label',
    errorCannotSaveEmptyDiagnostic: 'FR_You must choose at least one diagnostic, one kit and a label before saving',
    successTitleConfigureDiagnostic: 'FR_Result(s) labelling has/have been correctly updated',
    errorConfigureDiagnostic: 'FR_An error occurred while updating diagnostic',
    diagnosticConfigIntro: 'FR_Configure the diagnostic label displayed in the export files',
    modalConfirmLotDeletion: {
      title: 'FR_Update lot(s)',
      message: 'FR_Are you sure you want to update lot(s)?',
      okButtonText: 'FR_Yes',
      cancelButtonText: 'FR_No'
    },
    modalConfirmExpirationDateLot: {
      title: 'FR_Expiration date already passed',
      message: 'FR_One or more lot(s) have an expiration date in the past. Are you sure you want to add lot(s) ?',
      okButtonText: 'FR_Yes',
      cancelButtonText: 'FR_No'
    },
    modalSelectedLotsExpired: {
      title: 'FR_Expiration date already passed',
      message: 'FR_One or more lot(s) have an expiration date in the past.',
      okButtonText: 'FR_Ok'
    },
    plate: {
      missing: {
        kit: 'FR_Kit is not selected',
        lot: 'FR_Lot is not selected',
        sampleType: 'FR_Sample type is not selected'
      }
    },

    //Laboratory
    laboratory: {
      name: 'FR_Name',
      create: {
        title: 'FR_Create laboratory',
        notification: {
          title: 'FR_Create laboratories',
          success_message: 'FR_All laboratories have been created',
          cannot_save_message: 'FR_You cannot save if there is no laboratory or not all of them are named'
        }
      },
      update: {
        notification: {
          title: 'FR_Update laboratory',
          success_message: 'FR_Laboratory has been updated'
        }
      },
      delete: {
        title: 'FR_Delete laboratory',
        message: 'FR_Do you really wish to delete this laboratory ?',
        notification: {
          title: 'FR_Delete laboratory',
          success_message: 'FR_Laboratory has been deleted'
        }
      },
      cant_delete: {
        title: 'FR_Cannot delete laboratory',
        message: 'FR_You cannot delete this laboratory because users are still linked'
      },
      search: 'FR_Search by name'
    },

    //User
    user: {
      enabled: 'FR_Enabled',
      login: 'FR_Login',
      lastname: 'FR_Lastname',
      firstname: 'FR_Firstname',
      email: 'FR_E-mail',
      email_verified: 'FR_E-mail verified',
      role: {
        admin: 'FR_Admin',
        physician: 'FR_Physician'
      },
      update: {
        notification: {
          title: 'FR_Update user',
          success_message: 'FR_User has been updated'
        }
      },
      delete: {
        title: 'FR_Delete user',
        message: 'FR_Do you really wish to delete this user ?',
        notification: {
          title: 'FR_Delete user',
          success_message: 'FR_User has been deleted'
        }
      },
      search: 'FR_Search by login, lastname, firstname and email'
    },

    //reindex data
    modalConfirmReindexTitle: 'FR_Reindex',
    modalConfirmReindexMessage: 'FR_Do you want to reindex all runs?',
    reindexDataSuccessTitle: 'FR_Reindex data success',
    reindexDataSuccessMessage: 'FR_Reindex data has started asynchronously, please check server logs',
    reindexDataErrorTitle: 'FR_Reindex data error',
    reindexDataErrorMessage: 'FR_An error occured during reindex data',

    //about modal
    modalAboutTitle: 'FR_About GeneFoxCub-SMA',

    //release note modal
    modalReleaseNoteTitle: 'FR_Release Note',

    //lot changes modal
    modalLotChangesTitle: 'FR_Changes in lots since last login',
    modalLotChangesCreationSectionTitle: 'FR_New lots have been created',
    modalLotChangesCreationLine: 'FR_Lot {0} is using version {1} of {2}',
    modalLotChangesVersionSectionTitle: 'FR_Lots have changed current version of kit',
    modalLotChangesVersionLine: 'FR_Lot {0} is using version {1} of {2}',
    modalLotChangesDeactivateSectionTitle: 'FR_Lots have been disable',
    modalLotChangesDeactivateLine: 'FR_Lot {0} is not available anymore for version {1} of {2}',
    modalLotChangesActivateSectionTitle: 'FR_Lots have been activated',
    modalLotChangesActivateLine: 'FR_Lot {0} is available for version {1} of {2}',

    //configuration
    configuration: {
      kit: {
        kit: 'FR_Kit',
        analyzer: 'FR_Analyzer',
        reaction_buffers: 'FR_Reaction Buffers',
        phases: 'FR_Phases',
        zones: 'FR_Zones',
        create: {
          notification: {
            title: 'FR_Create kit',
            success_message: 'FR_Kit {0} has been saved'
          }
        },
        save: {
          notification: {
            title: 'FR_Save configuration',
            success_message: 'FR_Configuration has been saved',
            invalid_message: 'FR_Configuration cannot be saved: {0} must be configured'
          }
        },
        'delete-version': {
          notification: {
            title: 'FR_Delete kit version',
            success_message: 'FR_Version {0} has been deleted'
          }
        },
        'load-versions': {
          notification: {
            title: 'FR_Load versions'
          }
        },
        'load-analysers': {
          notification: {
            title: 'FR_Load analyzers'
          }
        },
        'load-lots': {
          notification: {
            title: 'FR_Load lots'
          }
        },
        modal: {
          'delete-version': {
            title: 'FR_Delete kit version',
            message: 'FR_Do you really wish to delete kit version {0} and all configuration data associated with ?'
          }
        },
        header: {
          version: 'FR_Version: {0}',
          customVersion: 'FR_Custom version: {0}',
          analyser: 'FR_Analyzer: {0}',
          laboratory: 'FR_Laboratory: {0}',
          kit: 'FR_Kit: {0}'
        }
      },
      lot: {
        save: {
          notification: {
            title: 'FR_Save lot',
            success_message: 'FR_Lot has been saved',
            invalid_message: 'FR_Lot cannot be saved: invalid inputs'
          }
        },
        deleting: {
          notification: {
            title: 'FR_Delete lot',
            success_message: 'FR_Lot has been deleted'
          }
        }
      },
      ftp: {
        not_configured: 'FR_Connection is not configured, press + button to setup connection',
        sendFilesOnApproval: 'FR_Send results when run is approved by the doctor',
        protocol: 'FR_Protocol',
        host: 'FR_Host',
        port: 'FR_Port',
        warn: 'FR_Warning: we recommend to use a SFTP server to ensure data is encrypted during the transfer',
        directory: 'FR_Directory',
        authentication: 'FR_Authentication',
        username: 'FR_Username',
        password: 'FR_Password',
        key_file: 'FR_Key file',
        test_connection: 'FR_Test connection',
        title: 'FR_FTP',
        error: {
          get: 'FR_Unable to find FTP configuration',
          save: 'FR_Unable to save FTP configuration',
          delete: 'FR_Unable to delete FTP configuration',
          test: 'FR_Test connection failed'
        },
        success: {
          save: 'FR_Successfully saved FTP configuration',
          delete: 'FR_Successfully deleted FTP configuration',
          test: 'FR_Successfully ran test connection'
        }
      }
    },
    expirationDateModal: 'FR_Select an expiration date',
    errorTitleAddingRb: 'FR_Can\'t add reaction buffer',
    errorNoRbName: 'FR_No reaction buffer name written',
    modal: {
      kit_configuration: {
        cancel: {
          title: 'FR_Cancel',
          message: 'FR_Are you sure you want to discard all changes ?'
        }
      },
      ftp_configuration: {
        cancel: {
          title: 'FR_Cancel',
          message: 'FR_Are you sure you want to discard all changes ?'
        },
        delete: {
          title: 'FR_Delete FTP Configuration',
          message: 'FR_This is irreversible. Are you sure you want to delete the FTP configuration ?'
        }
      }
    },
    verifyControlsModalMessage: 'FR_Are you sure that the controls are correct?',
    verifyControlsModalTitle: 'FR_Check all controls',
    tooltip: {
      envelope: {
        ok: 'FR_Results have been sent to the laboratory',
        ko: 'FR_An error has occurred while sending results to the laboratory'
      }
    },
    PASSWORD: 'FR_Password',
    SSH_KEY: 'FR_SSH Key'
  }
};
