<template>
  <div>
    <editable-multiselect
      ref="multiselect"
      v-model="model"
      :disabled="readonly"
      :multiple="multiple"
      :options="options"
      track-by="id"
      label="name"
      :custom-label="customLabel"
      :show-labels="showLabels"
      :placeholder="$t('formSelectLot')"
      :class="stateClass">
      <template
        slot="singleLabel"
        slot-scope="props">
        <div>
          <app-lot
            :lot="props.option"
            class="align-middle" />
        </div>
      </template>
      <template
        slot="option"
        slot-scope="props">
        <div>
          <app-lot
            :lot="props.option"
            class="align-middle" />
        </div>
      </template>
      <template
        v-if="canAdd && isAdmin"
        slot="afterList">
        <b-input-group>
          <b-input
            v-model="lotName"
            class="col-lg-10 dropdown-add-input"
            @keydown.enter="showModal"
            @mousedown.self.stop />
          <b-input-group-append>
            <b-button
              ref="addButton"
              variant="outline"
              class="btn--no-outline btn-icon btn-icon-plus dropdown-add-button"
              @click="showModal">
              <font-awesome-icon
                :icon="['fas', 'plus-circle']" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </template>
    </editable-multiselect>
    <app-expiration-date-modal
      v-if="canAdd && isAdmin"
      ref="selectLotExpirationDate"
      :title="$t('expirationDateModal')"
      @ok="addLot($event)" />
  </div>
</template>

<script>
import DropdownMixin from '@/mixins/DropdownMixin';
import SecurityMixin from '@/mixins/SecurityMixin';
import { findAllLots } from '@/service/SmazentechService';
import Lot from '@/components/run/editor/configuration/steps/run-meta-data/Lot';
import EditableMultiselect from '@/components/common/EditableMultiselect';
import { default as LotModel } from '@/models/Lot';
import ExpirationDateModal from '@/components/common/ExpirationDateModal.vue';

export default {
  components: {
    'app-lot': Lot,
    'editable-multiselect': EditableMultiselect,
    'app-expiration-date-modal': ExpirationDateModal
  },
  mixins: [
    DropdownMixin,
    SecurityMixin
  ],
  props: {
    value: {
      type: Array | Object,
      default: undefined
    },
    multiple: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    canAdd: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      lotName: null
    };
  },
  computed: {
    isAdmin() {
      return this.hasRole('ADMIN');
    },
    errorNotificationTitle() {
      return this.$t('lotDropdownLoadingErrorTitle');
    }
  },
  methods: {
    findOptions() {
      return findAllLots();
    },
    customLabel(value) {
      return value.id ? value.name : this.$t(value.name);
    },
    showModal() {
      this.$refs.selectLotExpirationDate.showModal();
    },
    addLot(date) {
      const lot = LotModel.parse({ name: this.lotName, expirationDate: date });
      this.$emit('optionAdded', lot);
      this.lotName = null;
    }
  }
};
</script>