export default class Acquisition {
  constructor(id, fluorescence, cycle, time, channel, wellId) {
    this.id = id;
    this.fluorescence = fluorescence;
    this.cycle = cycle;
    this.time = time;
    this.channel = channel;
    this.wellId = wellId;
  }

  static build({ id, fluorescence, cycle, time, channel, wellId }) {
    return new Acquisition(id, fluorescence, cycle, time, channel, wellId);
  }

  static parse(value) {
    return value ? Acquisition.build(value) : null;
  }

  toJSON() {
    return {
      id: this.id !== undefined && this.id !== '' ? this.id : null,
      fluorescence: this.fluorescence !== undefined && this.fluorescence !== '' ? this.fluorescence : null,
      cycle: this.cycle !== undefined && this.cycle !== '' ? this.cycle : null,
      time: this.time !== undefined && this.time !== '' ? this.time : null,
      channel: this.channel !== undefined && this.channel !== '' ? this.channel : null,
      wellId: this.wellId !== undefined && this.wellId !== '' ? this.wellId : null
    };
  }
}