<template>
  <div
    class="plate-caption dotted-text">
    <app-well
      class="align-middle"
      :well="caption.well" />
    <span class="align-middle">
      {{ caption.label }}
    </span>
  </div>
</template>

<script>
import Well from '@/components/run/editor/plate/Well';

export default {
  name: 'PlateCaption',
  components: {
    'app-well': Well
  },
  props: {
    caption: {
      type: Object,
      default: undefined
    }
  }
};
</script>