<template>
  <multiselect
    v-model="model"
    :disabled="disabled"
    :options="options"
    :placeholder="''"
    :custom-label="customLabel"
    :show-labels="showLabels"
    :class="cssClasses"
    @open="$emit('open', $event)"
    @close="$emit('close', $event)" />
</template>

<script>
import DropdownMixin from '@/mixins/DropdownMixin';
import {findAllDiagnosticExpressionVariables} from '@/service/SmazentechService';

export default {
  name: 'DiagnosticExpressionVariableDropdown',
  mixins: [
    DropdownMixin
  ],
  computed: {
    errorNotificationTitle() {
      return this.$t('diagnosticExpressionVariableDropdownLoadingErrorTitle');
    }
  },
  methods: {
    findOptions() {
      return findAllDiagnosticExpressionVariables();
    },
    customLabel (value) {
      return this.$t(value);
    }
  }
};
</script>