export default class User {
  constructor(id, login, lastname, firstname, email, enabled, emailVerified, requiredActions, admin, doctor) {
    this.id = id;
    this.login = login;
    this.lastname = lastname;
    this.firstname = firstname;
    this.email = email;
    this.enabled = enabled;
    this.emailVerified = emailVerified;
    this.requiredActions = requiredActions;
    this.admin = admin;
    this.doctor = doctor;
  }

  static build({ id, login, lastname, firstname, email, enabled, emailVerified, requiredActions, admin, doctor }) {
    return new User(id, login, lastname, firstname, email, enabled, emailVerified, requiredActions, admin, doctor);
  }

  static parse(value) {
    return value ? User.build({
                                id: value.id,
                                login: value.login,
                                lastname: value.lastname,
                                firstname: value.firstname,
                                email: value.email,
                                enabled: value.enabled,
                                emailVerified: value.emailVerified,
                                requiredActions: value.requiredActions,
                                admin: value.admin,
                                doctor: value.doctor
    }) : null;
  }

  toJSON() {
    return {
      id: this.id !== undefined || this.id !== '' ? this.id : null,
      login: this.login !== undefined || this.login !== '' ? this.login : null,
      lastname: this.lastname !== undefined || this.lastname !== '' ? this.lastname : null,
      firstname: this.firstname !== undefined || this.firstname !== '' ? this.firstname : null,
      email: this.email !== undefined || this.email !== '' ? this.email : null,
      enabled: this.enabled !== undefined || this.enabled !== '' ? this.enabled : null,
      emailVerified: this.emailVerified !== undefined || this.emailVerified !== '' ? this.emailVerified : null,
      requiredActions: this.requiredActions !== undefined || this.requiredActions !== [] ? this.requiredActions : null,
      admin: this.admin !== undefined || this.admin !== '' ? this.admin : null,
      doctor: this.doctor !== undefined || this.doctor !== '' ? this.doctor : null
    };
  }
}
