<template>
  <div class="kit-analyser-configuration">
    <app-kit-analyser-configuration-header />
    <b-form-row
      v-if="isAdmin"
      class="mt-5">
      <b-col lg="2">
        <b-form-group
          :label="$t('formKitStatus')">
          <app-kit-status-dropdown
            :value="kitStatus"
            :disabled="!isAdmin"
            @input="onSelectKitStatus" />
        </b-form-group>
      </b-col>
    </b-form-row>
    <template v-if="kitConfiguration">
      <h6>{{ $t('formThresholds') }}</h6>
      <b-form-row>
        <b-col
          v-for="channel in kitConfiguration.kitAnalyserChannels"
          :key="channel.channel"
          cols="2">
          <app-kit-analyser-channel-form
            :channel="channel"
            :readonly="locked"
            @change="onKitAnalyserChannelChange" />
        </b-col>
      </b-form-row>

      <div
        v-for="(group, index) in diagnosticGroups"
        :key="`diagnostic-group-${index}`"
        class="diagnostic-group position-relative">
        <b-button
          :key="`diagnostic-group-button-${index}`"
          :ref="`diagnosticGroupButton`"
          :aria-controls="`diagnostic-group-collapse-${index}`"
          :aria-expanded="group.visible ? 'true' : 'false'"
          :class="{ collapsed: !group.visible }"
          class="collapse-button"
          @click="toggleGroup(group, index)">
          <i
            :class="`fa fa-chevron-${group.visible?'up':'down'}`"
            aria-hidden="true" />
        </b-button>
        <h6 :key="`diagnostic-group-title-${index}`">
          {{ group.label }}
        </h6>
        <b-collapse
          :id="`diagnostic-group-collapse-${index}`"
          :ref="`diagnosticGroupCollapse`"
          v-model="group.visible">
          <table
            :key="`diagnostic-group-${group.label}`"
            :class="`table diagnostics diagnostic-${group.type} table-borderless table-sm`">
            <thead v-if="group.diagnostics.length">
              <tr>
                <th
                  v-if="!locked"
                  class="actions" />
                <th class="color">
                  <b-form-group :label="$t('formDiagnosticColor')" />
                </th>
                <th
                  v-if="group.type==='suggested-result'"
                  class="validity-type">
                  <b-form-group :label="$t('formValidityType')" />
                </th>
                <th
                  v-if="group.type==='control'"
                  class="control-bar-code">
                  <b-form-group :label="$t('formControlBarCode')" />
                </th>
                <th
                  v-if="group.type==='control'"
                  class="control-sample-type">
                  <b-form-group :label="$t('formControlSampleType')" />
                </th>
                <th class="label">
                  <b-form-group :label="$t('formDiagnosticLabel')" />
                </th>
                <th class="conditions">
                  <b-form-group :label="$t('formDiagnosticConditions')" />
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(diagnostic, i) in group.diagnostics">
                <app-diagnostic-form
                  :key="`diagnostic-form-${i}`"
                  :ref="`diagnosticForm${group.key}`"
                  :diagnostic="diagnostic"
                  :already-used-colors="alreadyUsedColors(diagnostic)"
                  :already-used-labels="alreadyUsedLabels(diagnostic)"
                  :already-used-bar-codes="alreadyUsedBarCodes(diagnostic)"
                  :already-used-sample-types="alreadyUsedSampleTypes(group, diagnostic)"
                  :sample-types="group.sampleTypes"
                  :readonly="locked"
                  @change="onDiagnosticChange($event)">
                  <template
                    v-if="!disable(group)"
                    #actions>
                    <td
                      class="actions">
                      <div class="d-flex justify-content-center align-items-center">
                        <b-form-group v-if="group.removable">
                          <b-button
                            class="btn--no-outline btn-icon btn-icon-minus"
                            variant="outline"
                            @click="onClickRemoveDiagnostic(diagnostic)"
                            @mouseenter="setRemoving(group.key, i, true)"
                            @mouseleave="setRemoving(group.key, i, false)">
                            <font-awesome-icon :icon="['fas', 'minus-circle']" />
                          </b-button>
                        </b-form-group>
                      </div>
                    </td>
                  </template>
                </app-diagnostic-form>
              </template>
              <tr v-if="!disable(group)">
                <td>
                  <div :class="group.diagnostics.length?'d-flex justify-content-center align-items-center':''">
                    <b-form-group v-if="group.addable">
                      <b-button
                        class="btn--no-outline btn-icon btn-icon-plus"
                        variant="outline"
                        @click="onClickAddDiagnostic(group.controlType)">
                        <font-awesome-icon :icon="['fas', 'plus-circle']" />
                      </b-button>
                    </b-form-group>
                  </div>
                </td>
                <td :colspan="group.type==='control'?5:3" />
              </tr>
            </tbody>
          </table>
        </b-collapse>
      </div>

      <div class="action-footer bg-light">
        <hr>
        <b-form-row class="justify-content-end">
          <b-button
            class="ml-4 mr-auto"
            :to="{ name: 'kit-configuration' }">
            {{ $t('common.back') }}
          </b-button>
          <b-button
            :disabled="disabled"
            class="mr-2"
            @click="cancel">
            {{ $t('common.reset') }}
          </b-button>
          <b-button
            v-if="!saving"
            :disabled="disabled"
            @click="save">
            {{ $t('save') }}
          </b-button>
          <b-button
            v-if="saving"
            class="form-button"
            disabled>
            <b-spinner
              small
              type="grow" />
            {{ $t('loading') }}
          </b-button>
        </b-form-row>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { store } from '@/store/store';
import SecurityMixin from '@/mixins/SecurityMixin';
import NotificationMixin from '@/mixins/NotificationMixin';
import ValidationMixin from '@/mixins/ValidationMixin';
import KitStatusDropdown from '@/components/common/KitStatusDropdown.vue';
import KitAnalyserChannelForm from './KitAnalyserChannelForm.vue';
import DiagnosticForm from './DiagnosticForm.vue';
import Diagnostic from '@/models/Diagnostic';
import {
  createKitConfiguration,
  findAllDiagnosticExpressionOperators,
  findAllDiagnosticExpressionVariables,
  findAllSampleTypesForControl,
  getKitConfiguration,
  getKitConfigurationNextVersion,
  updateKitConfiguration,
  updateKitConfigurationForDoctor
} from '@/service/SmazentechService';
import { required } from '@vuelidate/validators/dist/index.cjs';
import SampleType from '@/models/SampleType';
import KitAnalyserConfigurationHeader from '@/components/admin/configuration/kit/kitAnalyserConfigurationHeader.vue';


export default {
  name: 'KitAnalyserConfiguration',
  components: {
    'app-kit-status-dropdown': KitStatusDropdown,
    'app-kit-analyser-channel-form': KitAnalyserChannelForm,
    'app-diagnostic-form': DiagnosticForm,
    'app-kit-analyser-configuration-header': KitAnalyserConfigurationHeader
  },
  mixins: [
    SecurityMixin,
    NotificationMixin,
    ValidationMixin
  ],
  data() {
    return {
      canAddKitVersion: false,
      canAddKitVersionCustom: false,
      lastKitVersion: undefined,
      customizing: false,
      saving: false,
      groups: [
        {
          key: 'SampleInterpretation',
          label: this.$t('formKitSampleInterpretation'),
          type: 'suggested-result',
          diagnostics: [],
          visible: true,
          addable: false,
          removable: false
        },
        {
          key: 'KitZentechControls',
          label: this.$t('formKitZentechControls'),
          type: 'control',
          controlType: 'KIT',
          diagnostics: [],
          visible: true,
          addable: false,
          removable: false
        },
        {
          key: 'LaboratoryControls',
          label: this.$t('formLaboratoryControls'),
          type: 'control',
          controlType: 'INTERNAL',
          diagnostics: [],
          visible: true,
          addable: false,
          removable: false
        }
      ],
      searchedVersionLot: undefined
    };
  },
  validations: {
    kitConfiguration: {
      required,
      kitAnalyserChannels: {
        required
      },
      diagnostics: {
        required
      }
    }
  },
  computed: {
    ...mapFields('configuration', [
      'kit',
      'lotsRelatedToKit',
      'kitVersion',
      'customKitVersion',
      'customKitVersionLaboratory',
      'laboratoryId',
      'analyser',
      'kitStatus',
      'kitConfiguration',
      'controlSampleTypes',
      'loading',
      'kitAnalyser'
    ]),
    ...mapGetters('configuration', [
      'locked',
      'SampleInterpretation',
      'KitZentechControls',
      'LaboratoryControls'
    ]),
    isAdmin() {
      return this.hasRole('ADMIN');
    },
    isDoctor() {
      return this.hasRole('WRITE_DIAGNOSTIC');
    },
    laboratory() {
      return this.userLaboratory();
    },
    kitId() {
      return this.kit?.id;
    },
    kitVersionId() {
      return this.kitVersion?.id;
    },
    canAddKit() {
      return this.isAdmin && (this.laboratory?.kitManufacturer ?? false);
    },
    canManageOfficialVersion() {
      return this.laboratory?.kitManufacturer ?? false;
    },
    diagnosticGroups() {
      return this.groups.map(g => {
        const diagnostics = this[g.key];
        const sampleTypes = g.type === 'control' ?
          this.controlSampleTypes.map(st => ({ ...st, controlType: g.controlType })) :
          [];
        const addable = g.type !== 'control'
            || (g.controlType === 'KIT' && diagnostics.length < this.controlSampleTypes.length)
            || (g.controlType !== 'KIT' && !this.isAdmin);
        const removable = g.type !== 'control' || (!this.isAdmin || g.controlType === 'KIT');

        return {
          ...g,
          diagnostics,
          sampleTypes,
          addable,
          removable
        };
      });
    },
    disabled() {
      if (this.isAdmin && this.canManageOfficialVersion) {
        return this.locked;
      }
      if (this.isDoctor && this.customKitVersion?.source?.id) {
        return this.customKitVersion?.locked;
      }

      return true;
    }
  },
  beforeMount() {
    if (!this._loadConfiguration()) {
      return;
    }

    findAllSampleTypesForControl().then(this.setControlSampleTypes);
    findAllDiagnosticExpressionVariables().then(this.setExpressionVariables);
    findAllDiagnosticExpressionOperators().then(this.setExpressionOperators);
  },
  mounted() {
    this.setLaboratory(this.laboratory);
  },
  methods: {
    ...mapActions('configuration', [
      'setControlSampleTypes',
      'setExpressionVariables',
      'setExpressionOperators',
      'reset',
      'setLaboratory',
      'setKitConfiguration',
      'setKitStatus',
      'updateKitAnalyserChannel',
      'addDiagnostic',
      'updateDiagnostic',
      'removeDiagnostic'
    ]),
    disable(groupDiagnostics) {
      return this.locked ? this.locked : (this.isDoctor && !this.isAdmin) && groupDiagnostics.type === 'control' && groupDiagnostics.controlType === 'KIT';
    },
    save() {
      if (!this.isValid()) {
        const error = this.getError()[0];
        this.showErrorNotification(this.$t('configuration.kit.save.notification.title'), this.$t('configuration.kit.save.notification.invalid_message', [ error.$property ]));

        return;
      }
      const configuration = this.$store.getters['configuration/updatedKitConfiguration'];
      this.saving = true;
      const saving = configuration.kitAnalyser.kitAnalyserId ?
        this.isAdmin ? updateKitConfiguration(configuration) :
          updateKitConfigurationForDoctor(configuration) :
        createKitConfiguration(configuration);
      saving
        .then(kitConfiguration => {
          this.saving = false;
          this.setKitConfiguration(kitConfiguration);
          this.showSuccessNotification(this.$t('configuration.kit.save.notification.title'), this.$t('configuration.kit.save.notification.success_message'));
        })
        .catch(error => {
          this.saving = false;
          this.showErrorNotification(this.$t('configuration.kit.save.notification.title'), error);
        });
    },
    cancel() {
      this._loadConfiguration();
    },
    toggleGroup(group, index) {
      this.groups[index].visible = !this.groups[index].visible;
    },
    onSelectKitStatus(event) {
      this.setKitStatus(event);
    },
    onKitAnalyserChannelChange(event) {
      this.updateKitAnalyserChannel(event);
    },
    onClickAddDiagnostic(controlType) {
      const diagnostic = Diagnostic.parse({
        diagnosticType: controlType ? 'CONTROL' : 'RESULT',
        control: controlType ? { controlType } : null,
        validityType: 'VALID',
        conditions: [ Diagnostic.singleCondition() ]
      });
      this.addDiagnostic(diagnostic);
    },
    onDiagnosticChange(event) {
      this.updateDiagnostic(event);
    },
    onClickRemoveDiagnostic(diagnostic) {
      this.removeDiagnostic(diagnostic);
    },
    setRemoving(key, index, removing) {
      const cssClasses = this.$refs[`diagnosticForm${key}`][index].$el.classList;
      if (removing) {
        cssClasses.add('removing');
      } else {
        cssClasses.remove('removing');
      }
    },
    alreadyUsedColors(diagnostic) {
      return this.kitConfiguration?.diagnostics
        .filter(d => (!diagnostic || d.index !== diagnostic.index)
          && ((d.diagnosticType === 'CONTROL' && d.validityType === 'VALID')
            || d.diagnosticType === 'RESULT')
        && d.color)
        .map(d => d.color.toLowerCase());
    },
    alreadyUsedLabels(diagnostic) {
      return this.kitConfiguration?.diagnostics
        .filter(d => d.index !== diagnostic.index && (d.diagnosticType !== 'CONTROL' || d.validityType === 'VALID'))
        .map(d => d.control ?
          SampleType.labelize({ label: d.control.label, type: d.control.sampleType })
          : d.label);
    },
    alreadyUsedBarCodes(diagnostic) {
      return this.kitConfiguration?.diagnostics
        .filter(d => d.index !== diagnostic.index && (d.diagnosticType !== 'CONTROL' || d.validityType === 'VALID'))
        .filter(d => d.control)
        .map(d => d.control.barCode);
    },
    alreadyUsedSampleTypes(group, diagnostic) {
      if (group.type !== 'control' || group.controlType !== 'KIT') {
        return [];
      }

      return group.diagnostics
        .filter(d => (!diagnostic || d.index !== diagnostic.index) && d.diagnosticType === 'CONTROL' && d.validityType === 'VALID')
        .map(d => d.control.sampleType);
    },
    _loadConfiguration() {
      const { kitAnalyserId } = this.$route.params;
      const { versionId, analyserId } = this.$route.query;
      let fetching;
      if (kitAnalyserId) {
        fetching = getKitConfiguration(kitAnalyserId);
      } else if (versionId && analyserId) {
        const laboratoryId = !this.canManageOfficialVersion ? this.laboratory.id : null;
        fetching = getKitConfigurationNextVersion({ kitVersionId: versionId, analyserId, laboratoryId });
      }
      if (!fetching) {
        this.$router.push({ name: 'configure', params: { tab: 'kit' } });

        return;
      }

      return fetching
        .then(kitConfiguration => store.dispatch('configuration/setKitConfiguration', kitConfiguration))
        .catch(error => {
          console.error(error);
          this.showErrorNotification(this.$t('configuration.kit.load.notification.title'), error, { root: true });
          this.$router.push({ name: 'configure', params: { tab: 'kit' } });
        });
    }
  }
};
</script>