<template>
  <b-form-row class="lot-form">
    <b-col
      cols="1"
      class="active">
      <b-form-group :label="$t('formLotActiveLabel')">
        <b-form-checkbox
          v-model="lot.active"
          :readonly="readonly"
          :state="validateState('lot.active')"
          @blur="touch('lot.active')" />
      </b-form-group>
    </b-col>

    <b-col>
      <b-form-group :label="$t('formLotNameLabel')">
        <b-input
          v-model="lot.name"
          :readonly="readonly"
          :state="validateState('lot.name')"
          @blur="touch('lot.name')" />
      </b-form-group>
    </b-col>

    <b-col>
      <b-form-group :label="$t('formLotExpirationDateLabel')">
        <b-datepicker
          v-model="lot.expirationDate"
          :readonly="readonly"
          :locale="$i18n.locale"
          :state="validateState('lot.expirationDate')"
          @input="touch('lot.expirationDate')" />
      </b-form-group>
    </b-col>

    <b-col>
      <b-form-group :label="$t('formLotKitVersionLabel')">
        <app-kit-version-dropdown
          :value="kitVersion"
          :kit-id="kitId"
          :analyser-configured="true"
          :readonly="readonly"
          :show-labels="false"
          :state="validateState('lot.kitVersion')"
          @close="touch('lot.kitVersion')"
          @select="kitVersion = $event" />
      </b-form-group>
    </b-col>
  </b-form-row>
</template>

<script>
import SecurityMixin from '@/mixins/SecurityMixin';
import ValidationMixin from '@/mixins/ValidationMixin';
import KitVersionDropdown from '@/components/common/KitVersionDropdown.vue';
import {required} from '@vuelidate/validators/dist/index.cjs';
import {afterDate} from '@/validations/afterDate';

export default {
  name: 'LotForm',
  components: {
    'app-kit-version-dropdown': KitVersionDropdown
  },
  mixins: [
    SecurityMixin,
    ValidationMixin
  ],
  props: {
    value: {
      type: Object,
      default: undefined
    },
    kitId: {
      type: Number,
      default: undefined
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  validations() {
    return {
      lot: {
        name: {
          required
        },
        expirationDate: {
          afterNow: value => new Date(value).valueOf() !== new Date(this.expirationDate).valueOf() ? afterDate(this.now)(value) : true
        },
        kitVersion: {
          required
        }
      }
    };
  },
  data() {
    return {
      now: new Date(),
      expirationDate: undefined
    };
  },
  computed: {
    lot: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('change', value);
      }
    },
    kitVersion: {
      get() {
        return this.lot.kitVersion;
      },
      set(value) {
        this.lot.kitVersion = value;
      }
    }
  },
  watch: {
    lot(newLot) {
      this.expirationDate = newLot.expirationDate;

    }
  },
  beforeMount() {
    this.expirationDate = this.lot.expirationDate;
  }
};
</script>