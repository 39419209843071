export default class Analyser {
  constructor(id, name, version, maxNumberOfFilesByRun, minNumberOfFilesByRun, manufacturer, extension) {
    this.id = id;
    this.name = name;
    this.version = version;
    this.maxNumberOfFilesByRun = maxNumberOfFilesByRun;
    this.minNumberOfFilesByRun = minNumberOfFilesByRun;
    this.manufacturer = manufacturer;
    this.extension = extension;
  }

  static build({ id, name, version, maxNumberOfFilesByRun, minNumberOfFilesByRun, manufacturer, extension }) {
    return new Analyser(id, name, version, maxNumberOfFilesByRun, minNumberOfFilesByRun, manufacturer, extension);
  }

  static parse(value) {
    return value ? new Analyser(
      value.id,
      value.name,
      value.version,
      value.minNumberOfFilesByRun,
      value.maxNumberOfFilesByRun,
      value.manufacturer,
      value.extension
    ) : null;
  }

  toJSON() {
    return {
      id: this.id !== undefined || this.id !== '' ? this.id : null,
      name: this.name !== undefined || this.name !== '' ? this.name : null,
      version: this.version !== undefined || this.version !== '' ? this.version : null,
      maxNumberOfFilesByRun: this.maxNumberOfFilesByRun !== undefined || this.maxNumberOfFilesByRun !== '' ? this.maxNumberOfFilesByRun : null,
      minNumberOfFilesByRun: this.minNumberOfFilesByRun !== undefined || this.minNumberOfFilesByRun !== '' ? this.minNumberOfFilesByRun : null,
      manufacturer: this.manufacturer !== undefined || this.manufacturer !== '' ? this.manufacturer : null,
      extension: this.extension !== undefined || this.extension !== '' ? this.extension : null
    };
  }
}
