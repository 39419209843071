<template>
  <div>
    <b-jumbotron
      class="pb-4 well-configuration-jumbotron"
      border-variant="dark"
      bg-variant="transparent">
      <transition
        name="slide-fade"
        mode="out-in">
        <b-container>
          <b-form-group
            class="form-group-fixed"
            :label="$t('formTemplateName')">
            <b-form-input
              ref="templateNameInput"
              v-model="name"
              :state="validateState('name')"
              type="text" />
          </b-form-group>
          <b-form-row>
            <b-container class="d-flex flex-row align-items-center justify-content-between p-4">
              <b-link
                ref="cancelLink"
                class="fa-pull-left align-middle"
                @click="$emit('close')">
                {{ $t('cancel') }}
              </b-link>
              <b-button
                ref="savePlateTemplateButton"
                @click="openModalIfNecessaryOrSaveTemplate">
                {{ $t('save') }}
              </b-button>
            </b-container>
          </b-form-row>
        </b-container>
      </transition>
    </b-jumbotron>
    <app-confirmation-modal
      ref="cancelModal"
      :title="$t('modalCreateTemplateTitle')"
      :message="$t('modalCreateTemplateMessage', [name])"
      :ok-button-name="$t('yes')"
      :cancel-button-name="$t('no')"
      @ok="updatePlateTemplate" />
  </div>
</template>

<script>
import PlateTemplate from '@/models/PlateTemplate';
import NotificationMixin from '../../../../../../../mixins/NotificationMixin';
import ValidationMixin from '../../../../../../../mixins/ValidationMixin';
import { required } from '@vuelidate/validators/dist/index.cjs';
import ConfirmationModal from '../../../../../../common/ConfirmationModal';
import cloneDeep from 'lodash/cloneDeep';


export default {
  components: {
    'app-confirmation-modal': ConfirmationModal
  },
  mixins: [
    NotificationMixin,
    ValidationMixin
  ],
  props: {
    wells: {
      type: Array,
      required: true
    },
    availableTemplates: {
      type: Array,
      required: false,
      default: () => []
    },
    analyserId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      name: undefined
    };
  },
  validations: {
    name: {
      required
    }
  },
  methods: {
    openModalIfNecessaryOrSaveTemplate() {
      const isTemplateNameExist = !!this.availableTemplates.find(template => template.name === this.name);
      if (isTemplateNameExist) {
        this.$refs.cancelModal.showModal();
      } else {
        this.savePlateTemplate();
      }
    },
    updatePlateTemplate() {
      const templateToUpdate = cloneDeep(this.availableTemplates.find(template => template.name === this.name));
      const wells = cloneDeep(this.wells);
      wells.forEach(well => {
        if (!well.kitVersion) {
          well.kitVersion = well?.lot?.kitVersion;
        }
      });
      templateToUpdate.wellTemplates = wells;
      templateToUpdate.analyserId = this.analyserId;

      this.$store.dispatch('plateTemplate/updatePlateTemplate', templateToUpdate)
        .then(() => {
          this.showSuccessNotification(
            this.$t('plateTemplateCreatedTitle'),
            this.$t('plateTemplateCreatedBody'),
            {
              root: true
            });
          this.$emit('templateSaved');
        })
        .catch(error => {
          this.showErrorNotification(this.$t('plateTemplateCreatedErrorTitle'), error);
        });
    },
    savePlateTemplate() {
      const wells = cloneDeep(this.wells);
      wells.forEach(well => {
        if (!well.kitVersion) {
          well.kitVersion = well?.lot?.kitVersion;
        }
      });
      if (this.isValid()) {
        const templateToSave = PlateTemplate.build({
          id: null,
          laboratory: null,
          name: this.name,
          wellTemplates: wells,
          analyserId: this.analyserId
        });

        this.$store.dispatch('plateTemplate/createPlateTemplate', templateToSave)
          .then(() => {
            this.showSuccessNotification(
              this.$t('plateTemplateCreatedTitle'),
              this.$t('plateTemplateCreatedBody'),
              {
                root: true
              });
            this.$emit('templateSaved');
          })
          .catch(error => {
            this.showErrorNotification(this.$t('plateTemplateCreatedErrorTitle'), error);
          });
      }
    }
  }
};
</script>
