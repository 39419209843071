export default class WellChart {
  constructor(position, channels) {
    this.name = name;
    this.position = position;
    this.channels = channels;
  }

  static build({ position, channels }) {
    return new WellChart(position, channels);
  }

  static parse(value) {
    return value ? WellChart.build({
      ...value
    }) : null;
  }

  toJSON() {
    return {
      position: this.position !== undefined && this.position !== '' ? this.position : null,
      channels: this.channels !== undefined && this.channels !== '' ? this.channels : null
    };
  }
}