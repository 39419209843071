<template>
  <div
    :class="`${getLotClass} lot`">
    <span class="align-middle">{{ lot.name }}</span>
  </div>
</template>

<script>
export default {
  props: {
    lot: {
      type: Object,
      default: undefined
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getLotClass() {
      const date = new Date();
      date.setHours(0,0,0,0);

      return {
        'lot-expired': this.lot.expirationDate < date,
        'focused': this.selected
      };
    }
  }
};
</script>

