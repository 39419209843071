import { getField, updateField } from 'vuex-map-fields';
import Keycloak from 'keycloak-js';
import cloneDeep from 'lodash/cloneDeep';
import { getLaboratory } from '@/service/SmazentechService';

const state = {
  keycloak: new Keycloak(),
  userInfo: undefined
};

export const getters = {
  getField,
  hasRole: state => role => state.keycloak.hasResourceRole(role, process.env.VUE_APP_KEYCLOAK_RUNS_RESOURCE),
  userName: state => state.userInfo
    ? state.userInfo.preferred_username
    : '',
  userLaboratory: state => state.userInfo?.laboratory,
  userLaboratoryId: state => state.userInfo?.laboratory?.id ?? ''
};

const mutations = {
  updateField
};

export const actions = {
  setKeycloakInstance({ commit }, keycloak) {
    commit('updateField', { path: 'keycloak', value: cloneDeep(keycloak) });
    keycloak.loadUserInfo()
      .then(response => {
        const userInfo = response;
        const laboratoryName = userInfo?.laboratories && userInfo?.laboratories[0] ? userInfo.laboratories[0] : undefined;

        return getLaboratory(laboratoryName).then(laboratory => ({ ...userInfo, laboratory }));
      })
      .then(userInfo => commit('updateField', { path : 'userInfo', value: cloneDeep(userInfo) }));
  },
  logout({ state, commit }) {
    commit('updateField', { path : 'userInfo', value: undefined });
    // the redirect option specifies the uri to redirect to after logout.
    state.keycloak.logout({ redirectUri : `${location.origin}` });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
