import { i18n } from '@/translation/i18n';
import Comment from './Comment';
import Kit from './Kit';
import Lot from './Lot';
import SampleType from './SampleType';
import Acquisition from './Acquisition';
import WellDiagnostic from './WellDiagnostic';
import WellInterpretation from './WellInterpretation';
import KitVersion from '@/models/KitVersion';

export default class Well {
  constructor(
    id,
    name,
    position,
    analyseWell,
    sampleType,
    kit,
    kitVersion,
    lot,
    comments,
    diagnosticComment,
    acquisitions,
    interpretation,
    chart,
    diagnostic
  ) {
    this.id = id;
    this.name = name;
    this.position = position;
    this.analyseWell = analyseWell;
    this.sampleType = sampleType;
    this.kit = kit;
    this.kitVersion = kitVersion;
    this.lot = lot;
    this.comments = comments;
    this.acquisitions = acquisitions;
    this.interpretation = interpretation;
    this.chart = chart;
    this.diagnostic = diagnostic;
  }

  static build({
    id,
    name,
    position,
    analyseWell,
    sampleType,
    kit,
    kitVersion,
    lot,
    comments,
    diagnosticComment,
    acquisitions,
    interpretation,
    chart,
    diagnostic
  }) {
    return new Well(id, name, position, analyseWell, sampleType, kit, kitVersion, lot, comments, diagnosticComment, acquisitions, interpretation, chart, diagnostic);
  }

  static parse(value) {
    return value ? Well.build({
      ...value,
      kit: Well.parseKit(value),
      kitVersion: Well.parseKitVersion(value),
      lot: Lot.parse(value.lot),
      sampleType: SampleType.parse(value.sampleType),
      comments: value.comments ? value.comments.map(comment => Comment.parse(comment)) : [],
      acquisitions: value.acquisitions ? value.acquisitions.map(acquisition => Acquisition.parse(acquisition)) : [],
      interpretation: WellInterpretation.parse(value.interpretation),
      diagnostic: WellDiagnostic.parse(value.diagnostic)
    }) : null;
  }

  static parseKit(value) {
    let kit = null;
    if (value.kit) {
      kit = value.kit;
    } else if (value.kitVersion?.kit) {
      kit = value.kitVersion.kit;
    } else if (value.lot?.kitVersion?.kit) {
      kit = value.lot.kitVersion.kit;
    }

    return Kit.parse(kit);
  }

  static parseKitVersion(value) {
    let kitVersion = null;
    if (value.kitVersion) {
      kitVersion = value.kitVersion;
    } else if (value.lot?.kitVersion) {
      kitVersion = value.lot.kitVersion;
    }

    return KitVersion.parse(kitVersion);
  }

  hasComments() {
    return this.comments?.length > 0 || !!this.getWellDiagnosticComment();
  }

  getWellDiagnosticComment() {
    if (!this.diagnostic || this.diagnostic.origin !== 'SUBMITTED') {
      return undefined;
    }
    const comment = i18n.t('wellInterpretationChanged', [ this.interpretation.suggestedResult.getLabel(), this.diagnostic.result.getLabel(), this.diagnostic.technicianName ]);

    return Comment.build({ technicianName: this.diagnostic.technicianName, creationDateTime: this.diagnostic.creationDateTime, value: comment });
  }

  toJSON() {
    return {
      id: this.id !== undefined && this.id !== '' ? this.id : null,
      name: this.name !== undefined && this.name !== '' ? this.name : null,
      position: this.position !== undefined && this.position !== '' ? this.position : null,
      analyseWell: this.analyseWell !== undefined && this.analyseWell !== '' ? this.analyseWell : null,
      sampleType: this.sampleType !== undefined && this.sampleType !== '' ? this.sampleType : null,
      kit: this.kit !== undefined && this.kit !== '' ? this.kit : null,
      lot: this.lot !== undefined && this.lot !== '' ? this.lot : null,
      comments: this.comments !== undefined && this.comments !== '' ? this.comments : null,
      acquisitions: this.acquisitions !== undefined && this.acquisitions !== '' ? this.acquisitions : null
    };
  }
}

export function isSampleTypeOfControl(sampleType) {
  return sampleType && [ 'POSITIVE_CONTROL', 'NEGATIVE_AMPLIFICATION_CONTROL', 'NEGATIVE_EXTRACTION_CONTROL' ].includes(sampleType.type);
}

export function getDiagnostic(well) {
  return well.diagnostic ? well.diagnostic.result : well.interpretation?.suggestedResult;
}

export function getColor(well) {
  return (getDiagnostic(well) ?? well?.sampleType)?.color;
}
