<template>
  <div>
    <b-jumbotron
      bg-variant="transparent"
      border-variant="dark"
      class="pb-4 well-configuration-jumbotron">
      <transition
        mode="out-in"
        name="slide-fade">
        <b-container
          v-if="!updateConfirmationFor"
          key="configuration"
          ref="configuration">
          <b-form-group
            :label="$t('formKit')">
            <app-kit-dropdown
              v-model="kit"
              :overriding-options="availableKits"
              :show-labels="false" />
          </b-form-group>
          <div class="form-group-dropdown-container">
            <transition name="fade">
              <b-form-group
                v-if="isSingleKitSelected"
                :label="$t('formLot')">
                <app-lot-dropdown
                  v-model="lot"
                  :overriding-options="kitLots"
                  :preselect-single-overriding-option="true"
                  :show-labels="false" />
              </b-form-group>
            </transition>
          </div>
          <div class="form-group-dropdown-container">
            <transition name="fade">
              <b-form-group :label="$t('formSampleType')">
                <app-sample-type-dropdown
                  v-model="sampleType"
                  :show-labels="false"
                  :overriding-options="lotSamples" />
              </b-form-group>
            </transition>
          </div>
          <div class="form-group-container">
            <transition name="fade">
              <b-form-group
                v-if="isSingleSelect"
                :label="$t('formWellName')"
                class="form-group-fixed">
                <b-form-input
                  ref="wellNameInput"
                  v-model="name"
                  type="text" />
              </b-form-group>
            </transition>
          </div>
          <div class="text-center mt-4">
            <b-button @click="$emit('close')">
              {{ $t('close') }}
            </b-button>
          </div>
        </b-container>
        <b-container
          v-else
          key="confirmation"
          ref="confirmation">
          <p class="text-center">
            {{ $t('wellUpdateConfirmationMessage') }}
          </p>
          <b-form-row class="mt-4">
            <b-container class="d-flex flex-row align-items-center justify-content-center">
              <b-link
                class="mr-5"
                @click="updateConfirmationFor = null">
                {{ $t('no') }}
              </b-link>
              <b-button
                class="ml-5"
                @click="updateValue">
                {{ $t('yes') }}
              </b-button>
            </b-container>
          </b-form-row>
        </b-container>
      </transition>
    </b-jumbotron>
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual';
import uniqBy from 'lodash/uniqBy';
import { mapActions, mapState } from 'vuex';
import KitDropdown from '@/components/common/KitDropdown';
import SampleTypeDropdown from '@/components/common/SampleTypeDropdown';
import LotDropdown from '@/components/common/LotDropdown';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'WellConfiguration',
  components: {
    'app-sample-type-dropdown': SampleTypeDropdown,
    'app-kit-dropdown': KitDropdown,
    'app-lot-dropdown': LotDropdown
  },
  props: {
    vuexModel: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      updateConfirmationFor: null,
      kitSelected: null,
      lotSelected: null,
      sampleTypeSelected: null,
      diagnosticColorChannel1: [],
      diagnosticColorChannel2: []
    };
  },
  computed: {
    ...mapState('runImport', [
      'availableKits',
      'availableLots',
      'availableSampleTypes'
    ]),
    ...mapFields('runImport', [
      'run'
    ]),
    well() {
      return this.vuexModel[0];
    },
    kit: {
      get() {
        let kit = { name: 'dropdownMultiple' };
        if (uniqBy(this.vuexModel, 'kit').length === 1 || this.vuexModel.every(w => !w.kit)) {
          kit = this.vuexModel[0].kit;
        }

        return kit;
      },
      set(kit) {
        if (uniqBy(this.vuexModel, 'kit').length === 1 || this.vuexModel.every(w => !w.kit)) {
          this.setWellsKit({ 'wells': this.vuexModel, kit });
        } else {
          this.kitSelected = kit;
          this.updateConfirmationFor = 'kit';
        }
      }
    },
    kitLots() {
      if (!this.kit?.id) {
        return [];
      }

      return this.availableLots.filter(availableLot => availableLot.kitVersion.kit.id === this.kit.id);
    },
    lot: {
      get() {
        let lot = { name: 'dropdownMultiple' };
        if (uniqBy(this.vuexModel, 'lot').length === 1  || this.vuexModel.every(w => !w.lot)) {
          lot = this.vuexModel[0].lot;
        }

        return lot;
      },
      set(lot) {
        if (uniqBy(this.vuexModel, 'lot').length === 1 || this.vuexModel.every(w => !w.lot)) {
          this.setWellsLot({ 'wells': this.vuexModel, lot });
        } else {
          this.lotSelected = lot;
          this.updateConfirmationFor = 'lot';
        }
      }
    },
    lotSamples() {
      if (!this.lot?.kitVersion?.id) {
        return [];
      }

      return this.availableSampleTypes.filter(sampleType => sampleType.kitVersionId === null || sampleType.kitVersionId === this.lot.kitVersion.id);
    },
    sampleType: {
      get() {
        let sampleType = { label: this.$t('dropdownMultiple') };
        if (uniqBy(this.vuexModel, 'sampleType').length === 1  || this.vuexModel.every(w => !w.sampleType)) {
          sampleType = this.vuexModel[0].sampleType;
        }

        return sampleType;
      },
      set(sampleType) {
        if (uniqBy(this.vuexModel, 'sampleType').length === 1 || this.vuexModel.every(w => !w.sampleType)) {
          this.setWellsSampleType({ 'wells': this.vuexModel, sampleType });
        } else {
          this.sampleTypeSelected = sampleType;
          this.updateConfirmationFor = 'sampleType';
        }
      }
    },
    name: {
      get() {
        return this.vuexModel[0].name;
      },
      set(name) {
        this.setWellsName({ wells: this.vuexModel, name });
      }
    },
    isSingleSelect() {
      return this.vuexModel.length === 1;
    },
    isSingleKitSelected() {
      return this.isSingleSelect || !isEqual(this.kit, { name: 'dropdownMultiple' });
    }
  },
  methods: {
    ...mapActions('runImport', [
      'setWellsSampleType',
      'setWellsKit',
      'setWellsLot',
      'setWellsName'
    ]),
    updateValue() {
      if (this.updateConfirmationFor === 'sampleType') {
        this.setWellsSampleType({ 'wells': this.vuexModel, 'sampleType': this.sampleTypeSelected });
      } else if (this.updateConfirmationFor === 'kit') {
        this.setWellsKit({ 'wells': this.vuexModel, 'kit': this.kitSelected });
      } else if (this.updateConfirmationFor === 'lot') {
        this.setWellsLot({ 'wells': this.vuexModel, 'lot': this.lotSelected });
      }
      this.updateConfirmationFor = null;
    }
  }
};
</script>
