<template>
  <div class="diagnostic-condition-form">
    <template v-for="(expression, index) in condition.expressions">
      <div
        :key="`expression-row-${index}`"
        class="expression-row">
        <app-diagnostic-expression-form
          :key="`diagnostic-expression-${index}`"
          :ref="`expression${index}`"
          :diagnostic-type="diagnosticType"
          :expression="expression"
          :readonly="readonly"
          @change="onExpressionChange(index, $event)"/>
        <div
          :key="`expression-separator-${index}`"
          class="expression-separator">
          <b-form-group>
            <template v-if="!readonly">
              <b-button
                :class="{ invisible: condition.expressions.length===1 }"
                variant="outline"
                class="btn--no-outline btn-icon btn-icon-minus btn-remove"
                @mouseenter="setRemoving(index, true)"
                @mouseleave="setRemoving(index, false)"
                @click="removeExpression(expression)">
                <font-awesome-icon :icon="['fas', 'minus-circle']" />
              </b-button>
            </template>
            <span
              class="expression-join"
              :class="{ invisible: readonly && index === condition.expressions.length-1 }">
              AND
            </span>
            <template v-if="!readonly">
              <b-button
                :class="{ invisible: index !== condition.expressions.length-1 }"
                variant="outline"
                class="btn--no-outline btn-icon btn-icon-plus btn-add"
                @click="addExpression">
                <font-awesome-icon :icon="['fas', 'plus-circle']" />
              </b-button>
            </template>
          </b-form-group>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields';
import SecurityMixin from '@/mixins/SecurityMixin';
import ValidationMixin from '@/mixins/ValidationMixin';
import DiagnosticExpressionForm from './DiagnosticExpressionForm.vue';
import {minLength, required} from '@vuelidate/validators/dist/index.cjs';

export default {
  name: 'DiagnosticConditionForm',
  components: {
    'app-diagnostic-expression-form': DiagnosticExpressionForm
  },
  mixins: [
    SecurityMixin,
    ValidationMixin
  ],
  props: {
    diagnosticType: {
      type: String,
      default: 'RESULT'
    },
    condition: {
      type: Object,
      default: undefined
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapFields('configuration', [
      'expressionVariables',
      'expressionOperators'
    ])
  },
  validations: {
    condition: {
      expressions: {
        required,
        minLength: minLength(1)
      }
    }
  },
  methods: {
    onExpressionChange(index, event) {
      const condition = JSON.parse(JSON.stringify(this.condition));
      condition.expressions[index] = event;
      this.$emit('change', condition);
    },
    addExpression() {
      const condition = JSON.parse(JSON.stringify(this.condition));
      condition.expressions.push({});
      this.$emit('change', condition);
    },
    setRemoving(index, removing) {
      const cssClasses = this.$refs[`expression${index}`][0].$el.classList;
      if (removing) {
        cssClasses.add('removing');
      } else {
        cssClasses.remove('removing');
      }
    },
    removeExpression(expression) {
      const index = this.condition.expressions.findIndex(e => e === expression);
      const condition = JSON.parse(JSON.stringify(this.condition));
      condition.expressions.splice(index, 1);
      this.$emit('change', condition);
    }
  }
};
</script>
