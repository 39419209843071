import { v4 as uuidv4 } from 'uuid';

export default {
  props: {
    title: {
      type: String,
      default: undefined
    },
    message: {
      type: String,
      default: undefined
    },
    okButtonName: {
      type: String,
      default: 'ok'
    },
    okButtonVariant: {
      type: String,
      default: undefined
    },
    okButtonSpinnersEnabled: {
      type: Boolean,
      default: false
    },
    cancelButtonEnabled: {
      type: Boolean,
      default: true
    },
    okButtonClasses: {
      type: Array,
      default: () => []
    },
    okButtonPreventHiding: {
      type: Boolean,
      default: false
    },
    cancelButtonName: {
      type: String,
      default: 'cancel'
    },
    cancelButtonVariant: {
      type: String,
      default: 'secondary'
    },
    cancelButtonPreventHiding: {
      type: Boolean,
      default: false
    },
    headerTextVariant: {
      type: String,
      default: undefined
    },
    preventAutomaticHiding: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      id: uuidv4(),
      okButtonClicked: false
    };
  },
  methods: {
    handleOkButtonClick() {
      this.okButtonClicked = true;
      this.$emit('ok');

      if (!this.preventAutomaticHiding && !this.okButtonPreventHiding) {
        this.hideModal();
      }
    },
    handleCancelButtonClick(event) {
      if (!this.okButtonClicked) {
        this.$emit('cancel');
        if (!this.preventAutomaticHiding && !this.cancelButtonPreventHiding) {
          this.hideModal();
        } else {
          event.preventDefault();
        }
      } else {
        event.preventDefault();
      }
    },
    handleModalHideEvent(event) {
      if (event.trigger === 'backdrop' || event.trigger === 'esc') {
        this.handleCancelButtonClick(event);
      }
    },
    showModal() {
      this.$bvModal.show(this.id);
    },
    hideModal() {
      this.$bvModal.hide(this.id);
      this.okButtonClicked = false;
    }
  },
  computed: {
    cancelButtonVariantClass() {
      return this.cancelButtonVariant ? `text-${this.cancelButtonVariant}` : undefined;
    }
  }
};