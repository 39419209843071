<template>
  <div>
    <b-navbar
        class="border-bottom"
        type="light">
      <b-navbar-brand>
        <img src="@/assets/images/plus-flask-solid.png"
             class="add-laboratory-icon"
             :alt="'add-laboratory-icon'">
        {{ $t('laboratory.create.title') }}
      </b-navbar-brand>
    </b-navbar>
    <div class="p-4 add-laboratories">
      <b-form-row
          v-for="(input, index) in laboratoryInputRows"
          :key="input.uuid">
        <b-col lg="6">
          <b-form-group :label="index === 0 ? $t('laboratory.name') : undefined">
            <b-form-input v-model="input.name"/>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
              :label="index === 0 ? 'Remove / Add' : undefined">
            <b-button
                ref="removeButton"
                :disabled="laboratoryInputRows.length < 2"
                variant="outline"
                class="btn--no-outline btn-icon btn-icon-minus"
                @click="removeLaboratoryInputLine(input)">
              <font-awesome-icon
                  :icon="['fas', 'minus-circle']" />
            </b-button>
            <b-button
                v-if="index === laboratoryInputRows.length - 1"
                ref="addButton"
                variant="outline"
                class="btn--no-outline btn-icon btn-icon-plus"
                @click="addLaboratoryInputLine">
              <font-awesome-icon
                  :icon="['fas', 'plus-circle']" />
            </b-button>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-button
            ref="saveButton"
            class="right-align-60-button"
            @click="save">
          {{ $t('save') }}
        </b-button>
      </b-form-row>
    </div>
  </div>
</template>

<script>
import {v4 as uuidv4} from "uuid";
import {createLaboratories} from "@/service/SmazentechService";
import NotificationMixin from "@/mixins/NotificationMixin";

export default {
  name: "LaboratoryAdd",
  mixins: [
    NotificationMixin
  ],
  data() {
    return {
      laboratoryInputRows: [ { uuid: uuidv4(), name: null } ]
    };
  },
  methods: {
    addLaboratoryInputLine() {
      this.laboratoryInputRows.push({ uuid: uuidv4(), kit: null, lot: null, date: new Date() });
    },
    removeLaboratoryInputLine({ uuid }) {
      const index = this.laboratoryInputRows.findIndex(row => row.uuid === uuid);
      this.laboratoryInputRows.splice(index, 1);
    },
    isAllComplete() {
      return this.laboratoryInputRows.filter(row => !row.name).length === 0;
    },
    save() {
      if (!this.isAllComplete()) {
        this.showInfoNotification(this.$t('common.cannot_save'), this.$t('laboratory.create.notification.cannot_save_message'));
        return;
      }
      createLaboratories(this.laboratoryInputRows)
          .then(() => {
            this.showSuccessNotification(this.$t('laboratory.create.notification.title'), this.$t('laboratory.create.notification.success_message'));
            this.$router.push({ name: 'laboratories' });
          })
          .catch(error => {
            this.showErrorNotification(this.$t('laboratory.create.notification.title'), error);
          });
    }
  }
};
</script>