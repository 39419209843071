<template>
  <editable-multiselect
    ref="multiselect"
    v-model="model"
    :options="options"
    :custom-label="customLabel"
    :placeholder="multiselectPlaceholder"
    :loading="isLoading"
    :allow-empty="allowEmpty"
    :deselect-label="!allowEmpty ? '' : undefined"
    :show-labels="showLabels"
    :class="cssClasses"
    :disabled="disabled"
    @open="$emit('open', $event)"
    @close="$emit('close', $event)"
    @select="$emit('select', $event)"
    @remove="$emit('remove', $event)">
    <template
      v-if="canAdd"
      slot="afterList">
      <b-input-group>
        <b-input
          v-model="kitVersion.version"
          class="col-lg-10 dropdown-add-input"
          :state="validateState('kitVersion.version')"
          @blur="touch('kitVersion.version')"
          @keydown.enter="addKitVersion"
          @mousedown.self.stop />
        <b-input-group-append>
          <b-button
            ref="addButton"
            variant="outline"
            class="btn--no-outline btn-icon btn-icon-plus dropdown-add-button"
            @click="addKitVersion">
            <font-awesome-icon
              :icon="['fas', 'plus-circle']" />
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </template>
  </editable-multiselect>
</template>

<script>
import EditableMultiselect from '@/components/common/EditableMultiselect.vue';
import DropdownMixin from '@/mixins/DropdownMixin';
import SecurityMixin from '@/mixins/SecurityMixin';
import ValidationMixin from '@/mixins/ValidationMixin';
import KitVersion from '@/models/KitVersion';
import {requiredIf} from '@vuelidate/validators/dist/index.cjs';
import {searchLaboratoryVersions, searchOfficialVersions} from '@/service/KitVersionService';

export default {
  components: {
    'editable-multiselect': EditableMultiselect
  },
  mixins: [
    DropdownMixin,
    SecurityMixin,
    ValidationMixin
  ],
  props: {
    value: {
      type: Object,
      default: undefined
    },
    kitId: {
      type: Number,
      default: undefined
    },
    analyserConfigured: {
      type: Boolean,
      default: undefined
    },
    kitVersionId: {
      type: Number,
      default: undefined
    },
    laboratoryId: {
      type: Number,
      default: undefined
    },
    placeholder: {
      type: String,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    canAdd: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      kitVersion: {
        version: null
      }
    };
  },
  validations() {
    return {
      kitVersion: {
        version: {
          required: requiredIf(() => this.canAdd)
        }
      }
    };
  },
  computed: {
    multiselectPlaceholder() {
      return this.placeholder !== undefined ? this.placeholder : this.$t('formSelectVersion');
    }
  },
  watch: {
    kitId(newKitId, oldKitId) {
      if (newKitId !== oldKitId) {
        this.initOptions();
      }
    },
    kitVersionId(newKitVersionId, oldKitVersionId) {
      if (newKitVersionId !== oldKitVersionId) {
        this.initOptions();
      }
    },
    laboratoryId(newLaboratoryId, oldLaboratoryId) {
      if (newLaboratoryId !== oldLaboratoryId) {
        this.initOptions();
      }
    }
  },
  methods: {
    findOptions() {
      if (this.kitVersionId) {
        return searchLaboratoryVersions(this.kitVersionId, {}, {laboratoryId: this.laboratoryId}).then(page => page.content);
      }
      if (this.kitId) {
        return searchOfficialVersions(this.kitId, {}, {analyserConfigured: this.analyserConfigured}).then(page => page.content);
      }

      return Promise.resolve([]);
    },
    customLabel(value) {
      return value?.version;
    },
    addKitVersion() {
      this.touch('kitVersion.version');
      if (!this.isValid()) {
        return;
      }
      if (this.options.find(o => o.version === this.kitVersion.name)) {
        this.showErrorNotification(this.$t('KitVersionDropdownAddKitVersionErrorTitle'), this.$t('KitVersionDropdownAddKitVersionErrorMessage'));

        return;
      }
      const option = KitVersion.parse({ version: this.kitVersion.version, kit: { id: this.kitId } });
      this.options.push(option);
      this.resetValidation();
      this.kitVersion.version = null;
      this.$emit('optionAdded', option);
    },
    deactivate() {
      this.$refs.multiselect.deactivate();
    }
  }
};
</script>