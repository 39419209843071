<template>
  <div class="timeline block">
    <template v-for="(item, index) in items">
      <slot
        name="item"
        :item="item"
        :index="index">
        <app-timeline-item
          :description="item.description"
          :date="item.date" />
      </slot>
    </template>
  </div>
</template>

<script>
import TimelineItem from './TimelineItem.vue';

export default {
  name: 'Timeline',
  components: {
    'app-timeline-item': TimelineItem
  },
  props: {
    items: {
      type: Array,
      default: undefined
    }
  }
};
</script>