<template>
  <multiselect
    v-model="model"
    :options="options"
    :loading="isLoading"
    :placeholder="$t('diagnosticDropdownPlaceholder')"
    :show-labels="false"
    :allow-empty="false"
    :class="`${stateClass} diagnostic-dropdown`">
    <template #singleLabel="{ option }">
      <div class="diagnostic-dropdown-content-container">
        <app-well
          :well="makeWellWithColor(option)"
          class="align-middle" />
        <span class="align-middle">{{ option.getLabel() }}</span>
      </div>
    </template>
    <template #option="{ option }">
      <div class="diagnostic-dropdown-content-container">
        <app-well
          :well="makeWellWithColor(option)"
          class="align-middle" />
        <span class="align-middle">{{ option.getLabel() }}</span>
      </div>
    </template>
    <template #placeholder>
      <div class="diagnostic-dropdown-content-container">
        <span class="align-middle">{{ $t('diagnosticDropdownPlaceholder') }}</span>
      </div>
    </template>
  </multiselect>
</template>

<script>
import DropdownMixin from '../../mixins/DropdownMixin';
import Well from '../run/editor/plate/Well';
import WellModel from '@/models/Well';

export default {
  components: {
    'app-well': Well
  },
  mixins: [ DropdownMixin ],
  props: {
    value: {
      type: Object,
      default: undefined
    }
  },
  methods: {
    makeWellWithColor(diagnostic) {
      return WellModel.parse({ analyseWell: true, interpretation: { suggestedResult : diagnostic } });
    }
  }
};
</script>
