import isBoolean from 'lodash/isBoolean';
import isEqual from 'lodash/isEqual';

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    state: {
      // Tri-state prop: true, false, null (or undefined)
      type: Boolean,
      default: null
    },
    overridingOptions: {
      type: Array,
      default: undefined
    },
    preselectSingleOverridingOption: {
      type: Boolean,
      default: false
    },
    translated: {
      type: Boolean,
      default: false
    },
    allowEmpty: {
      type: Boolean,
      default: true
    },
    showLabels: {
      type: Boolean,
      default: true
    },
    overflowXNowrap: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loadedOptions: [],
      isLoading: false
    };
  },
  computed: {
    options() {
      return this.overridingOptions !== undefined ? this.overridingOptions : this.loadedOptions;
    },
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    computedState() {
      // If not a boolean, ensure that value is null
      return isBoolean(this.state) ? this.state : null;
    },
    stateClass() {
      const state = this.computedState;

      return state === true ? 'is-valid' : state === false ? 'is-invalid' : '';
    },
    cssClasses() {
      return [
        this.stateClass,
        this.overflowXNowrap ? 'overflow-x-nowrap' : ''
      ];
    },
    //DEFAULT NOTIFICATION TITLE: SHOULD BE OVERRIDDEN IN COMPONENT
    errorNotificationTitle() {
      return this.$t('errorDefaultDropdown');
    }
  },
  watch: {
    overridingOptions (newOverridingOptions, oldOverridingOptions) {
      // Set initial model value
      if (!isEqual(newOverridingOptions, oldOverridingOptions)) {
        this.preselectOption(newOverridingOptions);
      }
    }
  },
  beforeMount() {
    this.initOptions();
    this.preselectOption();
  },
  methods: {
    initOptions() {
      if (!this.overridingOptions) {
        this.isLoading = true;
        const self = this;
        const fetching = this.fetching ?? Promise.resolve();
        this.fetching = fetching.then(() => this.findOptions()
          .then(response => {
            self.loadedOptions = response;
            self.isLoading = false;
            self.$emit('optionsLoaded', self.loadedOptions);
          })
          .catch(error => {
            this.showErrorNotification(this.errorNotificationTitle, error.message);
            self.isLoading = false;
          })
        );
      }
    },
    preselectOption(options) {
      if (!options) {
        options = this.overridingOptions;
      }
      if (this.preselectSingleOverridingOption && (Array.isArray(options) ? options.length > 0 : options) && !this.model) {
        this.model = options && options.length === 1 ? options[0] : null;
      }
    },
    //DEFAULT METHOD: SHOULD BE OVERRIDDEN IN COMPONENT
    findOptions() {
      return new Promise(resolve => resolve([]));
    },
    showErrorNotification(title, content) {
      this.$bvToast.toast(content, {
        title: this.$options.filters.capitalize(title),
        variant: 'danger',
        autoHideDelay: '5000',
        toaster: 'b-toaster-bottom-left'
      });
    },
    customLabel(value) {
      return this.translated ? this.$t(value) : value;
    }
  }
};
