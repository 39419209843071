<template>
  <div class="plate-container">
    <!-- headers -->
    <b-row class="justify-content-center">
      <!-- corner -->
      <app-plate-header
        :key="`header(0)`"
        :class="`d-flex justify-content-center align-items-center ` + wellContainerCssClass"
        @select="selectAllWells()">
        All
      </app-plate-header>
      <!-- top header (number) -->
      <app-plate-header
        v-for="num in rows[0].length"
        :key="`header(${num})`"
        :class="`d-flex justify-content-center align-items-center ` + wellContainerCssClass"
        @select="onSelectColumn(num - 1, $event)">
        {{ num }}
      </app-plate-header>
    </b-row>

    <!-- rows -->
    <b-row
      v-for="(row, y) in rows"
      :key="`row(${y})`"
      class="justify-content-center">
      <!-- left header (letter) -->
      <app-plate-header
        :class="`d-flex justify-content-center align-items-center ` + wellContainerCssClass"
        @select="onSelectRow(y, $event)">
        {{ toLetter(y) }}
      </app-plate-header>
      <!-- wells -->
      <div
        v-for="(well, x) in row"
        :key="`column(${x},${y})`"
        :class="`d-flex justify-content-center align-items-center ` + wellContainerCssClass">
        <app-well
          :key="well.position"
          :ref="well.position"
          :selected="selectedWells.has(well.position)"
          :badged="hasWellComments(well)"
          :well="well"
          class="w-100 h-100"
          @select="onSelectWell(x, y, $event)" />
      </div>
    </b-row>
  </div>
</template>

<script>
import Well from '@/components/run/editor/plate/Well.vue';
import PlateHeader from '@/components/run/editor/plate/PlateHeader.vue';
import PlateType from '@/models/PlateType';

export default {
  components: {
    'app-well': Well,
    'app-plate-header': PlateHeader
  },
  props: {
    wells: {
      type: Map,
      default: () => new Map()
    },
    plateType: {
      type: PlateType,
      default: () => PlateType.RECTANGULAR_96
    },
    disableSelection: {
      type: Boolean,
      default: false
    },
    isInterpretation: {
      type: Boolean,
      default: false
    },
    getFirstWell: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      startingLetter: 'A',
      selectedWells: new Map(),
      lastSelect: undefined
    };
  },
  computed: {
    rows() {
      return Array.from(this.wells.values()).reduce((rows, well) => {
        const { x, y } = this.positionToCoordinate(well.position);
        if (!rows[y]) {
          rows[y] = [];
        }
        rows[y][x] = well;

        return rows;
      }, []);
    },
    wellContainerCssClass() {
      let cssClass;

      switch (this.plateType) {
        case PlateType.RECTANGULAR_384:
          cssClass = 'well-container-rectangular-384';
          break;
        case PlateType.RECTANGULAR_96:
        default:
          cssClass = 'well-container-rectangular-96';
          break;
      }

      return cssClass;
    }
  },
  beforeMount() {
    if (this.isInterpretation) {
      this.selectAllWells();
    }
    if (this.getFirstWell) {
      this.selectedWells.set(...this.wells.entries().next().value);
    }
  },
  methods: {
    toLetter(rowIndex) {
      return String.fromCharCode(this.startingLetter.charCodeAt(0) + rowIndex);
    },
    onSelectColumn(x, select) {
      this.applySelection({ x }, select.options);
    },
    onSelectRow(y, select) {
      this.applySelection({ y }, select.options);
    },
    onSelectWell(x, y, select) {
      this.applySelection({ x, y }, select.options);
    },
    applySelection(select, options) {
      if (!select) {
        this.selectedWells = new Map();
        this.$emit('select', []);

        return;
      }
      if ((options.ctrl && options.shift) || this.disableSelection) {
        return;
      }
      const cells = this.selectCells(select, options);
      const selection = options.ctrl || options.shift ?
        new Map(this.selectedWells.entries()) :
        new Map();
      const allAlreadySelected = cells.every(cell => this.selectedWells.has(cell.position));
      if (cells.every(cell => !((cell.sampleType && cell.chart) || !cell.id))) {
        return;
      }
      for (const cell of cells) {
        if (!selection.has(cell.position) && ((cell.sampleType && cell.chart) || !cell.id)) {
          selection.set(cell.position, cell);
        } else if ((!options.shift && allAlreadySelected) || (!options.ctrl && !options.shift)) {
          selection.delete(cell.position);
        }
      }
      this.selectedWells = selection;

      this.$emit('select', Array.from(this.selectedWells.values()));
    },
    selectCells(select, options) {
      const cells = [];
      const start = this.lastSelect ?? { x: 0, y: 0 };
      const end = { ...select };
      if (select.x !== undefined && select.y !== undefined) {
        cells.push(this.rows[select.y][select.x]);
      } else if (select.x !== undefined) {
        cells.push(...this.rows.map(row => row[select.x]));
      } else if (select.y !== undefined) {
        const row = this.rows[select.y];
        cells.push(...row);
      }
      if (!options.shift) {
        this.lastSelect = select;

        return cells;
      }

      let rows = [];
      if (start.y <= end.y) {
        rows = this.rows.slice(start.y, end.y !== undefined ? end.y + 1 : end.y);
      } else {
        rows = this.rows.slice(end.y, start.y !== undefined ? start.y + 1 : start.y);

      }
      for (let i = 0; i < rows.length; i++) {
        let columns = [];
        if (start.x <= end.x) {
          columns = rows[i].slice(
            start.x === undefined ? 0 : start.x,
            end.x === undefined ? undefined : end.x + 1
          );
        } else {
          columns = rows[i].slice(
            end.x === undefined ? 0 : end.x,
            start.x === undefined ? undefined : start.x + 1
          );
        }

        cells.push(...columns);
      }

      return cells;
    },
    selectAll(wells) {
      this.lastSelect = undefined;
      const selection = new Map();
      if (wells) {
        wells.forEach(well => this.wells.has(well.position) && selection.set(well.position, this.wells.get(well.position)));
      }
      this.selectedWells = selection;
    },
    selectAllWells() {
      this.lastSelect = undefined;
      this.applySelection({ x: this.plateType.columnCount - 1, y: this.plateType.rowCount - 1 }, { shift: true });
    },
    hasWellComments(well) {
      return well?.hasComments();
    },
    positionToCoordinate(position) {
      return {
        x: Number.parseInt(position.substring(1)) - 1,
        y: position.charCodeAt(0) - this.startingLetter.charCodeAt(0)
      };
    }
  }
};
</script>