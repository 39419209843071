<template>
  <div class="kit-configuration">
    <b-form-row>
      <b-form-group
        :label="$t('formKit')"
        class="col">
        <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
          <app-kit-dropdown
            ref="kitDropdown"
            :value="kit"
            :search-linked-to-lot="!isAdmin"
            @input="onSelectKit" />
          <b-input-group-append v-if="canAddKit">
            <b-button
              variant="outline-success"
              @click="onClickAddKit">
              <font-awesome-icon :icon="['fas', 'plus-circle']" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>

      <b-col class="row align-items-end justify-content-end form-group">
        <b-button
          v-if="isAdmin && kit && withoutOfficialVersions"
          :disabled="!canAddKitVersion"
          @click="onClickAddKitVersion">
          {{ $t('buttonAddOfficialVersion') }}
        </b-button>
      </b-col>
    </b-form-row>

    <b-table
      id="official-versions"
      ref="officialVersionsTable"
      :fields="kitOfficialVersionFields"
      :items="officialVersionsProvider"
      :per-page="officialVersions.page.size"
      :current-page="officialVersions.page.current"
      bordered
      class="kit-versions official-versions"
      :tbody-tr-class="officialVersionRowClass"
      head-variant="light"
      primary-key="id"
      responsive
      :selectable="canReadCustomKitVersions"
      select-mode="single"
      hover
      @row-clicked="onSelectedOfficialVersion">
      <template #head(version)="data">
        <div>
          {{ data.label }}
          <b-dropdown
            v-if="kit"
            ref="officialFilterByVersion"
            size="sm"
            boundary="viewport">
            <template #button-content>
              <b-icon icon="search" />
            </template>
            <b-dropdown-form>
              <b-form-group :label="$t('formKitVersion')">
                <b-input
                  v-model="officialVersions.filters.version"
                  @keydown.enter.prevent="onClickFilterOfficialByVersion" />
              </b-form-group>
              <b-button
                variant="secondary"
                @click="onClickFilterOfficialByVersion">
                {{ $t('formKitVersionCriteriaVersion') }}
              </b-button>
            </b-dropdown-form>
          </b-dropdown>
        </div>
      </template>


      <template #head(analysers)="data">
        <div>
          {{ data.label }}
          <b-dropdown
            v-if="kit && availableAnalysers.length"
            ref="officialFilterByAnalyser"
            size="sm"
            boundary="viewport">
            <template #button-content>
              <b-icon icon="search" />
            </template>
            <b-dropdown-form>
              <b-form-group :label="$t('formAnalyser')">
                <app-analyser-dropdown
                  v-model="officialVersions.filters.analysers"
                  :overriding-options="availableAnalysers"
                  multiple />
              </b-form-group>
              <b-button
                variant="secondary"
                @click="onClickFilterOfficialByAnalyser">
                {{ $t('formKitVersionCriteriaAnalyser') }}
              </b-button>
            </b-dropdown-form>
          </b-dropdown>
        </div>
      </template>
      <template #cell(analysers)="data">
        <template v-for="analyser in data.item.analysers">
          <app-kit-analyser-badge
            :key="analyser.kitAnalyserId"
            :kit-analyser="analyser" />
        </template>
        <template v-if="canAddKitAnalyser && data.item.analysers.length < availableAnalysers.length">
          <b-dropdown
            class="kit-analyser-add"
            size="sm"
            boundary="viewport"
            no-caret>
            <template #button-content>
              <b-icon
                icon="plus"
                scale="1.5" />
            </template>
            <b-dropdown-form>
              <b-form-group :label="$t('formAnalyser')">
                <app-analyser-dropdown
                  v-model="data.item.analyser"
                  :overriding-options="missingAnalysers(data.item.analysers)" />
              </b-form-group>
              <b-button
                variant="secondary"
                @click="onClickConfigure(data.item, data.item.analyser)">
                {{ $t('common.configure') }}
              </b-button>
            </b-dropdown-form>
          </b-dropdown>
        </template>
      </template>


      <template #head(lots)="data">
        <div>
          {{ data.label }}
          <b-dropdown
            v-if="lotsRelatedToKit.length"
            ref="officialFilterByLot"
            size="sm"
            boundary="viewport">
            <template #button-content>
              <b-icon icon="search" />
            </template>
            <b-dropdown-form>
              <b-form-group :label="$t('formLot')">
                <app-lot-dropdown
                  v-model="officialVersions.filters.lots"
                  :overriding-options="lotsRelatedToKit"
                  multiple />
              </b-form-group>
              <b-button
                variant="secondary"
                @click="onClickFilterOfficialByLot">
                {{ $t('formKitVersionCriteriaLot') }}
              </b-button>
            </b-dropdown-form>
          </b-dropdown>
        </div>
      </template>
      <template #cell(lots)="data">
        <app-badges-overflow
          badge-key="id"
          badge-label="name"
          :badge-variant="lotVariant"
          :badges="data.item.lots"
          nowrap />
      </template>


      <template #cell(actions)="data">
        <template v-if="canCopyOfficialVersion(data.item)">
          <b-button
            size="sm"
            variant="outline-secondary"
            @click="onClickCopyVersion(data.item)">
            <b-icon
              icon="files"
              scale="1.25" />
          </b-button>
        </template>
        <template v-if="canDeleteOfficialVersion(data.item)">
          <b-button
            size="sm"
            variant="outline-danger"
            @click="onClickDeleteOfficialVersion(data.item)">
            <b-icon
              icon="trash"
              scale="1.25" />
          </b-button>
        </template>
      </template>

      <template #custom-foot="{fields}">
        <b-tr v-if="kit">
          <b-td
            :colspan="fields.length"
            class="py-0">
            <b-pagination
              v-model="officialVersions.page.current"
              :total-rows="officialVersions.page.totalRows"
              :per-page="officialVersions.page.size"
              align="center"
              class="my-0" />
          </b-td>
        </b-tr>
      </template>
    </b-table>


    <b-table
      v-if="officialVersion && canReadCustomKitVersions"
      id="laboratory-versions"
      ref="laboratoryVersionsTable"
      :fields="kitLaboratoryVersionFields"
      :items="laboratoryVersionsProvider"
      :per-page="laboratoryVersions.page.size"
      :current-page="laboratoryVersions.page.current"
      :tbody-tr-class="laboratoryVersionRowClass"
      bordered
      class="kit-versions official-versions"
      head-variant="light"
      primary-key="id"
      responsive
      show-empty>
      <template #head(version)="data">
        <div>
          {{ data.label }}
          <b-dropdown
            v-if="kit"
            ref="laboratoryFilterByVersion"
            size="sm"
            boundary="viewport">
            <template #button-content>
              <b-icon icon="search" />
            </template>
            <b-dropdown-form>
              <b-form-group
                v-if="isAdmin"
                :label="$t('formKitVersionLaboratory')">
                <b-input
                  v-model="laboratoryVersions.filters.laboratoryName"
                  @keydown.enter.prevent="onClickFilterLaboratoryVersionByVersion" />
              </b-form-group>
              <b-form-group :label="$t('formKitVersion')">
                <b-input
                  v-model="laboratoryVersions.filters.version"
                  @keydown.enter.prevent="onClickFilterLaboratoryVersionByVersion" />
              </b-form-group>
              <b-button
                variant="secondary"
                @click="onClickFilterLaboratoryVersionByVersion">
                {{ $t(isAdmin ? 'formKitVersionCriteriaLaboratoryVersion' : 'formKitVersionCriteriaVersion') }}
              </b-button>
            </b-dropdown-form>
          </b-dropdown>
        </div>
      </template>
      <template
        v-if="isAdmin"
        #cell(version)="data">
        {{ data.item.laboratoryName }} - {{ data.item.version }}
      </template>
      <template
        v-else
        #cell(version)="data">
        {{ data.item.version }}
      </template>

      <template #head(analysers)="data">
        <div>
          {{ data.label }}
          <b-dropdown
            v-if="kit && availableAnalysers.length"
            ref="laboratoryFilterByAnalyser"
            size="sm"
            boundary="viewport">
            <template #button-content>
              <b-icon icon="search" />
            </template>
            <b-dropdown-form>
              <b-form-group :label="$t('formAnalyser')">
                <app-analyser-dropdown
                  v-model="laboratoryVersions.filters.analysers"
                  :overriding-options="availableAnalysers"
                  multiple />
              </b-form-group>
              <b-button
                variant="secondary"
                @click="onClickFilterLaboratoryVersionByAnalyser">
                {{ $t('formKitVersionCriteriaAnalyser') }}
              </b-button>
            </b-dropdown-form>
          </b-dropdown>
        </div>
      </template>
      <template #cell(analysers)="data">
        <template v-for="analyser in data.item.analysers">
          <app-kit-analyser-badge
            :key="analyser.kitAnalyserId"
            :kit-analyser="analyser" />
        </template>
      </template>


      <template #cell(actions)="data">
        <template v-if="canCustomizeVersion(data.item)">
          <b-button
            size="sm"
            variant="outline-secondary"
            @click="onClickCustomizeVersion()">
            <b-icon
              icon="files"
              scale="1.25" />
          </b-button>
        </template>
        <template v-if="canDeleteCustomizeVersion(data.item)">
          <b-button
            size="sm"
            variant="outline-danger"
            @click="onClickDeleteLaboratoryVersion(data.item)">
            <b-icon
              icon="trash"
              scale="1.25" />
          </b-button>
        </template>
      </template>

      <template #empty="scope">
        <div class="text-center my-2">
          {{ isAdmin ? $t('tableEmptyRowLaboratoryVersions') : $t('tableEmptyRowOwnLaboratoryVersions') }}
        </div>
        <div
          v-if="canCustomizeVersion()"
          class="text-center my-2">
          {{ $t('tableEmptyRowCustomizeVersionLabel') }} :
          <b-button
            size="sm"
            variant="outline-secondary"
            @click="onClickCustomizeVersion()">
            <b-icon
              icon="files"
              scale="1.25" />
          </b-button>
        </div>
      </template>

      <template #custom-foot="{fields}">
        <b-tr>
          <b-td
            :colspan="fields.length"
            class="py-0">
            <b-pagination
              v-model="laboratoryVersions.page.current"
              :total-rows="laboratoryVersions.page.totalRows"
              :per-page="laboratoryVersions.page.size"
              align="center"
              class="my-0" />
          </b-td>
        </b-tr>
      </template>
    </b-table>

    <app-add-kit-modal
      v-if="canAddKit"
      ref="addKitModal"
      @ok="onKitAdded" />

    <app-add-kit-version-modal
      v-if="kit"
      ref="addKitVersionModal"
      :kit="kit"
      :source="sourceVersion"
      @ok="onKitVersionAdded" />

    <app-confirmation-modal
      ref="kitVersionDeletionModal"
      :title="$t('configuration.kit.modal.delete-version.title')"
      :message="$t('configuration.kit.modal.delete-version.message', [confirmKitVersionName])"
      :ok-button-name="$t('common.yes')"
      ok-button-variant="outline-danger"
      :cancel-button-name="$t('common.no')"
      header-text-variant="danger"
      @ok="deleteKitVersion" />
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import SecurityMixin from '@/mixins/SecurityMixin';
import NotificationMixin from '@/mixins/NotificationMixin';
import KitDropdown from '@/components/common/KitDropdown.vue';
import AnalyserDropdown from '@/components/common/AnalyserDropdown.vue';
import LotDropdown from '@/components/common/LotDropdown.vue';
import KitAnalyserBadge from '@/components/admin/configuration/kit/KitAnalyserBadge.vue';
import BadgesCell from '@/components/common/BadgesOverflow.vue';
import AddKitModal from '@/components/admin/configuration/kit/AddKitModal.vue';
import AddKitVersionModal from '@/components/admin/configuration/kit/AddKitVersionModal.vue';
import ConfirmationModal from '@/components/common/ConfirmationModal.vue';
import { findAllAnalysers, findAllLotsByKitId } from '@/service/SmazentechService';
import {
  deleteLaboratoryVersion,
  deleteOfficialVersion,
  getLastKitVersions,
  getLastLaboratoryVersionConfigured,
  searchLaboratoryVersions,
  searchOfficialVersions
} from '@/service/KitVersionService';
import { findAllActiveLotsByKitId } from '../../../../service/SmazentechService';

export default {
  name: 'KitConfiguration',
  components: {
    'app-kit-dropdown': KitDropdown,
    'app-analyser-dropdown': AnalyserDropdown,
    'app-lot-dropdown': LotDropdown,
    'app-kit-analyser-badge': KitAnalyserBadge,
    'app-badges-overflow': BadgesCell,
    'app-add-kit-modal': AddKitModal,
    'app-add-kit-version-modal': AddKitVersionModal,
    'app-confirmation-modal': ConfirmationModal
  },
  mixins: [
    SecurityMixin,
    NotificationMixin
  ],
  data() {
    return {
      officialVersions: {
        last: undefined,
        filters: {
          version: undefined,
          lots: undefined,
          analysers: undefined
        },
        page: {
          size: 5,
          current: 1,
          content: [],
          totalRows: undefined
        },
        busy: 0
      },
      officialVersion: undefined,
      sourceVersion: undefined,
      confirmOfficialVersion: undefined,
      confirmLaboratoryVersion: undefined,
      laboratoryVersions: {
        last: undefined,
        allLocked: undefined,
        filters: {
          version: undefined,
          laboratoryName: undefined,
          analysers: undefined
        },
        page: {
          size: 5,
          current: 1,
          content: [],
          totalRows: undefined
        },
        busy: 0
      },
      availableAnalysers: []
    };
  },
  computed: {
    ...mapFields('configuration', [
      'kit',
      'lotsRelatedToKit',
      'loading'
    ]),
    isAdmin() {
      return this.hasRole('ADMIN');
    },
    isDoctor() {
      return this.hasRole('WRITE_DIAGNOSTIC');
    },
    laboratory() {
      return this.userLaboratory();
    },
    kitOfficialVersionFields() {
      const fields = [
        {
          key: 'version', label: this.$t('tableHeaderOfficialVersion'), class: 'version-name',
          sortable: true
        },
        {
          key: 'analysers', label: this.$t('tableHeaderVersionAnalysers'), class: 'version-analysers'
        },
        {
          key: 'lots', label: this.$t('tableHeaderVersionLots'), class: 'version-lots'
        }
      ];
      if (this.isAdmin) {
        fields.push({
          key: 'actions', label: this.$t('tableHeaderVersionActions'), class: 'version-actions',
          sortable: false
        });
      }

      return fields;
    },
    kitLaboratoryVersionFields() {
      const fields = [
        {
          key: 'version', label: this.$t('tableHeaderLaboratoryVersion'), class: 'version-name',
          sortable: true
        },
        {
          key: 'analysers', label: this.$t('tableHeaderVersionAnalysers'), class: 'version-analysers'
        }
      ];
      if (this.isDoctor) {
        fields.push({
          key: 'actions', label: this.$t('tableHeaderVersionActions'), class: 'version-actions',
          sortable: false
        });
      }

      return fields;
    },
    withoutOfficialVersions() {
      return this.officialVersions.page.totalRows === 0;
    },
    lastOfficialVersionId() {
      return this.officialVersions.last?.id;
    },
    lastLaboratoryVersionId() {
      return this.laboratoryVersions.last?.id;
    },
    canAddKit() {
      return this.isAdmin && (this.laboratory?.kitManufacturer ?? false);
    },
    canAddKitVersion() {
      return this.isAdmin && this.canAddKit && (this.officialVersions?.last?.locked ?? true);
    },
    canCopyKitVersion() {
      return this.isAdmin && this.canAddKitVersion;
    },
    canReadCustomKitVersions() {
      return this.isAdmin || this.canCustomizeKitVersion;
    },
    canCustomizeKitVersion() {
      return this.isDoctor && (this.laboratory?.kitManufacturer === false ?? false);
    },
    canDeleteKitVersion() {
      return this.isAdmin && this.canAddKit;
    },
    canAddKitAnalyser() {
      return this.isAdmin && this.canAddKit;
    },
    confirmKitVersion() {
      return this.confirmOfficialVersion ? this.confirmOfficialVersion : this.confirmLaboratoryVersion;
    },
    confirmKitVersionName() {
      return this.confirmKitVersion?.version;
    },
    customizableOfficialVersion() {
      return this.officialVersion.locked && this.officialVersion.lots?.length > 0;
    },
    officialVersionsBusy() {
      return this.officialVersions.busy > 0;
    }
  },
  beforeMount() {
    this._loadOfficialVersions();
    this._loadAvailableAnalysers();
  },
  methods: {
    ...mapActions('configuration', [
      'setKit',
      'setLotsRelatedToKit'
    ]),
    canCopyOfficialVersion(version) {
      return !this.officialVersionsBusy
          && this.canCopyKitVersion
          && this.lastOfficialVersionId === version.id
          && version.locked;
    },
    canDeleteOfficialVersion(version) {
      return !this.officialVersionsBusy
          && this.canDeleteKitVersion
          && !version.locked;
    },
    canCustomizeVersion(version) {
      return !this.laboratoryVersions.busy
          && this.canCustomizeKitVersion
          && this.customizableOfficialVersion
          && (!version || (
            this.lastLaboratoryVersionId === version.id
            && version.locked
          ));
    },
    canDeleteCustomizeVersion(version) {
      return (this.isDoctor && !this.isAdmin)
          && version.laboratoryId === this.laboratory.id
          && !version.locked;
    },
    onSelectKit(event) {
      if (this.kit?.id === event?.id) {
        event = null;
      }
      this.setKit(event);
      this._loadLots();
      this.officialVersion = undefined;
      this._loadOfficialVersions();
      this._loadLaboratoryVersions();
    },
    onClickAddKit() {
      this.$bvModal.show('add-kit-modal');
    },
    onKitAdded(kit) {
      this.onSelectKit(kit);
      this.$refs.kitDropdown.initOptions();
    },
    onClickAddKitVersion() {
      this.$bvModal.show('add-kit-version-modal');
    },
    onKitVersionAdded(kitVersion) {
      if (kitVersion.officialVersionId) {
        this.sourceVersion = undefined;
      } else {
        this.officialVersion = undefined;
      }
      this._loadOfficialVersions();
      this._loadLaboratoryVersions();
    },
    onClickCopyVersion(kitVersion) {
      if (!kitVersion?.id || !kitVersion?.locked)  {
        return;
      }
      this.onClickAddKitVersion();
    },
    onClickDeleteOfficialVersion(kitVersion) {
      if (!kitVersion?.id || kitVersion?.locked)  {
        return;
      }
      this.confirmOfficialVersion = kitVersion;
      this.$refs.kitVersionDeletionModal.showModal();
    },
    onSelectedOfficialVersion(selection) {
      if (this.officialVersion === selection) {
        this.officialVersion = undefined;

        return;
      }
      this.officialVersion = selection;
      this._loadLaboratoryVersions();

    },
    onClickCustomizeVersion() {
      this.sourceVersion = this.officialVersion;
      this.onClickAddKitVersion();
    },
    onClickDeleteLaboratoryVersion(kitVersion) {
      if (!kitVersion?.id || kitVersion?.locked)  {
        return;
      }
      this.confirmLaboratoryVersion = kitVersion;
      this.$refs.kitVersionDeletionModal.showModal();
    },
    onClickFilterOfficialByVersion() {
      this._refreshOfficialVersions();
    },
    onClickFilterOfficialByLot() {
      this._refreshOfficialVersions();
    },
    onClickFilterOfficialByAnalyser() {
      this._refreshOfficialVersions();
    },
    onClickFilterLaboratoryVersionByVersion() {
      this._refreshLaboratoryVersions();
    },
    onClickFilterLaboratoryVersionByAnalyser() {
      this._refreshLaboratoryVersions();
    },
    onClickConfigure(kitVersion, analyser) {
      if (!this.kit || !kitVersion || !analyser) {
        return;
      }
      this.$router.push({ name: 'kit-analyser-configuration', query: {
        kitId: this.kit.id,
        versionId: kitVersion.id,
        analyserId: analyser.id
      } });
    },
    missingAnalysers(currentAnalysers) {
      const ids = currentAnalysers.reduce((map, analyser) => ({ ...map,[analyser.analyserId]: analyser }), {});

      return this.availableAnalysers.filter(analyser => !ids[analyser.id]);
    },
    deleteKitVersion() {
      if (this.confirmOfficialVersion) {
        this._deleteOfficialVersion(this.confirmOfficialVersion);
      }
      if (this.confirmLaboratoryVersion) {
        this._deleteLaboratoryVersion(this.confirmLaboratoryVersion);
      }
    },
    _deleteOfficialVersion(kitVersion) {
      if (!kitVersion?.id || kitVersion?.locked)  {
        return;
      }
      this.officialVersions.busy++;
      deleteOfficialVersion(kitVersion.id)
        .then(() => {
          this.officialVersion = undefined;
          this._loadOfficialVersions();
          this.showSuccessNotification(this.$t('configuration.kit.delete-version.notification.title'), this.$t('configuration.kit.delete-version.notification.success_message', [ kitVersion.version ]));
        })
        .catch(error => {
          this.showErrorNotification(this.$t('configuration.kit.delete-version.notification.title'), error);
        })
        .finally(() => {
          this.officialVersions.busy--;
          this.$refs.kitVersionDeletionModal.hideModal();
        });
    },
    _deleteLaboratoryVersion(kitVersion) {
      if (!kitVersion?.id || kitVersion?.locked)  {
        return;
      }
      deleteLaboratoryVersion(kitVersion.id)
        .then(() => {
          this._loadOfficialVersions();
          this._loadLaboratoryVersions();
          this.showSuccessNotification(this.$t('configuration.kit.delete-version.notification.title'), this.$t('configuration.kit.delete-version.notification.success_message', [ kitVersion.version ]));
        })
        .catch(error => {
          this.showErrorNotification(this.$t('configuration.kit.delete-version.notification.title'), error);
        })
        .finally(() => {
          this.$refs.kitVersionDeletionModal.hideModal();
        });
    },
    officialVersionsProvider(ctx) {
      if (!this.kit?.id) {
        this.officialVersions.page.totalRows = undefined;
        this.officialVersions.page.current = 1;

        return Promise.resolve([]);
      }

      this.officialVersions.busy++;
      const page = {
        // backend api use zero-indexed pagination
        index: ctx.currentPage - 1,
        size: ctx.perPage,
        sort: ctx.sortBy ?
          `${ctx.sortBy},${ctx.sortDesc ? 'desc' : 'asc'}`
          : 'createdDate,desc'
      };

      const filters = {
        version: this.officialVersions.filters.version,
        lotIds: this.officialVersions.filters.lots?.map(lot => lot.id),
        analyserIds: this.officialVersions.filters.analysers?.map(a => a.id)
      };

      return searchOfficialVersions(this.kit.id, page, filters)
        .then(pageResponse => {
          this.officialVersions.page.totalRows = pageResponse.totalElements;
          // bootstrap api do not use zero-indexed pagination
          this.officialVersions.page.current = pageResponse.number + 1;
          this.officialVersions.page.content = pageResponse.content;

          return this.officialVersions.page.content;
        })
        .catch(error => {
          console.error(error);
          this.showErrorNotification(this.$t('configuration.kit.load-versions.notification.title'), error);

          return [];
        })
        .finally(() => {
          this.officialVersions.busy--;
          this.$refs.officialFilterByVersion?.hide();
          this.$refs.officialFilterByLot?.hide();
          this.$refs.officialFilterByAnalyser?.hide();
        });
    },
    _refreshOfficialVersions() {
      this.$root.$emit('bv::refresh::table', 'official-versions');
    },
    _loadOfficialVersions() {
      this._refreshOfficialVersions();
      if (!this.kit?.id) {
        this.officialVersions.last = undefined;

        return;
      }
      this.officialVersions.busy++;
      getLastKitVersions(this.kit.id)
        .then(last => {
          this.officialVersions.last = last;
        })
        .catch(error => {
          this.officialVersions.last = undefined;
          this.showErrorNotification(this.$t('configuration.kit.load-versions.notification.title'), error);
        })
        .finally(() => this.officialVersions.busy--);
    },
    laboratoryVersionsProvider(ctx) {
      if (!this.officialVersion?.id) {
        this.laboratoryVersions.page.totalRows = undefined;
        this.laboratoryVersions.page.current = 1;

        return Promise.resolve([]);
      }
      this.laboratoryVersions.busy++;
      const page = {
        // backend api use zero-indexed pagination
        index: ctx.currentPage - 1,
        size: ctx.perPage,
        sort: ctx.sortBy ?
          [ `${ctx.sortBy},${ctx.sortDesc ? 'desc' : 'asc'}` ]
          : 'createdDate,desc'
      };

      const filters = {
        version: this.laboratoryVersions.filters.version,
        laboratoryName: this.laboratoryVersions.filters.laboratoryName,
        analyserIds: this.laboratoryVersions.filters.analysers?.map(a => a.id)
      };

      return searchLaboratoryVersions(this.officialVersion.id, page, filters)
        .then(pageResponse => {
          this.laboratoryVersions.page.totalRows = pageResponse.totalElements;
          // bootstrap api do not use zero-indexed pagination
          this.laboratoryVersions.page.current = pageResponse.number + 1;
          this.laboratoryVersions.page.content = pageResponse.content;

          return this.laboratoryVersions.page.content;
        })
        .catch(error => {
          console.error(error);
          this.showErrorNotification(this.$t('configuration.kit.load-versions.notification.title'), error);

          return [];
        })
        .finally(() => {
          this.laboratoryVersions.busy--;
          this.$refs.laboratoryFilterByVersion?.hide();
        });
    },
    _refreshLaboratoryVersions() {
      this.$root.$emit('bv::refresh::table', 'laboratory-versions');
    },
    _loadLaboratoryVersions() {
      this._refreshLaboratoryVersions();
      if (!this.officialVersion?.id) {
        this.laboratoryVersions.last = undefined;

        return;
      }
      this.laboratoryVersions.busy++;
      getLastLaboratoryVersionConfigured(this.officialVersion.id)
        .then(laboratoryVersion => this.laboratoryVersions.last = laboratoryVersion)
        .catch(error => {
          this.laboratoryVersions.last = undefined;
          this.showErrorNotification(this.$t('configuration.kit.load-versions.notification.title'), error);
        })
        .finally(() => this.laboratoryVersions.busy--);
    },
    _loadAvailableAnalysers() {
      findAllAnalysers()
        .then(analysers => this.availableAnalysers = analysers)
        .catch(error => {
          this.availableAnalysers = [];
          this.showErrorNotification(this.$t('configuration.kit.load-analysers.notification.title'), error);
        });
    },
    _loadLots() {
      if (!this.kit?.id) {
        this.setLotsRelatedToKit([]);

        return;
      }
      const lotsPromise = this.isAdmin ? findAllLotsByKitId(this.kit.id) : findAllActiveLotsByKitId(this.kit.id);
      lotsPromise
        .then(lots => this.setLotsRelatedToKit(lots))
        .catch(error => {
          this.setLotsRelatedToKit([]);
          this.showErrorNotification(this.$t('configuration.kit.load-lots.notification.title'), error);
        });
    },
    officialVersionRowClass(item, type) {
      if (!item || type !== 'row' || this.isAdmin) return;
      if (item.laboratoryVersionsConfiguredSize > 0) return 'row-version-customized';
      if (item.lots?.length > 0) return 'row-version-used';
    },
    laboratoryVersionRowClass(item, type) {
      if (!item || type !== 'row' || item?.analysers.every(analyser => !analyser.configured)) return;
      if (item.id === this.lastLaboratoryVersionId) return 'row-version-used';
    },
    lotVariant(lot) {
      return lot.active ? 'secondary' : 'danger';
    }
  }
};
</script>