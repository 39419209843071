<template>
  <div>
    <b-jumbotron
      class="well-interpretation-container py-4 d-flex flex-column"
      border-variant="dark"
      bg-variant="transparent">
      <transition
        name="slide-fade"
        mode="out-in">
        <div
          key="well-interpretation">
          <app-well-selection-filters
            :wells="wells"
            @filter="filteredWells = $event" />
          <div
            class="flex-grow-1 d-flex flex-column justify-content-between">
            <transition
              name="slide-fade"
              mode="out-in">
              <div
                v-if="isMultipleKitOrVersion"
                ref="multi-kit-or-reaction-buffer-selected"
                key="multi-kit-or-reaction-buffer-selected"
                class="d-flex align-items-center justify-content-center chart-bloc-container">
                <b-container>
                  <p class="text-center my-auto">
                    {{ $t('wellInterpretationMultipleKitOrVersionSelected') }}
                  </p>
                </b-container>
              </div>
              <div
                v-else
                ref="interpretation"
                key="interpretation">
                <b-tabs
                  pills
                  card>
                  <b-tab
                    :title="$t('wellInterpretationChartTab')"
                    active>
                    <app-well-interpretation-chart
                      v-if="channels"
                      :well-chart-diagnostics="wellChartDiagnostics"
                      :channels="channels" />
                  </b-tab>
                  <b-tab
                    :title="$t('wellInterpretationTableTab')"
                    class="px-0">
                    <app-well-interpretation-data-table :interpretations="wellInterpretations" />
                  </b-tab>
                </b-tabs>
                <hr>
                <transition
                  v-if="action === 'update-diagnostic'"
                  name="fade"
                  mode="out-in">
                  <app-diagnostic-update-form
                    :run-id="run.id"
                    :well="well"
                    :kit-version-id="well.kitVersion.id"
                    @cancel="emitUpdateDiagnostic(undefined)"
                    @updated="showNotificationAndCloseDiagnosticUpdateForm" />
                </transition>

                <transition
                  v-else-if="wells.length > 0"
                  name="fade"
                  mode="out-in">
                  <div>
                    <span
                      v-if="wells.length === 1">
                      <app-well-meta-data :well="well" />
                      <hr>
                      <b-container>
                        <app-well-comments-form
                          :run-id="run.id"
                          :wells="wells"
                          :comments="well.comments"
                          @updated="showNotificationForWellsComment" />
                      </b-container>
                      <b-container v-if="wellDiagnosticComment">
                        <app-comment :comment="wellDiagnosticComment" />
                      </b-container>
                    </span>
                    <span
                      v-if="wells.length > 1">
                      <b-container>
                        <app-well-comments-form
                          :run-id="run.id"
                          :wells="wells"
                          :comments="[]"
                          @updated="showNotificationForWellsComment" />
                      </b-container>
                    </span>
                    <b-container>
                      <b-form-row class="mt-4">
                        <b-col sm="12">
                          <div class="d-flex flex-row align-items-center justify-content-between">
                            <b-link
                              class="fa-pull-left align-middle"
                              @click="emitCloseEvent">
                              {{ $t('cancel') }}
                            </b-link>
                            <b-button
                              ref="updateDiagnosticButton"
                              variant="outline-secondary"
                              :disabled="!isDiagnosticUpdateAllowed"
                              @click="emitUpdateDiagnostic('update-diagnostic')">
                              {{ $t('wellInterpretationUpdateDiagnosticButtonText') }}
                            </b-button>
                          </div>
                        </b-col>
                      </b-form-row>
                    </b-container>
                  </div>
                </transition>
              </div>
            </transition>
          </div>
        </div>
      </transition>
    </b-jumbotron>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import uniqBy from 'lodash/uniqBy';
import NotificationMixin from '@/mixins/NotificationMixin';
import SecurityMixin from '@/mixins/SecurityMixin';
import WellSelectionFilters from './WellSelectionFilters';
import WellMetaData from './WellMetaData';
import WellInterpretationChart from './WellInterpretationChart.vue';
import WellInterpretationDataTable from './WellInterpretationDataTable';
import DiagnosticUpdateForm from '../diagnostic/DiagnosticUpdateForm.vue';
import Comment from '../comment/Comment.vue';
import WellCommentsForm from '../comment/WellCommentsForm.vue';

export default {
  components: {
    'app-well-selection-filters': WellSelectionFilters,
    'app-well-meta-data': WellMetaData,
    'app-well-interpretation-chart': WellInterpretationChart,
    'app-well-interpretation-data-table': WellInterpretationDataTable,
    'app-diagnostic-update-form': DiagnosticUpdateForm,
    'app-comment': Comment,
    'app-well-comments-form': WellCommentsForm
  },
  mixins: [
    NotificationMixin,
    SecurityMixin
  ],
  props: {
    wells: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      filteredWells: [],
      action: undefined
    };
  },
  beforeMount() {
    this.filteredWells = this.wells;
  },
  computed: {
    ...mapState('runImport', [
      'run'
    ]),
    well() {
      return this.wells[0];
    },
    wellDiagnosticComment() {
      return this.well?.getWellDiagnosticComment();
    },
    wellInterpretations() {
      const interpretations = [];
      for (const well of this.filteredWells) {
        if (well.interpretation) {
          interpretations.push(well.interpretation);
        }
      }

      return interpretations;
    },
    wellChartDiagnostics() {
      const charts = [];
      for (const well of this.filteredWells) {
        if (well.chart) {
          const diag = well.diagnostic ? well.diagnostic : well?.interpretation?.suggestedResult;
          charts.push({ chart: well.chart, diagnostic: diag });
        }
      }

      return charts;
    },
    isMultipleKitOrVersion() {
      return uniqBy(this.filteredWells, 'kit.id').length > 1 || uniqBy(this.filteredWells, 'kitVersion.id').length > 1;
    },
    isDiagnosticUpdateAllowed() {
      return this.wells.length === 1 && this.canUpdateRun();
    },
    channels() {
      const channels = [];
      if (this.filteredWells.map(well => well?.kitVersion?.id).every((id, index, array) => id === array[0])) {
        this.filteredWells[0].chart.channels.forEach(channel => {
          channels.push({
            name: channel.name,
            threshold: channel.threshold
          });
        });
      }

      return channels;
    }
  },
  methods: {
    emitCloseEvent() {
      this.$emit('close');
    },
    showNotificationAndCloseDiagnosticUpdateForm(wellDiagnostic) {
      this.emitUpdateDiagnostic(undefined);
      this.$store.dispatch('runImport/setWellDiagnostics', { wellDiagnostics: [ wellDiagnostic ] });
      this.showSuccessNotification(
        this.$t('diagnosticUpdateFormUpdateSuccessNotificationTitle', [ wellDiagnostic.position ]),
        this.$t('diagnosticUpdateFormUpdateSuccessNotificationMessage', [ wellDiagnostic.result.getLabel() ]),
        { delay: 6000 });
    },
    showNotificationForWellsComment(wellComments) {
      this.$store.dispatch('runImport/setWellComments', { wellComments });
      this.showSuccessNotification(this.$t('wellInterpretationNotificationTitle'), this.$t('wellInterpretationCommentAdded'));
    },
    emitUpdateDiagnostic(action) {
      this.action = action;
      this.$emit('updateDiagnostic', action !== undefined);
    }
  }

};
</script>
