<template>
  <div>
    <b-button
      class="align-middle"
      @click="openCancelModal">
      {{ $t('common.exitRun') }}
    </b-button>
    <app-confirmation-modal
      ref="cancelModal"
      :title="$t('modalDiscardChangesTitle')"
      :message="$t('modalDiscardChangesMessage')"
      :ok-button-name="$t('yes')"
      :cancel-button-name="$t('no')"
      @ok="$router.push({ name: 'run-list' })" />
  </div>
</template>

<script>
import ConfirmationModal from '../../common/ConfirmationModal';

export default {
  components: {
    'app-confirmation-modal': ConfirmationModal
  },
  methods: {
    openCancelModal() {
      this.$refs.cancelModal.showModal();
    }
  }
};
</script>
