import Kit from './Kit';
import SampleType from './SampleType';
import WellDiagnostic from './WellDiagnostic';
import KitVersion from '@/models/KitVersion';

export default class WellTemplate {
  constructor(id, position, sampleType, kit, kitVersion, diagnostic) {
    this.id = id;
    this.name = name;
    this.position = position;
    this.sampleType = sampleType;
    this.kit = kit;
    this.kitVersion = kitVersion;
    this.diagnostic = diagnostic;
  }

  static build({ id, position, sampleType, kit, kitVersion, diagnostic }) {
    return new WellTemplate(id, position, sampleType, kit, kitVersion, diagnostic);
  }

  static parse(value) {
    return value ? WellTemplate.build({
      ...value,
      kit: WellTemplate.parseKit(value),
      kitVersion: WellTemplate.parseKitVersion(value),
      sampleType: SampleType.parse(value.sampleType),
      diagnostic: WellDiagnostic.parse(value.diagnostic)
    }) : null;
  }

  static parseKit(value) {
    let kit = null;
    if (value.kit) {
      kit = value.kit;
    } else if (value.kitVersion?.kit) {
      kit = value.kitVersion.kit;
    }

    return Kit.parse(kit);
  }

  static parseKitVersion(value) {
    let kitVersion = null;
    if (value.kitVersion) {
      kitVersion = value.kitVersion;
    }

    return KitVersion.parse(kitVersion);
  }
  toJSON() {
    return {
      id: this.id !== undefined && this.id !== '' ? this.id : null,
      position: this.position !== undefined && this.position !== '' ? this.position : null,
      sampleType: this.sampleType !== undefined && this.sampleType !== '' ? this.sampleType : null,
      kit: this.kit !== undefined && this.kit !== '' ? this.kit : null,
      diagnostic: this.diagnostic !== undefined && this.diagnostic !== '' ? this.diagnostic : null,
      kitVersion: this.kitVersion !== undefined && this.kitVersion !== '' ? this.kitVersion : null
    };
  }
}

export function isSampleTypeOfControl(sampleType) {
  return sampleType && [ 'POSITIVE_CONTROL', 'NEGATIVE_AMPLIFICATION_CONTROL', 'NEGATIVE_EXTRACTION_CONTROL' ].includes(sampleType.type);
}

export function getDiagnostic(well) {
  return well.diagnostic ? well.diagnostic.result : well.interpretation?.suggestedResult;
}

export function getColor(well) {
  return (getDiagnostic(well) ?? well?.sampleType)?.color;
}
