<template>
  <div class="center-70">
    <template v-if="!ftpConfiguration">
      <b-form-row>
        <b-col lg="11">
          <h5>{{ $t('configuration.ftp.not_configured') }}</h5>
        </b-col>
        <b-col lg="1">
          <b-button
            variant="outline"
            class="btn--no-outline btn-icon btn-icon-plus float-right"
            @click="newFTP">
            <font-awesome-icon
              :icon="['fas', 'plus-circle']" />
          </b-button>
        </b-col>
      </b-form-row>
    </template>
    <template v-else>
      <b-form-row>
        <b-col
          lg="11"
          class="pb-3">
          <b-form-checkbox
            v-model="ftpConfiguration.sendFilesOnApproval">
            {{ $t('configuration.ftp.sendFilesOnApproval') }}
          </b-form-checkbox>
        </b-col>
        <b-col lg="1">
          <b-button
            variant="outline"
            class="btn--no-outline btn-icon btn-icon-minus float-right"
            @click="deleteFTP">
            <font-awesome-icon
              :icon="['fas', 'minus-circle']" />
          </b-button>
          <app-confirmation-modal
            ref="deleteModal"
            :title="$t('modal.ftp_configuration.delete.title')"
            :message="$t('modal.ftp_configuration.delete.message')"
            :ok-button-spinners-enabled="true"
            ok-button-variant="outline-danger"
            header-text-variant="danger"
            @ok="deleteConfirmed" />
        </b-col>
        <b-col lg="4">
          <b-form-group :label="$t('configuration.ftp.protocol')">
            <app-string-array-dropdown
              v-model="ftpConfiguration.protocol"
              :allow-empty="false"
              :preselect-single-overriding-option="true"
              :overriding-options="Object.keys(ProtocolEnum)"
              @select="protocolChanged($event)" />
          </b-form-group>
        </b-col>
      </b-form-row>
      <hr>
      <b-form-row>
        <b-col lg="5">
          <b-form-group :label="$t('configuration.ftp.host')">
            <b-form-input
              v-model="ftpConfiguration.host" />
          </b-form-group>
        </b-col>
        <b-col lg="1">
          <b-form-group :label="$t('configuration.ftp.port')">
            <b-form-input
              v-model="ftpConfiguration.port"
              :number="true"
              :type="'number'" />
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group :label="$t('configuration.ftp.directory')">
            <b-form-input
              v-model="ftpConfiguration.directory" />
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group :label="$t('configuration.ftp.authentication')">
            <app-string-array-dropdown
              v-model="ftpConfiguration.authentication"
              :allow-empty="false"
              :preselect-single-overriding-option="true"
              :disabled="disableAuthentication"
              :translate="true"
              :overriding-options="Object.keys(AuthenticationEnum)" />
            <!--              @select="authenticationChanged($event)"/>-->
          </b-form-group>
        </b-col>
        <b-col
          v-if="displayUserPassword"
          lg="8">
          <b-form-group :label="$t('configuration.ftp.username')">
            <b-form-input
              v-model="ftpConfiguration.username" />
          </b-form-group>
          <b-form-group :label="$t('configuration.ftp.password')">
            <b-form-input
              v-model="ftpConfiguration.password"
              :type="'password'" />
          </b-form-group>
        </b-col>
        <!--        <b-col v-else lg="8">
          <b-form-group :label="$t('configuration.ftp.key_file')">
            <b-form-file
              v-model="ftpConfiguration.keyFile"
              :placeholder="$t('formLoadFile')"
              :drop-placeholder="$t('formDropFile')"/>
          </b-form-group>
        </b-col>-->
      </b-form-row>
      <hr>
      <b-form-row class="justify-content-between">
        <b-form-group>
          <b-button
            v-if="!restCallRunning"
            :disabled="incompleteConfiguration"
            @click="testConnection">
            {{ $t('configuration.ftp.test_connection') }}
          </b-button>
          <b-button
            v-if="restCallRunning"
            disabled>
            <b-spinner
              small
              type="grow" />
            Loading...
          </b-button>
        </b-form-group>
        <b-form-group>
          <b-button
            class="mr-2"
            @click="cancel">
            {{ $t('cancel') }}
          </b-button>
          <b-button
            v-if="!restCallRunning"
            :disabled="incompleteConfiguration"
            @click="save">
            {{ $t('save') }}
          </b-button>
          <b-button
            v-if="restCallRunning"
            disabled>
            <b-spinner
              small
              type="grow" />
            Loading...
          </b-button>
          <app-confirmation-modal
            ref="cancelModal"
            :title="$t('modal.ftp_configuration.cancel.title')"
            :message="$t('modal.ftp_configuration.cancel.message')"
            :ok-button-spinners-enabled="true"
            ok-button-variant="outline-danger"
            header-text-variant="danger"
            @ok="cancelConfirmed" />
        </b-form-group>
      </b-form-row>
    </template>
  </div>
</template>
<script>

import StringArrayDropdown from '@/components/common/StringArrayDropdown.vue';
import ConfirmationModal from '@/components/common/ConfirmationModal.vue';
import FTPConfiguration from '@/models/FTPConfiguration';
import { createFTP, deleteFTP, getFTP, testFTP, updateFTP } from '@/service/SmazentechService';
import NotificationMixin from '@/mixins/NotificationMixin';
import { ProtocolEnum } from '../../../../enums/Protocol.enum';
import { AuthenticationEnum } from '../../../../enums/Authentication.enum';

export default {
  name: 'ConfigurationFTP',
  components: {
    'app-string-array-dropdown': StringArrayDropdown,
    'app-confirmation-modal': ConfirmationModal
  },
  mixins: [
    NotificationMixin
  ],
  data() {
    return {
      ftpConfiguration: undefined,
      restCallRunning: false,
      ProtocolEnum,
      AuthenticationEnum
    };
  },
  computed: {
    displayUserPassword() {
      return this.ftpConfiguration.authentication === AuthenticationEnum.PASSWORD;
    },
    disableAuthentication() {
      return this.ftpConfiguration.protocol === ProtocolEnum.FTP;
    },
    incompleteConfiguration() {
      return !this.ftpConfiguration.host
             || !this.ftpConfiguration.port
             || !this.ftpConfiguration.username;
      //|| this.ftpConfiguration.authentication === AuthenticationEnum.SSH_KEY && !this.ftpConfiguration.keyFile;
    }
  },
  beforeMount() {
    getFTP()
      .then(ftpConfiguration => {
        if (ftpConfiguration) {
          this.ftpConfiguration = ftpConfiguration;
        }
      })
      .catch(error => console.error(error));
  },
  methods: {
    newFTP() {
      this.ftpConfiguration = FTPConfiguration.build({ sendFilesOnApproval: false, protocol: ProtocolEnum.FTP, port: 21, authentication: AuthenticationEnum.PASSWORD });
    },
    deleteFTP() {
      this.$refs.deleteModal.showModal();
    },
    deleteConfirmed() {
      if (this.ftpConfiguration.id) {
        deleteFTP(this.ftpConfiguration.id)
          .then(() => {
            this.ftpConfiguration = undefined;
            this.showSuccessNotification(this.$t('configuration.ftp.title'), this.$t('configuration.ftp.success.delete'));
          })
          .catch(error => this.catchError('configuration.ftp.error.delete', error));
      } else {
        this.ftpConfiguration = undefined;
      }
    },
    protocolChanged(value) {
      if (ProtocolEnum.FTP === value) {
        this.ftpConfiguration.authentication = AuthenticationEnum.PASSWORD;
        if (!this.ftpConfiguration.port || this.ftpConfiguration.port === 22) {
          this.ftpConfiguration.port = 21;
        }
      } else if (ProtocolEnum.SFTP === value) {
        if (!this.ftpConfiguration.port || this.ftpConfiguration.port === 21) {
          this.ftpConfiguration.port = 22;
        }
      }
    },
    /*authenticationChanged(value) {
      if (AuthenticationEnum.PASSWORD === value) {
        this.ftpConfiguration.keyFile = undefined;
      }
    },*/
    cancel() {
      this.$refs.cancelModal.showModal();
    },
    cancelConfirmed() {
      if (this.ftpConfiguration.id) {
        getFTP()
          .then(ftpConfiguration => {
            this.ftpConfiguration = ftpConfiguration;
          })
          .catch(error => this.catchError('configuration.ftp.error.get', error));
      } else {
        this.ftpConfiguration = undefined;
      }
    },
    testConnection() {
      this.restCallRunning = true;
      testFTP(this.ftpConfiguration)
        .then(() => {
          this.showSuccessNotification(this.$t('configuration.ftp.title'), this.$t('configuration.ftp.success.test'));
          this.restCallRunning = false;
        })
        .catch(error => {
          this.catchError('configuration.ftp.error.test', error);
          this.restCallRunning = false;
        });
    },
    save() {
      this.restCallRunning = true;

      let promise;
      if (this.ftpConfiguration.id) {
        promise = updateFTP(this.ftpConfiguration);
      } else {
        promise = createFTP(this.ftpConfiguration);
      }

      promise
        .then(ftpConfiguration => {
          this.ftpConfiguration = ftpConfiguration;
          this.showSuccessNotification(this.$t('configuration.ftp.title'), this.$t('configuration.ftp.success.save'));
          this.restCallRunning = false;
        })
        .catch(error => {
          this.catchError('configuration.ftp.error.save', error);
          this.restCallRunning = false;
        });
    },
    catchError(title, error) {
      console.error(error);
      this.showErrorNotification(this.$t(title), error);
    }
  }
};
</script>