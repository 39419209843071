import Diagnostic from '@/models/Diagnostic';

export default class WellInterpretation {
  constructor(id, name, position, channel1, channel2, ratio, suggestedResult) {
    this.id = id;
    this.name = name;
    this.position = position;
    this.channel1 = channel1;
    this.channel2 = channel2;
    this.ratio = ratio;
    this.suggestedResult = suggestedResult;
  }

  static build({ id, name, position, channel1, channel2, ratio, suggestedResult }) {
    return new WellInterpretation(id, name, position, channel1, channel2, ratio, suggestedResult);
  }

  static parse(value) {
    return value ? WellInterpretation.build({
      ...value,
      suggestedResult: Diagnostic.parse(value.suggestedResult)
    }) : null;
  }

  toJSON() {
    return {
      id: this.id !== undefined && this.id !== '' ? this.id : null,
      name: this.name !== undefined && this.name !== '' ? this.name : null,
      position: this.position !== undefined && this.position !== '' ? this.position : null,
      channel1: this.channel1 !== undefined && this.channel1 !== '' ? this.channel1 : null,
      channel2: this.channel2 !== undefined && this.channel2 !== '' ? this.channel2 : null,
      ratio: this.ratio !== undefined && this.ratio !== '' ? this.ratio : null,
      suggestedResult: this.suggestedResult !== undefined && this.suggestedResult !== '' ? this.suggestedResult : null
    };
  }
}