<template>
  <div class="lot-configuration">
    <b-form-row>
      <b-col :cols="editedLot ? '3' : null">
        <b-form-group
          :label="$t('formKit')"
          label-cols="2">
          <app-kit-dropdown
            v-model="kit"
            @input="onSelectKit" />
        </b-form-group>
        <b-form-group
          v-if="kit"
          :label="$t('formLots')">
          <b-list-group class="lots-list">
            <b-list-group-item
              v-for="(item, index) in lots"
              :key="index"
              ref="lotListGroupItems"
              button
              class="lot-item"
              :class="{ 'lot-expired': isLotExpired(item) }"
              :active="isSelected(item)"
              @click="onSelectLot(item)">
              <span
                class="name"
                :class="{ inactive: !item.active }">{{ item.name }}</span>
              <b-badge
                v-if="item.kitVersion.locked"
                variant="danger"
                pill>
                <i class="fa fa-lock" />
              </b-badge>
              <b-badge
                variant="info"
                pill>
                v{{ item.kitVersion.version }}
              </b-badge>
            </b-list-group-item>
          </b-list-group>
        </b-form-group>

        <b-button
          v-if="kit"
          @click="add">
          {{ $t('common.add') }}
        </b-button>
      </b-col>

      <b-col>
        <b-card v-if="kit && editedLot">
          <app-lot-form
            v-model="editedLot"
            :kit-id="kit.id" />

          <b-form-row class="versions-historic">
            <b-col>
              <b-form-group :label="$t('lotVersionsTimeline')">
                <app-timeline
                  :items="lotTimeLine"
                  class="px-4">
                  <template
                    slot="item"
                    slot-scope="props">
                    <app-timeline-item
                      :description="props.item.description"
                      :date="props.item.date+''"
                      :active="isSelectedLotCurrentVersion(props.item)"
                      :deleted="isVersionDeleted(props.item)"
                      :variant="'secondary'" />
                  </template>
                </app-timeline>
              </b-form-group>
            </b-col>
          </b-form-row>

          <hr>

          <b-form-row class="justify-content-end">
            <b-button
              v-if="editedLot.id"
              style="margin-right: auto;"
              :disabled="!canRemove"
              variant="outline-danger"
              @click="showDeleteModal()">
              <b-spinner
                v-if="requesting === 'deleting'"
                small
                type="grow" />
              {{ $t('common.delete') }}
            </b-button>

            <b-button
              class="mr-2"
              :disabled="!canCancel"
              variant="outline-secondary"
              @click="cancel">
              {{ $t('cancel') }}
            </b-button>

            <b-button
              :disabled="!canSave"
              @click="verify()">
              <b-spinner
                v-if="requesting === 'saving'"
                small
                type="grow" />
              {{ $t('save') }}
            </b-button>
          </b-form-row>
        </b-card>
      </b-col>
    </b-form-row>
    <app-confirmation-modal
      ref="deleteModal"
      :cancel-button-enabled="true"
      :ok-button-name="$t('yes')"
      :cancel-button-name="$t('no')"
      :title="$t('common.delete')"
      :message="$t('deleteLotModal')"
      @ok="remove()" />
    <app-confirmation-modal
      ref="updateModal"
      :cancel-button-enabled="true"
      :ok-button-name="$t('yes')"
      :cancel-button-name="$t('no')"
      :title="$t('updateLot')"
      :message="$t('updateLotModal')"
      @ok="save()" />
  </div>
</template>

<script>
import SecurityMixin from '@/mixins/SecurityMixin';
import NotificationMixin from '@/mixins/NotificationMixin';
import ValidationMixin from '@/mixins/ValidationMixin';
import KitDropdown from '@/components/common/KitDropdown.vue';
import LotForm from './LotForm.vue';
import Timeline from '@/components/common/Timeline.vue';
import TimelineItem from '@/components/common/TimelineItem.vue';
import { createLot, deleteLot, findAllLotsByKitId, findLotChanges, updateLot } from '@/service/SmazentechService';
import Lot from '@/models/Lot';
import ConfirmationModal from '@/components/common/ConfirmationModal.vue';

export default {
  name: 'LotConfiguration',
  components: {
    'app-kit-dropdown': KitDropdown,
    'app-lot-form': LotForm,
    'app-timeline': Timeline,
    'app-timeline-item': TimelineItem,
    'app-confirmation-modal': ConfirmationModal
  },
  mixins: [
    SecurityMixin,
    NotificationMixin,
    ValidationMixin
  ],
  data() {
    return {
      kit: undefined,
      lots: undefined,
      lot: undefined,
      editedLot: undefined,
      disabled: false,
      requesting: false,
      now: new Date(),
      lotChangesData: []
    };
  },
  computed: {
    canCancel() {
      return !this.disabled && this.requesting === false;
    },
    canRemove() {
      return !this.disabled && this.requesting === false;
    },
    canSave() {
      return !this.disabled && this.requesting === false;
    },
    lotTimeLine() {
      const timeLine = [];
      for (const lotChanges of this.lotChangesData) {
        const changes = lotChanges;
        let description = undefined;
        if (changes.type === 'UPDATE') {
          if (changes.modifications.some(modification => modification === 'kitVersion')) {
            timeLine.push(this.setVersionChange(changes));
            changes.modifications = changes.modifications.filter(modification => modification !== 'kitVersion');
          }
          if (changes.modifications.length > 0) {
            description = this.$t('lotModification');
            for (const modification of changes.modifications) {
              description += ` ${modification},`;
            }
            description = `${description.slice(0, -1)} `;
            description += this.$tc('lotDescriptionModification', changes.modifications.length, [ changes.userName ]);
          }
        } else if (changes.type === 'INSERT') {
          timeLine.push(this.setVersionChange(changes));
          description = this.$t('lotDescriptionCreation', [ changes.userName ]);
        }
        if (description) {
          timeLine.push({ description,
            date: changes.modificationDate,
            isKitVersion: false
          });
        }
      }

      return timeLine.sort((a, b) => a.date > b.date ? -1 : a.date < b.date ? 1 : a.date === b.date ? a?.isKitVersion ? -1 : 1 : 0);
    }
  },
  methods: {
    isSelected(lot) {
      return this.editedLot?.id === lot.id;
    },
    isLotExpired(lot) {
      return this.now > lot.expirationDate;
    },
    isSelectedLotCurrentVersion(lotChanges) {
      return lotChanges.isKitVersion ? this.editedLot?.kitVersion?.id === lotChanges?.kitVersion?.id : false;
    },
    isVersionDeleted(lotChanges) {
      return lotChanges.isKitVersion ? lotChanges.kitVersion?.deleted : false;
    },
    onSelectKit(event) {
      if (!this.kit) {
        this.lots = [];
        this._select(undefined);

        return;
      }
      findAllLotsByKitId(this.kit.id)
        .then(lots => {
          this.lots = lots;
          this._select(undefined);
        })
        .catch(error => console.error(error));
    },
    onSelectLot(lot) {
      if (this.editedLot?.id === lot?.id) {
        this._select(undefined);

        return;
      }
      this._select(lot);
    },
    _select(lot) {
      this.lot = lot;
      this.editedLot = Lot.parse(lot);
      this.v$.$reset();
      if (!this.editedLot?.id) {
        this.lotChangesData = [];

        return;
      }
      if (this.$refs.lotListGroupItems) {
        this.$refs.lotListGroupItems
          .find(lgi => lgi.querySelector('span')?.innerText === lot.name)
          ?.focus();
      }
      findLotChanges(this.editedLot.id).then(lotChangesData => {
        this.lotChangesData = lotChangesData;
      }).catch(error => this.showErrorNotification(this.$t('lotTimeLineErrorTitle'), error));
    },
    add() {
      if (this.requesting) {
        return;
      }
      this._select(Lot.parse({ kit: this.kit, active: true }));
    },
    cancel() {
      if (this.requesting || !this.editedLot) {
        return;
      }
      this._select(Lot.parse(this.lots.find(l => l.id === this.editedLot.id)));
    },
    remove() {
      if (this.requesting || !this.editedLot || !this.editedLot.id) {
        return;
      }
      this.requesting = 'deleting';
      const deleting = deleteLot(this.editedLot.id);
      deleting.then(lot => {
        this.requesting = false;
        this._removeLot(this.editedLot);
        this.editedLot = undefined;
        this.showSuccessNotification(this.$t('configuration.lot.deleting.notification.title'), this.$t('configuration.lot.deleting.notification.success_message'));

        return lot;
      })
        .catch(error =>  {
          this.requesting = false;
          this.showErrorNotification(this.$t('configuration.lot.deleting.notification.title'), error);
        });
    },
    showDeleteModal() {
      this.$refs.deleteModal.showModal();
    },
    verify() {
      if (this.requesting || !this.editedLot) {
        return;
      }
      if (!this.isValid()) {
        this.showErrorNotification(this.$t('configuration.lot.save.notification.title'), this.$t('configuration.lot.save.notification.invalid_message'));

        return;
      }
      if (this.editedLot.id) {
        this.$refs.updateModal.showModal();

        return;
      }
      this.save();
    },
    save() {
      this.requesting = 'saving';
      const saving = this.editedLot.id ? updateLot(this.editedLot) : createLot(this.editedLot);
      saving.then(lot => {
        this.requesting = false;
        this._updateLot(lot);
        this._select(lot);
        this.showSuccessNotification(this.$t('configuration.lot.save.notification.title'), this.$t('configuration.lot.save.notification.success_message'));

        return lot;
      })
        .catch(error =>  {
          this.requesting = false;
          this.showErrorNotification(this.$t('configuration.lot.save.notification.title'), error);
        });
    },
    _updateLot(lot) {
      const index = this.lots.findIndex(l => l.id === lot.id);
      if (index < 0) {
        this.lots = [ lot ].concat(this.lots);

        return;
      }
      this.lots = this.lots.slice();
      this.lots[index] = lot;
    },
    _removeLot(lot) {
      this.lots = this.lots.filter(l => l.id !== lot.id);
    },
    setVersionChange(data) {
      return {
        date: data.modificationDate,
        description: data.kitVersion.version,
        isKitVersion: true,
        kitVersion: data.kitVersion
      };
    }
  }
};
</script>