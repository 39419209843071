class RunExportFormat {
    static PDF = new RunExportFormat('PDF', 'pdf');
    static XLSX = new RunExportFormat('XLSX', 'xlsx');
    static CSV = new RunExportFormat('CSV', 'csv');
    static XML = new RunExportFormat('XML', 'xml');

    static get values() {
      return [ this.PDF, this.XLSX, this.CSV, this.XML ];
    }

    static fromString(name) {
      const value = this[name];
      if (value) return value;

      throw new RangeError(`No instance of RunExportFormat exists with the name ${name}.`);
    }

    get translationKey() {
      return `EXPORT_FORMAT_${this.name}`;
    }

    constructor(name, fileExtension) {
      this.name = name;
      this.fileExtension = fileExtension;
      Object.freeze(this);
    }

    toJSON() {
      return this.name;
    }
}

export default new Proxy(RunExportFormat, {
  construct() {
    throw new TypeError(`${RunExportFormat.name} is an RunExportFormat; no instances of it can be constructed.`);
  },
  defineProperty() {
    throw new TypeError(`${RunExportFormat.name} is an RunExportFormat; no new properties can be appended to it.`);
  },
  deleteProperty() {
    throw new TypeError(`${RunExportFormat.name} is an RunExportFormat; no new properties can be appended to it.`);
  },
  set() {
    throw new TypeError(`${RunExportFormat.name} is an RunExportFormat; its instances cannot be modified.`);
  },
  setPrototypeOf() {
    throw new TypeError(`${RunExportFormat.name} is an RunExportFormat; its prototype cannot be changed.`);
  }
});
