export default class Release {
  constructor(version, date, note, alreadySeen) {
    this.version = version;
    this.date = date;
    this.note = note;
    this.alreadySeen = alreadySeen;
  }

  static build({ version, date, note, alreadySeen }) {
    return new Release(version, date, note, alreadySeen);
  }

  static parse(value) {
    return value ? Release.build({
      version: value.version,
      date: value.date,
      note: value.note,
      alreadySeen: value.alreadySeen
    }) : null;
  }

  toJSON() {
    return {
      version: this.version !== undefined || this.version !== '' ? this.version : null,
      date: this.date !== undefined || this.date !== '' ? this.date : null,
      note: this.note !== undefined || this.note !== '' ? this.note : null,
      alreadySeen: this.alreadySeen !== undefined || this.alreadySeen !== '' ? this.alreadySeen : null
    };
  }
}
