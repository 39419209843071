<template>
  <div>
    <b-jumbotron
      class="pb-4 pt-4"
      border-variant="dark"
      bg-variant="transparent">
      <h3>{{ $t('exportWindowTitle') }}</h3>
      <b-form-group
        :label="$t('formExportFormat')">
        <app-export-format-dropdown v-model="selectedExportFormat" />
      </b-form-group>
      <b-form-group
        :label="$t('formExportData')">
        <app-export-data-dropdown v-model="selectedExportMode" />
        <b-form-checkbox
          v-if="isPdfExport"
          v-model="isExportAsPlate">
          {{ $t('exportAsAPlate') }}
        </b-form-checkbox>
        <b-form-group
          v-if="isCsvExport"
          :label="$t('separator')">
          <b-form-input
            id="input-horizontal"
            v-model="separator" />
        </b-form-group>
        <b-form-group
          :label="$t('filters')">
          <app-well-selection-filters
            v-if="isAllPlateSelected"
            :wells="wellsArray"
            @filter="wellFiltered = $event" />
        </b-form-group>
      </b-form-group>
      <b-form-row class="mt-4">
        <b-container class="d-flex flex-row align-items-center justify-content-between">
          <b-link
            ref="cancelLink"
            class="fa-pull-left align-middle"
            @click="emitCloseEvent">
            {{ $t('cancel') }}
          </b-link>
          <b-button
            v-if="isExportInProgress"
            ref="exportInProgressButton"
            disabled>
            <b-spinner
              small
              type="grow" />
            <b-spinner
              small
              type="grow" />
            <b-spinner
              small
              type="grow" />
          </b-button>
          <b-button
            v-else
            ref="exportButton"
            @click="generateExportFile">
            {{ $t('exportButton') }}
          </b-button>
        </b-container>
      </b-form-row>
    </b-jumbotron>
  </div>
</template>

<script>
import ExportFormatDropdown from '@/components/common/ExportFormatDropdown';
import ExportDataDropdown from '@/components/common/ExportDataDropdown';
import { downloadReport } from '@/service/SmazentechService';
import NotificationMixin from '@/mixins/NotificationMixin';
import { mapGetters, mapState } from 'vuex';
import RunExportFormat from '../../../../../models/RunExportFormat';
import WellSelectionFilters from '@/components/run/editor/interpretation/well/WellSelectionFilters';
import Export from '@/models/Export';

export default {
  components: {
    'app-export-format-dropdown': ExportFormatDropdown,
    'app-export-data-dropdown': ExportDataDropdown,
    'app-well-selection-filters': WellSelectionFilters
  },
  mixins: [ NotificationMixin ],
  props: {
    selectedWells: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedExportFormat: RunExportFormat.PDF,
      selectedExportMode: 'EXPORT_DATA_ALL_PLATE',
      isExportInProgress: false,
      isExportAsPlate: false,
      separator: ',',
      wellFiltered: []
    };
  },
  computed: {
    ...mapState('runImport', {
      runId: state => state.run.id,
      fileName : state => state.run.fileName
    }
    ),
    ...mapGetters('runImport', [
      'wells'
    ]),
    wellIdsToExport() {
      let wellsToExport = [];
      if (!this.isAllPlate()) {
        wellsToExport = this.selectedWells.map(well => well.id);
      }
      else {
        wellsToExport = this.wellFiltered.map(well => well.id);
      }

      return wellsToExport;
    },
    isAllPlateSelected() {
      return this.isAllPlate();
    },
    isPdfExport() {
      if (this.selectedExportFormat === RunExportFormat.PDF) {
        return true;
      }

      return this.isExportAsPlate = false;
    },
    isCsvExport() {
      return this.selectedExportFormat === RunExportFormat.CSV;
    },
    wellsArray() {
      return Array.from(this.wells).map(([ position, well ]) => well);
    }
  },
  methods: {
    emitCloseEvent() {
      this.$emit('close');
    },
    isAllPlate() {
      return this.selectedExportMode !== 'EXPORT_DATA_SELECTION';
    },
    generateExportFile() {
      if (this.isCsvExport && this.separator.length !== 1) {
        this.showErrorNotification(this.$t('errorExportGeneration'), this.$t('errorExportGenerationSeparatorIncorrect'));

        return;
      }
      if (this.wellIdsToExport.length > 0) {
        this.isExportInProgress = true;
        downloadReport(this.fileName,
          this.runId,
          Export.build({
            wellIds: this.wellIdsToExport,
            format: this.selectedExportFormat.name,
            isExportAsPlate: this.isExportAsPlate,
            separator: this.separator
          }))
          .then(() => this.emitCloseEvent())
          .catch(error => {
            this.emitCloseEvent();
            this.showErrorNotification(this.$t('errorExportGeneration'), error, { root: true });
          });
      } else {
        this.showErrorNotification(this.$t('errorExportGeneration'), this.$t('errorExportGenerationNoWellAvailable'));
      }
    }
  }
};
</script>
