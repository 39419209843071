import Vue from 'vue';
import Router from 'vue-router';
import RunImport from '@/components/run/import/RunImport';
import Run from '@/components/run/editor/Run';
import RunList from '@/components/list/RunList';
import RunConfiguration from '@/components/run/editor/configuration/RunConfiguration';
import RunInterpretation from '@/components/run/editor/interpretation/RunInterpretation';
import Laboratory from '@/components/admin/laboratory/Laboratory';
import Admin from '@/components/admin/Admin';
import Laboratories from '@/components/admin/laboratory/Laboratories';
import LaboratoryAdd from '@/components/admin/laboratory/LaboratoryAdd';
import Configuration from '@/components/admin/configuration/Configuration';
import KitAnalyserConfiguration from '@/components/admin/configuration/kit/KitAnalyserConfiguration.vue';
import LotConfiguration from '@/components/admin/configuration/lot/LotConfiguration.vue';
import KitConfiguration from '@/components/admin/configuration/kit/KitConfiguration.vue';
import ConfigurationFTP from './components/admin/configuration/ftp/ConfigurationFTP.vue';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/*&state*'
    },
    {
      path: '/run/import',
      name: 'run-import',
      component: RunImport
    },
    {
      path: '/run/configuration',
      name: 'run-creation',
      component: RunConfiguration,
      props: {
        initialFormStep: 1
      }
    },
    {
      path: '/run/:id',
      component: Run,
      children: [
        {
          path: 'interpretation',
          name: 'run-interpretation',
          component: RunInterpretation
        }
      ]
    },
    {
      path: '/',
      name: 'run-list',
      component: RunList
    },
    {
      path: '/laboratory',
      name: 'laboratory',
      component: Laboratory
    },
    {
      path: '/admin',
      name: 'admin',
      component: Admin,
      children: [
        {
          path: 'configuration/:tab?',
          name: 'configure',
          component: Configuration,
          children: [
            {
              path: 'lot',
              name: 'lot-configuration',
              component: LotConfiguration
            },
            {
              path: 'kit',
              name: 'kit-configuration',
              component: KitConfiguration
            },
            {
              path: 'ftp',
              name: 'configuration-ftp',
              component: ConfigurationFTP
            },
            {
              path: 'kit/analyser/:kitAnalyserId?',
              name: 'kit-analyser-configuration',
              component: KitAnalyserConfiguration
            }
          ]
        },
        {
          path: 'laboratories',
          name: 'laboratories',
          component: Laboratories
        },
        {
          path: 'add',
          name: 'laboratory-add',
          component: LaboratoryAdd
        }
      ]
    },
    {
      path: '/*',
      redirect: '/404'
    }
  ]
});
