<template>
  <b-container v-if="changes && changes.length">
    <h6>{{ $t(titleTranslation) }}</h6>

    <ul>
      <li
        v-for="(change, index) in changes"
        :key="index">
        {{ $t(messageTranslation, [change.lot.name, change.kitVersion.version, change.kitVersion.kit.name]) }}
      </li>
    </ul>
  </b-container>
</template>

<script>

export default {
  props: {
    changes: {
      type: Array,
      default: undefined
    },
    titleTranslation: {
      type: String,
      default: undefined
    },
    messageTranslation: {
      type: String,
      default: undefined
    }
  }
};
</script>
