<template>
  <multiselect
    v-model="model"
    :options="options"
    :custom-label="translatedOption"
    :placeholder="$t('formExportFormatPlaceholder')"
    deselect-label=""
    :allow-empty="false"
    :loading="isLoading"
    :class="stateClass" />
</template>

<script>
import DropdownMixin from '@/mixins/DropdownMixin';
import RunExportFormat from '@/models/RunExportFormat';

export default {
  mixins: [ DropdownMixin ],
  props: {
    value: {
      type: RunExportFormat,
      default: undefined
    }
  },
  methods: {
    findOptions() {
      return new Promise(resolve => resolve(RunExportFormat.values));
    },
    translatedOption(runExportFormat) {
      return this.$t(runExportFormat.translationKey);
    }
  }
};
</script>
