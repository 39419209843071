import { getField, updateField } from 'vuex-map-fields';
import { findAllActiveLotsByKitId, reindexData } from '@/service/SmazentechService';
import KitVersion from '@/models/KitVersion';

const state = {
  kit: undefined,
  lotsRelatedToKit: [],
  kitVersion: undefined,
  customKitVersion: undefined,
  customKitVersionLaboratory: undefined,
  lotsRelatedToVersion: undefined,
  laboratory: undefined,
  analyser: undefined,
  kitStatus: undefined,
  kitAnalyser: undefined,
  kitConfiguration: undefined,
  controlSampleTypes: [],
  loading: false
};

const getters = {
  getField,
  kitAnalyserStatus: state => state.kitAnalyser?.status,
  locked: state => (state.customKitVersion ?? state.kitVersion)?.locked,
  SampleInterpretation: state => state.kitConfiguration?.diagnostics.filter(d => d.diagnosticType === 'RESULT' && !d.control),
  KitZentechControls: state => state.kitConfiguration?.diagnostics.filter(d => d.diagnosticType === 'CONTROL' && d.control?.controlType === 'KIT' && d.validityType === 'VALID'),
  LaboratoryControls: state => state.kitConfiguration?.diagnostics.filter(d => d.diagnosticType === 'CONTROL' && d.control?.controlType === 'INTERNAL' && d.validityType === 'VALID'),
  updatedKitConfiguration: state => {
    const source = state.kitVersion;
    const custom = state.customKitVersion ?
      KitVersion.parse({ ...state.customKitVersion, source, kit: source.kit })
      : undefined;

    return {
      kitAnalyser: {
        ...state.kitAnalyser,
        kitVersion: custom ?? source
      },
      status: state.kitStatus,
      kitAnalyserChannels: state.kitConfiguration?.kitAnalyserChannels,
      diagnostics: state.kitConfiguration?.diagnostics
    };
  }
};

export const mutations = {
  updateField,
  updateKitAnalyserChannel(state, kitAnalyserChannel) {
    const index = state.kitConfiguration.kitAnalyserChannels.findIndex(ch => ch.channel === kitAnalyserChannel.channel);
    if (index >= 0) {
      const source = state.kitConfiguration.kitAnalyserChannels[index];
      const { threshold } = kitAnalyserChannel;
      state.kitConfiguration.kitAnalyserChannels = state.kitConfiguration.kitAnalyserChannels.slice();
      state.kitConfiguration.kitAnalyserChannels[index] = {
        ...source,
        threshold
      };
    }
  },
  addDiagnostic(state, diagnostic) {
    diagnostic.index = state.kitConfiguration.diagnostics.length;
    state.kitConfiguration.diagnostics.push(diagnostic);
  },
  updateDiagnostic(state, diagnostic) {
    const index = state.kitConfiguration.diagnostics.findIndex(d => d.index === diagnostic.index);
    if (index < 0) {
      return;
    }
    state.kitConfiguration.diagnostics = JSON.parse(JSON.stringify(state.kitConfiguration.diagnostics));
    const source = state.kitConfiguration.diagnostics[index];
    state.kitConfiguration.diagnostics[index] = {
      ...source,
      control: source.control ? {
        ...source.control,
        barCode: diagnostic.control.barCode,
        sampleType: diagnostic.control.sampleType,
        label: diagnostic.control.label
      } : null,
      color: diagnostic.color,
      validityType: diagnostic.validityType,
      label: diagnostic.label,
      conditions: diagnostic.conditions
    };
  },
  removeDiagnostic(state, diagnostic) {
    const index = state.kitConfiguration.diagnostics.findIndex(d => d.index === diagnostic.index);
    state.kitConfiguration.diagnostics = state.kitConfiguration.diagnostics
      .filter((d, i) => i !== index)
      .map((d, index) => {
        d.index = index;

        return d;
      });
  }
};

export const actions = {
  reindexData() {
    return new Promise((resolve, reject) => {
      reindexData()
        .then(_ => resolve())
        .catch(_ => reject());
    });
  },
  reset({ dispatch }) {
    dispatch('setKit', undefined);
    dispatch('setAnalyser', undefined);
    dispatch('setKitStatus', undefined);
    dispatch('setKitVersion', undefined);
  },
  setLaboratory({ commit }, laboratory) {
    commit('updateField', { path: 'laboratory', value: laboratory });
  },
  setKit({ dispatch, commit }, kit) {
    commit('updateField', { path: 'kit', value: kit });
    dispatch('setLotsRelatedToKit', []);
    dispatch('setKitVersion', undefined);
  },
  setLotsRelatedToKit({ commit }, lots) {
    commit('updateField', { path: 'lotsRelatedToKit', value: lots });
  },
  setKitVersion({ dispatch, commit }, kitVersion) {
    const source = kitVersion?.source ? kitVersion.source : kitVersion;
    const custom = kitVersion?.source ? kitVersion : null;
    commit('updateField', { path: 'kitVersion', value: source });
    dispatch('setCustomKitVersion', custom);
  },
  setCustomKitVersion({ commit }, customKitVersion) {
    commit('updateField', { path: 'customKitVersion', value: customKitVersion });
  },
  setCustomKitVersionLaboratory({ state, dispatch, commit }, customKitVersionLaboratory) {
    commit('updateField', { path: 'customKitVersionLaboratory', value: customKitVersionLaboratory });
    if (state.customKitVersion) {
      dispatch('setCustomKitVersion', undefined);
    }
  },
  setAnalyser({ commit }, analyser) {
    commit('updateField', { path: 'analyser', value: analyser });
  },
  setKitStatus({ commit, getters }, kitStatus) {
    // kit status cannot be null, so we reset to last known value when null
    commit('updateField', { path: 'kitStatus', value: kitStatus ?? getters.kitAnalyserStatus });
  },
  setKitAnalyser({ commit }, kitAnalyser) {
    commit('updateField', { path: 'kitAnalyser', value: kitAnalyser });
  },
  setKitConfiguration({ dispatch, commit, getters }, kitConfiguration) {
    dispatch('setKitVersion', kitConfiguration?.kitVersion);
    dispatch('setKitAnalyser', kitConfiguration?.kitAnalyser);
    // kit status cannot be null, init with current value from configuration (meaning dispatch('setKitAnalyser') must be first called)
    dispatch('setKitStatus', getters.kitAnalyserStatus);
    /* kitConfiguration should be undefined,
     * else should have at least empty arrays in properties when creating configuration from scratch (should not happen at the moment),
     * otherwise take value
     */
    const configuration = kitConfiguration ?
      {
        kitAnalyserChannels: kitConfiguration.kitAnalyserChannels ?? [],
        diagnostics: kitConfiguration.diagnostics.map((d, index) => ({ ...d, index })) ?? []
      } : undefined;
    commit('updateField', { path: 'kitConfiguration', value: configuration });
  },
  setControlSampleTypes({ commit }, sampleTypes) {
    commit('updateField', { path: 'controlSampleTypes', value: sampleTypes });
  },
  setExpressionVariables({ commit }, variables) {
    commit('updateField', { path: 'expressionVariables', value: variables });
  },
  setExpressionOperators({ commit }, operators) {
    commit('updateField', { path: 'expressionOperators', value: operators });
  },
  updateKitAnalyserChannel({ commit }, channel) {
    commit('updateKitAnalyserChannel', channel);
  },
  addDiagnostic({ commit }, diagnostic) {
    commit('addDiagnostic', diagnostic);
  },
  updateDiagnostic({ commit }, diagnostic) {
    commit('updateDiagnostic', diagnostic);
  },
  removeDiagnostic({ commit }, diagnostic) {
    commit('removeDiagnostic', diagnostic);
  },
  findLotsRelatedToKit({ dispatch }, kit) {
    dispatch('setLotsRelatedToKit', []);
    if (!kit) {
      return;
    }
    findAllActiveLotsByKitId(kit.id)
      .then(lots => {
        dispatch('setLotsRelatedToKit', lots);
      });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
