<template>
  <b-table
    :items="interpretations"
    :fields="fields"
    sticky-header
    :bordered="true"
    :small="true"
    :head-variant="'light'">
    <template #thead-top="data">
      <b-tr>
        <b-th colspan="2" />
        <b-th colspan="2" class="text-center">
          {{ $t('wellInterpretationDataTableColumnHeaderChannel1Name') }}
        </b-th>
        <b-th colspan="2" class="text-center">
          {{ $t('wellInterpretationDataTableColumnHeaderChannel2Name') }}
        </b-th>
        <b-th colspan="2" />
      </b-tr>
    </template>
  </b-table>
</template>

<script>
export default {
  props: {
    interpretations: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      fields: [
        { key: 'position', label: this.$t('wellInterpretationDataTableColumnHeaderPosition'), sortable: true },
        { key: 'name', label: this.$t('wellInterpretationDataTableColumnHeaderName') },
        { key: 'channel1.cq', label: this.$t('wellInterpretationDataTableColumnHeaderChannelCq'), formatter: 'roundCq' },
        { key: 'channel1.endRFU', label: this.$t('wellInterpretationDataTableColumnHeaderChannelEndRFU'), formatter: 'roundEndRFU' },
        { key: 'channel2.cq', label: this.$t('wellInterpretationDataTableColumnHeaderChannelCq'), formatter: 'roundCq' },
        { key: 'channel2.endRFU', label: this.$t('wellInterpretationDataTableColumnHeaderChannelEndRFU'), formatter: 'roundEndRFU' },
        { key: 'ratio', label: this.$t('wellInterpretationDataTableColumnHeaderRatio'), formatter: 'roundRatio' },
        { key: 'suggestedResult', label: this.$t('wellInterpretationDataTableColumnHeaderSuggestedResult'), formatter: 'translateSuggestedResult', sortable: true }
      ]
    };
  },
  methods: {
    round(decimal, digits = 2) {
      return decimal ? decimal.toFixed(digits) : decimal;
    },
    roundCq(cq) {
      return this.round(cq, 2);
    },
    roundEndRFU(endRFU) {
      return this.round(endRFU, 2);
    },
    roundRatio(ratio) {
      return this.round(ratio, 8);
    },
    translateSuggestedResult(result) {
      return result ? result.label : '';
    }
  }
};

</script>