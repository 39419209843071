export default {
  methods: {
    hasRole(role) {
      return this.$store.getters['security/hasRole'](role);
    },
    userName() {
      return this.$store.getters['security/userName'];
    },
    userLaboratory() {
      return this.$store.getters['security/userLaboratory'];
    },
    canEditComment(comment) {
      if (!comment.id) {
        return true;
      }

      return this.userName() === comment?.technicianName;
    },
    canUpdateRun() {
      // If run is in WAIT_FOR_APPROVAL
      // Only doctors can add/edit a comment
      // Technicians can edit in RUN_TO_INTERPRET status only
      const status = this.$store.getters['runImport/getRunStatus'];
      if (status === 'RUN_TO_INTERPRET') {
        return true;
      } else {
        if (status === 'WAIT_FOR_APPROVAL') {
          if (this.hasRole('WRITE_DIAGNOSTIC')) {
            return true;
          }
        }
      }

      return false;
    },
    canDeleteRun() {
      // If run is in WAIT_FOR_APPROVAL status,
      // Only doctors can delete the run
      if (this.$store.getters['runImport/getRunStatus'] === 'REJECTED'
          || this.$store.getters['runImport/getRunStatus'] === 'APPROVED') {
        return false;
      } else {
        if (!this.hasRole('WRITE_DIAGNOSTIC')) {
          if (this.$store.getters['runImport/getRunStatus'] !== 'RUN_TO_INTERPRET') {
            return false;
          }
        }
      }

      return true;
    }
  }
};
