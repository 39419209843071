import KitVersion from '@/models/KitVersion';

export default class Lot {
  constructor(id, name, expirationDate, active, kitVersion) {
    this.id = id;
    this.name = name;
    this.expirationDate = expirationDate;
    this.active = active;
    this.kitVersion = kitVersion;
  }

  static build({ id, name, expirationDate, active, kitVersion }) {
    return new Lot(id, name, expirationDate, active, kitVersion);
  }

  static parse(value) {
    return value ? Lot.build({
      id: value.id,
      name: value.name,
      expirationDate: new Date(value.expirationDate),
      active: value.active,
      kitVersion: KitVersion.parse(value.kitVersion)
    }) : null;
  }

  static convertToIsoDate(value) {
    value.expirationDate = value.expirationDate ?  new Date(value.expirationDate).toISOString() : new Date().toISOString();

    return value;
  }
}
