export function includedIn(values) {
  if (!values) {
    return () => false;
  }

  return value => {
    if (!value) {
      return true;
    }

    return values.includes(value);
  };
}