<template>
  <div>
    <b-form-row
      v-for="(input, index) in kitAndLotInputRows"
      :key="input.uuid">
      <b-col lg="6">
        <b-form-group :label="index === 0 ? $t('formKit') : undefined">
          <app-kit-dropdown
            v-model="input.kit"
            :overriding-options="getKitOverridingOptions(input.kit)"
            :preselect-single-overriding-option="true"
            :analyser-id="analyserId"
            :state="state"
            @input="updateKitInput(input, $event)" />
        </b-form-group>
      </b-col>
      <b-col :lg="getKitOverridingOptions(input.kit).length > 1 ? 4 : 6">
        <b-form-group :label="index === 0 ? $t('formLot') : undefined">
          <app-lot-dropdown
            v-model="input.selectionLots"
            :overriding-options="input.lots ? input.lots : []"
            :preselect-single-overriding-option="true"
            :state="state"
            :multiple="true"
            @input="updateLotInput(input, $event)" />
        </b-form-group>
      </b-col>
      <b-col v-if="getKitOverridingOptions(input.kit).length > 1">
        <b-form-group
          :label="index === 0 ? 'Remove / Add' : undefined">
          <b-button
            ref="removeButton"
            :disabled="kitAndLotInputRows.length < 2"
            variant="outline"
            class="btn--no-outline btn-icon btn-icon-minus"
            @click="removeKitAndLotInputLine(input)">
            <font-awesome-icon
              :icon="['fas', 'minus-circle']" />
          </b-button>
          <b-button
            v-if="index === kitAndLotInputRows.length - 1"
            ref="addButton"
            variant="outline"
            class="btn--no-outline btn-icon btn-icon-plus"
            @click="addKitAndLotInputLine">
            <font-awesome-icon
              :icon="['fas', 'plus-circle']" />
          </b-button>
        </b-form-group>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import KitDropdown from '@/components/common/KitDropdown';
import LotDropdown from '@/components/common/LotDropdown';
import NotificationMixin from '@/mixins/NotificationMixin';
import { findAllKitsByAnalyserIdLinkedToLots, findAllLotByAnalyserIdAndKitId } from '@/service/SmazentechService';
import { mapState } from 'vuex';
import { v4 as uuidv4 } from 'uuid';

export default {
  components: {
    'app-kit-dropdown': KitDropdown,
    'app-lot-dropdown': LotDropdown
  },
  mixins: [
    NotificationMixin
  ],
  props: {
    state: {
      // Tri-state prop: true, false, null (or undefined)
      type: Boolean,
      default: null
    }
  },
  data() {
    return {
      kits: [],
      kitAndLotInputRows: []
    };
  },
  computed: {
    ...mapState('runImport', {
      analyserId: state => state.run?.analyser?.id
    })
  },
  beforeMount() {
    findAllKitsByAnalyserIdLinkedToLots(this.analyserId)
      .then(kits => {
        this.kits = kits;
        this.addKitAndLotInputLine();
      });
  },
  methods: {
    addKitAndLotInputLine() {
      this.kitAndLotInputRows.push({ uuid: uuidv4(), kit: null, selectionLots: undefined, lots: [] });
    },
    removeKitAndLotInputLine({ uuid }) {
      const index = this.kitAndLotInputRows.findIndex(row => row.uuid === uuid);
      if (index < 0) return;
      this.kitAndLotInputRows.splice(index, 1);
      this.emitInputEvent();
    },
    updateKitInput(input, kit) {
      this.updateKitLotRow({ uuid: input.uuid, kit });
    },
    updateLotInput(input, lots) {
      this.updateKitLotRow({ uuid: input.uuid, lots });
    },
    updateKitLotRow({ uuid, kit, lots }) {
      const row = this.kitAndLotInputRows.find(r => r.uuid === uuid);
      if (!row) return;
      if (kit) {
        row.kit = kit;
        findAllLotByAnalyserIdAndKitId(this.analyserId, row.kit.id).then(lotsByKit => {
          row.lots = lotsByKit;
        }).catch(error => console.error(error));
      }
      if (lots) {
        row.selectionLots = lots;
      }
      this.emitInputEvent();
    },
    emitInputEvent() {
      this.verifyKitLotCompatibility();
      const completeRows = this.kitAndLotInputRows.filter(row => !!row.kit && !!row.selectionLots);
      const lots = completeRows.flatMap(row => row.selectionLots);

      this.$emit('input', lots);
    },
    verifyKitLotCompatibility() {
      this.kitAndLotInputRows.forEach(row => {
        let isKitIncludeInLot = undefined;
        if (row.kit && row.selectionLots) {
          isKitIncludeInLot = row.selectionLots.find(lot => lot?.kitVersion?.kit?.id === row.kit?.id);
        }
        if (!isKitIncludeInLot) {
          row.selectionLots = [];
        }
      });
    },
    getKitOverridingOptions(kit) {
      let kitOverridingOptions = this.kits;

      return kitOverridingOptions = kitOverridingOptions.filter(kitOption => !this.kitAndLotInputRows.map(row => row.kit).includes(kitOption) || kitOption === kit);
    }
  }
};
</script>
