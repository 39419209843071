<template>
  <b-badge
    :key="kitAnalyser.kitAnalyserId"
    :to="{ name: 'kit-analyser-configuration', params: { kitAnalyserId: kitAnalyser.kitAnalyserId } }"
    :variant="kitAnalyser.configured ? 'success' : 'danger'">
    {{ kitAnalyser.name }}
    <font-awesome-icon
      prefix="fas"
      icon="cog" />
  </b-badge>
</template>

<script>
export default {
  name: 'KitAnalyserBadge',
  props: {
    kitAnalyser: {
      type: Object,
      default: undefined
    }
  }
};
</script>