<template>
  <app-confirmation-modal
    ref="runStatusUpdateModal"
    :title="title"
    :message="message"
    :ok-button-name="$t('yes')"
    ok-button-prevent-hiding
    ok-button-spinners-enabled
    :cancel-button-name="$t('no')"
    @ok="updateStatus" />
</template>

<script>
import NotificationMixin from '../../../../mixins/NotificationMixin';
import ConfirmationModal from '../../../common/ConfirmationModal';
import { mapFields } from 'vuex-map-fields';

export default {
  components: {
    'app-confirmation-modal': ConfirmationModal
  },
  mixins: [
    NotificationMixin
  ],
  props: {
    newStatus: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: undefined
    },
    message: {
      type: String,
      default: undefined
    }
  },
  computed: {
    ...mapFields('runImport', [
      'run.id',
      'run.status',
      'run.ftpSent',
      'run.approvedBy',
      'run.rejectedBy',
      'run.submittedByName'
    ])
  },
  methods: {
    updateStatus() {
      let observable;
      if (this.newStatus === 'WAIT_FOR_APPROVAL') {
        observable = this.$store.dispatch('runImport/submitResults', { id: this.id });
      } else {
        observable = this.$store.dispatch('runImport/updateStatus', { id: this.id, status: this.newStatus });
      }
      observable.then(run => {
        if (this.newStatus !== 'WAIT_FOR_APPROVAL' && run.ftpSent === false) {
          this.showErrorNotification(this.$t('runInterpretation.ftp.errorNotification.title'), this.$t('runInterpretation.ftp.errorNotification.message'));
        }
        this.approvedBy = run.approvedBy;
        this.rejectedBy = run.rejectedBy;
        this.submittedByName = run.submittedByName;
        this.status = run.status;
        this.ftpSent = run.ftpSent;
        this.$refs.runStatusUpdateModal.hideModal();
      }).catch(error => {
        this.showErrorNotification(this.$t('errorUpdateStatusTitle'), error);
        this.$refs.runStatusUpdateModal.hideModal();
      });
    }
    ,
    showModal() {
      this.$refs.runStatusUpdateModal.showModal();
    }
  }
};
</script>
