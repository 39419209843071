<template>
  <b-row>
    <b-col
      class="px-1"
      sm="6">
      <app-kit-dropdown
        v-model="selectedKit"
        :overriding-options="kits"
        :placeholder="$t('runInterpretation.wellInterpretation.wellSelectionFilters.kitDropdownPlaceholder')"
        :show-labels="false"
        @remove="removeKit" />
    </b-col>
    <b-col
      class="px-1"
      sm="6">
      <app-kit-version-dropdown
        v-model="selectedKitVersion"
        :overriding-options="kitVersions"
        :show-labels="false"
        @input="emitInputEvent"
        @remove="removeKitVersion" />
    </b-col>
  </b-row>
</template>

<script>
import uniqBy from 'lodash/uniqBy';

import KitDropdown from '@/components/common/KitDropdown';
import KitVersionDropdown from '@/components/common/KitVersionDropdown.vue';

export default {
  components: {
    'app-kit-dropdown': KitDropdown,
    'app-kit-version-dropdown': KitVersionDropdown
  },
  props: {
    wells: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedKit: undefined,
      selectedKitVersion: undefined
    };
  },
  computed: {
    kits() {
      return uniqBy(this.wells.filter(well => well.kit).map(well => well.kit), 'id').sort();
    },
    kitVersions() {
      if (!this.selectedKit) {
        return [];
      }

      return uniqBy(this.wells.filter(well => well.kitVersion && well.kit.id === this.selectedKit.id).map(well => well.kitVersion), 'id').sort();
    }
  },
  watch: {
    kits(kits) {
      if (kits.length === 1) {
        this.selectedKit = kits[0];
      } else {
        this.selectedKit = undefined;
      }
    },
    selectedKit() {
      this.selectedKitVersion = this.kitVersions.length === 1 ? this.kitVersions[0] : undefined;
      this.emitInputEvent();
    },
    wells() {
      this.emitInputEvent();
    }
  },
  mounted() {
    if (this.kits.length === 1) {
      this.selectedKit = this.kits[0];
    }
  },
  methods: {
    removeKit() {
      this.selectedKit = undefined;
    },
    removeKitVersion() {
      this.selectedKitVersion = undefined;
    },
    emitInputEvent() {
      let wellsFiltered = this.wells;
      if (this.selectedKit) {
        wellsFiltered = this.wells.filter(well => well?.kit?.id === this.selectedKit?.id);
        if (this.selectedKitVersion) {
          wellsFiltered = this.wells.filter(well => well?.kitVersion?.id === this.selectedKitVersion?.id);
        }
      }
      this.$emit('filter', wellsFiltered);
    }
  }
};
</script>
