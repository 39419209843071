import { getField, updateField } from 'vuex-map-fields';
import {
  createPlateTemplate,
  deletePlateTemplate,
  findAllPlateTemplatesByVersions,
  updatePlateTemplate
} from '@/service/SmazentechService';

const state = {
  availableTemplates: [],
  template: undefined
};

const getters = {
  getField,
  template: state => state.template
};

export const mutations = {
  updateField,
  addPlateTemplate(state, { plateTemplate }) {
    state.availableTemplates.push(plateTemplate);
  },
  updatePlateTemplate(state, { plateTemplate }) {
    const i = state.availableTemplates.findIndex(template => template.id === plateTemplate.id);
    if (i >= 0) {
      state.availableTemplates.splice(i, 1, plateTemplate);
    }
  },
  deletePlateTemplate(state, { plateTemplate }) {
    const i = state.availableTemplates.findIndex(template => template.id === plateTemplate.id);
    if (i >= 0) {
      state.availableTemplates.splice(i, 1);
    }
  }
};

export const actions = {
  findAllPlateTemplates({ commit }, { versionIds }) {
    return new Promise((resolve, reject) => {
      findAllPlateTemplatesByVersions(versionIds).then(response => {
        commit('updateField', { path: 'availableTemplates', value: response });
        resolve(response);
      }).catch(error => reject(error));
    });
  },
  createPlateTemplate({ commit }, plateTemplate) {
    return new Promise((resolve, reject) => {
      createPlateTemplate(plateTemplate)
        .then(response => {
          commit('addPlateTemplate', { plateTemplate: response });
          resolve(response);
        })
        .catch(error => reject(error));
    });

  },
  updatePlateTemplate({ commit }, plateTemplate) {
    return new Promise((resolve, reject) => {
      updatePlateTemplate(plateTemplate)
        .then(response => {
          commit('updatePlateTemplate', { plateTemplate: response });
          resolve(response);
        })
        .catch(error => reject(error));
    });

  },
  deletePlateTemplate({ commit }, plateTemplate) {
    return new Promise((resolve, reject) => {
      deletePlateTemplate(plateTemplate)
        .then(response => {
          commit('deletePlateTemplate', { plateTemplate: response });
          resolve(response);
        })
        .catch(error => reject(error));
    });

  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
