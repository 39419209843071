<template>
  <b-container class="diagnostic-update-form-container">
    <p class="text-center mb-4">
      {{ $t('diagnosticUpdateFormIntroductionParagraph', [well.position]) }}
    </p>
    <b-form-group>
      <app-diagnostic-dropdown
        v-model="selectedDiagnostic"
        :overriding-options="wellDiagnostics"
        :translated="true" />
    </b-form-group>
    <b-form-row class="mt-4">
      <b-col sm="12">
        <div class="d-flex flex-row align-items-center justify-content-around">
          <b-link
            class="fa-pull-left align-middle"
            @click="$emit('cancel')">
            {{ $t('cancel') }}
          </b-link>
          <b-button
            :disabled="!isModificationAllowed"
            @click="updateDiagnostic">
            {{ $t('diagnosticUpdateFormValidationButtonText') }}
          </b-button>
        </div>
      </b-col>
    </b-form-row>
  </b-container>
</template>

<script>
import DiagnosticDropdown from '@/components/common/DiagnosticDropdown.vue';
import NotificationMixin from '@/mixins/NotificationMixin';
import { getRunDiagnosticsByKitVersionId, updateRunDiagnostic } from '@/service/SmazentechService';
import Well, { getDiagnostic, isSampleTypeOfControl } from '@/models/Well';

export default {
  components: {
    'app-diagnostic-dropdown': DiagnosticDropdown
  },
  mixins: [
    NotificationMixin
  ],
  props: {
    runId: {
      type: Number,
      required: true
    },
    well: {
      type: Well,
      required: true
    },
    kitVersionId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      initialDiagnostic: undefined,
      selectedDiagnostic: undefined,
      diagnostics: []
    };
  },
  computed: {
    isModificationAllowed() {
      return this.initialDiagnostic !== this.selectedDiagnostic;
    },
    wellDiagnostics() {
      return this.diagnostics.filter(d => (d.diagnosticType === 'ABNORMAL' ||
          (isSampleTypeOfControl(this.well.sampleType) ?
            d.diagnosticType === 'CONTROL' && d.control?.sampleType === this.well.sampleType.type :
            d.diagnosticType !== 'CONTROL')) && (this.well?.diagnostic ? this.well?.diagnostic?.result?.id !== d.id : this.well?.interpretation?.suggestedResult?.id !== d.id)
      );
    }
  },
  watch: {
    runId(newValue) {
      if (newValue) {
        this.initDiagnostics(newValue, this.kitVersionId);
      }
    },
    well(newValue) {
      if (newValue) {
        this.initSelectedDiagnostic(newValue);
      }
    },
    kitVersionId(newValue) {
      if (newValue) {
        this.initDiagnostics(this.runId, this.kitVersionId);
      }
    }
  },
  created() {
    this.initDiagnostics(this.runId, this.kitVersionId);
  },
  methods: {
    initDiagnostics(runId, kitVersionId) {
      getRunDiagnosticsByKitVersionId(runId, kitVersionId)
        .then(diagnostics => {
          this.diagnostics = diagnostics ?? [];
          this.initSelectedDiagnostic(this.well);
        });
    },
    initSelectedDiagnostic(well) {
      this.selectedDiagnostic = this.diagnostics.find(d => d.id === getDiagnostic(well)?.id);
    },
    updateDiagnostic() {
      const diagnostic = this.selectedDiagnostic.id ? this.selectedDiagnostic : { id: null };
      updateRunDiagnostic(this.runId, { wellId: this.well.id, result: diagnostic })
        .then(wellDiagnostic => this.$emit('updated', wellDiagnostic))
        .catch(error => this.showErrorNotification(this.$t('diagnosticUpdateFormUpdateErrorNotificationTitle'), error));
    }
  }
};
</script>
