export default {
  'en-GB': {
    currency: {
      style: 'currency', currency: 'USD'
    }
  },
  'fr-FR': {
    currency: {
      style: 'currency', currency: 'EUR'
    }
  }
};
