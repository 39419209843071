<template>
  <b-modal
    :id="id"
    :title="$t('modalTitleNewKitVersion', [kit.name])"
    :ok-title="$t('common.saveChanges')"
    :ok-disabled="disableOk"
    ok-variant="secondary"
    :cancel-title="$t('common.cancel')"
    cancel-variant="outline-secondary"
    @ok="onAddKitVersion">
    <b-form-row>
      <b-form-group
        :label="$t('formKitVersion')"
        label-cols="6"
        class="col">
        <b-form-input
          v-model="kitVersion.version"
          :state="validateState('kitVersion.version')"
          @blur="touch('kitVersion.version')" />
      </b-form-group>
    </b-form-row>
  </b-modal>
</template>

<script>
import NotificationMixin from '@/mixins/NotificationMixin';
import ValidationMixin from '@/mixins/ValidationMixin';
import KitVersion from '@/models/KitVersion';
import {createLaboratoryVersion, createOfficialVersion} from '@/service/KitVersionService';
import Kit from '@/models/Kit';

export default {
  mixins: [
    NotificationMixin,
    ValidationMixin
  ],
  props: {
    kit: {
      type: Object,
      default: undefined
    },
    source: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      id: 'add-kit-version-modal',
      kitVersion: {
        version: undefined
      }
    };
  },
  computed: {
    disableOk() {
      return this.v$.invalid;
    }
  },
  beforeMount() {
    this.kitVersion = { version: undefined };
  },
  methods: {
    onAddKitVersion() {
      if (!this.isValid()) {
        return;
      }

      const kit = Kit.parse(this.kit);

      const creating = this.source
        ? createLaboratoryVersion(KitVersion.parse({ source: this.source, version: this.kitVersion?.version }))
        : createOfficialVersion(KitVersion.parse({ kit, version: this.kitVersion?.version }));

      creating
        .then(kitVersion => {
          this.$emit('ok', kitVersion);
          this.$bvModal.hide(this.id);
        })
        .catch(error => {
          console.error(error);
          this.showErrorNotification(this.$t('configuration.kit.add-version.notification.title'), error);
        });
    }
  }

};
</script>