import Diagnostic from '@/models/Diagnostic';

export default class WellDiagnostic {
  constructor(id, position, origin, technicianName, creationDateTime, result) {
    this.id = id;
    this.position = position;
    this.origin = origin;
    this.technicianName = technicianName;
    this.creationDateTime = creationDateTime;
    this.result = result;
  }

  static build({ id, position, origin, technicianName, creationDateTime, result }) {
    return new WellDiagnostic(id, position, origin, technicianName, creationDateTime, result);
  }

  static parse(value) {
    return value ? WellDiagnostic.build({
      ...value,
      creationDateTime: value.creationDateTime ? new Date(value.creationDateTime) : null,
      result: Diagnostic.parse(value.result)
    }) : null;
  }

  toJSON() {
    return {
      id: this.id !== undefined && this.id !== '' ? this.id : null,
      position: this.position !== undefined && this.position !== '' ? this.position : null,
      origin: this.origin !== undefined && this.origin !== '' ? this.origin : null,
      technicianName: this.technicianName !== undefined && this.technicianName !== '' ? this.technicianName : null,
      creationDateTime: this.creationDateTime !== undefined && this.creationDateTime !== '' ? this.creationDateTime : null,
      result: this.result !== undefined && this.result !== '' ? this.result : null
    };
  }
}