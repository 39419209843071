<template class="run-information-header">
  <b-container fluid>
    <hr>
    <b-form-row>
      <div class="col-lg-1" />
      <div class="col-lg-6">
        <h6
          class="text-nowrap text-truncate"
          :title="runName">
          <template v-if="runName !== undefined">
            <font-awesome-icon
              class="icon-width"
              :icon="['far', 'play-circle']" /> {{ runName }}
          </template>
        </h6>
      </div>
      <div class="col-lg-1" />
      <div class="col-lg-4">
        <h6
          class="text-nowrap text-truncate"
          :title="createdByName">
          <template v-if="createdByName !== undefined">
            <font-awesome-icon
              class="icon-width"
              :icon="['far', 'user']" /> {{ $t('formRunBy') }}  {{ createdByName }}
          </template>
        </h6>
      </div>
    </b-form-row>
    <b-form-row>
      <div class="col-lg-1" />
      <div class="col-lg-4">
        <h6
          class="text-nowrap text-truncate"
          :title="instrumentName">
          <template v-if="instrumentName !== undefined">
            <font-awesome-icon
              class="icon-width"
              :icon="['fas', 'laptop-medical']" /> {{ instrumentName }}
          </template>
        </h6>
      </div>
      <div class="col-lg-3" />
      <div class="col-lg-4">
        <h6
          class="text-nowrap text-truncate"
          :title="interpretationRunByName">
          <template v-if="interpretationRunByName">
            <font-awesome-icon
              class="icon-width"
              :icon="['far', 'user']" /> {{ $t('formInterpretationRunBy') }}  {{ interpretationRunByName }}
          </template>
        </h6>
      </div>
    </b-form-row>
    <b-form-row>
      <div class="col-lg-1" />
      <div class="col-lg-4">
        <h6
          class="text-nowrap text-truncate mb-0"
          :title="plateId">
          <template v-if="plateId !== undefined">
            <font-awesome-icon
              class="icon-width"
              :icon="['fas', 'braille']" /> {{ plateId }}
          </template>
        </h6>
      </div>
      <div class="col-lg-3" />
      <div class="col-lg-4">
        <h6
          class="text-nowrap text-truncate"
          :title="submittedByName">
          <template v-if="submittedByName">
            <font-awesome-icon
              class="icon-width"
              :icon="['far', 'user']" /> {{ $t('formSubmittedBy') }}  {{ submittedByName }}
          </template>
        </h6>
      </div>
    </b-form-row>
    <b-form-row>
      <div class="col-lg-1" />
      <div class="col-lg-4" />
      <div class="col-lg-3" />
      <div class="col-lg-4 d-flex flex-wrap">
        <div class="overflow-hidden">
          <h6
            class="text-truncate"
            :title="getApprovedRejectedTitle">
            <template v-if="approvedBy">
              <font-awesome-icon
                class="icon-width"
                :icon="['far', 'user']" /> {{ $t('formApprovedBy') }}  {{ approvedBy }}
            </template>
            <template v-if="rejectedBy">
              <font-awesome-icon
                class="icon-width"
                :icon="['far', 'user']" /> {{ $t('formRejectedBy') }}  {{ rejectedBy }}
            </template>
          </h6>
        </div>
        <div>
          <template v-if="ftpSent !== null">
            <h6>
              <span
                :class="ftpSent ? 'envelopeOk' : 'envelopeKo'"
                @mouseover="mouseOverTooltipFtpSent"
                @mouseleave="mouseLeaveTooltipFtpSent">
                <font-awesome-icon
                  :icon="['far', 'envelope']"
                  class="ml-1" />
                <span
                  :ref="`ftpSentTooltip`"
                  :class="`ftp-tooltip ${ftpSent ? 'ok' : 'ko'}`"
                  :hidden="true">
                  {{ $t(`tooltip.envelope.${ftpSent ? 'ok' : 'ko'}`) }}
                </span>
              </span>
            </h6>
          </template>
        </div>
      </div>
    </b-form-row>
    <hr>
  </b-container>
</template>

<script>
export default {
  props: {
    createdByName: {
      type: String,
      default: undefined
    },
    submittedByName: {
      type: String,
      default: undefined
    },
    interpretationRunByName: {
      type: String,
      default: undefined
    },
    businessId: {
      type: Number,
      default: undefined
    },
    instrumentName: {
      type: String,
      default: undefined
    },
    plateId: {
      type: String,
      default: undefined
    },
    fileName: {
      type: String,
      default: undefined
    },
    approvedBy: {
      type: String,
      default: undefined
    },
    rejectedBy: {
      type: String,
      default: undefined
    },
    ftpSent: {
      type: Boolean,
      default: null
    }
  },
  computed: {
    runName() {
      let runName = undefined;

      if (this.businessId !== undefined || this.fileName !== undefined) {
        runName = `Run ${this.businessId} - ${this.fileName}`;
      }

      return runName;
    },
    getApprovedRejectedTitle() {
      if (this.approvedBy) {
        return this.approvedBy;
      }
      if (this.rejectedBy) {
        return this.rejectedBy;
      }

      return '';
    }
  },
  methods: {
    mouseOverTooltipFtpSent() {
      this.$refs.ftpSentTooltip.hidden = false;
    },
    mouseLeaveTooltipFtpSent() {
      this.$refs.ftpSentTooltip.hidden = true;
    }
  }
};
</script>
