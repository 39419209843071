<template>
  <b-modal
    :id="id"
    :title="title"
    :header-text-variant="headerTextVariant"
    @close="handleCancelButtonClick"
    @hide="handleModalHideEvent">
    <div
      v-if="message"
      class="text-center allowLineBreak">
      <p class="my-4">
        {{ message }}
      </p>
    </div>
    <div v-else>
      <slot />
    </div>
    <template #modal-footer>
      <div class="d-flex w-100 justify-content-center align-items-center">
        <b-button
          v-if="okButtonSpinnersEnabled && okButtonClicked"
          ref="exportInProgressButton"
          :class="okButtonClasses"
          :variant="okButtonVariant"
          disabled>
          <b-spinner
            small
            type="grow" />
          <b-spinner
            small
            type="grow" />
          <b-spinner
            small
            type="grow" />
        </b-button>
        <b-button
          v-else
          :class="okButtonClasses"
          :variant="okButtonVariant"
          @click.once="handleOkButtonClick">
          {{ okButtonName }}
        </b-button>
        <span
          v-if="cancelButtonEnabled"
          class="mx-2">|</span>
        <b-link
          v-if="cancelButtonEnabled"
          :class="cancelButtonVariantClass"
          @click="handleCancelButtonClick">
          {{ cancelButtonName }}
        </b-link>
      </div>
    </template>
  </b-modal>
</template>

<script>
import ModalMixin from '../../mixins/ModalMixin';

export default {
  mixins: [ ModalMixin ]
};
</script>
