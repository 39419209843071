import Vue from 'vue';
import VueI18n from 'vue-i18n';

import messageEN from '@/translation/en/message';
import messageFR from '@/translation/fr/message';
import dateTimeFormats from '@/translation/dateTimeFormats';
import numberFormats from '@/translation/numberFormats';

Vue.use(VueI18n);

const messages = [];
messages['en-GB'] = messageEN['en-GB'];
messages['fr-FR'] = messageFR['fr-FR'];

export const i18n = new VueI18n({
  fallbackLocale: 'en-GB',
  locale: 'en-GB',
  messages,
  dateTimeFormats,
  numberFormats
});