<template>
  <div>
    <app-information-header
        ref="informationHeader"
        :business-id="businessId"
        :instrument-name="instrumentName"
        :plate-id="plateId"
        :created-by-name="createdByName"
        :submitted-by-name="submittedByName"
        :interpretation-run-by-name="interpretationRunByName"
        :file-name="fileName"
        :approved-by="approvedBy"
        :rejected-by="rejectedBy"
        :ftp-sent="ftpSent" />
    <b-container
      class="run-interpretation-content"
      fluid>
      <app-run-status-update-modal
        ref="updateStatusModal"
        :new-status="newRunStatus"
        :title="modalTitle"
        :message="modalMessage" />
      <b-form-row>
        <b-col cols="5"
               class="scrollable"
               :style="`height: calc(100vh - ${plateHeight}px)`">
          <b-container class="px-0">
            <b-form-group>
              <h3>
                {{ $t('wellInterpretationConfigurationTitle') }}
              </h3>
            </b-form-group>
            <b-form-group class="overflow-auto">
              <transition
                name="fade"
                mode="out-in">
                <app-plate
                  ref="plate"
                  class="mx-auto"
                  :wells="wells"
                  :plate-type="plateType"
                  :disable-selection="disableSelection"
                  :is-interpretation="true"
                  @select="handleWellSelection" />
              </transition>
            </b-form-group>
            <app-plate-captions
              mode="interpretation"
              :sample-types="availableSampleTypes">
              <template #caption="{ caption }">
                <div
                  v-if="caption.diagnosticId"
                  role="button"
                  @click="selectWellsByDiagnosticAndSampleType({ diagnosticId: caption.diagnosticId })">
                  <app-plate-caption :caption="caption" />
                </div>
                <app-plate-caption
                  v-else
                  :caption="caption" />
              </template>
            </app-plate-captions>
            <template v-if="displayFtpButton">
              <b-button
                v-if="!isSendingFTP"
                ref="sendFtpButton"
                variant="outline-secondary"
                @click="sendFileFtp">
                {{ $t('runInterpretation.ftp.runInterpretationSendFileFtp') }}
              </b-button>
              <b-button
                v-if="isSendingFTP"
                class="mr-2"
                disabled>
                <b-spinner
                  small
                  type="grow" />
                Loading...
              </b-button>
            </template>
          </b-container>
        </b-col>
        <b-col cols="7"
               :style="`height: calc(100vh - ${interpretationHeight}px)`"
               class="scrollable">
          <transition
            appear
            name="slide-fade"
            mode="out-in">
            <b-container
              v-if="action || selectedWells"
              class="pr-0">
              <template v-if="action === 'export'">
                <app-well-export
                  :selected-wells="selectedWells"
                  @close="hideExportWindow" />
              </template>
              <template v-else-if="selectedWells">
                <app-well-interpretation
                  :wells="selectedWells"
                  @updateDiagnostic="disableSelection = $event"
                  @close="handleWellSelection(undefined)" />
              </template>
            </b-container>
            <b-container
              v-else
              class="pr-0">
              <app-run-comments-form
                :run-id="id"
                :comments="comments"
                @updated="showNotificationForRunComment" />
            </b-container>
          </transition>
        </b-col>
      </b-form-row>
    </b-container>
    <b-form-row class="mx-0 justify-content-end fixed-bottom"
                ref="buttonsRow">
      <b-col class="d-flex bd-highlight mb-3 pl-5 pr-4 justify-content-between"
             cols="7">
        <app-cancel-run class="p-2 pl-4 bd-highlight" />
        <transition name="fade">
          <div v-if="chartDataLoaded" class="pr-3">
            <b-button
                ref="showExportWindowButton"
                class="p-2 mr-2"
                variant="outline-secondary"
                @click="showExportWindow">
              {{ $t('showExportWindowButton') }}
            </b-button>
            <b-button
              v-if="isSubmitButtonDisplayed"
              ref="submitResultsButton"
              class="p-2 bd-highlight mr-2"
              @click="submitResult">
              {{ $t('submitResults') | capitalizeAll }}
            </b-button>
            <template v-if="areApproveAndRejectButtonsDisplayed">
              <b-button
                ref="rejectResultsButton"
                class="p-2 bd-highlight mr-2"
                @click="rejectResult">
                {{ $t('rejectResults') | capitalizeAll }}
              </b-button>
              <b-button
                ref="approveResultsButton"
                class="p-2 bd-highlight mr-2"
                @click="approveResult">
                {{ $t('approveResults') | capitalizeAll }}
              </b-button>
            </template>
            <b-button
              ref="deleteRunButton"
              class="p-2 bd-highlight"
              variant="outline-danger"
              :disabled="!canDelete"
              @click="showRunDeletionModal">
              {{ $t('runInterpretationFooterDeleteRunButtonText') | capitalizeAll }}
            </b-button>
            <app-confirmation-modal
              ref="runDeletionModal"
              :title="$t('runInterpretation.deletionModal.title')"
              :message="$t('runInterpretation.deletionModal.message')"
              :ok-button-name="$t('runInterpretation.deletionModal.okButtonText')"
              :ok-button-classes="['run-deletion-modal-ok-button']"
              ok-button-prevent-hiding
              ok-button-spinners-enabled
              ok-button-variant="outline-danger"
              :cancel-button-name="$t('runInterpretation.deletionModal.cancelButtonText')"
              header-text-variant="danger"
              @ok="deleteRun" />
          </div>
        </transition>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { deleteRun } from '@/service/SmazentechService';
import NotificationMixin from '@/mixins/NotificationMixin';
import SecurityMixin from '@/mixins/SecurityMixin';
import { getDiagnostic } from '@/models/Well';
import ConfirmationModal from '@/components/common/ConfirmationModal';
import RunInformationHeader from '@/components/run/editor/RunInformationHeader';
import CancelRun from '@/components/run/editor/CancelRun';
import Plate from '@/components/run/editor/plate/Plate';
import PlateCaptions from '@/components/run/editor/plate/PlateCaptions.vue';
import PlateCaption from '@/components/run/editor/plate/PlateCaption.vue';
import WellInterpretation from './well/WellInterpretation';
import WellExport from './well/WellExport.vue';
import RunCommentsForm from './comment/RunCommentsForm.vue';
import RunStatusUpdateModal from '@/components/run/editor/interpretation/RunStatusUpdateModal.vue';
import { isConfigurationValidAndSendEnable, sendFileFtp } from '../../../../service/SmazentechService';

export default {
  name: 'RunInterpretation',
  components: {
    'app-information-header': RunInformationHeader,
    'app-plate': Plate,
    'app-plate-captions': PlateCaptions,
    'app-plate-caption': PlateCaption,
    'app-well-interpretation': WellInterpretation,
    'app-cancel-run': CancelRun,
    'app-confirmation-modal': ConfirmationModal,
    'app-well-export': WellExport,
    'app-run-status-update-modal': RunStatusUpdateModal,
    'app-run-comments-form': RunCommentsForm
  },
  mixins: [
    NotificationMixin,
    SecurityMixin
  ],
  data() {
    return {
      wellSelection: undefined,
      action: undefined,
      newRunStatus: undefined,
      modalTitle: undefined,
      modalMessage: undefined,
      disableSelection: false,
      isFtpConfigured: false,
      isSendingFTP: false,
      plateHeight: 0,
      interpretationHeight: 0,
    };
  },
  beforeMount() {
    if (this.isDoctor) {
      isConfigurationValidAndSendEnable().then(isValid => this.isFtpConfigured = isValid);
    }
  },
  mounted() {
    this.interpretationHeight = this.getInterpretationHeight();
    let plateHeight = this.getInterpretationHeight();

    if(this.$refs?.buttonsRow) {
      plateHeight = plateHeight - this.$refs.buttonsRow.clientHeight;
    }

    this.plateHeight = plateHeight;

  },
  computed: {
    ...mapState('runImport', {
      plateType: state => state.run.plateType
    }),
    ...mapFields('runImport', [
      'run.id',
      'run.instrumentName',
      'run.plateId',
      'run.createdByName',
      'run.submittedByName',
      'run.interpretationRunByName',
      'run.status',
      'run.fileName',
      'run.businessId',
      'run.approvedBy',
      'run.rejectedBy',
      'run.wells',
      'run.comments',
      'availableSampleTypes',
      'run.ftpSent'
    ]),
    selectedWells() {
      return this.wellSelection?.map(w => this.wells.get(w.position));
    },
    isSubmitButtonDisplayed() {
      return this.status === 'RUN_TO_INTERPRET';
    },
    areApproveAndRejectButtonsDisplayed() {
      return this.status === 'WAIT_FOR_APPROVAL' && this.isDoctor;
    },
    isDoctor() {
      return this.hasRole('WRITE_DIAGNOSTIC');
    },
    canDelete() {
      return this.canDeleteRun();
    },
    chartDataLoaded() {
      return this.wells ? Array.from(this.wells.values()).some(w => !!w.chart) : false;
    },
    displayFtpButton() {
      return this.ftpSent !== true && this.isFtpConfigured && this.isDoctor && this.status === 'APPROVED';
    }
  },
  methods: {
    handleWellSelection(wells) {
      if (!wells) {
        this.$refs.plate.applySelection(undefined);
      }
      this.wellSelection = wells && wells.length ? wells.sort((well1, well2) => well1.position.localeCompare(well2.position, undefined, { sensitivity: 'accent', numeric: true  }) >= 0 ? 1 : -1) : undefined;
    },
    showRunDeletionModal() {
      this.$refs.runDeletionModal.showModal();
    },
    selectWellsByDiagnosticAndSampleType({ sampleType, diagnosticId }) {
      const wells = [];
      for (const well of this.wells.values()) {
        if (!well.sampleType) {
          continue;
        }
        if ((!sampleType || well.sampleType.equals(sampleType))
          && (!diagnosticId || getDiagnostic(well)?.id === diagnosticId)) {
          wells.push(well);
        }
      }
      this.$refs.plate.selectAll(wells);
      this.handleWellSelection(wells);
    },
    showExportWindow() {
      this.action = 'export';
    },
    hideExportWindow() {
      this.action = undefined;
    },
    showNotificationForRunComment(comments) {
      this.$store.dispatch('runImport/setRunComments', { comments });
      this.showSuccessNotification(this.$t('wellInterpretationNotificationTitle'), this.$t('wellInterpretationCommentAdded'));
    },
    submitResult() {
      this.newRunStatus = 'WAIT_FOR_APPROVAL';
      this.modalTitle = this.$t('modalSubmitResultsTitle');
      this.modalMessage = this.$t('modalSubmitResultsMessage');
      this.$refs.updateStatusModal.showModal();
    },
    approveResult() {
      this.newRunStatus = 'APPROVED';
      this.modalTitle = this.$options.filters.capitalizeAll(this.$t('approveResults'));
      this.modalMessage = this.$t('modalApproveResultsMessage');
      this.$refs.updateStatusModal.showModal();
    },
    rejectResult() {
      this.newRunStatus = 'REJECTED';
      this.modalTitle = this.$options.filters.capitalizeAll(this.$t('rejectResults'));
      this.modalMessage = this.$t('modalRejectResultsMessage');
      this.$refs.updateStatusModal.showModal();
    },
    deleteRun() {
      deleteRun(this.id)
        .then(run => {
          this.showSuccessNotification(
            this.$t('runInterpretation.deleteRun.successNotification.title'),
            this.$t('runInterpretation.deleteRun.successNotification.message', [ run.businessId ]),
            { root: true });
          this.$router.push({ name: 'run-list' });
        })
        .catch(error => {
          this.$refs.runDeletionModal.hideModal();
          this.showErrorNotification(this.$t('runInterpretation.deleteRun.errorNotification.title'), error);
        });
    },
    sendFileFtp() {
      this.isSendingFTP = true;
      sendFileFtp(this.id)
        .then(() => {
          this.ftpSent = true;
          this.showSuccessNotification(this.$t('runInterpretation.ftp.successNotification.title'), this.$t('runInterpretation.ftp.successNotification.message'));
        })
        .catch(() => {
          this.ftpSent = false;
          this.showErrorNotification(this.$t('runInterpretation.ftp.errorNotification.title'), this.$t('runInterpretation.ftp.errorNotification.message'));
        })
        .finally(() => this.isSendingFTP = false);
    },
    getInterpretationHeight() {
      let height = 0;
      if(this.$refs?.informationHeader?.$el) {
        height += this.$refs.informationHeader.$el.clientHeight;
      }

      if(this.$refs?.buttonsRow.clientHeight) {
        height += this.$refs?.buttonsRow.clientHeight;
      }
      // 100 is for paddings and nav size
      return height + 100;
    }
  }
};

</script>