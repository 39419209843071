<template>
  <div>
    <b-form-group
      v-for="comment in comments"
      :key="comment.id">
      <app-comment :comment="comment">
        <template slot="action">
          <b-button
            v-if="canEdit(comment)"
            variant="outline"
            class="btn-icon"
            @click="updateComment(comment)">
            <b-row>
              <v-icon name="pen" />
            </b-row>
          </b-button>
        </template>
        <template
          v-if="isEditingComment(comment)"
          slot="textarea">
          <b-form-textarea
            v-model="currentComment.value"
            rows="3"
            @input="emitInputEvent" />
          <div class="d-flex justify-content-between mt-3">
            <b-link
              class="fa-pull-left align-middle"
              @click="reset">
              {{ $t('cancel') }}
            </b-link>
            <b-button
              :disabled="isCommentEmpty"
              @click="save">
              {{ $t('save') }}
            </b-button>
          </div>
        </template>
      </app-comment>
    </b-form-group>
    <b-form-group
      v-if="!currentComment && canUpdate"
      ref="commentEdition"
      :label="$t('addComment')">
      <b-input-group>
        <b-form-textarea
          ref="commentInput"
          v-model="value"
          rows="3"
          @input="emitInputEvent" />
      </b-input-group>
    </b-form-group>
    <b-form-group
      v-if="!currentComment">
      <b-row class="d-flex justify-content-between align-items-center">
        <b-button
          v-if="canUpdate"
          ref="saveButton"
          :disabled="isCommentEmpty"
          @click="save">
          {{ $t('save') }}
        </b-button>
      </b-row>
    </b-form-group>
  </div>
</template>

<script>
import Comment from './Comment.vue';
import SecurityMixin from '@/mixins/SecurityMixin';
import cloneDeep from 'lodash/cloneDeep';
import {default as CommentModel} from '@/models/Comment';

export default {
  components: {
    'app-comment': Comment
  },
  mixins: [
    SecurityMixin
  ],
  props: {
    comments: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isCommentEmpty: true,
      currentComment: undefined,
      value: undefined
    };
  },
  computed: {
    canUpdate() {
      return this.comments?.length ? (this.canUpdateRun() || this.currentComment) : this.canUpdateRun();
    }
  },
  methods: {
    canEdit(comment) {
      return this.comments?.length ? this.canUpdateRun() && this.canEditComment(comment) : (!this.isCommentEmpty && this.canUpdateRun());
    },
    updateComment(comment) {
      this.currentComment = cloneDeep(comment);
    },
    isEditingComment(comment) {
      return this.currentComment?.id === comment?.id;
    },
    emitInputEvent(commentValue) {
      this.isCommentEmpty = commentValue.length === 0;
    },
    reset() {
      this.currentComment = undefined;
      this.value = undefined;
      this.isCommentEmpty = true;
    },
    save() {
      const comment = CommentModel.parse(this.currentComment ?? { value: this.value });
      this.$emit('save', comment);
    }
  }
};
</script>