<template>
  <div
    class="timeline-item"
    :class="itemClasses">
    <div
      class="timeline-dot"
      :class="dotClasses" />
    <div class="timeline-content">
      <div class="">
        {{ description }}
      </div>
      <div class="timeline-date text-muted mt-1">
        {{ date }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TimelineItem',
  props: {
    description: {
      type: String,
      default: undefined
    },
    date: {
      type: String,
      default: undefined
    },
    active: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: 'info'
    },
    deleted: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    itemClasses() {
      return {
        'active': this.active ?? false,
        'deleted': this.deleted ?? false
      };
    },
    dotClasses() {
      return {
        [`border-${this.variant ?? 'info'}`]: true
      };
    }
  }
};
</script>