<template>
  <transition
    appear
    name="fade"
    mode="out-in">
    <app-run-meta-data v-if="showStep1" />
    <app-run-wells-meta-data v-if="showStep2" />
  </transition>
</template>

<script>
import RunMetaData from './steps/run-meta-data/RunMetaData';
import RunWellsMetaData from './steps/wells-meta-data/RunWellsMetaData';
import { mapFields } from 'vuex-map-fields';
export default {
  components: {
    'app-run-meta-data': RunMetaData,
    'app-run-wells-meta-data': RunWellsMetaData
  },
  computed: {
    ...mapFields('runImport', [
      'runFormStep'
    ]),
    showStep1() {
      return this.runFormStep === 1;
    },
    showStep2() {
      return this.runFormStep === 2;
    }
  },
  beforeMount() {
    this.runFormStep = 1;
  }
};
</script>
