<template>
  <b-dropdown :text="$t('language')"
              class="m-md-2"
              size="sm"
              variant="outline"
              >
    <b-dropdown-item
        v-for="(lang, i) in langs"
        :key="`Lang${i}`"
        :value="lang.code"
        @click="changeLanguage(lang)">{{ lang.text }}</b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  data() {
    return {
      langs: [
        { code: "en-GB", text: this.$t('language', 'en-GB') },
        { code: "fr-FR", text: this.$t('language', 'fr-FR') },
      ],
    };
  },
  methods: {
    changeLanguage(lang) {
      this.$i18n.locale = lang.code;
    }
  }
};
</script>