<template>
  <div class="color-type">
    <span class="name">
      {{ name }}
    </span>
    <input
      v-model="modelColor"
      class="value"
      @keydown.enter.prevent="$emit('enter', colorSend ? colorSend : color)">
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      colorSend: undefined
    };
  },
  computed: {
    modelColor: {
      get() {
        return this.color || '';
      },
      set(val) {
        this.colorSend = val;
        this.$emit('inputColor', val);
      }
    }
  }
};
</script>

<style lang="scss">
.color-type {
  display: flex;
  font-size: 12px;
  .name {
    width: 60px;
    height: 30px;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #999;
    background: #252930;
  }
  .value {
    flex: 1;
    height: 30px;
    width: 105px;
    padding: 0 12px;
    border: 0;
    color: #fff;
    background: #2e333a;
    box-sizing: border-box;
  }
}
</style>
