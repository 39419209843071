<template>
  <div>
    <b-navbar
      class="border-bottom"
      type="light">
      <b-navbar-brand>
        <font-awesome-icon :icon="['far', 'play-circle']" />
        {{ $t('titleNewRun') }}
      </b-navbar-brand>
    </b-navbar>
    <b-container class="pt-5">
      <h3>{{ $t('subTitleImportPage') }}</h3>
      <b-form-row class="mb-4 ml-auto mr-auto">
        <b-form-group>
          <span>{{ $t('descriptionSubTitleImportPage') }}</span>
        </b-form-group>
      </b-form-row>
      <b-form-row class="mb-4 ml-auto mr-auto">
        <b-col
          lg="6">
          <h4>{{ $t('importRunSelectAnalyser') }}</h4>
          <app-analyser-dropdown
            v-model="analyser"
            :state="validateState('analyser')"
            :allow-empty="false"
            @input="resetFileInputs"
            @optionsLoaded="selectDefault" />
        </b-col>
        <b-col
          lg="6">
          <h4>{{ fileToImportTitle }}</h4>
          <app-file-inputs
            ref="fileInputs"
            v-model="files"
            :placeholder="$t('formLoadFile')"
            :max-number-of-inputs="analyser ? analyser.maxNumberOfFilesByRun : undefined"
            :min-number-of-inputs="analyser ? analyser.minNumberOfFilesByRun : undefined"
            :accepts="analyser ? analyser.extension : null"
            :state="validateState('files')" />
        </b-col>
        <b-container class="d-flex flex-row align-items-center justify-content-between p-4">
          <app-cancel-run />
          <b-button
            v-if="!isLoading"
            id="nextPageButton"
            class="text-right"
            @click="verifyRun">
            {{ $t('next') }}
          </b-button>
          <b-button
            v-if="isLoading"
            disabled>
            <b-spinner
              small
              type="grow" />
            Loading...
          </b-button>
        </b-container>
      </b-form-row>
    </b-container>
  </div>
</template>

<script>
import AnalyserDropdown from '../../common/AnalyserDropdown';
import {parseRun} from '@/service/SmazentechService';
import {required} from '@vuelidate/validators/dist/index.cjs';
import ValidationMixin from '../../../mixins/ValidationMixin';
import NotificationMixin from '@/mixins/NotificationMixin';
import FileInputs from './FileInputs';
import CancelRun from '../editor/CancelRun';

export default {
  components: {
    'app-analyser-dropdown': AnalyserDropdown,
    'app-file-inputs': FileInputs,
    'app-cancel-run': CancelRun
  },
  mixins: [
    ValidationMixin,
    NotificationMixin
  ],
  data() {
    return {
      oldRun: null,
      files: [],
      isLoading: false,
      analyser: undefined,
      acquisitionAllChannelNumberRecommended: 80
    };
  },
  computed: {
    fileToImportTitle() {
      return this.analyser && this.analyser.maxNumberOfFilesByRun > 1 ? this.$t('importRunSelectFiles') : this.$t('importRunSelectFile');
    }
  },
  beforeMount() {
    this.initFormData();
  },
  methods: {
    initFormData() {
      this.$store.dispatch('runImport/resetRunImportState');
    },
    verifyRun() {
      if (this.isValid() && this.files.length > 0) {
        this.isLoading = true;
        this.$store.dispatch('runImport/setCurrentFiles', this.files);
        parseRun(this.files, this.analyser.id)
          .then(run => {
            this.$store.dispatch('runImport/setCurrentRun', run);
            this.nextPage();
            this.isLoading = false;
          })
          .catch(error => {
            this.isLoading = false;
            this.showErrorNotification(this.$t('importRunSelectFiles'), error);
          });
      }
    },
    nextPage() {
      this.$router.push({ name: 'run-creation' });
    },
    resetFileInputs() {
      this.touch('analyser');
      this.$refs.fileInputs.reset();
    },
    selectDefault(model) {
      this.analyser = model[0];
    }
  },
  validations: {
    analyser: {
      required
    },
    files: {
      containsAtLeastOneFile: files => files.length > 0
    }
  }
};
</script>
