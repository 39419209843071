<template>
  <multiselect
    v-model="model"
    :options="options"
    :custom-label="getField"
    :placeholder="multiselectPlaceholder"
    :loading="isLoading"
    :allow-empty="allowEmpty"
    :deselect-label="!allowEmpty ? '' : undefined"
    :show-labels="showLabels"
    :open-direction="openDirection"
    :max-height="maxHeight"
    :class="cssClasses"
    :disabled="disabled" />
</template>

<script>
import DropdownMixin from '../../mixins/DropdownMixin';

export default {
  mixins: [ DropdownMixin ],
  props: {
    value: {
      type: String | Object,
      default: undefined
    },
    placeholder: {
      type: String,
      default: undefined
    },
    field: {
      type: String,
      default: undefined
    },
    translate: {
      type: Boolean,
      default: undefined
    },
    openDirection: {
      type: String,
      default: undefined
    },
    maxHeight: {
      type: Number,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    errorNotificationTitle() {
      return this.$t('kitStatusDropdownLoadingErrorTitle');
    },
    multiselectPlaceholder() {
      return this.placeholder !== undefined ? this.placeholder : this.$t('formSelectStatus');
    }
  },
  watch: {
    model(newValue) {
      // Vue can't guarantee that v-model will be executed before the v-on:change.
      // Use watchers to only call the emit once the value of the property has been changed in the component
      if (newValue) {
        this.$emit('select', newValue);
      } else {
        this.$emit('remove', newValue);
      }
    }
  },
  methods: {
    getField(option) {
      if (option) {
        if (this.field) {
          if (this.translate) {
            return this.$t(option[this.field]);
          }

          return option[this.field];
        }
        if (this.translate) {
          return this.$t(option);
        }
      }

      return option;
    }
  }
};
</script>
