export default class Kit {
  constructor(id, name, reference) {
    this.id = id;
    this.name = name;
    this.reference = reference;
  }

  static build({ id, name, reference }) {
    return new Kit(id, name, reference);
  }

  static parse(value) {
    return value ? Kit.build({
      id: value.id,
      name: value.name,
      reference: value.reference
    }) : null;
  }

  toJSON() {
    return {
      id: this.id !== undefined || this.id !== '' ? this.id : null,
      name: this.name !== undefined || this.name !== '' ? this.name : null,
      reference: this.reference !== undefined || this.reference !== '' ? this.reference : null
    };
  }
}
