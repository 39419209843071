<template>
  <b-jumbotron
    class="pb-4 pt-4"
    border-variant="dark"
    bg-variant="transparent">
    <h3>{{ $t('runCommentTitle') }}</h3>
    <app-comments-form
      ref="form"
      :comments="comments"
      @save="save" />
  </b-jumbotron>
</template>

<script>
import {createRunComment, updateComment} from '@/service/SmazentechService';
import CommentsForm from './CommentsForm.vue';

export default {
  components: {
    'app-comments-form': CommentsForm
  },
  props: {
    runId: {
      type: Number,
      default: () => undefined
    },
    comments: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    well() {
      return this.wells[0];
    }
  },
  methods: {
    save(comment) {
      let save;
      if (comment.id) {
        const comments = this.comments;
        const commentId = comment.id;
        save = updateComment(this.runId, commentId, comment.value)
          .then(updatedComment => {
            const updates = comments.slice();
            updates.splice(comments.findIndex(c => c.id === commentId), 1, updatedComment);

            return updates;
          });
      } else {
        save = createRunComment(this.runId, comment.value);
      }
      save.then(comments => {
        this.$refs.form.reset();
        this.$emit('updated', comments);
      })
        .catch(error => this.showErrorNotification(this.$t('errorCommentCreation'), error));
    }
  }
};
</script>