export default class Export {
  constructor(wellIds, format, isExportAsPlate, separator) {
    this.wellIds = wellIds;
    this.format = format;
    this.isExportAsPlate = isExportAsPlate;
    this.separator = separator;
  }

  static build({ wellIds, format, isExportAsPlate, separator }) {
    return new Export(wellIds, format, isExportAsPlate, separator);
  }

  static parse(value) {
    return value ? new Export(
      value.wellIds,
      value.format,
      value.isExportAsPlate,
      value.separator
    ) : null;
  }

  toJSON() {
    return {
      wellIds: this.wellIds,
      format: this.format,
      isExportAsPlate: this.isExportAsPlate,
      separator: this.separator
    };
  }
}