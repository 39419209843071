<template>
  <b-modal
    :id="id"
    :title="$t('modalTitleNewKit')">
    <b-form-row>
      <b-form-group
        :label="$t('formKitName')"
        label-cols="6"
        class="col">
        <b-form-input
          v-model="kit.name"
          :state="validateState('kit.name')"
          @blur="touch('kit.name')" />
      </b-form-group>
    </b-form-row>
    <b-form-row>
      <b-form-group
        :label="$t('formKitReference')"
        label-cols="6"
        class="col">
        <b-form-input
          v-model="kit.reference"
          :state="validateState('kit.reference')"
          @blur="touch('kit.reference')" />
      </b-form-group>
    </b-form-row>

    <template #modal-footer>
      <b-button variant="outline-secondary" @click="$bvModal.hide(id)">{{ $t('common.cancel') }}</b-button>
      <b-button variant="secondary" :disabled="disableOk" @click="onAddKit">{{ $t('common.saveChanges') }}</b-button>
    </template>

  </b-modal>
</template>

<script>
import NotificationMixin from '@/mixins/NotificationMixin';
import ValidationMixin from '@/mixins/ValidationMixin';
import {createKit} from '@/service/SmazentechService';
import Kit from '@/models/Kit';
import {required} from '@vuelidate/validators/dist/index.cjs';

export default {
  mixins: [
    NotificationMixin,
    ValidationMixin
  ],
  props: {

  },
  data() {
    return {
      id: 'add-kit-modal',
      kit: {
        name: undefined,
        reference: undefined
      }
    };
  },
  computed: {
    disableOk() {
      return this.v$.$invalid;
    }
  },
  validations() {
    return {
      kit: {
        name: {
          required
        },
        reference: {
          required
        }
      }
    };
  },
  methods: {
    onAddKit() {
      if (!this.isValid()) {
        return;
      }

      const kit = Kit.parse(this.kit);
      createKit(kit)
        .then(newKit => {
          this.$emit('ok', newKit);
          this.$bvModal.hide(this.id);
          this.reset();
          this.showSuccessNotification(this.$t('configuration.kit.create.notification.title'), this.$t('configuration.kit.create.notification.success_message', [ kit.name ]));
        })
        .catch(error => {
          this.showErrorNotification(this.$t('configuration.kit.create.notification.title'), error);
        });
    },
    reset() {
      this.kit.name = undefined;
      this.kit.reference = undefined;
      this.resetValidation();
    }
  }

};
</script>