<template>
  <editable-multiselect
    v-model="model"
    :options="options"
    :custom-label="customLabel"
    :placeholder="multiselectPlaceholder"
    :loading="isLoading"
    :allow-empty="allowEmpty"
    :deselect-label="!allowEmpty ? '' : undefined"
    :show-labels="showLabels"
    :class="cssClasses"
    :disabled="disabled"
    @select="$emit('select', $event)"
    @remove="$emit('remove', $event)">
    <template
      v-if="canAdd && isAdmin"
      slot="afterList">
      <span>
        <b-input
          v-model="kitName"
          class="col-lg-10 dropdown-add-input"
          @mousedown.self.stop />
        <b-button
          ref="addButton"
          variant="outline"
          class="btn--no-outline btn-icon btn-icon-plus dropdown-add-button"
          :disabled="!kitName"
          @click="addKit">
          <font-awesome-icon
            :icon="['fas', 'plus-circle']" />
        </b-button>
      </span>
    </template>
  </editable-multiselect>
</template>

<script>
import DropdownMixin from '../../mixins/DropdownMixin';
import {
  findAllKits,
  findAllKitsByAnalyserId,
  findAllKitsByAnalyserIdLinkedToLots,
  findAllKitsLinkedToLots
} from '@/service/SmazentechService';
import EditableMultiselect from '@/components/common/EditableMultiselect';
import SecurityMixin from '@/mixins/SecurityMixin';
import Kit from '@/models/Kit';

export default {
  components: {
    'editable-multiselect': EditableMultiselect
  },
  mixins: [
    DropdownMixin,
    SecurityMixin
  ],
  props: {
    value: {
      type: Object,
      default: undefined
    },
    analyserId: {
      type: Number,
      default: undefined
    },
    placeholder: {
      type: String,
      default: undefined
    },
    canAdd: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    searchLinkedToLot: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      kitName: null
    };
  },
  computed: {
    isAdmin() {
      return this.hasRole('ADMIN');
    },
    errorNotificationTitle() {
      return this.$t('kitDropdownLoadingErrorTitle');
    },
    multiselectPlaceholder() {
      return this.placeholder !== undefined ? this.placeholder : this.$t('formSelectKit');
    }
  },
  methods: {
    findOptions() {
      if (this.searchLinkedToLot) {
        return this.analyserId ? findAllKitsByAnalyserIdLinkedToLots(this.analyserId) : findAllKitsLinkedToLots();
      }

      return this.analyserId ? findAllKitsByAnalyserId(this.analyserId) : findAllKits();
    },
    customLabel(value) {
      return value.id ? value.name : this.$t(value.name);
    },
    addKit() {
      if (this.options.find(o => o.name === this.kitName)) {
        this.showErrorNotification(this.$t('KitDropdownAddKitErrorTitle'), this.$t('KitDropdownAddKitErrorMessage'));

        return;
      }
      this.$emit('addKit', this.kitName);
      this.options.push(new Kit(null, this.kitName, []));
      this.kitName = null;
    }
  }
};
</script>
